import React, { useEffect, useState } from 'react';
import Select from '../../common/select/Select';
import Range from '../../common/range/Range';
import style from './Filters.module.css';
import { SORT_BY } from '../../Constants';
import VenueBaseDateSelect from '../../common/venueBaseDateSelect/VenueBaseDateSelect';
import VenueSelector from '../../common/venueSelector/VenueSelector';

const Filters = (props) => {
  const { allowFeedback, companies, whenSelectingCompany, venues, selectedCompany, mustResetFilters, resetFilters, user } = props;
  const [sortByFilter, setSortByFilter] = useState(undefined);
  const [companiesOptions, setCompaniesOptions] = useState(undefined);

  useEffect(() => {
    if (companies === undefined) return;
    setCompaniesOptions(companies.map(company => { return { value: company.pk, name: company.displayName, selected: company.pk === selectedCompany } }));
  }, [companies, selectedCompany])

  useEffect(() => {
    if (sortByFilter === undefined) {
      let keys =  Object.keys(SORT_BY);
      if (user.daily_feed_only) {
        keys = keys.filter(key => !key.includes('score'))
      }
      setSortByFilter(keys.map(option => { return { name: option, selected: SORT_BY[option].default } }));
    }
  }, [sortByFilter])

  return (
    <div className={style.wrapper}>
      <div className={style.mainFilters}>
        {
          companiesOptions && companiesOptions.length > 0 ? (
            <Select
              name='Company'
              optionsList={companiesOptions}
              multiple={false}
              checkboxes={false}
              main={true}
              callback={whenSelectingCompany}
              extraActions={false} />
          ) : (
            null
          )
        }
        {
          venues !== undefined && venues.length > 0 ? (
            <React.Fragment>
              <VenueSelector venues={venues} multiple={false} callback={props.whenSelectingVenue} main={true} defaultVenue={props.selectedVenue} />
              <VenueBaseDateSelect venue={props.selectedVenue} date={props.selectedDate} callback={props.whenSelectingDate} main={true} />
            </React.Fragment>
          ) : (
            null
          )
        }
      </div>
      {
        venues !== undefined && venues.length > 0 ? (
          <div className={style.secondaryFilters}>
            {
              sortByFilter ? (
                <Select
                  name='Sort by'
                  optionsList={sortByFilter}
                  multiple={false}
                  checkboxes={false}
                  callback={props.sortCallback}>
                </Select>
              ) : (
                <React.Fragment />
              )
            }
            {
              props.cardsFilters.map((filter, index) => {
                if (filter.type === 'select' && filter.visible && (filter.feedbackFilter === undefined || filter.feedbackFilter === allowFeedback) && (!user.daily_feed_only || filter.dailyFeedOnly)) {
                  return <Select
                    canHighlight={true}
                    mustResetFilters={mustResetFilters}
                    key={index}
                    {...filter}
                    callback={props.addFilter}
                    icon=''>
                  </Select>
                }
                else if (filter.type === 'range' && filter.visible) {
                  return <Range
                    mustResetFilters={mustResetFilters}
                    key={index}
                    {...filter}
                    callback={props.addFilter}>
                  </Range>
                }
                else {
                  return <React.Fragment key={index} />
                }
              })
            }
            <button
              style={{
                background: '#2864c8',
                color: 'white',
                fontWeight: '700',
                fontSize: '15px',
                border: '1px solid #E1E3E6',
                padding: '6px 15px'
              }}
              onClick={() => resetFilters()}>Reset Filters</button>
          </div>
        ) : (
          null
        )
      }
    </div >
  )
}

export default Filters