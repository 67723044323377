import React, { useState, useEffect } from 'react';
import style from './Score.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SCORE_THRESHOLD } from '../../Constants';

const Score = (props) => {
  const { color, score, label, icon, callback } = props;
  const [scoreColor, setScoreColor] = useState('#FF5E48');
  const [scoreBigPart, setScoreBigPart] = useState('');
  const [scoreSmallPart, setScoreSmallPart] = useState('');

  useEffect(() => {
    if (score === '') {
      setScoreBigPart('');
      setScoreSmallPart('');
    }
    let pIndex = score.indexOf('.');
    let bIndex = score.indexOf('/');
    let index = Math.max(pIndex, bIndex);
    if (index !== -1) {
      setScoreBigPart(score.substr(0, index));
      setScoreSmallPart(score.substr(index));
    }
    else {
      setScoreBigPart(score);
      setScoreSmallPart('.00');
    }
    if (color) return;
    let temp = parseFloat(score) > (localStorage.getItem('qualityDefinitionMax') || SCORE_THRESHOLD.good) ? '#35CF6C' : parseFloat(score) > (localStorage.getItem('qualityDefinitionMin') || SCORE_THRESHOLD.regular) ? '#2864C8' : '#FF5E48';
    setScoreColor(temp);
  }, [score, color])

  const scoreWrapperStyles = {
    backgroundColor: `${color || scoreColor}19`,
    borderTop: `1px solid ${color || scoreColor}32`,
    borderBottom: `1px solid ${color || scoreColor}32`,
    borderRight: `1px solid ${color || scoreColor}32`,
    borderLeft: label ? `4px solid ${color || scoreColor}ff` : `1px solid ${color || scoreColor}32`,
    cursor: `${callback ? 'pointer' : 'auto'}`
  }

  const scoreStyles = {
    color: `${color || scoreColor}`,
  }

  const iconStyles = {
    color: `${color || scoreColor}`,
    fontSize: '14px'
  }

  return (
    <div className={`${label ? style.wrapper : style.wrapperSimple}`} style={scoreWrapperStyles} onClick={() => { callback ? callback() : console.log('') }}>
      <span className={style.label}>{label || ''}</span>
      <div className={style.main}>
        <div style={{display: 'flex'}}>
          <span className={`${style.score} ${style.big}`} style={scoreStyles}>{`${scoreBigPart}${scoreSmallPart}`}</span>
          {/* <span className={`${style.score} ${style.small}`} style={scoreStyles}>{scoreSmallPart}</span> */}
        </div>
        {
          icon ? (
            <FontAwesomeIcon style={iconStyles} icon={icon} />
          ) : (
            <div></div>
          )
        }
      </div>
    </div>
  )
}

export default Score