import React from 'react';
import style from './Table.module.css';
import Select from '../../common/select/Select';
import firstPlace from '../../../vectors/first-place.svg';
import secondPlace from '../../../vectors/second-place.svg';
import thirdPlace from '../../../vectors/third-place.svg';

const RANK = [
  firstPlace,
  secondPlace,
  thirdPlace
]

const Table = (props) => {
  const { rank, filters, title, total } = props;

  return (
    <div className={style.wrapper}>
      {
        title ? (
          <div className={style.tableHeader}>
            <span className={style.title}>
              {title}
            </span>
          </div>
        ) : (
          <React.Fragment />
        )
      }
      {
        filters ? (
          <div className={style.tableFilters}>
            {
              filters.map((filter, index) => {
                return <Select key={index} {...filter} />
              })
            }
          </div>
        ) : (
          <React.Fragment />
        )
      }
      <div className={style.tableWrapper} style={{ ...props.style }}>
        <table className={`${style.table} ${total? style.tableWithTotal: ''}`}>
          <tbody>
            <tr>
              {
                props.header.map((colName, index) => {
                  return <th key={index}>{colName}</th>
                })
              }
            </tr>
            {
              props.data.map((row, indexR) => {
                return <tr key={indexR}>
                  {
                    row.map((col, indexC) => {
                      let text = typeof col === 'object' ? col.text : col;
                      let img = typeof col === 'object' ? col.img : undefined;
                      let customStyle = (col && col.style) ?? {};
                      let customImgStyle = (col && col.imgStyle) ?? {};
                      return (
                        text === undefined && img ? (
                          <img src={img} alt={''} style={{ ...customStyle }} />
                        ) : (
                          <td key={indexC} style={{ ...customStyle }}>
                            <div className={style.tableData}>
                              {
                                rank ? (
                                  indexC === 0 ? (
                                    RANK[indexR] ? (
                                      <img src={RANK[indexR]} alt='Podium' style={{ marginRight: '17px', height: '16px' }} />
                                    ) : (
                                      <img style={{ width: '18px', marginRight: '17px' }} alt='' />
                                    )
                                  ) : (
                                    <React.Fragment />
                                  )
                                ) : (
                                  <React.Fragment />
                                )
                              }
                              {img ? (
                                <img src={img} alt={''} style={{ ...customImgStyle }} />
                              ) : (
                                <React.Fragment />
                              )}
                              {text}
                            </div>
                          </td>
                        )
                      )
                    })
                  }
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Table;