const getGranularitySelector = (selection, whenChangingGranularity) => {
  return {
    label: 'Monthly view',
    optionsList: [
      { name: 'Daily view', value: 'daily', selected: selection === 'daily' ? true : false },
      { name: 'Weekly view', value: 'weekly', selected: selection === 'weekly' ? true : false },
      { name: 'Monthly view', value: 'monthly', selected: selection === 'monthly' ? true : false }
    ],
    callback: (name, selection) => {
      if (selection.length !== 1) return;
      whenChangingGranularity(selection[0].value)
    }
  }
}

const getQualitySelector = (selection, whenChangingGranularity) => {
  return {
    label: 'Bad pizzas',
    optionsList: [
      { name: 'Bad pizzas', value: 'Bad', selected: selection === 'Bad' ? true : false },
      { name: 'Good pizzas', value: 'Good', selected: selection === 'Good' ? true : false },
      { name: 'Excellent pizzas', value: 'Excellent', selected: selection === 'Excellent' ? true : false }
    ],
    callback: (name, selection) => {
      if (selection.length !== 1) return;
      whenChangingGranularity(selection[0].value)
    }
  }
}

const BADS_GOODS_EXCELLENTS_SELECTOR = {
  name: '',
  label: 'Metric',
  optionsList: [
    { name: 'Bad pizzas', value: 'Bad', selected: true },
    { name: 'Good pizzas', value: 'Good' },
    { name: 'Excellent pizzas', value: 'Excellent' }
  ]
}

const parseDateRange = (dateRange) => {
  return [`${dateRange.min.day}-${dateRange.min.month}-${dateRange.min.year}`, `${dateRange.max.day}-${dateRange.max.month}-${dateRange.max.year}`]
}

const parseDate = (date) => {
  return `${date.day}-${date.month}-${date.year}`
}

const fixPeriodName = (name) => {
  return `${name.split('-')[1]}-${name.split('-')[0]}`
}

const getDayOfYear = (date) => {
  let start = new Date(date.getFullYear(), 0, 0);
  let diff = (date - start) + ((start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000);
  let oneDay = 1000 * 60 * 60 * 24;
  let day = Math.floor(diff / oneDay);
  return day
}

const getLastNDay = (d, n) => {
  var t = new Date(d);
  t.setDate(t.getDate() - (t.getDay() + n));
  return t;
}

const getGranularityCats = (dateRange, dictData, granularity, subcats, totalKey) => {
  let conf = {
    data: [],
    cats: [],
    auxCats: [],
    tickText: [],
    tickVals: []
  };
  let maxDate = new Date(dateRange.max.year, dateRange.max.month - 1, dateRange.max.day);
  let minDate = new Date(dateRange.min.year, dateRange.min.month - 1, dateRange.min.day);
  if (granularity === 'monthly') {
    minDate.setDate(1);
    maxDate.setMonth(maxDate.getMonth() + 1);
    maxDate.setDate(1);
    maxDate.setDate(maxDate.getDate() - 1);
    for (; minDate <= maxDate; minDate.setMonth(minDate.getMonth() + 1)) {
      let cat = `${minDate.toLocaleString('default', { month: 'long' }).substring(0, 3)} ${minDate.getFullYear()}`
      let auxCat = `${(minDate.getMonth() + 1).toString().padStart(2, '0')}-${minDate.getFullYear()}`;
      conf.cats.push(cat);
      conf.auxCats.push(auxCat);
    }
  }
  else if (granularity === 'weekly') {
    if (getDayOfYear(maxDate) > 0 && getDayOfYear(maxDate) < 8) maxDate.setDate(maxDate.getDate() + 7);
    // Locate prev monday to the current range min value
    let daysToPrevMonday = minDate.getUTCDay() === 0 ? 6 : minDate.getUTCDay() === 1 ? 0 : minDate.getUTCDay() - 1;
    minDate.setUTCDate(minDate.getUTCDate() - daysToPrevMonday)
    let lastWeekID = -1,
      year = -1,
      msDiff = 0,
      week = 0,
      daysDiff = 0,
      firstDayOfYear = new Date(minDate.getUTCFullYear(), 0, 1);

    for (; minDate <= maxDate; minDate.setDate(minDate.getUTCDate() + 1)) {
      // For every new year find first monday
      if (minDate.getUTCFullYear() !== year) {
        year = minDate.getUTCFullYear();
        firstDayOfYear = new Date(minDate.getUTCFullYear(), 0, 1);
        let weekDay = firstDayOfYear.getDay();
        let daysToMonday = weekDay === 1 ? 0 : weekDay === 0 ? 1 : (8 - weekDay);
        firstDayOfYear.setDate(firstDayOfYear.getUTCDate() + daysToMonday);
      }
      msDiff = minDate - firstDayOfYear;
      // Days before first monday are consider part of week 0
      if (msDiff < 0) {
        week = 0;
      }
      else {
        daysDiff = Math.ceil(msDiff / (1000 * 60 * 60 * 24))
        week = Math.floor(daysDiff / 7) + 1
      }
      if (lastWeekID !== week) {
        let cat = `${minDate.toLocaleString('default', { month: 'long' }).substring(0, 3)} ${minDate.getDate().toString().padStart(2, '0')} ${minDate.getFullYear()}`;
        let auxCat = `${(week).toString().padStart(2, '0')}-${minDate.getFullYear()}`;
        conf.cats.push(cat);
        conf.auxCats.push(auxCat);
        lastWeekID = week;
      }
    }
  }
  else if (granularity === 'daily') {
    for (; minDate <= maxDate; minDate.setDate(minDate.getDate() + 1)) {
      let cat = `${minDate.toLocaleString('default', { month: 'long' }).substring(0, 3)} ${minDate.getDate().toString().padStart(2, '0')} ${minDate.getFullYear()}`;
      let auxCat = `${(getDayOfYear(minDate)).toString().padStart(3, '0')}-${minDate.getFullYear()}`;
      conf.cats.push(cat);
      conf.auxCats.push(auxCat);
    }
  }
  for (let cat of conf.auxCats) {
    if (subcats) {
      conf.data.push([]);
      for (let subcat of subcats) {
        conf.data[conf.data.length - 1].push(dictData[cat] ? (dictData[cat][totalKey] * (dictData[cat][subcat] / 100)).commaRepresentation(0) : undefined)
      }
    }
    else {
      conf.data.push([dictData[cat] ?? undefined])
    }
  }
  if (conf.cats.length >= 15) {
    let step = Math.round(conf.cats.length / 8);
    for (let i = conf.cats.length - 1; i >= 0; i -= step) {
      conf.tickVals.push(conf.cats[i]);
      conf.tickText.push(conf.cats[i]);
    }
  }
  return conf;
}

const HEATMAP_COLORSCALE = {
  common: [
    ['0.0', '#B2C7EB'],
    ['0.2', '#7FA3DE'],
    ['0.4', '#5987D4'],
    ['0.6', '#3E74CE'],
    ['0.8', '#2E68CA'],
    ['1.0', '#2864C8'],
  ],
  bad: [
    ['0.0', '#FDD9D7'],
    ['0.2', '#FBBBB6'],
    ['0.4', '#F99D96'],
    ['0.6', '#F88077'],
    ['0.8', '#F66156'],
    ['1.0', '#F44336'],
  ],
  good: [
    ['0.0', '#FCF0CD'],
    ['0.2', '#F9E4A5'],
    ['0.4', '#F6D87D'],
    ['0.6', '#F4CC56'],
    ['0.8', '#F1C02E'],
    ['1.0', '#EEB406'],
  ],
  excellent: [
    ['0.0', '#CCF1CD'],
    ['0.2', '#A3E5A5'],
    ['0.4', '#7ADA7E'],
    ['0.6', '#52CF57'],
    ['0.8', '#29C42F'],
    ['1.0', '#00B807'],
  ]
}

export {
  BADS_GOODS_EXCELLENTS_SELECTOR,
  HEATMAP_COLORSCALE,
  getGranularitySelector,
  getQualitySelector,
  parseDateRange,
  parseDate,
  fixPeriodName,
  getGranularityCats,
  getLastNDay
}