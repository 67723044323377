import { FetchService } from '../../../services/Api';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import reportStyle from '../Report.module.css';
import style from './Performance.module.css';
import 'react-input-range/lib/css/index.css';
import { parseDateRange } from '../Aux';
import VenuePizzasDistributionComparison from '../charts/VenuePizzasDistributionComparison';
import VenueQualityDistribution from '../charts/VenueQualityDistribution';
import VenueAvgScoreDistribution from '../charts/VenueAvgScoreDistribution';
import VenueShiftPerformance from '../charts/VenueShiftPerformance';
import VenueQualityBySpecialty from '../charts/VenueQualityBySpecialty';


const Performance = (props) => {
  const { language, selectedVenue, dateRange, whenContentUpdateStarts, whenContentUpdateEnds, staffUser } = props;
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const [data, setData] = useState(undefined);
  const [summaryChartData, setSummaryChartData] = useState(undefined);

  useEffect(() => {
    if (data === undefined) return;
    setSummaryChartData({ scored_pizzas: data.summary.scored_pizzas, total_pizzas: data.summary.total_pizzas, best_score: data.summary.best_score, avg_score: data.summary.avg_score })
  }, [data])

  const fetchSummary = useCallback(() => {
    if (selectedVenue === undefined) return;
    setData(undefined);
    let requestConf = {
      endpoint: `analytics/performance/summary/venue/${selectedVenue}/start_date/${parseDateRange(dateRange)[0]}/end_date/${parseDateRange(dateRange)[1]}/`,
      method: 'GET',
      data: {}
    }
    FetchService(requestConf).then(
      (response) => {
        if (!mountedRef.current) {
          return;
        }
        else {
          setData(response.data);
        }
      },
      (err) => {
      }
    ).catch(err => console.log(err));
  }, [selectedVenue, dateRange])

  useEffect(() => {
    fetchSummary();
  }, [fetchSummary])

  const [venueAvgScoreDistributionUrl, setVenueAvgScoreDistributionUrl] = useState(undefined);

  const [renderingVenueQualityDistribution, setRenderingVenueQualityDistribution] = useState(undefined);
  const [renderingVenueAvgScoreDistribution, setRenderingVenueAvgScoreDistribution] = useState(undefined);
  const [renderingVenuePizzasDistribution, setRenderingVenuePizzasDistribution] = useState(undefined);
  const [renderingVenueShiftPerformance, setRenderingVenueShiftPerformance] = useState(undefined);
  const [renderingVenueQualityBySpecialty, setRenderingVenueQualityBySpecialty] = useState(undefined);

  useEffect(() => {
    setVenueAvgScoreDistributionUrl(`start_date/${parseDateRange(dateRange)[0]}/end_date/${parseDateRange(dateRange)[1]}/`);
  }, [dateRange])

  useEffect(() => {
    if (renderingVenueAvgScoreDistribution && renderingVenueQualityDistribution && renderingVenuePizzasDistribution && whenContentUpdateEnds && renderingVenueShiftPerformance && renderingVenueQualityBySpecialty && summaryChartData) whenContentUpdateEnds();
  }, [renderingVenueAvgScoreDistribution, renderingVenueQualityDistribution, renderingVenuePizzasDistribution, renderingVenueShiftPerformance, renderingVenueQualityBySpecialty, whenContentUpdateEnds, summaryChartData])

  useEffect(() => {
    whenContentUpdateStarts()
    if (selectedVenue === undefined || dateRange === undefined) return;
    setSummaryChartData(undefined);
  }, [selectedVenue, dateRange, whenContentUpdateStarts])

  return (
    <div>
      {
        summaryChartData ? (
          <div>
            <div className={style.featuresRow}>
              <div className={style.feature}>
                <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                  <span className={style.featureValue} style={{ color: '#2864C8' }}>{parseFloat(summaryChartData.scored_pizzas).commaRepresentation(0)}</span>
                  <span className={style.featureLabel}>Scored pizzas</span>
                </div>
              </div>
              <div className={style.feature}>
                <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                  <span className={style.featureValue} style={{ color: '#F44336' }}>{parseFloat(summaryChartData.total_pizzas).commaRepresentation(0)}</span>
                  <span className={style.featureLabel}>Detected pizzas</span>
                </div>
              </div>
              <div className={style.feature}>
                <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                  <span className={style.featureValue} style={{ color: '#00B807' }}>{parseFloat(summaryChartData.best_score).commaRepresentation(2)}</span>
                  <span className={style.featureLabel}>Best score</span>
                </div>
              </div>
              <div className={style.feature}>
                <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                  <span className={style.featureValue} style={{ color: '#EEB406' }}>{parseFloat(summaryChartData.avg_score).commaRepresentation(2)}</span>
                  <span className={style.featureLabel}>Average score</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )
      }
      <div className={reportStyle.col2}>
        <VenueQualityDistribution
          data={data}
          selectedVenue={selectedVenue}
          dateRange={dateRange}
          onRendering={setRenderingVenueQualityDistribution} />
        <VenueAvgScoreDistribution
          url={venueAvgScoreDistributionUrl}
          staffUser={staffUser}
          selectedVenue={selectedVenue}
          dateRange={dateRange}
          onRendering={setRenderingVenueAvgScoreDistribution} />
      </div>
      <div>
        <VenuePizzasDistributionComparison
          data={data}
          dateRange={dateRange}
          selectedVenue={selectedVenue}
          onRendering={setRenderingVenuePizzasDistribution} />
      </div>
      <div className={reportStyle.col1}>
        <VenueShiftPerformance
          data={data}
          selectedVenue={selectedVenue}
          dateRange={dateRange}
          onRendering={setRenderingVenueShiftPerformance} />
      </div>
      <div className={reportStyle.col1}>
        <VenueQualityBySpecialty
          data={data}
          selectedVenue={selectedVenue}
          language={language}
          onRendering={setRenderingVenueQualityBySpecialty} />
      </div>
    </div>
  )
}

export default Performance