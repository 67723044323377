import { FetchService } from '../../../services/Api';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import BasicChart from '../basicChart/BasicChart';
import { getGranularitySelector, getGranularityCats, getQualitySelector } from '../Aux';
import { useChartRendering } from '../../../hooks/useChartRendering';


const VenueShiftPerformance = (props) => {
  const { url, selectedVenue, dateRange, data } = props;
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const [completedUrl, setCompletedUrl] = useState(undefined);
  const [rawData, setRawData] = useState(undefined);
  const [chartData, setChartData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [metric, setMetric] = useState('Bad');
  const [metricSelector, setMetricSelector] = useState(undefined);
  useEffect(() => {
    setMetricSelector({ ...getQualitySelector(metric, setMetric) })
  }, [metric])
  const [granularity, setGranularity] = useState('weekly')
  const [granularitySelector, setGranularitySelector] = useState({});
  useEffect(() => {
    setGranularitySelector({ ...getGranularitySelector(granularity, setGranularity) })
  }, [granularity])
  const [chartYaxisBoundaries, setChartYaxisBoundaries] = useState({ min: 0, max: 1.05 });

  useChartRendering(chartData, props.onRendering);

  useEffect(() => {
    if (data !== undefined) setRawData(data.quality_by_shift);
    else setChartData(undefined);
  }, [data])

  useEffect(() => {
    if (rawData === undefined) return;
    let subcats = ['AM', 'PM'];
    let dictData = {};
    let data = [];
    let max = 0;
    let min = 1;
    for (let period of rawData[granularity]) {
      let val = parseFloat(period.AM[`pct_${metric.toLowerCase()}s`]) / 100;
      dictData[period.datetime] = val;
      max = Math.max(max, val);
      min = Math.min(min, val);
    }
    let conf1 = getGranularityCats(dateRange, dictData, granularity);
    dictData = {};
    for (let period of rawData[granularity]) {
      let val = parseFloat(period.PM[`pct_${metric.toLowerCase()}s`]) / 100;
      dictData[period.datetime] = val;
      max = Math.max(max, val);
      min = Math.min(min, val);
    }
    let conf2 = getGranularityCats(dateRange, dictData, granularity);
    for (let i = 0; i < conf1.data.length; i++) {
      data.push([conf1.data[i][0], conf2.data[i][0]])
    }
    let median = (max + min) / 2.0;
    let offset = (max - median) * 1.15;
    setChartYaxisBoundaries({ min: median - offset, max: median + offset });
    setChartData({ cats: conf1.cats, subcats: subcats, data: data, customXAxis: { tickVals: conf1.tickVals, tickText: conf1.tickText } })
  }, [rawData, granularity, metric, dateRange])

  useEffect(() => {
    if (url === undefined || selectedVenue === undefined || granularity === undefined) return;
    setCompletedUrl(`analytics/performance/shift_quality/venue/${selectedVenue}/type/${granularity}/${url}`)
  }, [url, selectedVenue, granularity])

  useEffect(() => {
    if (url === undefined) return;
    setChartData(undefined);
  }, [url])

  const fetchShiftQualityDistribution = useCallback(() => {
    if (completedUrl === undefined) return;
    setLoading(true);
    let requestConf = {
      endpoint: completedUrl,
      method: 'GET',
      data: {
      }
    }
    FetchService(requestConf).then(
      (response) => {
        if (!mountedRef.current) {
          return;
        }
        else {
          setRawData(response.data);
          setLoading(false);
        }
      },
      (err) => {
        setLoading(false);
      }
    ).catch(err => console.log(err));
  }, [completedUrl])

  useEffect(() => {
    fetchShiftQualityDistribution();
  }, [fetchShiftQualityDistribution])

  return (
    chartData ? (
      <BasicChart
      type='Scater'
      title={`Shift peformance % ${metric} pizzas`}
      height={500 - 70}
      customXAxis={chartData.customXAxis}
      cats={chartData.cats}
      subcats={chartData.subcats}
      data={chartData.data}
      subcatsColors={metric === 'Bad' ? ['#F66156', '#F99D96'] : metric === 'Good' ? ['#F1C02E', '#F6D87D'] : ['#29C42F', '#7ADA7E']}
      filters={[granularitySelector, metricSelector]}
      loading={loading}
      showYTickLabels={true}
      legend={true}
      yrange={[chartYaxisBoundaries.min, chartYaxisBoundaries.max]}
      tickFormat=',.0%' />
    ) : (
      <React.Fragment />
    )
  )
}

export default VenueShiftPerformance;