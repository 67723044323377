import { FetchService } from '../../../services/Api';
import React, { useState, useEffect, useRef } from 'react';
import BasicChart from '../basicChart/BasicChart';
import Table from '../table/Table';
import reportStyle from '../Report.module.css';
import { getSpecialtiesByLanguage, INGREDIENT_ICON } from '../../Constants';
import 'react-input-range/lib/css/index.css';
import { parseDateRange } from '../Aux';


const PortionControl = (props) => {
  const { language, selectedVenue, dateRange, whenContentUpdateStarts, whenContentUpdateEnds } = props;
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => { 
      mountedRef.current = false
    }
  }, [])

  const [portionControl, setPortionControl] = useState(undefined);
  const [portionControlChartData, setPortionControlChartData] = useState(undefined);

  const [specialtyPortionControl, setSpecialtyPortionControl] = useState(undefined);
  const [specialtyPortionControlChartData, setSpecialtyPortionControlChartData] = useState(undefined);

  const [shiftPortionControl, setShiftPortionControl] = useState(undefined);
  const [shiftPortionControlChartData, setShiftPortionControlChartData] = useState(undefined);

  useEffect(() => {
    if (portionControl === undefined || Object.keys(portionControl).length === 0) return;
    let data = [];
    let sortedIngredients = Object.keys(portionControl).sort((a, b) => (a > b) ? -1 : (b > a) ? 1 : 0);
    for (let ingredient of sortedIngredients) {
      let overUsed = portionControl[ingredient].over_used * 100;
      let overSpent = portionControl[ingredient].over_spent;
      let overUsedColor = overUsed > 0 ? '#F44336' : '#00B807';
      data.push([{ img: INGREDIENT_ICON[ingredient], imgStyle: { width: '35px' }, text: ingredient, style: { color: '#11111B' } }, { text: `$ ${overSpent.commaRepresentation(2)}`, style: { color: overUsedColor } }, { text: `${overUsed.commaRepresentation(0)} %`, style: { color: overUsedColor } }])
    }
    setPortionControlChartData({ title: 'Food usage vs recipes', header: ['Ingredient', '$ Over spent', '% Over used'], data: data })
  }, [portionControl])

  useEffect(() => {
    if (specialtyPortionControl === undefined || Object.keys(specialtyPortionControl).length === 0) return;
    let data = [];
    for (let specialty of Object.keys(specialtyPortionControl)) {
      let overUsedAM = specialtyPortionControl[specialty].AM.over_used * 100;
      let overUsedPM = specialtyPortionControl[specialty].PM.over_used * 100;
      let overSpentAM = specialtyPortionControl[specialty].AM.over_spent;
      let overSpentPM = specialtyPortionControl[specialty].PM.over_spent;
      let overUsedAMColor = overUsedAM > 0 ? '#F44336' : '#00B807';
      let overUsedPMColor = overUsedPM > 0 ? '#F44336' : '#00B807';
      let overUsedColor = overUsedAM > 0 || overUsedPM > 0 ? '#F44336' : '#00B807';
      data.push([
        { text: getSpecialtiesByLanguage(language)[specialty], style: { color: '#11111B' } },
        { text: `$ ${(overSpentAM + overSpentPM).commaRepresentation(2)}`, style: { color: overUsedColor } },
        { text: `${overUsedAM.commaRepresentation(0)} %`, style: { color: overUsedAMColor } },
        { text: `${overUsedPM.commaRepresentation(0)} %`, style: { color: overUsedPMColor } }
      ])
    }
    setSpecialtyPortionControlChartData({ title: 'Recipe accuracy by shift', header: ['Specialty', '$ Spent', 'AM', 'PM'], data: data })
  }, [specialtyPortionControl, language])

  useEffect(() => {
    if (shiftPortionControl === undefined || Object.keys(shiftPortionControl).length === 0) return;
    let data = [];
    let cats = Object.keys(shiftPortionControl).sort((a, b) => a > b ? 1 : b > a ? -1 : 0);
    for (let shift of cats) {
      data.push([1, shiftPortionControl[shift].over_used])
    }
    setShiftPortionControlChartData({ cats: cats, subcats: ['Normal', 'Extra'], data: data })
  }, [shiftPortionControl])

  useEffect(() => {
    setPortionControlChartData(undefined);
    let requestConf = {
      endpoint: `analytics/portion_control/venue/${selectedVenue}/start_date/${parseDateRange(dateRange)[0]}/end_date/${parseDateRange(dateRange)[1]}/`,
      method: 'GET',
      data: {}
    }

    FetchService(requestConf).then(
      (response) => {
        if (Object.keys(response.data).length === 0) {
          if (!mountedRef.current) {
            return;
          }
          else {
            whenContentUpdateEnds();
          }
        }
        else {
          if (!mountedRef.current) {
            return;
          }
          else {
            setPortionControl(response.data.ingredients);
            setSpecialtyPortionControl(response.data.specialty);
            setShiftPortionControl(response.data.shift);
          }
        }
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }, [dateRange, selectedVenue, whenContentUpdateEnds])

  useEffect(() => {
    if (portionControlChartData && specialtyPortionControl && shiftPortionControl && whenContentUpdateEnds) whenContentUpdateEnds();
  }, [portionControlChartData, specialtyPortionControl, shiftPortionControl, whenContentUpdateEnds])

  useEffect(() => {
    whenContentUpdateStarts()
    if (selectedVenue === undefined || dateRange === undefined) return;
    setPortionControlChartData(undefined);
    setSpecialtyPortionControlChartData(undefined);
    setShiftPortionControlChartData(undefined);
  }, [selectedVenue, dateRange, whenContentUpdateStarts])

  return (
    <div>
      <div className={reportStyle.col2}>
        {
          portionControlChartData ? (
            <Table
              title={portionControlChartData.title}
              header={portionControlChartData.header}
              data={portionControlChartData.data} />
          ) : (
            <React.Fragment />
          )
        }
        {
          shiftPortionControlChartData ? (
            <BasicChart
              title={'Ingredients usage by shift'}
              type='bar'
              tickFormat=',.0%'
              height={500 - 70}
              showYTickLabels={true}
              cats={shiftPortionControlChartData.cats}
              subcats={shiftPortionControlChartData.subcats}
              data={shiftPortionControlChartData.data}
              subcatsColors={['#CDCCCC', '#FC5245B2']}
              legend={true} />
          ) : (
            <React.Fragment />
          )
        }
      </div>
      <div className={reportStyle.col1}>
        {
          specialtyPortionControlChartData ? (
            <Table
              title={specialtyPortionControlChartData.title}
              header={specialtyPortionControlChartData.header}
              data={specialtyPortionControlChartData.data} />
          ) : (
            <React.Fragment />
          )
        }
      </div>
    </div>
  )
}

export default PortionControl;