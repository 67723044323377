import React, { useState, useCallback } from 'react';
import style from './Report.module.css';
import Header from '../header/Header';
import { useParams } from 'react-router-dom';
import Production from './production/Production';
import Performance from './performance/Performance';
import PortionControl from './portionControl/PortionControl';
import Compare from './compare/Compare';
import Leaderboard from './leaderboard/Leaderboard';
import LoadingScreen from '../common/loadingScreen/LoadingScreen';

const Report = (props) => {
  const { language, venues, selectedVenue, selectedDateRange, parsedSelectedDateRange, whenSelectingVenue, whenSelectingDateRange, user } = props;
  const params = useParams();
  const staffUser = user && user.employer && user.employer.pk === 'Kwali'
  const breadcrumb = [{ name: 'Reports' }, { name: params.reportPage.replace('-', ' ') }];
  const [loading, setLoading] = useState(false);

  const displayLoading = useCallback(() => {
    setLoading(true)
  }, [])

  const hideLoading = useCallback(() => {
    setLoading(false)
  }, [])

  return (
    <LoadingScreen loading={loading}>
      <div className={style.wrapper}>
        <Header
          breadcrumb={breadcrumb}
          pageTitle={params.reportPage.replace('-', ' ')}
          venues={venues}
          customPeriods={params.reportPage === 'leaderboard'}
          selectedVenue={selectedVenue}
          selectedDateRange={selectedDateRange}
          whenSelectingVenue={['leaderboard', 'compare'].includes(params.reportPage) ? undefined : whenSelectingVenue}
          whenSelectingDateRange={whenSelectingDateRange} />
        <div className={style.pageContent}>
          {
            params.reportPage === 'portion-control' ? (
              selectedVenue && parsedSelectedDateRange ? (
                <PortionControl language={language} selectedVenue={selectedVenue} dateRange={parsedSelectedDateRange} whenContentUpdateStarts={displayLoading} whenContentUpdateEnds={hideLoading} />
              ) : (
                <React.Fragment />
              )
            ) : (
              params.reportPage === 'performance' ? (
                selectedVenue && parsedSelectedDateRange ? (
                  <Performance
                    language={language}
                    selectedVenue={selectedVenue}
                    dateRange={parsedSelectedDateRange}
                    staffUser={staffUser}
                    whenContentUpdateStarts={displayLoading}
                    whenContentUpdateEnds={hideLoading} />
                ) : (
                  <React.Fragment />
                )
              ) : (
                params.reportPage === 'production' ? (
                  selectedVenue && parsedSelectedDateRange ? (
                    <Production selectedVenue={selectedVenue} dateRange={parsedSelectedDateRange} whenContentUpdateStarts={displayLoading} whenContentUpdateEnds={hideLoading} />
                  ) : (
                    <React.Fragment />
                  )
                ) : (
                  params.reportPage === 'leaderboard' ? (
                    parsedSelectedDateRange ? (
                      <Leaderboard
                        staffUser={staffUser}
                        dateRangeMin={parsedSelectedDateRange.min}
                        dateRangeMax={parsedSelectedDateRange.max}
                        whenContentUpdateStarts={displayLoading}
                        whenContentUpdateEnds={hideLoading} />
                    ) : (
                      <React.Fragment />
                    )
                  ) : (
                    params.reportPage === 'compare' ? (
                      parsedSelectedDateRange ? (
                        <Compare
                          language={language}
                          venues={venues}
                          selectedVenue={selectedVenue}
                          dateRangeMin={parsedSelectedDateRange.min}
                          dateRangeMax={parsedSelectedDateRange.max}
                          whenContentUpdateStarts={displayLoading}
                          whenContentUpdateEnds={hideLoading} />
                      ) : (
                        <React.Fragment />
                      )
                    ) : (
                      <React.Fragment />
                    )
                  )
                )
              )
            )
          }
        </div>
      </div>
    </LoadingScreen>
  )
}

export default Report