const TEST_DATA = {
  "vids_and_results": [
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64819c61fa287a55d0dd72a1",
        "REPORT_capture_start_ts": "2023/06/08 09:01:23"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "64819ebb12502ec08c1d80be",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64819c61fa287a55d0dd72a1",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "6481fe170183b1c417b90f6d",
        "REPORT_capture_start_ts": "2023/06/08 16:01:30"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "6482016712502ec08c1d80bf",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "6481fe170183b1c417b90f6d",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648200781312420a5ca5e1de",
        "REPORT_capture_start_ts": "2023/06/08 16:11:31"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "REPORT_localized_capture_start_ts": "12:11:47 2023/06/08",
            "optimal_frame_quality": {
              "blur": 0.0011445976560935378,
              "occ": 0.019519435241818428
            },
            "bubbles": null,
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_12:11:31_v9p1-ssd-dense_id0001(None)-fr0129-00:16.jpg",
            "snap_quality": "buena",
            "multisize": false,
            "raw_score": false,
            "snap_quality_trusted": true,
            "original_data": {
              "result": {
                "score_details": {
                  "Ingredientes interior": {
                    "1": 0.25,
                    "2": 0.25,
                    "3": 0.25,
                    "4": 0.25,
                    "5": 0.25,
                    "6": 0.25,
                    "7": 0.25,
                    "8": 0.25
                  },
                  "Queso interior": {
                    "1": 0.1,
                    "2": 0.1,
                    "3": 0.1,
                    "4": 0.1,
                    "5": 0.1,
                    "6": 0.1,
                    "7": 0.1,
                    "8": 0.1
                  },
                  "Ingredientes exterior": {
                    "1": 0,
                    "2": 0.25,
                    "3": 0,
                    "4": 0,
                    "5": 0,
                    "6": 0,
                    "7": 0,
                    "8": 0
                  },
                  "Bordes": {
                    "1": 0.1,
                    "2": 0,
                    "3": 0,
                    "4": 0,
                    "5": 0,
                    "6": 0,
                    "7": 0.1,
                    "8": 0.1
                  },
                  "Cocción queso": {
                    "1": 0.1,
                    "2": 0.1,
                    "3": 0.1,
                    "4": 0.1,
                    "5": 0.1,
                    "6": 0.1,
                    "7": 0.1,
                    "8": 0.1
                  },
                  "Queso exterior": {
                    "1": 0.1,
                    "2": 0.1,
                    "3": 0.1,
                    "4": 0.1,
                    "5": 0,
                    "6": 0,
                    "7": 0.1,
                    "8": 0.1
                  },
                  "Sello de queso": {
                    "1": 0.1,
                    "2": 0,
                    "3": 0.1,
                    "4": 0.1,
                    "5": 0,
                    "6": 0,
                    "7": 0.1,
                    "8": 0.1
                  },
                  "Limpieza": {
                    "1": 0.1,
                    "2": 0.1,
                    "3": 0.1,
                    "4": 0.1,
                    "5": 0.1,
                    "6": 0.1,
                    "7": 0.1,
                    "8": 0.1
                  }
                },
                "score_final": 7.25,
                "specialty_check": false,
                "score_adjusted": 7.249999999999998
              }
            },
            "overbaked": null,
            "id": 1,
            "standard": "to_standard",
            "category": "napolitana",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_12:11:31_v9p1-ssd-dense_id0001(None)-fr0129-00:16.jpg",
            "REPORT_frame_detection_summary": {},
            "censored": null,
            "attributes": null,
            "omit_soft": true,
            "bad_cut": null,
            "optimal_figure": {
              "coords": [
                1755,
                1383,
                506
              ],
              "__type__": "Circle"
            },
            "REPORT_detections_categ_summary": [],
            "optimal_frame": 129,
            "reviewer": "rodrigo.pulcha",
            "crust": "tradicional"
          },
          {
            "REPORT_localized_capture_start_ts": "12:12:13 2023/06/08",
            "optimal_frame_quality": {
              "blur": 0.007160950917750597,
              "occ": 0.018619736656546593
            },
            "bubbles": 0,
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_12:11:31_v9p1-ssd-dense_id0002(None)-fr0340-00:42.jpg",
            "snap_quality": "buena",
            "multisize": false,
            "raw_score": false,
            "snap_quality_trusted": true,
            "original_data": {
              "result": {
                "score_details": {
                  "Ingredientes interior": {
                    "1": 0.25,
                    "2": 0.25,
                    "3": 0.25,
                    "4": 0.25,
                    "5": 0.25,
                    "6": 0.25,
                    "7": 0.25,
                    "8": 0.25
                  },
                  "Queso interior": {
                    "1": 0.1,
                    "2": 0.1,
                    "3": 0.1,
                    "4": 0.1,
                    "5": 0.1,
                    "6": 0.1,
                    "7": 0.1,
                    "8": 0.1
                  },
                  "Ingredientes exterior": {
                    "1": 0.25,
                    "2": 0.25,
                    "3": 0.25,
                    "4": 0.25,
                    "5": 0.25,
                    "6": 0.25,
                    "7": 0.25,
                    "8": 0.25
                  },
                  "Bordes": {
                    "1": 0.1,
                    "2": 0.1,
                    "3": 0,
                    "4": 0,
                    "5": 0,
                    "6": 0,
                    "7": 0.1,
                    "8": 0.1
                  },
                  "Cocción queso": {
                    "1": 0.1,
                    "2": 0.1,
                    "3": 0.1,
                    "4": 0.1,
                    "5": 0.1,
                    "6": 0.1,
                    "7": 0.1,
                    "8": 0.1
                  },
                  "Queso exterior": {
                    "1": 0.1,
                    "2": 0.1,
                    "3": 0.1,
                    "4": 0.1,
                    "5": 0.1,
                    "6": 0.1,
                    "7": 0.1,
                    "8": 0.1
                  },
                  "Sello de queso": {
                    "1": 0.1,
                    "2": 0.1,
                    "3": 0.1,
                    "4": 0.1,
                    "5": 0.1,
                    "6": 0.1,
                    "7": 0.1,
                    "8": 0
                  },
                  "Limpieza": {
                    "1": 0.1,
                    "2": 0.1,
                    "3": 0.1,
                    "4": 0.1,
                    "5": 0.1,
                    "6": 0.1,
                    "7": 0.1,
                    "8": 0.1
                  }
                },
                "score_final": 9.5,
                "specialty_check": false,
                "score_adjusted": 9.499999999999996
              }
            },
            "overbaked": 0,
            "id": 2,
            "standard": "not_to_standard",
            "category": "todas_las_carnes",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_12:11:31_v9p1-ssd-dense_id0002(None)-fr0340-00:42.jpg",
            "REPORT_frame_detection_summary": {},
            "censored": null,
            "attributes": null,
            "omit_soft": true,
            "bad_cut": 0,
            "optimal_figure": {
              "coords": [
                654,
                1162,
                506
              ],
              "__type__": "Circle"
            },
            "REPORT_detections_categ_summary": [],
            "optimal_frame": 340,
            "reviewer": "rodrigo.pulcha",
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "6482042d12502ec08c1d80c0",
        "optimal_snap_ingredients": {
          "1": [
            {
              "type": "tomate",
              "rectangle": [
                654,
                317,
                863,
                522
              ],
              "weight": 0.975284218788147
            },
            {
              "type": "pepperoni",
              "rectangle": [
                256,
                766,
                364,
                836
              ],
              "weight": 0.9715825915336609
            },
            {
              "type": "tomate",
              "rectangle": [
                347,
                464,
                504,
                646
              ],
              "weight": 0.9510186910629272
            },
            {
              "type": "pepperoni",
              "rectangle": [
                267,
                664,
                398,
                726
              ],
              "weight": 0.9171754717826843
            },
            {
              "type": "pepperoni",
              "rectangle": [
                728,
                670,
                858,
                799
              ],
              "weight": 0.862881064414978
            },
            {
              "type": "tomate",
              "rectangle": [
                214,
                270,
                444,
                474
              ],
              "weight": 0.7868448495864868
            },
            {
              "type": "tomate",
              "rectangle": [
                520,
                556,
                711,
                734
              ],
              "weight": 0.7162671089172363
            },
            {
              "type": "tomate",
              "rectangle": [
                498,
                196,
                670,
                359
              ],
              "weight": 0.7027401328086853
            },
            {
              "type": "pollo",
              "rectangle": [
                480,
                353,
                525,
                405
              ],
              "weight": 0.4541192650794983
            }
          ],
          "2": [
            {
              "type": "pepperoni",
              "rectangle": [
                656,
                701,
                752,
                821
              ],
              "weight": 0.9698561429977417
            },
            {
              "type": "pepperoni",
              "rectangle": [
                267,
                721,
                365,
                812
              ],
              "weight": 0.9576544761657715
            },
            {
              "type": "carne",
              "rectangle": [
                720,
                472,
                757,
                520
              ],
              "weight": 0.9425713419914246
            },
            {
              "type": "jamón",
              "rectangle": [
                271,
                254,
                374,
                345
              ],
              "weight": 0.9171647429466248
            },
            {
              "type": "carne",
              "rectangle": [
                331,
                209,
                386,
                266
              ],
              "weight": 0.9147093296051025
            },
            {
              "type": "pepperoni",
              "rectangle": [
                366,
                634,
                460,
                738
              ],
              "weight": 0.8990771174430847
            },
            {
              "type": "jamón",
              "rectangle": [
                619,
                423,
                727,
                512
              ],
              "weight": 0.8986983895301819
            },
            {
              "type": "jamón",
              "rectangle": [
                745,
                593,
                807,
                717
              ],
              "weight": 0.8954089283943176
            },
            {
              "type": "aceituna",
              "rectangle": [
                551,
                737,
                595,
                797
              ],
              "weight": 0.8881811499595642
            },
            {
              "type": "carne",
              "rectangle": [
                445,
                789,
                494,
                837
              ],
              "weight": 0.8880859613418579
            },
            {
              "type": "carne",
              "rectangle": [
                492,
                435,
                540,
                482
              ],
              "weight": 0.8876845240592957
            },
            {
              "type": "pimiento",
              "rectangle": [
                135,
                581,
                167,
                634
              ],
              "weight": 0.8734052777290344
            },
            {
              "type": "carne",
              "rectangle": [
                267,
                436,
                309,
                477
              ],
              "weight": 0.8680301904678345
            },
            {
              "type": "carne",
              "rectangle": [
                505,
                252,
                562,
                299
              ],
              "weight": 0.8569443225860596
            },
            {
              "type": "carne",
              "rectangle": [
                291,
                481,
                389,
                558
              ],
              "weight": 0.849939227104187
            },
            {
              "type": "carne",
              "rectangle": [
                217,
                733,
                273,
                808
              ],
              "weight": 0.8438769578933716
            },
            {
              "type": "jamón",
              "rectangle": [
                588,
                189,
                656,
                301
              ],
              "weight": 0.8068351745605469
            },
            {
              "type": "carne",
              "rectangle": [
                394,
                486,
                440,
                523
              ],
              "weight": 0.780068576335907
            },
            {
              "type": "pepperoni",
              "rectangle": [
                360,
                785,
                456,
                884
              ],
              "weight": 0.7727614045143127
            },
            {
              "type": "pepperoni",
              "rectangle": [
                475,
                482,
                570,
                566
              ],
              "weight": 0.770290732383728
            },
            {
              "type": "carne",
              "rectangle": [
                702,
                268,
                775,
                353
              ],
              "weight": 0.7450411319732666
            },
            {
              "type": "carne",
              "rectangle": [
                567,
                500,
                611,
                539
              ],
              "weight": 0.7298626899719238
            },
            {
              "type": "tocino",
              "rectangle": [
                555,
                640,
                584,
                729
              ],
              "weight": 0.708535373210907
            },
            {
              "type": "pepperoni",
              "rectangle": [
                482,
                344,
                584,
                453
              ],
              "weight": 0.6833561062812805
            },
            {
              "type": "pimiento",
              "rectangle": [
                628,
                667,
                652,
                699
              ],
              "weight": 0.6430637240409851
            },
            {
              "type": "carne",
              "rectangle": [
                178,
                578,
                257,
                675
              ],
              "weight": 0.6407772898674011
            },
            {
              "type": "carne",
              "rectangle": [
                723,
                556,
                780,
                591
              ],
              "weight": 0.6353703737258911
            },
            {
              "type": "carne",
              "rectangle": [
                776,
                544,
                824,
                609
              ],
              "weight": 0.6317344307899475
            },
            {
              "type": "carne",
              "rectangle": [
                196,
                314,
                235,
                362
              ],
              "weight": 0.613102376461029
            },
            {
              "type": "pepperoni",
              "rectangle": [
                426,
                146,
                534,
                217
              ],
              "weight": 0.5737075209617615
            },
            {
              "type": "carne",
              "rectangle": [
                816,
                376,
                908,
                466
              ],
              "weight": 0.57025146484375
            },
            {
              "type": "jamón",
              "rectangle": [
                342,
                572,
                402,
                659
              ],
              "weight": 0.518927812576294
            },
            {
              "type": "pimiento",
              "rectangle": [
                808,
                612,
                854,
                641
              ],
              "weight": 0.49687233567237854
            },
            {
              "type": "carne",
              "rectangle": [
                389,
                287,
                434,
                341
              ],
              "weight": 0.4903682470321655
            },
            {
              "type": "carne",
              "rectangle": [
                599,
                611,
                654,
                665
              ],
              "weight": 0.47872909903526306
            },
            {
              "type": "carne",
              "rectangle": [
                462,
                683,
                502,
                734
              ],
              "weight": 0.459566205739975
            },
            {
              "type": "carne",
              "rectangle": [
                804,
                611,
                850,
                682
              ],
              "weight": 0.4356830418109894
            },
            {
              "type": "pepperoni",
              "rectangle": [
                520,
                154,
                595,
                257
              ],
              "weight": 0.4243841767311096
            },
            {
              "type": "tocino",
              "rectangle": [
                378,
                462,
                468,
                495
              ],
              "weight": 0.3323751986026764
            },
            {
              "type": "carne",
              "rectangle": [
                593,
                185,
                634,
                224
              ],
              "weight": 0.32657021284103394
            },
            {
              "type": "pepperoni",
              "rectangle": [
                456,
                811,
                545,
                941
              ],
              "weight": 0.30768445134162903
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "648200781312420a5ca5e1de",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "1": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.10000000000000003,
                "2": 0.10000000000000003,
                "3": 0.10000000000000003,
                "4": 0.10000000000000003,
                "5": 0.10000000000000003,
                "6": 0.10000000000000003,
                "7": 0.10000000000000003,
                "8": 0.10000000000000003
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0.31,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0
              },
              "Bordes": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.10000000000000003,
                "2": 0.10000000000000003,
                "3": 0.10000000000000003,
                "4": 0.10000000000000003,
                "5": 0.10000000000000003,
                "6": 0.10000000000000003,
                "7": 0.10000000000000003,
                "8": 0.10000000000000003
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.10000000000000003,
                "2": 0.10000000000000003,
                "3": 0.10000000000000003,
                "4": 0.10000000000000003,
                "5": 0.10000000000000003,
                "6": 0.10000000000000003,
                "7": 0.10000000000000003,
                "8": 0.10000000000000003
              }
            },
            "score_final": 7.31,
            "specialty_check": false,
            "score_adjusted": 7.31
          },
          "2": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 9.5,
            "specialty_check": false,
            "score_adjusted": 9.5
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648202db86080b102372e85d",
        "REPORT_capture_start_ts": "2023/06/08 16:21:31"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "REPORT_localized_capture_start_ts": "12:31:30 2023/06/08",
            "optimal_frame_quality": {
              "blur": 0.0003085668431594968,
              "occ": 0.004866920877248049
            },
            "bubbles": null,
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_12:21:31_v9p1-ssd-dense_id0005(None)-fr4793-09:59.jpg",
            "snap_quality": "buena",
            "multisize": false,
            "raw_score": false,
            "snap_quality_trusted": true,
            "original_data": {
              "specialty": "unknown"
            },
            "overbaked": null,
            "id": 5,
            "standard": null,
            "category": "half",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_12:21:31_v9p1-ssd-dense_id0005(None)-fr4793-09:59.jpg",
            "REPORT_frame_detection_summary": {},
            "censored": null,
            "attributes": null,
            "omit_soft": true,
            "bad_cut": null,
            "optimal_figure": {
              "coords": [
                879,
                1248,
                506
              ],
              "__type__": "Circle"
            },
            "REPORT_detections_categ_summary": [],
            "optimal_frame": 4793,
            "reviewer": "rodrigo.pulcha",
            "crust": "tradicional"
          },
          {
            "REPORT_localized_capture_start_ts": "12:31:30 2023/06/08",
            "optimal_frame_quality": {
              "blur": 0.000012150018847023603,
              "occ": 0.000002337012801945093
            },
            "bubbles": null,
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_12:21:31_v9p1-ssd-dense_id0006(None)-fr4794-09:59.jpg",
            "snap_quality": "buena",
            "multisize": false,
            "raw_score": false,
            "snap_quality_trusted": true,
            "original_data": null,
            "overbaked": null,
            "id": 6,
            "standard": "not_to_standard",
            "category": "super_pepperoni",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_12:21:31_v9p1-ssd-dense_id0006(None)-fr4794-09:59.jpg",
            "REPORT_frame_detection_summary": {},
            "censored": null,
            "attributes": null,
            "omit_soft": true,
            "bad_cut": null,
            "optimal_figure": {
              "coords": [
                1945,
                1243,
                506
              ],
              "__type__": "Circle"
            },
            "REPORT_detections_categ_summary": [],
            "optimal_frame": 4794,
            "reviewer": "rodrigo.pulcha",
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "648206e512502ec08c1d80c1",
        "optimal_snap_ingredients": {
          "6": [
            {
              "type": "pepperoni",
              "rectangle": [
                676,
                546,
                789,
                639
              ],
              "weight": 0.9993330836296082
            },
            {
              "type": "pepperoni",
              "rectangle": [
                801,
                456,
                912,
                543
              ],
              "weight": 0.9992572665214539
            },
            {
              "type": "pepperoni",
              "rectangle": [
                698,
                442,
                807,
                543
              ],
              "weight": 0.9991284012794495
            },
            {
              "type": "pepperoni",
              "rectangle": [
                408,
                733,
                518,
                848
              ],
              "weight": 0.9989868998527527
            },
            {
              "type": "pepperoni",
              "rectangle": [
                328,
                356,
                434,
                470
              ],
              "weight": 0.998920202255249
            },
            {
              "type": "pepperoni",
              "rectangle": [
                319,
                529,
                421,
                634
              ],
              "weight": 0.9988973140716553
            },
            {
              "type": "pepperoni",
              "rectangle": [
                167,
                407,
                285,
                521
              ],
              "weight": 0.9987958669662476
            },
            {
              "type": "pepperoni",
              "rectangle": [
                395,
                446,
                492,
                539
              ],
              "weight": 0.9987560510635376
            },
            {
              "type": "pepperoni",
              "rectangle": [
                598,
                732,
                689,
                840
              ],
              "weight": 0.9986514449119568
            },
            {
              "type": "pepperoni",
              "rectangle": [
                585,
                838,
                699,
                951
              ],
              "weight": 0.9985743761062622
            },
            {
              "type": "pepperoni",
              "rectangle": [
                780,
                342,
                896,
                455
              ],
              "weight": 0.9984784722328186
            },
            {
              "type": "pepperoni",
              "rectangle": [
                690,
                228,
                792,
                335
              ],
              "weight": 0.9984706044197083
            },
            {
              "type": "pepperoni",
              "rectangle": [
                234,
                753,
                351,
                860
              ],
              "weight": 0.9984275102615356
            },
            {
              "type": "pepperoni",
              "rectangle": [
                601,
                468,
                705,
                560
              ],
              "weight": 0.9983826875686646
            },
            {
              "type": "pepperoni",
              "rectangle": [
                725,
                634,
                839,
                743
              ],
              "weight": 0.9983748197555542
            },
            {
              "type": "pepperoni",
              "rectangle": [
                183,
                635,
                300,
                732
              ],
              "weight": 0.9982656836509705
            },
            {
              "type": "pepperoni",
              "rectangle": [
                518,
                749,
                605,
                857
              ],
              "weight": 0.9981650710105896
            },
            {
              "type": "pepperoni",
              "rectangle": [
                227,
                307,
                341,
                425
              ],
              "weight": 0.9981591105461121
            },
            {
              "type": "pepperoni",
              "rectangle": [
                666,
                367,
                770,
                467
              ],
              "weight": 0.998116135597229
            },
            {
              "type": "pepperoni",
              "rectangle": [
                605,
                188,
                714,
                291
              ],
              "weight": 0.9980972409248352
            },
            {
              "type": "pepperoni",
              "rectangle": [
                170,
                522,
                280,
                641
              ],
              "weight": 0.9980806112289429
            },
            {
              "type": "pepperoni",
              "rectangle": [
                428,
                290,
                530,
                383
              ],
              "weight": 0.9980644583702087
            },
            {
              "type": "pepperoni",
              "rectangle": [
                449,
                373,
                538,
                468
              ],
              "weight": 0.998034656047821
            },
            {
              "type": "pepperoni",
              "rectangle": [
                683,
                728,
                781,
                825
              ],
              "weight": 0.9978535771369934
            },
            {
              "type": "pepperoni",
              "rectangle": [
                539,
                640,
                647,
                753
              ],
              "weight": 0.9975786805152893
            },
            {
              "type": "pepperoni",
              "rectangle": [
                471,
                179,
                572,
                291
              ],
              "weight": 0.9972032308578491
            },
            {
              "type": "pepperoni",
              "rectangle": [
                293,
                837,
                396,
                942
              ],
              "weight": 0.9968318343162537
            },
            {
              "type": "pepperoni",
              "rectangle": [
                501,
                553,
                600,
                647
              ],
              "weight": 0.9966225624084473
            },
            {
              "type": "pepperoni",
              "rectangle": [
                422,
                548,
                505,
                652
              ],
              "weight": 0.996189534664154
            },
            {
              "type": "pepperoni",
              "rectangle": [
                391,
                834,
                470,
                950
              ],
              "weight": 0.9961819648742676
            },
            {
              "type": "pepperoni",
              "rectangle": [
                336,
                656,
                424,
                755
              ],
              "weight": 0.99600750207901
            },
            {
              "type": "pepperoni",
              "rectangle": [
                370,
                176,
                472,
                283
              ],
              "weight": 0.9954842329025269
            },
            {
              "type": "pepperoni",
              "rectangle": [
                462,
                629,
                554,
                731
              ],
              "weight": 0.9952056407928467
            },
            {
              "type": "pepperoni",
              "rectangle": [
                576,
                277,
                673,
                378
              ],
              "weight": 0.9948815107345581
            },
            {
              "type": "pepperoni",
              "rectangle": [
                585,
                550,
                667,
                652
              ],
              "weight": 0.9936876893043518
            },
            {
              "type": "pepperoni",
              "rectangle": [
                268,
                497,
                375,
                538
              ],
              "weight": 0.9916607737541199
            },
            {
              "type": "pepperoni",
              "rectangle": [
                500,
                861,
                564,
                982
              ],
              "weight": 0.9912614822387695
            },
            {
              "type": "pepperoni",
              "rectangle": [
                583,
                402,
                665,
                489
              ],
              "weight": 0.9874432682991028
            },
            {
              "type": "pepperoni",
              "rectangle": [
                638,
                620,
                733,
                712
              ],
              "weight": 0.9613484144210815
            },
            {
              "type": "pepperoni",
              "rectangle": [
                484,
                460,
                587,
                555
              ],
              "weight": 0.9187663793563843
            },
            {
              "type": "pepperoni",
              "rectangle": [
                253,
                232,
                368,
                326
              ],
              "weight": 0.4758009910583496
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "648202db86080b102372e85d",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "6": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0
              },
              "Bordes": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0,
                "7": 0,
                "8": 0
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 8.25,
            "specialty_check": true,
            "score_adjusted": 8.249999999999996
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "6482053c902736d05d996898",
        "REPORT_capture_start_ts": "2023/06/08 16:31:32"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "REPORT_localized_capture_start_ts": "12:31:42 2023/06/08",
            "optimal_frame_quality": {
              "blur": 9.559962563798763e-7,
              "occ": 0.0036538857966661453
            },
            "bubbles": null,
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_12:31:32_v9p1-ssd-dense_id0007(None)-fr0079-00:10.jpg",
            "snap_quality": "buena",
            "multisize": false,
            "raw_score": false,
            "snap_quality_trusted": true,
            "original_data": null,
            "overbaked": null,
            "id": 7,
            "standard": "not_to_standard",
            "category": "super_pepperoni",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_12:31:32_v9p1-ssd-dense_id0007(None)-fr0079-00:10.jpg",
            "REPORT_frame_detection_summary": {},
            "censored": null,
            "attributes": null,
            "omit_soft": true,
            "bad_cut": null,
            "optimal_figure": {
              "coords": [
                1940,
                1208,
                506
              ],
              "__type__": "Circle"
            },
            "REPORT_detections_categ_summary": [],
            "optimal_frame": 79,
            "reviewer": "rodrigo.pulcha",
            "crust": "tradicional"
          },
          {
            "id": 9,
            "REPORT_localized_capture_start_ts": "12:33:36 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": null,
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_12:31:32_v9p1-ssd-dense_id0009(None)-fr0994-02:04.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_12:31:32_v9p1-ssd-dense_id0009(None)-fr0994-02:04.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "mala",
            "optimal_figure": {
              "coords": [
                822,
                1346,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.012878087349236012,
              "occ": 0.997605562210083
            },
            "optimal_frame": 994,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true
          },
          {
            "id": 10,
            "REPORT_localized_capture_start_ts": "12:33:39 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "chicken_garlic",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_12:31:32_v9p1-ssd-dense_id0010(None)-fr1016-02:07.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_12:31:32_v9p1-ssd-dense_id0010(None)-fr1016-02:07.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1914,
                1354,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 5.567933267225378e-11,
              "occ": 0.729322075843811
            },
            "optimal_frame": 1016,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "648209a512502ec08c1d80c2",
        "optimal_snap_ingredients": {
          "7": [
            {
              "type": "pepperoni",
              "rectangle": [
                700,
                402,
                806,
                502
              ],
              "weight": 0.9989652633666992
            },
            {
              "type": "pepperoni",
              "rectangle": [
                697,
                507,
                803,
                602
              ],
              "weight": 0.9988331198692322
            },
            {
              "type": "pepperoni",
              "rectangle": [
                762,
                588,
                873,
                693
              ],
              "weight": 0.9988252520561218
            },
            {
              "type": "pepperoni",
              "rectangle": [
                584,
                628,
                685,
                738
              ],
              "weight": 0.9987351298332214
            },
            {
              "type": "pepperoni",
              "rectangle": [
                175,
                465,
                292,
                581
              ],
              "weight": 0.9986750483512878
            },
            {
              "type": "pepperoni",
              "rectangle": [
                324,
                390,
                429,
                501
              ],
              "weight": 0.9986705780029297
            },
            {
              "type": "pepperoni",
              "rectangle": [
                402,
                466,
                500,
                558
              ],
              "weight": 0.9986193180084229
            },
            {
              "type": "pepperoni",
              "rectangle": [
                345,
                559,
                445,
                666
              ],
              "weight": 0.9986158609390259
            },
            {
              "type": "pepperoni",
              "rectangle": [
                801,
                396,
                910,
                484
              ],
              "weight": 0.9984381794929504
            },
            {
              "type": "pepperoni",
              "rectangle": [
                761,
                289,
                875,
                405
              ],
              "weight": 0.998428463935852
            },
            {
              "type": "pepperoni",
              "rectangle": [
                196,
                579,
                311,
                698
              ],
              "weight": 0.9981719255447388
            },
            {
              "type": "pepperoni",
              "rectangle": [
                469,
                741,
                579,
                856
              ],
              "weight": 0.9981697797775269
            },
            {
              "type": "pepperoni",
              "rectangle": [
                303,
                791,
                417,
                902
              ],
              "weight": 0.9981585144996643
            },
            {
              "type": "pepperoni",
              "rectangle": [
                657,
                333,
                755,
                433
              ],
              "weight": 0.9981263279914856
            },
            {
              "type": "pepperoni",
              "rectangle": [
                796,
                507,
                908,
                611
              ],
              "weight": 0.998112678527832
            },
            {
              "type": "pepperoni",
              "rectangle": [
                214,
                356,
                329,
                473
              ],
              "weight": 0.9981119632720947
            },
            {
              "type": "pepperoni",
              "rectangle": [
                405,
                300,
                508,
                402
              ],
              "weight": 0.9981049299240112
            },
            {
              "type": "pepperoni",
              "rectangle": [
                734,
                693,
                834,
                785
              ],
              "weight": 0.9980937838554382
            },
            {
              "type": "pepperoni",
              "rectangle": [
                611,
                446,
                714,
                536
              ],
              "weight": 0.9980891346931458
            },
            {
              "type": "pepperoni",
              "rectangle": [
                654,
                710,
                746,
                816
              ],
              "weight": 0.9978906512260437
            },
            {
              "type": "pepperoni",
              "rectangle": [
                661,
                813,
                772,
                923
              ],
              "weight": 0.9975095987319946
            },
            {
              "type": "pepperoni",
              "rectangle": [
                648,
                194,
                753,
                302
              ],
              "weight": 0.9974800944328308
            },
            {
              "type": "pepperoni",
              "rectangle": [
                561,
                169,
                666,
                269
              ],
              "weight": 0.9974072575569153
            },
            {
              "type": "pepperoni",
              "rectangle": [
                230,
                685,
                343,
                784
              ],
              "weight": 0.9971675276756287
            },
            {
              "type": "pepperoni",
              "rectangle": [
                580,
                741,
                669,
                851
              ],
              "weight": 0.9971354007720947
            },
            {
              "type": "pepperoni",
              "rectangle": [
                430,
                189,
                531,
                299
              ],
              "weight": 0.997047483921051
            },
            {
              "type": "pepperoni",
              "rectangle": [
                522,
                552,
                624,
                639
              ],
              "weight": 0.9967411160469055
            },
            {
              "type": "pepperoni",
              "rectangle": [
                389,
                679,
                461,
                787
              ],
              "weight": 0.9964988231658936
            },
            {
              "type": "pepperoni",
              "rectangle": [
                503,
                629,
                593,
                730
              ],
              "weight": 0.9957784414291382
            },
            {
              "type": "pepperoni",
              "rectangle": [
                380,
                865,
                476,
                972
              ],
              "weight": 0.9956730008125305
            },
            {
              "type": "pepperoni",
              "rectangle": [
                605,
                534,
                694,
                632
              ],
              "weight": 0.9955664277076721
            },
            {
              "type": "pepperoni",
              "rectangle": [
                438,
                380,
                529,
                472
              ],
              "weight": 0.9952801465988159
            },
            {
              "type": "pepperoni",
              "rectangle": [
                468,
                853,
                553,
                959
              ],
              "weight": 0.9943206310272217
            },
            {
              "type": "pepperoni",
              "rectangle": [
                328,
                204,
                431,
                309
              ],
              "weight": 0.9943053126335144
            },
            {
              "type": "pepperoni",
              "rectangle": [
                585,
                857,
                651,
                975
              ],
              "weight": 0.9932429194450378
            },
            {
              "type": "pepperoni",
              "rectangle": [
                553,
                262,
                646,
                364
              ],
              "weight": 0.9929031729698181
            },
            {
              "type": "pepperoni",
              "rectangle": [
                447,
                558,
                530,
                667
              ],
              "weight": 0.9927428960800171
            },
            {
              "type": "pepperoni",
              "rectangle": [
                681,
                593,
                767,
                677
              ],
              "weight": 0.9896761775016785
            },
            {
              "type": "pepperoni",
              "rectangle": [
                283,
                538,
                389,
                584
              ],
              "weight": 0.9881933331489563
            },
            {
              "type": "pepperoni",
              "rectangle": [
                516,
                422,
                610,
                511
              ],
              "weight": 0.9800281524658203
            },
            {
              "type": "pepperoni",
              "rectangle": [
                509,
                268,
                561,
                377
              ],
              "weight": 0.9794971942901611
            }
          ],
          "10": [
            {
              "type": "pollo",
              "rectangle": [
                615,
                341,
                673,
                405
              ],
              "weight": 0.9581857919692993
            },
            {
              "type": "pollo",
              "rectangle": [
                674,
                250,
                725,
                295
              ],
              "weight": 0.9528578519821167
            },
            {
              "type": "pollo",
              "rectangle": [
                276,
                578,
                324,
                633
              ],
              "weight": 0.9523999094963074
            },
            {
              "type": "pollo",
              "rectangle": [
                289,
                319,
                392,
                418
              ],
              "weight": 0.9508017301559448
            },
            {
              "type": "pollo",
              "rectangle": [
                770,
                639,
                815,
                699
              ],
              "weight": 0.9405699968338013
            },
            {
              "type": "pollo",
              "rectangle": [
                354,
                522,
                453,
                581
              ],
              "weight": 0.9139515161514282
            },
            {
              "type": "carne",
              "rectangle": [
                690,
                229,
                733,
                267
              ],
              "weight": 0.9126207232475281
            },
            {
              "type": "carne",
              "rectangle": [
                352,
                463,
                404,
                511
              ],
              "weight": 0.9077708721160889
            },
            {
              "type": "pollo",
              "rectangle": [
                435,
                411,
                467,
                475
              ],
              "weight": 0.8974404335021973
            },
            {
              "type": "pollo",
              "rectangle": [
                535,
                519,
                605,
                573
              ],
              "weight": 0.8968833088874817
            },
            {
              "type": "pollo",
              "rectangle": [
                210,
                519,
                279,
                600
              ],
              "weight": 0.7952862977981567
            },
            {
              "type": "pollo",
              "rectangle": [
                359,
                706,
                426,
                802
              ],
              "weight": 0.7888038754463196
            },
            {
              "type": "pollo",
              "rectangle": [
                550,
                730,
                635,
                781
              ],
              "weight": 0.7867413759231567
            },
            {
              "type": "pollo",
              "rectangle": [
                244,
                585,
                279,
                630
              ],
              "weight": 0.7674970626831055
            },
            {
              "type": "pollo",
              "rectangle": [
                801,
                336,
                925,
                365
              ],
              "weight": 0.7536953091621399
            },
            {
              "type": "carne",
              "rectangle": [
                583,
                361,
                635,
                411
              ],
              "weight": 0.7417171597480774
            },
            {
              "type": "pollo",
              "rectangle": [
                606,
                556,
                656,
                596
              ],
              "weight": 0.7189998626708984
            },
            {
              "type": "pollo",
              "rectangle": [
                650,
                580,
                694,
                626
              ],
              "weight": 0.7135594487190247
            },
            {
              "type": "pollo",
              "rectangle": [
                274,
                501,
                320,
                560
              ],
              "weight": 0.7029264569282532
            },
            {
              "type": "pollo",
              "rectangle": [
                332,
                573,
                379,
                613
              ],
              "weight": 0.6562317609786987
            },
            {
              "type": "carne",
              "rectangle": [
                796,
                389,
                830,
                421
              ],
              "weight": 0.6391960382461548
            },
            {
              "type": "pollo",
              "rectangle": [
                744,
                526,
                822,
                564
              ],
              "weight": 0.6249147653579712
            },
            {
              "type": "carne",
              "rectangle": [
                485,
                194,
                560,
                229
              ],
              "weight": 0.5822514295578003
            },
            {
              "type": "carne",
              "rectangle": [
                487,
                677,
                530,
                725
              ],
              "weight": 0.5174785852432251
            },
            {
              "type": "carne",
              "rectangle": [
                558,
                850,
                596,
                891
              ],
              "weight": 0.5043519735336304
            },
            {
              "type": "pollo",
              "rectangle": [
                681,
                384,
                746,
                473
              ],
              "weight": 0.49790099263191223
            },
            {
              "type": "champiñón",
              "rectangle": [
                577,
                627,
                637,
                733
              ],
              "weight": 0.47664040327072144
            },
            {
              "type": "carne",
              "rectangle": [
                268,
                328,
                318,
                376
              ],
              "weight": 0.4761536717414856
            },
            {
              "type": "champiñón",
              "rectangle": [
                592,
                131,
                671,
                204
              ],
              "weight": 0.47010374069213867
            },
            {
              "type": "carne",
              "rectangle": [
                634,
                669,
                688,
                711
              ],
              "weight": 0.4108087718486786
            },
            {
              "type": "pollo",
              "rectangle": [
                519,
                570,
                560,
                607
              ],
              "weight": 0.38284605741500854
            },
            {
              "type": "pollo",
              "rectangle": [
                600,
                816,
                642,
                858
              ],
              "weight": 0.37775975465774536
            },
            {
              "type": "pollo",
              "rectangle": [
                739,
                605,
                807,
                648
              ],
              "weight": 0.37414035201072693
            },
            {
              "type": "carne",
              "rectangle": [
                540,
                662,
                588,
                700
              ],
              "weight": 0.3637455999851227
            },
            {
              "type": "carne",
              "rectangle": [
                450,
                523,
                476,
                566
              ],
              "weight": 0.34673550724983215
            },
            {
              "type": "carne",
              "rectangle": [
                368,
                382,
                426,
                434
              ],
              "weight": 0.33828261494636536
            },
            {
              "type": "carne",
              "rectangle": [
                299,
                427,
                335,
                489
              ],
              "weight": 0.3217948079109192
            },
            {
              "type": "carne",
              "rectangle": [
                730,
                672,
                793,
                730
              ],
              "weight": 0.31862595677375793
            },
            {
              "type": "carne",
              "rectangle": [
                497,
                140,
                578,
                198
              ],
              "weight": 0.3144852817058563
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "6482053c902736d05d996898",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "7": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0.25,
                "7": 0.25,
                "8": 0
              },
              "Bordes": {
                "1": 0.1,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 8.1,
            "specialty_check": true,
            "score_adjusted": 8.099999999999996
          },
          "10": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0,
                "3": 0,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0,
                "8": 0
              },
              "Bordes": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0.1,
                "7": 0,
                "8": 0
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0.1,
                "7": 0,
                "8": 0
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 7.5,
            "specialty_check": false,
            "score_adjusted": 7.5
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648207a0b2b815c779e0885d",
        "REPORT_capture_start_ts": "2023/06/08 16:41:33"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 11,
            "REPORT_localized_capture_start_ts": "12:49:15 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "espinacas_crema",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_12:41:33_v9p1-ssd-dense_id0011(None)-fr3692-07:42.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_12:41:33_v9p1-ssd-dense_id0011(None)-fr3692-07:42.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1247,
                1273,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.000027282956580165774,
              "occ": 0.007596244569867849
            },
            "optimal_frame": 3692,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 12,
            "REPORT_localized_capture_start_ts": "12:51:05 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "todas_las_carnes",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_12:41:33_v9p1-ssd-dense_id0012(None)-fr4576-09:32.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_12:41:33_v9p1-ssd-dense_id0012(None)-fr4576-09:32.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                2029,
                1195,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.16705012321472168,
              "occ": 0.3569501042366028
            },
            "optimal_frame": 4576,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 13,
            "REPORT_localized_capture_start_ts": "12:51:30 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "todas_las_carnes",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_12:41:33_v9p1-ssd-dense_id0013(None)-fr4774-09:57.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_12:41:33_v9p1-ssd-dense_id0013(None)-fr4774-09:57.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1020,
                1373,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.027501370757818222,
              "occ": 0.004136120434850454
            },
            "optimal_frame": 4774,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "64820c9412502ec08c1d80c3",
        "optimal_snap_ingredients": {
          "11": [
            {
              "type": "jamón",
              "rectangle": [
                475,
                460,
                573,
                551
              ],
              "weight": 0.9777296781539917
            },
            {
              "type": "jamón",
              "rectangle": [
                381,
                444,
                486,
                549
              ],
              "weight": 0.9739400744438171
            },
            {
              "type": "jamón",
              "rectangle": [
                388,
                559,
                469,
                677
              ],
              "weight": 0.9655407071113586
            },
            {
              "type": "tomate",
              "rectangle": [
                595,
                228,
                799,
                415
              ],
              "weight": 0.9654305577278137
            },
            {
              "type": "jamón",
              "rectangle": [
                690,
                545,
                772,
                664
              ],
              "weight": 0.9413567781448364
            },
            {
              "type": "tomate",
              "rectangle": [
                192,
                325,
                400,
                534
              ],
              "weight": 0.9178071618080139
            },
            {
              "type": "tomate",
              "rectangle": [
                488,
                376,
                685,
                566
              ],
              "weight": 0.8945725560188293
            },
            {
              "type": "tocino",
              "rectangle": [
                354,
                746,
                413,
                813
              ],
              "weight": 0.8598536252975464
            },
            {
              "type": "tomate",
              "rectangle": [
                549,
                790,
                597,
                883
              ],
              "weight": 0.8332019448280334
            },
            {
              "type": "tomate",
              "rectangle": [
                725,
                652,
                881,
                807
              ],
              "weight": 0.7900767922401428
            },
            {
              "type": "tomate",
              "rectangle": [
                225,
                599,
                402,
                755
              ],
              "weight": 0.7572901844978333
            },
            {
              "type": "jamón",
              "rectangle": [
                811,
                299,
                883,
                375
              ],
              "weight": 0.7169502973556519
            },
            {
              "type": "tomate",
              "rectangle": [
                379,
                161,
                588,
                355
              ],
              "weight": 0.7005595564842224
            },
            {
              "type": "aceituna",
              "rectangle": [
                746,
                502,
                781,
                541
              ],
              "weight": 0.6780402660369873
            },
            {
              "type": "carne",
              "rectangle": [
                316,
                264,
                377,
                334
              ],
              "weight": 0.6076974868774414
            },
            {
              "type": "aceituna",
              "rectangle": [
                567,
                361,
                603,
                402
              ],
              "weight": 0.5800914764404297
            },
            {
              "type": "tomate",
              "rectangle": [
                727,
                465,
                871,
                544
              ],
              "weight": 0.5389851331710815
            },
            {
              "type": "carne",
              "rectangle": [
                479,
                390,
                511,
                430
              ],
              "weight": 0.39852702617645264
            },
            {
              "type": "champiñón",
              "rectangle": [
                635,
                641,
                703,
                699
              ],
              "weight": 0.3662564158439636
            },
            {
              "type": "carne",
              "rectangle": [
                359,
                553,
                411,
                607
              ],
              "weight": 0.35238516330718994
            },
            {
              "type": "pepperoni",
              "rectangle": [
                488,
                382,
                581,
                474
              ],
              "weight": 0.34162089228630066
            },
            {
              "type": "carne",
              "rectangle": [
                300,
                777,
                356,
                825
              ],
              "weight": 0.3341694474220276
            },
            {
              "type": "tomate",
              "rectangle": [
                759,
                539,
                841,
                622
              ],
              "weight": 0.32429200410842896
            },
            {
              "type": "anana",
              "rectangle": [
                483,
                575,
                555,
                628
              ],
              "weight": 0.31325897574424744
            },
            {
              "type": "champiñón",
              "rectangle": [
                789,
                364,
                843,
                409
              ],
              "weight": 0.30714842677116394
            },
            {
              "type": "tomate",
              "rectangle": [
                869,
                542,
                954,
                602
              ],
              "weight": 0.30632826685905457
            },
            {
              "type": "carne",
              "rectangle": [
                675,
                489,
                750,
                532
              ],
              "weight": 0.3002728521823883
            }
          ],
          "12": [
            {
              "type": "pepperoni",
              "rectangle": [
                644,
                199,
                758,
                305
              ],
              "weight": 0.9919975399971008
            },
            {
              "type": "pepperoni",
              "rectangle": [
                453,
                646,
                572,
                765
              ],
              "weight": 0.9918184280395508
            },
            {
              "type": "pepperoni",
              "rectangle": [
                771,
                349,
                855,
                478
              ],
              "weight": 0.9898362755775452
            },
            {
              "type": "aceituna",
              "rectangle": [
                849,
                379,
                906,
                429
              ],
              "weight": 0.9892064332962036
            },
            {
              "type": "pepperoni",
              "rectangle": [
                671,
                340,
                785,
                454
              ],
              "weight": 0.9867119789123535
            },
            {
              "type": "pepperoni",
              "rectangle": [
                621,
                541,
                730,
                658
              ],
              "weight": 0.9864634275436401
            },
            {
              "type": "pepperoni",
              "rectangle": [
                502,
                268,
                601,
                346
              ],
              "weight": 0.9736083745956421
            },
            {
              "type": "pepperoni",
              "rectangle": [
                304,
                383,
                405,
                467
              ],
              "weight": 0.9649240970611572
            },
            {
              "type": "pepperoni",
              "rectangle": [
                229,
                262,
                351,
                374
              ],
              "weight": 0.9617751836776733
            },
            {
              "type": "aceituna",
              "rectangle": [
                257,
                467,
                306,
                503
              ],
              "weight": 0.9584246873855591
            },
            {
              "type": "pepperoni",
              "rectangle": [
                508,
                388,
                630,
                493
              ],
              "weight": 0.949313223361969
            },
            {
              "type": "pimiento",
              "rectangle": [
                394,
                555,
                457,
                638
              ],
              "weight": 0.9408999085426331
            },
            {
              "type": "pepperoni",
              "rectangle": [
                453,
                147,
                557,
                241
              ],
              "weight": 0.9142839908599854
            },
            {
              "type": "carne",
              "rectangle": [
                428,
                132,
                488,
                187
              ],
              "weight": 0.9093008637428284
            },
            {
              "type": "pepperoni",
              "rectangle": [
                190,
                498,
                261,
                604
              ],
              "weight": 0.8974606990814209
            },
            {
              "type": "pepperoni",
              "rectangle": [
                210,
                673,
                334,
                805
              ],
              "weight": 0.8818576335906982
            },
            {
              "type": "aceituna",
              "rectangle": [
                576,
                713,
                622,
                743
              ],
              "weight": 0.860931932926178
            },
            {
              "type": "pimiento",
              "rectangle": [
                760,
                550,
                793,
                581
              ],
              "weight": 0.8599349856376648
            },
            {
              "type": "pimiento",
              "rectangle": [
                857,
                428,
                908,
                478
              ],
              "weight": 0.8438116908073425
            },
            {
              "type": "aceituna",
              "rectangle": [
                506,
                262,
                541,
                289
              ],
              "weight": 0.8211222887039185
            },
            {
              "type": "aceituna",
              "rectangle": [
                711,
                796,
                743,
                841
              ],
              "weight": 0.8182095289230347
            },
            {
              "type": "aceituna",
              "rectangle": [
                665,
                641,
                731,
                672
              ],
              "weight": 0.7924298644065857
            },
            {
              "type": "pepperoni",
              "rectangle": [
                731,
                253,
                848,
                365
              ],
              "weight": 0.7585989236831665
            },
            {
              "type": "aceituna",
              "rectangle": [
                703,
                542,
                742,
                575
              ],
              "weight": 0.7472525238990784
            },
            {
              "type": "aceituna",
              "rectangle": [
                258,
                526,
                326,
                616
              ],
              "weight": 0.7345831990242004
            },
            {
              "type": "pepperoni",
              "rectangle": [
                592,
                299,
                688,
                423
              ],
              "weight": 0.7266854047775269
            },
            {
              "type": "aceituna",
              "rectangle": [
                668,
                507,
                696,
                549
              ],
              "weight": 0.7205004096031189
            },
            {
              "type": "aceituna",
              "rectangle": [
                434,
                403,
                479,
                451
              ],
              "weight": 0.7081363797187805
            },
            {
              "type": "aceituna",
              "rectangle": [
                477,
                379,
                509,
                420
              ],
              "weight": 0.6792330145835876
            },
            {
              "type": "jamón",
              "rectangle": [
                316,
                176,
                432,
                284
              ],
              "weight": 0.6771456599235535
            },
            {
              "type": "pimiento",
              "rectangle": [
                567,
                254,
                638,
                310
              ],
              "weight": 0.6257340908050537
            },
            {
              "type": "pepperoni",
              "rectangle": [
                406,
                311,
                494,
                411
              ],
              "weight": 0.6052629351615906
            },
            {
              "type": "jamón",
              "rectangle": [
                303,
                521,
                422,
                569
              ],
              "weight": 0.6011093258857727
            },
            {
              "type": "pepperoni",
              "rectangle": [
                300,
                557,
                409,
                644
              ],
              "weight": 0.5648555159568787
            },
            {
              "type": "aceituna",
              "rectangle": [
                499,
                832,
                531,
                867
              ],
              "weight": 0.5288639664649963
            },
            {
              "type": "pepperoni",
              "rectangle": [
                685,
                440,
                810,
                558
              ],
              "weight": 0.479910284280777
            },
            {
              "type": "pepperoni",
              "rectangle": [
                791,
                487,
                907,
                600
              ],
              "weight": 0.47012820839881897
            },
            {
              "type": "aceituna",
              "rectangle": [
                770,
                246,
                830,
                276
              ],
              "weight": 0.4662277102470398
            },
            {
              "type": "champiñón",
              "rectangle": [
                723,
                646,
                808,
                725
              ],
              "weight": 0.4637703597545624
            },
            {
              "type": "carne",
              "rectangle": [
                628,
                719,
                674,
                752
              ],
              "weight": 0.38497433066368103
            },
            {
              "type": "aceituna",
              "rectangle": [
                664,
                444,
                699,
                462
              ],
              "weight": 0.37750327587127686
            },
            {
              "type": "pimiento",
              "rectangle": [
                836,
                343,
                870,
                370
              ],
              "weight": 0.3707508146762848
            },
            {
              "type": "aceituna",
              "rectangle": [
                486,
                322,
                529,
                358
              ],
              "weight": 0.3686063885688782
            },
            {
              "type": "pepperoni",
              "rectangle": [
                481,
                779,
                546,
                879
              ],
              "weight": 0.3656841814517975
            },
            {
              "type": "pepperoni",
              "rectangle": [
                602,
                454,
                676,
                574
              ],
              "weight": 0.3097771108150482
            },
            {
              "type": "pepperoni",
              "rectangle": [
                557,
                640,
                665,
                725
              ],
              "weight": 0.3028661608695984
            }
          ],
          "13": [
            {
              "type": "carne",
              "rectangle": [
                181,
                274,
                229,
                326
              ],
              "weight": 0.9677748084068298
            },
            {
              "type": "jamón",
              "rectangle": [
                312,
                552,
                420,
                652
              ],
              "weight": 0.9423715472221375
            },
            {
              "type": "pepperoni",
              "rectangle": [
                199,
                382,
                290,
                485
              ],
              "weight": 0.9231618046760559
            },
            {
              "type": "carne",
              "rectangle": [
                149,
                400,
                198,
                463
              ],
              "weight": 0.9158591032028198
            },
            {
              "type": "jamón",
              "rectangle": [
                603,
                441,
                718,
                544
              ],
              "weight": 0.8964153528213501
            },
            {
              "type": "carne",
              "rectangle": [
                409,
                899,
                462,
                952
              ],
              "weight": 0.8945992588996887
            },
            {
              "type": "carne",
              "rectangle": [
                899,
                529,
                937,
                607
              ],
              "weight": 0.8878216743469238
            },
            {
              "type": "carne",
              "rectangle": [
                383,
                488,
                437,
                551
              ],
              "weight": 0.86075758934021
            },
            {
              "type": "carne",
              "rectangle": [
                264,
                670,
                313,
                729
              ],
              "weight": 0.8568595051765442
            },
            {
              "type": "pepperoni",
              "rectangle": [
                697,
                534,
                805,
                652
              ],
              "weight": 0.8549689054489136
            },
            {
              "type": "jamón",
              "rectangle": [
                477,
                659,
                559,
                760
              ],
              "weight": 0.8399943113327026
            },
            {
              "type": "carne",
              "rectangle": [
                78,
                532,
                108,
                583
              ],
              "weight": 0.8304430246353149
            },
            {
              "type": "jamón",
              "rectangle": [
                751,
                331,
                868,
                441
              ],
              "weight": 0.8160485625267029
            },
            {
              "type": "carne",
              "rectangle": [
                542,
                428,
                601,
                494
              ],
              "weight": 0.7867050766944885
            },
            {
              "type": "carne",
              "rectangle": [
                695,
                285,
                760,
                360
              ],
              "weight": 0.7827966213226318
            },
            {
              "type": "pepperoni",
              "rectangle": [
                347,
                209,
                400,
                331
              ],
              "weight": 0.6950228214263916
            },
            {
              "type": "carne",
              "rectangle": [
                811,
                644,
                867,
                713
              ],
              "weight": 0.6853770613670349
            },
            {
              "type": "carne",
              "rectangle": [
                720,
                785,
                759,
                828
              ],
              "weight": 0.6804112792015076
            },
            {
              "type": "carne",
              "rectangle": [
                457,
                485,
                511,
                524
              ],
              "weight": 0.6624888777732849
            },
            {
              "type": "jamón",
              "rectangle": [
                650,
                781,
                729,
                895
              ],
              "weight": 0.6200606822967529
            },
            {
              "type": "pepperoni",
              "rectangle": [
                191,
                714,
                314,
                816
              ],
              "weight": 0.6055445075035095
            },
            {
              "type": "pepperoni",
              "rectangle": [
                261,
                789,
                398,
                903
              ],
              "weight": 0.5994811654090881
            },
            {
              "type": "pepperoni",
              "rectangle": [
                493,
                763,
                615,
                906
              ],
              "weight": 0.5992345809936523
            },
            {
              "type": "carne",
              "rectangle": [
                571,
                528,
                629,
                577
              ],
              "weight": 0.5883324146270752
            },
            {
              "type": "pepperoni",
              "rectangle": [
                249,
                478,
                375,
                577
              ],
              "weight": 0.5872945189476013
            },
            {
              "type": "jamón",
              "rectangle": [
                480,
                574,
                559,
                662
              ],
              "weight": 0.5572375059127808
            },
            {
              "type": "jamón",
              "rectangle": [
                323,
                368,
                404,
                487
              ],
              "weight": 0.5368530750274658
            },
            {
              "type": "pepperoni",
              "rectangle": [
                228,
                237,
                366,
                346
              ],
              "weight": 0.48486804962158203
            },
            {
              "type": "carne",
              "rectangle": [
                325,
                650,
                400,
                724
              ],
              "weight": 0.46136701107025146
            },
            {
              "type": "carne",
              "rectangle": [
                371,
                342,
                427,
                382
              ],
              "weight": 0.42956146597862244
            },
            {
              "type": "jamón",
              "rectangle": [
                725,
                685,
                803,
                734
              ],
              "weight": 0.42120954394340515
            },
            {
              "type": "carne",
              "rectangle": [
                313,
                719,
                371,
                782
              ],
              "weight": 0.41795483231544495
            },
            {
              "type": "pepperoni",
              "rectangle": [
                679,
                709,
                768,
                823
              ],
              "weight": 0.4125034213066101
            },
            {
              "type": "carne",
              "rectangle": [
                729,
                210,
                783,
                244
              ],
              "weight": 0.3359595537185669
            },
            {
              "type": "carne",
              "rectangle": [
                689,
                721,
                751,
                776
              ],
              "weight": 0.3304378092288971
            },
            {
              "type": "carne",
              "rectangle": [
                456,
                548,
                505,
                606
              ],
              "weight": 0.32549911737442017
            },
            {
              "type": "carne",
              "rectangle": [
                577,
                152,
                685,
                242
              ],
              "weight": 0.3216589689254761
            },
            {
              "type": "tocino",
              "rectangle": [
                588,
                573,
                652,
                636
              ],
              "weight": 0.31419870257377625
            },
            {
              "type": "carne",
              "rectangle": [
                863,
                457,
                896,
                514
              ],
              "weight": 0.30617401003837585
            },
            {
              "type": "carne",
              "rectangle": [
                108,
                614,
                181,
                724
              ],
              "weight": 0.30386245250701904
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "648207a0b2b815c779e0885d",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "11": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 9.05,
            "specialty_check": false,
            "score_adjusted": 9.049999999999997
          },
          "12": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0,
                "3": 0.1,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 9.249999999999998,
            "specialty_check": false,
            "score_adjusted": 9.249999999999996
          },
          "13": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 9.499999999999998,
            "specialty_check": true,
            "score_adjusted": 9.499999999999996
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64820a0178eb59b7489c3aef",
        "REPORT_capture_start_ts": "2023/06/08 16:51:34"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 14,
            "REPORT_localized_capture_start_ts": "12:51:52 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "todas_las_carnes",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_12:51:34_v9p1-ssd-dense_id0014(None)-fr0146-00:18.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_12:51:34_v9p1-ssd-dense_id0014(None)-fr0146-00:18.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1018,
                1249,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00010432687849970534,
              "occ": 0.0000213406983675668
            },
            "optimal_frame": 146,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 17,
            "REPORT_localized_capture_start_ts": "12:53:32 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": null,
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_12:51:34_v9p1-ssd-dense_id0017(None)-fr0944-01:58.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_12:51:34_v9p1-ssd-dense_id0017(None)-fr0944-01:58.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "mala",
            "optimal_figure": {
              "coords": [
                1353,
                1277,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.02171502076089382,
              "occ": 0.9246783256530762
            },
            "optimal_frame": 944,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true
          },
          {
            "id": 20,
            "REPORT_localized_capture_start_ts": "12:56:38 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "pepperoni",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_12:51:34_v9p1-ssd-dense_id0020(None)-fr2429-05:04.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_12:51:34_v9p1-ssd-dense_id0020(None)-fr2429-05:04.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1749,
                1270,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.0001738880091579631,
              "occ": 0.0001400467735948041
            },
            "optimal_frame": 2429,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 21,
            "REPORT_localized_capture_start_ts": "12:57:12 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_12:51:34_v9p1-ssd-dense_id0021(None)-fr2705-05:38.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_12:51:34_v9p1-ssd-dense_id0021(None)-fr2705-05:38.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1374,
                1353,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00013924702943768352,
              "occ": 0.011251386255025864
            },
            "optimal_frame": 2705,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 22,
            "REPORT_localized_capture_start_ts": "12:57:51 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_12:51:34_v9p1-ssd-dense_id0022(None)-fr3013-06:17.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_12:51:34_v9p1-ssd-dense_id0022(None)-fr3013-06:17.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1126,
                1151,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.04201609641313553,
              "occ": 0.014887100085616112
            },
            "optimal_frame": 3013,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "64820ff512502ec08c1d80c4",
        "optimal_snap_ingredients": {
          "14": [
            {
              "type": "jamón",
              "rectangle": [
                300,
                535,
                407,
                638
              ],
              "weight": 0.9684596657752991
            },
            {
              "type": "carne",
              "rectangle": [
                154,
                373,
                201,
                431
              ],
              "weight": 0.957160472869873
            },
            {
              "type": "pepperoni",
              "rectangle": [
                204,
                357,
                289,
                461
              ],
              "weight": 0.9334442615509033
            },
            {
              "type": "carne",
              "rectangle": [
                201,
                246,
                246,
                298
              ],
              "weight": 0.9311649203300476
            },
            {
              "type": "carne",
              "rectangle": [
                374,
                882,
                427,
                936
              ],
              "weight": 0.9184791445732117
            },
            {
              "type": "jamón",
              "rectangle": [
                600,
                446,
                718,
                536
              ],
              "weight": 0.8993762135505676
            },
            {
              "type": "carne",
              "rectangle": [
                378,
                474,
                431,
                531
              ],
              "weight": 0.8979156613349915
            },
            {
              "type": "pepperoni",
              "rectangle": [
                248,
                458,
                368,
                556
              ],
              "weight": 0.8914838433265686
            },
            {
              "type": "carne",
              "rectangle": [
                248,
                644,
                294,
                701
              ],
              "weight": 0.8843643665313721
            },
            {
              "type": "jamón",
              "rectangle": [
                765,
                362,
                872,
                457
              ],
              "weight": 0.8749863505363464
            },
            {
              "type": "pepperoni",
              "rectangle": [
                225,
                770,
                371,
                875
              ],
              "weight": 0.821692943572998
            },
            {
              "type": "pepperoni",
              "rectangle": [
                691,
                547,
                785,
                664
              ],
              "weight": 0.8004425764083862
            },
            {
              "type": "jamón",
              "rectangle": [
                453,
                651,
                547,
                742
              ],
              "weight": 0.7713686227798462
            },
            {
              "type": "carne",
              "rectangle": [
                884,
                562,
                920,
                630
              ],
              "weight": 0.7595611214637756
            },
            {
              "type": "carne",
              "rectangle": [
                688,
                792,
                728,
                835
              ],
              "weight": 0.7450653314590454
            },
            {
              "type": "carne",
              "rectangle": [
                536,
                427,
                595,
                493
              ],
              "weight": 0.69102543592453
            },
            {
              "type": "pepperoni",
              "rectangle": [
                590,
                785,
                697,
                901
              ],
              "weight": 0.6873499155044556
            },
            {
              "type": "carne",
              "rectangle": [
                73,
                493,
                103,
                542
              ],
              "weight": 0.6644772291183472
            },
            {
              "type": "tocino",
              "rectangle": [
                580,
                575,
                644,
                639
              ],
              "weight": 0.6481655240058899
            },
            {
              "type": "carne",
              "rectangle": [
                312,
                646,
                375,
                696
              ],
              "weight": 0.642327070236206
            },
            {
              "type": "meatball",
              "rectangle": [
                468,
                759,
                571,
                875
              ],
              "weight": 0.633372962474823
            },
            {
              "type": "carne",
              "rectangle": [
                564,
                528,
                622,
                580
              ],
              "weight": 0.6204741597175598
            },
            {
              "type": "pepperoni",
              "rectangle": [
                361,
                200,
                422,
                321
              ],
              "weight": 0.6096698641777039
            },
            {
              "type": "pepperoni",
              "rectangle": [
                316,
                358,
                407,
                475
              ],
              "weight": 0.5898206233978271
            },
            {
              "type": "carne",
              "rectangle": [
                785,
                662,
                847,
                734
              ],
              "weight": 0.5876650214195251
            },
            {
              "type": "carne",
              "rectangle": [
                454,
                479,
                512,
                518
              ],
              "weight": 0.5583422183990479
            },
            {
              "type": "pepperoni",
              "rectangle": [
                654,
                724,
                743,
                820
              ],
              "weight": 0.5235547423362732
            },
            {
              "type": "carne",
              "rectangle": [
                289,
                698,
                349,
                759
              ],
              "weight": 0.5202335715293884
            },
            {
              "type": "carne",
              "rectangle": [
                858,
                483,
                890,
                538
              ],
              "weight": 0.5017777681350708
            },
            {
              "type": "carne",
              "rectangle": [
                703,
                299,
                777,
                373
              ],
              "weight": 0.46922990679740906
            },
            {
              "type": "jamón",
              "rectangle": [
                463,
                563,
                536,
                655
              ],
              "weight": 0.4609646499156952
            },
            {
              "type": "carne",
              "rectangle": [
                373,
                329,
                432,
                364
              ],
              "weight": 0.4265403747558594
            },
            {
              "type": "carne",
              "rectangle": [
                93,
                571,
                163,
                688
              ],
              "weight": 0.4145047068595886
            },
            {
              "type": "jamón",
              "rectangle": [
                618,
                199,
                711,
                266
              ],
              "weight": 0.407569944858551
            },
            {
              "type": "carne",
              "rectangle": [
                743,
                227,
                798,
                269
              ],
              "weight": 0.374308317899704
            },
            {
              "type": "pepperoni",
              "rectangle": [
                440,
                532,
                505,
                616
              ],
              "weight": 0.3731573522090912
            },
            {
              "type": "carne",
              "rectangle": [
                603,
                156,
                661,
                205
              ],
              "weight": 0.3577236831188202
            },
            {
              "type": "tocino",
              "rectangle": [
                303,
                274,
                358,
                335
              ],
              "weight": 0.34109732508659363
            },
            {
              "type": "pepperoni",
              "rectangle": [
                570,
                566,
                672,
                662
              ],
              "weight": 0.3344295620918274
            },
            {
              "type": "carne",
              "rectangle": [
                447,
                560,
                479,
                596
              ],
              "weight": 0.3256039321422577
            },
            {
              "type": "pepperoni",
              "rectangle": [
                383,
                332,
                471,
                423
              ],
              "weight": 0.3219399154186249
            },
            {
              "type": "tocino",
              "rectangle": [
                567,
                635,
                619,
                675
              ],
              "weight": 0.31508031487464905
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "64820a0178eb59b7489c3aef",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "14": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 9.5,
            "specialty_check": false,
            "score_adjusted": 9.499999999999996
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64820c629a532c050defd5c5",
        "REPORT_capture_start_ts": "2023/06/08 17:01:34"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 23,
            "REPORT_localized_capture_start_ts": "13:08:04 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "todas_las_carnes",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:01:34_v9p1-ssd-dense_id0023(None)-fr3117-06:30.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:01:34_v9p1-ssd-dense_id0023(None)-fr3117-06:30.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1196,
                1267,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00016532976587768644,
              "occ": 0.01103664468973875
            },
            "optimal_frame": 3117,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 24,
            "REPORT_localized_capture_start_ts": "13:11:16 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "todas_las_carnes",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:01:34_v9p1-ssd-dense_id0024(None)-fr4655-09:42.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:01:34_v9p1-ssd-dense_id0024(None)-fr4655-09:42.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                2017,
                1375,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00000200199269784207,
              "occ": 0.0005948200123384595
            },
            "optimal_frame": 4655,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "648212a812502ec08c1d80c5",
        "optimal_snap_ingredients": {
          "23": [
            {
              "type": "pepperoni",
              "rectangle": [
                392,
                805,
                497,
                913
              ],
              "weight": 0.9832031726837158
            },
            {
              "type": "pepperoni",
              "rectangle": [
                810,
                671,
                929,
                778
              ],
              "weight": 0.9237194061279297
            },
            {
              "type": "pimiento",
              "rectangle": [
                934,
                436,
                986,
                465
              ],
              "weight": 0.9025548100471497
            },
            {
              "type": "pimiento",
              "rectangle": [
                404,
                185,
                478,
                227
              ],
              "weight": 0.893903911113739
            },
            {
              "type": "carne",
              "rectangle": [
                221,
                380,
                289,
                420
              ],
              "weight": 0.8416225910186768
            },
            {
              "type": "pepperoni",
              "rectangle": [
                511,
                850,
                608,
                967
              ],
              "weight": 0.8082153797149658
            },
            {
              "type": "pepperoni",
              "rectangle": [
                497,
                735,
                586,
                801
              ],
              "weight": 0.8017847537994385
            },
            {
              "type": "pepperoni",
              "rectangle": [
                391,
                390,
                492,
                488
              ],
              "weight": 0.8009177446365356
            },
            {
              "type": "carne",
              "rectangle": [
                451,
                643,
                510,
                690
              ],
              "weight": 0.791217029094696
            },
            {
              "type": "jamón",
              "rectangle": [
                418,
                706,
                521,
                800
              ],
              "weight": 0.7902305126190186
            },
            {
              "type": "pimiento",
              "rectangle": [
                850,
                803,
                885,
                833
              ],
              "weight": 0.7661309838294983
            },
            {
              "type": "champiñón",
              "rectangle": [
                740,
                505,
                805,
                559
              ],
              "weight": 0.707364559173584
            },
            {
              "type": "pepperoni",
              "rectangle": [
                717,
                778,
                813,
                856
              ],
              "weight": 0.689778208732605
            },
            {
              "type": "pepperoni",
              "rectangle": [
                467,
                196,
                578,
                312
              ],
              "weight": 0.6025965213775635
            },
            {
              "type": "carne",
              "rectangle": [
                763,
                625,
                809,
                685
              ],
              "weight": 0.5794969201087952
            },
            {
              "type": "jamón",
              "rectangle": [
                428,
                908,
                533,
                971
              ],
              "weight": 0.5567120909690857
            },
            {
              "type": "jamón",
              "rectangle": [
                832,
                403,
                935,
                496
              ],
              "weight": 0.5550780296325684
            },
            {
              "type": "champiñón",
              "rectangle": [
                714,
                464,
                766,
                510
              ],
              "weight": 0.5460574626922607
            },
            {
              "type": "jamón",
              "rectangle": [
                302,
                302,
                402,
                384
              ],
              "weight": 0.5405067205429077
            },
            {
              "type": "pepperoni",
              "rectangle": [
                837,
                576,
                954,
                693
              ],
              "weight": 0.49331167340278625
            },
            {
              "type": "carne",
              "rectangle": [
                860,
                663,
                919,
                713
              ],
              "weight": 0.4403586685657501
            },
            {
              "type": "carne",
              "rectangle": [
                664,
                230,
                721,
                339
              ],
              "weight": 0.42346203327178955
            },
            {
              "type": "carne",
              "rectangle": [
                564,
                521,
                624,
                576
              ],
              "weight": 0.4213840365409851
            },
            {
              "type": "champiñón",
              "rectangle": [
                614,
                381,
                692,
                513
              ],
              "weight": 0.39335495233535767
            },
            {
              "type": "pepperoni",
              "rectangle": [
                589,
                809,
                695,
                909
              ],
              "weight": 0.38504892587661743
            },
            {
              "type": "carne",
              "rectangle": [
                340,
                651,
                380,
                681
              ],
              "weight": 0.37890949845314026
            },
            {
              "type": "carne",
              "rectangle": [
                415,
                557,
                452,
                589
              ],
              "weight": 0.37737759947776794
            },
            {
              "type": "carne",
              "rectangle": [
                388,
                497,
                437,
                541
              ],
              "weight": 0.35058724880218506
            },
            {
              "type": "pimiento",
              "rectangle": [
                696,
                345,
                746,
                395
              ],
              "weight": 0.3503231704235077
            },
            {
              "type": "champiñón",
              "rectangle": [
                449,
                494,
                518,
                544
              ],
              "weight": 0.34738096594810486
            },
            {
              "type": "pollo",
              "rectangle": [
                572,
                411,
                635,
                524
              ],
              "weight": 0.32799771428108215
            },
            {
              "type": "champiñón",
              "rectangle": [
                623,
                497,
                744,
                594
              ],
              "weight": 0.32740890979766846
            },
            {
              "type": "carne",
              "rectangle": [
                678,
                386,
                734,
                447
              ],
              "weight": 0.3111497461795807
            },
            {
              "type": "champiñón",
              "rectangle": [
                576,
                253,
                653,
                330
              ],
              "weight": 0.3024761378765106
            }
          ],
          "24": [
            {
              "type": "pepperoni",
              "rectangle": [
                340,
                565,
                454,
                664
              ],
              "weight": 0.9867021441459656
            },
            {
              "type": "pepperoni",
              "rectangle": [
                539,
                141,
                648,
                285
              ],
              "weight": 0.9847669005393982
            },
            {
              "type": "pepperoni",
              "rectangle": [
                267,
                360,
                361,
                468
              ],
              "weight": 0.9797381162643433
            },
            {
              "type": "pepperoni",
              "rectangle": [
                437,
                437,
                542,
                542
              ],
              "weight": 0.9734164476394653
            },
            {
              "type": "pepperoni",
              "rectangle": [
                499,
                876,
                590,
                1026
              ],
              "weight": 0.9671056866645813
            },
            {
              "type": "pepperoni",
              "rectangle": [
                646,
                430,
                720,
                545
              ],
              "weight": 0.9592565894126892
            },
            {
              "type": "pepperoni",
              "rectangle": [
                247,
                687,
                407,
                886
              ],
              "weight": 0.953291118144989
            },
            {
              "type": "pepperoni",
              "rectangle": [
                245,
                240,
                384,
                336
              ],
              "weight": 0.9446432590484619
            },
            {
              "type": "pepperoni",
              "rectangle": [
                345,
                489,
                465,
                583
              ],
              "weight": 0.9055553078651428
            },
            {
              "type": "pepperoni",
              "rectangle": [
                741,
                277,
                827,
                347
              ],
              "weight": 0.9010744094848633
            },
            {
              "type": "pollo",
              "rectangle": [
                794,
                609,
                874,
                672
              ],
              "weight": 0.8643659949302673
            },
            {
              "type": "pepperoni",
              "rectangle": [
                121,
                425,
                317,
                643
              ],
              "weight": 0.8382814526557922
            },
            {
              "type": "pepperoni",
              "rectangle": [
                627,
                532,
                722,
                624
              ],
              "weight": 0.8041769862174988
            },
            {
              "type": "pepperoni",
              "rectangle": [
                608,
                850,
                812,
                994
              ],
              "weight": 0.749325692653656
            },
            {
              "type": "pepperoni",
              "rectangle": [
                493,
                743,
                557,
                853
              ],
              "weight": 0.7482596635818481
            },
            {
              "type": "pepperoni",
              "rectangle": [
                496,
                526,
                608,
                591
              ],
              "weight": 0.7361575961112976
            },
            {
              "type": "pepperoni",
              "rectangle": [
                563,
                668,
                676,
                722
              ],
              "weight": 0.7164595723152161
            },
            {
              "type": "pepperoni",
              "rectangle": [
                488,
                259,
                567,
                326
              ],
              "weight": 0.7080169320106506
            },
            {
              "type": "pepperoni",
              "rectangle": [
                144,
                394,
                265,
                449
              ],
              "weight": 0.6640716195106506
            },
            {
              "type": "pepperoni",
              "rectangle": [
                299,
                169,
                386,
                257
              ],
              "weight": 0.5499451160430908
            },
            {
              "type": "pepperoni",
              "rectangle": [
                450,
                581,
                529,
                651
              ],
              "weight": 0.37746039032936096
            },
            {
              "type": "carne",
              "rectangle": [
                406,
                716,
                452,
                758
              ],
              "weight": 0.3051976263523102
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "64820c629a532c050defd5c5",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "23": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0.25,
                "3": 0.25,
                "4": 0,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 8.899999999999999,
            "specialty_check": false,
            "score_adjusted": 8.899999999999997
          },
          "24": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0,
                "4": 0,
                "5": 0.25,
                "6": 0,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0,
                "7": 0.1,
                "8": 0
              },
              "Sello de queso": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 8.25,
            "specialty_check": false,
            "score_adjusted": 8.249999999999998
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64820ec2c2d35b02bd6329e9",
        "REPORT_capture_start_ts": "2023/06/08 17:11:35"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "REPORT_localized_capture_start_ts": "13:12:28 2023/06/08",
            "optimal_frame_quality": {
              "blur": 0.000043697102228179574,
              "occ": 0.010031369514763355
            },
            "bubbles": null,
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:11:35_v9p1-ssd-dense_id0026(None)-fr0425-00:53.jpg",
            "snap_quality": "buena",
            "multisize": false,
            "raw_score": false,
            "snap_quality_trusted": true,
            "original_data": null,
            "overbaked": null,
            "id": 26,
            "standard": "to_standard",
            "category": "chicken_garlic",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:11:35_v9p1-ssd-dense_id0026(None)-fr0425-00:53.jpg",
            "REPORT_frame_detection_summary": {},
            "censored": null,
            "attributes": null,
            "omit_soft": true,
            "bad_cut": null,
            "optimal_figure": {
              "coords": [
                1906,
                1183,
                506
              ],
              "__type__": "Circle"
            },
            "REPORT_detections_categ_summary": [],
            "optimal_frame": 425,
            "reviewer": "rodrigo.pulcha",
            "crust": "tradicional"
          },
          {
            "id": 28,
            "REPORT_localized_capture_start_ts": "13:12:25 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:11:35_v9p1-ssd-dense_id0028(None)-fr0404-00:50.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:11:35_v9p1-ssd-dense_id0028(None)-fr0404-00:50.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                766,
                1122,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.01921745017170906,
              "occ": 0.06097957491874695
            },
            "optimal_frame": 404,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 30,
            "REPORT_localized_capture_start_ts": "13:14:59 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:11:35_v9p1-ssd-dense_id0030(None)-fr1632-03:24.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:11:35_v9p1-ssd-dense_id0030(None)-fr1632-03:24.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1210,
                1237,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.003860289230942726,
              "occ": 0.005240166559815407
            },
            "optimal_frame": 1632,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 32,
            "REPORT_localized_capture_start_ts": "13:18:10 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "super_pepperoni",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:11:35_v9p1-ssd-dense_id0032(None)-fr3163-06:35.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:11:35_v9p1-ssd-dense_id0032(None)-fr3163-06:35.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                890,
                1325,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.0000010695066521293484,
              "occ": 0.00022182356042321771
            },
            "optimal_frame": 3163,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 33,
            "REPORT_localized_capture_start_ts": "13:18:54 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "vegetariana",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:11:35_v9p1-ssd-dense_id0033(None)-fr3511-07:19.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:11:35_v9p1-ssd-dense_id0033(None)-fr3511-07:19.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1613,
                1380,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.0000249020285991719,
              "occ": 0.25910067558288574
            },
            "optimal_frame": 3511,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          },
          {
            "id": 37,
            "REPORT_localized_capture_start_ts": "13:20:24 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": null,
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:11:35_v9p1-ssd-dense_id0037(None)-fr4229-08:49.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:11:35_v9p1-ssd-dense_id0037(None)-fr4229-08:49.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "mala",
            "optimal_figure": {
              "coords": [
                1671,
                1358,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.9758578538894653,
              "occ": 0.08888296782970428
            },
            "optimal_frame": 4229,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true
          }
        ],
        "REPORT_id": "648215ab12502ec08c1d80c6",
        "optimal_snap_ingredients": {
          "26": [
            {
              "type": "pollo",
              "rectangle": [
                598,
                807,
                656,
                887
              ],
              "weight": 0.9605332612991333
            },
            {
              "type": "pollo",
              "rectangle": [
                463,
                754,
                521,
                811
              ],
              "weight": 0.9047337174415588
            },
            {
              "type": "pollo",
              "rectangle": [
                453,
                541,
                527,
                631
              ],
              "weight": 0.8859934210777283
            },
            {
              "type": "pollo",
              "rectangle": [
                400,
                219,
                477,
                269
              ],
              "weight": 0.8735548853874207
            },
            {
              "type": "pollo",
              "rectangle": [
                662,
                334,
                723,
                394
              ],
              "weight": 0.8112751841545105
            },
            {
              "type": "pollo",
              "rectangle": [
                369,
                556,
                439,
                588
              ],
              "weight": 0.7788183689117432
            },
            {
              "type": "pollo",
              "rectangle": [
                371,
                740,
                430,
                796
              ],
              "weight": 0.7497187256813049
            },
            {
              "type": "pepperoni",
              "rectangle": [
                659,
                824,
                778,
                947
              ],
              "weight": 0.7245606184005737
            },
            {
              "type": "pepperoni",
              "rectangle": [
                189,
                645,
                346,
                805
              ],
              "weight": 0.6814591884613037
            },
            {
              "type": "pollo",
              "rectangle": [
                471,
                846,
                536,
                903
              ],
              "weight": 0.6382599472999573
            },
            {
              "type": "pollo",
              "rectangle": [
                706,
                724,
                749,
                777
              ],
              "weight": 0.6224800944328308
            },
            {
              "type": "carne",
              "rectangle": [
                500,
                293,
                568,
                360
              ],
              "weight": 0.6211041808128357
            },
            {
              "type": "carne",
              "rectangle": [
                255,
                297,
                356,
                364
              ],
              "weight": 0.6009974479675293
            },
            {
              "type": "pollo",
              "rectangle": [
                607,
                447,
                678,
                493
              ],
              "weight": 0.574150025844574
            },
            {
              "type": "carne",
              "rectangle": [
                465,
                166,
                500,
                215
              ],
              "weight": 0.5263763070106506
            },
            {
              "type": "carne",
              "rectangle": [
                804,
                335,
                867,
                397
              ],
              "weight": 0.5084324479103088
            },
            {
              "type": "carne",
              "rectangle": [
                449,
                794,
                482,
                867
              ],
              "weight": 0.4654337763786316
            },
            {
              "type": "carne",
              "rectangle": [
                498,
                500,
                552,
                558
              ],
              "weight": 0.4638521671295166
            },
            {
              "type": "pollo",
              "rectangle": [
                440,
                330,
                492,
                396
              ],
              "weight": 0.45698466897010803
            },
            {
              "type": "carne",
              "rectangle": [
                217,
                360,
                266,
                408
              ],
              "weight": 0.4300439655780792
            },
            {
              "type": "pollo",
              "rectangle": [
                559,
                264,
                694,
                338
              ],
              "weight": 0.3813681900501251
            },
            {
              "type": "pollo",
              "rectangle": [
                400,
                626,
                453,
                681
              ],
              "weight": 0.3753487169742584
            },
            {
              "type": "carne",
              "rectangle": [
                538,
                155,
                589,
                221
              ],
              "weight": 0.350906640291214
            },
            {
              "type": "pollo",
              "rectangle": [
                495,
                631,
                523,
                701
              ],
              "weight": 0.309773325920105
            }
          ],
          "32": [
            {
              "type": "pepperoni",
              "rectangle": [
                196,
                573,
                313,
                686
              ],
              "weight": 0.9986361861228943
            },
            {
              "type": "pepperoni",
              "rectangle": [
                662,
                583,
                768,
                698
              ],
              "weight": 0.9978208541870117
            },
            {
              "type": "pepperoni",
              "rectangle": [
                161,
                453,
                267,
                551
              ],
              "weight": 0.9976325035095215
            },
            {
              "type": "pepperoni",
              "rectangle": [
                756,
                674,
                869,
                780
              ],
              "weight": 0.9975528120994568
            },
            {
              "type": "pepperoni",
              "rectangle": [
                458,
                662,
                568,
                768
              ],
              "weight": 0.9965119957923889
            },
            {
              "type": "pepperoni",
              "rectangle": [
                808,
                570,
                919,
                676
              ],
              "weight": 0.9964802861213684
            },
            {
              "type": "pepperoni",
              "rectangle": [
                590,
                803,
                700,
                917
              ],
              "weight": 0.99638831615448
            },
            {
              "type": "pepperoni",
              "rectangle": [
                431,
                140,
                534,
                253
              ],
              "weight": 0.9962815642356873
            },
            {
              "type": "pepperoni",
              "rectangle": [
                229,
                681,
                340,
                792
              ],
              "weight": 0.996180534362793
            },
            {
              "type": "pepperoni",
              "rectangle": [
                569,
                542,
                674,
                622
              ],
              "weight": 0.9953275918960571
            },
            {
              "type": "pepperoni",
              "rectangle": [
                328,
                219,
                431,
                322
              ],
              "weight": 0.9952906370162964
            },
            {
              "type": "pepperoni",
              "rectangle": [
                587,
                649,
                680,
                751
              ],
              "weight": 0.9952345490455627
            },
            {
              "type": "pepperoni",
              "rectangle": [
                295,
                537,
                412,
                613
              ],
              "weight": 0.9947400689125061
            },
            {
              "type": "pepperoni",
              "rectangle": [
                464,
                382,
                571,
                483
              ],
              "weight": 0.9945765733718872
            },
            {
              "type": "pepperoni",
              "rectangle": [
                678,
                768,
                774,
                878
              ],
              "weight": 0.9943056702613831
            },
            {
              "type": "pepperoni",
              "rectangle": [
                492,
                595,
                583,
                675
              ],
              "weight": 0.9934096336364746
            },
            {
              "type": "pepperoni",
              "rectangle": [
                574,
                318,
                684,
                423
              ],
              "weight": 0.9928743839263916
            },
            {
              "type": "pepperoni",
              "rectangle": [
                789,
                328,
                891,
                438
              ],
              "weight": 0.9919750094413757
            },
            {
              "type": "pepperoni",
              "rectangle": [
                300,
                370,
                396,
                471
              ],
              "weight": 0.9908353090286255
            },
            {
              "type": "pepperoni",
              "rectangle": [
                364,
                309,
                469,
                403
              ],
              "weight": 0.9906965494155884
            },
            {
              "type": "pepperoni",
              "rectangle": [
                148,
                356,
                266,
                451
              ],
              "weight": 0.9888173341751099
            },
            {
              "type": "pepperoni",
              "rectangle": [
                375,
                619,
                467,
                714
              ],
              "weight": 0.98703533411026
            },
            {
              "type": "pepperoni",
              "rectangle": [
                643,
                181,
                748,
                283
              ],
              "weight": 0.987016499042511
            },
            {
              "type": "pepperoni",
              "rectangle": [
                552,
                141,
                624,
                253
              ],
              "weight": 0.9842190146446228
            },
            {
              "type": "pepperoni",
              "rectangle": [
                240,
                239,
                311,
                325
              ],
              "weight": 0.981999933719635
            },
            {
              "type": "pepperoni",
              "rectangle": [
                855,
                481,
                978,
                552
              ],
              "weight": 0.9762567281723022
            },
            {
              "type": "pepperoni",
              "rectangle": [
                684,
                515,
                796,
                557
              ],
              "weight": 0.9675190448760986
            },
            {
              "type": "pepperoni",
              "rectangle": [
                575,
                447,
                672,
                540
              ],
              "weight": 0.9674950838088989
            },
            {
              "type": "pepperoni",
              "rectangle": [
                315,
                731,
                429,
                829
              ],
              "weight": 0.955565869808197
            },
            {
              "type": "pepperoni",
              "rectangle": [
                688,
                365,
                776,
                462
              ],
              "weight": 0.9513887763023376
            },
            {
              "type": "pepperoni",
              "rectangle": [
                513,
                300,
                571,
                401
              ],
              "weight": 0.9455347061157227
            },
            {
              "type": "pepperoni",
              "rectangle": [
                503,
                821,
                590,
                935
              ],
              "weight": 0.9264797568321228
            },
            {
              "type": "pepperoni",
              "rectangle": [
                430,
                555,
                501,
                623
              ],
              "weight": 0.7504994869232178
            },
            {
              "type": "pepperoni",
              "rectangle": [
                407,
                460,
                496,
                555
              ],
              "weight": 0.5047693848609924
            }
          ],
          "33": [
            {
              "type": "tomate",
              "rectangle": [
                175,
                411,
                375,
                618
              ],
              "weight": 0.9873150587081909
            },
            {
              "type": "tomate",
              "rectangle": [
                351,
                622,
                575,
                829
              ],
              "weight": 0.9743392467498779
            },
            {
              "type": "tomate",
              "rectangle": [
                138,
                660,
                353,
                847
              ],
              "weight": 0.973893940448761
            },
            {
              "type": "aceituna",
              "rectangle": [
                884,
                501,
                932,
                549
              ],
              "weight": 0.9699487090110779
            },
            {
              "type": "aceituna",
              "rectangle": [
                562,
                768,
                617,
                823
              ],
              "weight": 0.9679649472236633
            },
            {
              "type": "tomate",
              "rectangle": [
                635,
                326,
                830,
                519
              ],
              "weight": 0.9541038274765015
            },
            {
              "type": "pimiento",
              "rectangle": [
                96,
                534,
                177,
                626
              ],
              "weight": 0.95047527551651
            },
            {
              "type": "tomate",
              "rectangle": [
                551,
                515,
                775,
                724
              ],
              "weight": 0.9375854730606079
            },
            {
              "type": "aceituna",
              "rectangle": [
                323,
                335,
                378,
                387
              ],
              "weight": 0.9343202114105225
            },
            {
              "type": "tomate",
              "rectangle": [
                392,
                241,
                582,
                414
              ],
              "weight": 0.9296461343765259
            },
            {
              "type": "aceituna",
              "rectangle": [
                914,
                538,
                957,
                571
              ],
              "weight": 0.9239059686660767
            },
            {
              "type": "tomate",
              "rectangle": [
                376,
                436,
                544,
                635
              ],
              "weight": 0.8672794699668884
            },
            {
              "type": "aceituna",
              "rectangle": [
                546,
                374,
                585,
                417
              ],
              "weight": 0.8263210654258728
            },
            {
              "type": "pimiento",
              "rectangle": [
                550,
                249,
                597,
                272
              ],
              "weight": 0.8191644549369812
            },
            {
              "type": "aceituna",
              "rectangle": [
                686,
                305,
                735,
                332
              ],
              "weight": 0.8161476850509644
            },
            {
              "type": "pimiento",
              "rectangle": [
                668,
                782,
                703,
                846
              ],
              "weight": 0.8140636682510376
            },
            {
              "type": "pepperoni",
              "rectangle": [
                723,
                607,
                942,
                826
              ],
              "weight": 0.7796423435211182
            },
            {
              "type": "pimiento",
              "rectangle": [
                856,
                495,
                891,
                531
              ],
              "weight": 0.7352133393287659
            },
            {
              "type": "pimiento",
              "rectangle": [
                615,
                286,
                651,
                360
              ],
              "weight": 0.7242618799209595
            },
            {
              "type": "pimiento",
              "rectangle": [
                567,
                696,
                610,
                733
              ],
              "weight": 0.7214998006820679
            },
            {
              "type": "pimiento",
              "rectangle": [
                541,
                492,
                574,
                522
              ],
              "weight": 0.6657397150993347
            },
            {
              "type": "aceituna",
              "rectangle": [
                375,
                361,
                425,
                411
              ],
              "weight": 0.6412332653999329
            },
            {
              "type": "champiñón",
              "rectangle": [
                747,
                910,
                854,
                979
              ],
              "weight": 0.6061251163482666
            },
            {
              "type": "aceituna",
              "rectangle": [
                299,
                605,
                335,
                627
              ],
              "weight": 0.5629351139068604
            },
            {
              "type": "aceituna",
              "rectangle": [
                498,
                429,
                538,
                462
              ],
              "weight": 0.5354785919189453
            },
            {
              "type": "aceituna",
              "rectangle": [
                355,
                299,
                392,
                324
              ],
              "weight": 0.5239285826683044
            },
            {
              "type": "champiñón",
              "rectangle": [
                747,
                510,
                824,
                612
              ],
              "weight": 0.5187953114509583
            },
            {
              "type": "carne",
              "rectangle": [
                159,
                383,
                224,
                455
              ],
              "weight": 0.499003142118454
            },
            {
              "type": "champiñón",
              "rectangle": [
                530,
                801,
                578,
                841
              ],
              "weight": 0.4749075770378113
            },
            {
              "type": "pimiento",
              "rectangle": [
                929,
                606,
                953,
                634
              ],
              "weight": 0.45875468850135803
            },
            {
              "type": "aceituna",
              "rectangle": [
                719,
                782,
                762,
                844
              ],
              "weight": 0.4404364824295044
            },
            {
              "type": "aceituna",
              "rectangle": [
                604,
                821,
                634,
                852
              ],
              "weight": 0.39783981442451477
            },
            {
              "type": "champiñón",
              "rectangle": [
                677,
                720,
                738,
                794
              ],
              "weight": 0.38315272331237793
            },
            {
              "type": "aceituna",
              "rectangle": [
                648,
                475,
                669,
                500
              ],
              "weight": 0.35744932293891907
            },
            {
              "type": "aceituna",
              "rectangle": [
                801,
                917,
                839,
                944
              ],
              "weight": 0.3404521346092224
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "64820ec2c2d35b02bd6329e9",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "26": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0.25,
                "3": 0.25,
                "4": 0,
                "5": 0,
                "6": 0.25,
                "7": 0.25,
                "8": 0
              },
              "Bordes": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0
              },
              "Sello de queso": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0.1,
                "6": 0,
                "7": 0,
                "8": 0
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 6.8999999999999995,
            "specialty_check": false,
            "score_adjusted": 6.900000000000002
          },
          "32": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0,
                "7": 0.25,
                "8": 0
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0.25,
                "3": 0.25,
                "4": 0,
                "5": 0,
                "6": 0.25,
                "7": 0.25,
                "8": 0
              },
              "Bordes": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 7.55,
            "specialty_check": true,
            "score_adjusted": 7.549999999999997
          },
          "33": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0,
                "7": 0,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 8.25,
            "specialty_check": false,
            "score_adjusted": 8.25
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64821388d2626a97b4a6cc45",
        "REPORT_capture_start_ts": "2023/06/08 17:31:36"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 38,
            "REPORT_localized_capture_start_ts": "13:31:43 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:31:36_v9p1-ssd-dense_id0038(None)-fr0053-00:07.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:31:36_v9p1-ssd-dense_id0038(None)-fr0053-00:07.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1230,
                1080,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.000001088565113605,
              "occ": 0.00000497347537020687
            },
            "optimal_frame": 53,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "REPORT_localized_capture_start_ts": "13:32:21 2023/06/08",
            "optimal_frame_quality": {
              "blur": 4.917442097962521e-8,
              "occ": 0.0902530774474144
            },
            "bubbles": 1,
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:31:36_v9p1-ssd-dense_id0039(None)-fr0357-00:45.jpg",
            "snap_quality": "buena",
            "multisize": false,
            "raw_score": false,
            "snap_quality_trusted": true,
            "original_data": {
              "result": {
                "score_details": {
                  "Ingredientes interior": {
                    "1": 0.25,
                    "2": 0.25,
                    "3": 0.25,
                    "4": 0.25,
                    "5": 0.25,
                    "6": 0.25,
                    "7": 0.25,
                    "8": 0.25
                  },
                  "Queso interior": {
                    "1": 0.1,
                    "2": 0.1,
                    "3": 0.1,
                    "4": 0.1,
                    "5": 0.1,
                    "6": 0.1,
                    "7": 0.1,
                    "8": 0.1
                  },
                  "Ingredientes exterior": {
                    "1": 0.25,
                    "2": 0.25,
                    "3": 0.25,
                    "4": 0.25,
                    "5": 0.25,
                    "6": 0.25,
                    "7": 0,
                    "8": 0.25
                  },
                  "Bordes": {
                    "1": 0.1,
                    "2": 0,
                    "3": 0,
                    "4": 0.1,
                    "5": 0.1,
                    "6": 0.1,
                    "7": 0.1,
                    "8": 0.1
                  },
                  "Cocción queso": {
                    "1": 0.1,
                    "2": 0.1,
                    "3": 0.1,
                    "4": 0.1,
                    "5": 0.1,
                    "6": 0.1,
                    "7": 0.1,
                    "8": 0.1
                  },
                  "Queso exterior": {
                    "1": 0.1,
                    "2": 0.1,
                    "3": 0.1,
                    "4": 0.1,
                    "5": 0.1,
                    "6": 0.1,
                    "7": 0.1,
                    "8": 0.1
                  },
                  "Sello de queso": {
                    "1": 0.1,
                    "2": 0,
                    "3": 0.1,
                    "4": 0,
                    "5": 0.1,
                    "6": 0.1,
                    "7": 0.1,
                    "8": 0.1
                  },
                  "Limpieza": {
                    "1": 0.1,
                    "2": 0.1,
                    "3": 0.1,
                    "4": 0.1,
                    "5": 0.1,
                    "6": 0.1,
                    "7": 0.1,
                    "8": 0.1
                  }
                },
                "score_final": 9.349999999999998,
                "specialty_check": false,
                "score_adjusted": 9.349999999999996
              }
            },
            "overbaked": 1,
            "id": 39,
            "standard": "maybe_to_standard",
            "category": "napolitana",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:31:36_v9p1-ssd-dense_id0039(None)-fr0357-00:45.jpg",
            "REPORT_frame_detection_summary": {},
            "censored": null,
            "attributes": null,
            "omit_soft": true,
            "bad_cut": 1,
            "optimal_figure": {
              "coords": [
                1166,
                1265,
                506
              ],
              "__type__": "Circle"
            },
            "REPORT_detections_categ_summary": [],
            "optimal_frame": 357,
            "reviewer": "rodrigo.pulcha",
            "crust": "delgada"
          },
          {
            "id": 40,
            "REPORT_localized_capture_start_ts": "13:35:11 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "pepperoni",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:31:36_v9p1-ssd-dense_id0040(None)-fr1723-03:35.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:31:36_v9p1-ssd-dense_id0040(None)-fr1723-03:35.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1285,
                1273,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.001146963331848383,
              "occ": 0.0009090009261853993
            },
            "optimal_frame": 1723,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 41,
            "REPORT_localized_capture_start_ts": "13:36:43 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "sweet_blue_cheese",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:31:36_v9p1-ssd-dense_id0041(None)-fr2457-05:07.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:31:36_v9p1-ssd-dense_id0041(None)-fr2457-05:07.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1285,
                1278,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.000004115227966394741,
              "occ": 0.059845104813575745
            },
            "optimal_frame": 2457,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 43,
            "REPORT_localized_capture_start_ts": "13:37:51 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:31:36_v9p1-ssd-dense_id0043(None)-fr2999-06:15.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:31:36_v9p1-ssd-dense_id0043(None)-fr2999-06:15.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1474,
                1241,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00060796900652349,
              "occ": 0.000930876936763525
            },
            "optimal_frame": 2999,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          },
          {
            "id": 46,
            "REPORT_localized_capture_start_ts": "13:40:51 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:31:36_v9p1-ssd-dense_id0046(None)-fr4441-09:15.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:31:36_v9p1-ssd-dense_id0046(None)-fr4441-09:15.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1993,
                1322,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.004660365171730518,
              "occ": 0.0011335760354995728
            },
            "optimal_frame": 4441,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "6482191b12502ec08c1d80c7",
        "optimal_snap_ingredients": {
          "39": [
            {
              "type": "tomate",
              "rectangle": [
                712,
                581,
                927,
                798
              ],
              "weight": 0.9821245670318604
            },
            {
              "type": "tomate",
              "rectangle": [
                218,
                747,
                440,
                952
              ],
              "weight": 0.9773434400558472
            },
            {
              "type": "tomate",
              "rectangle": [
                560,
                355,
                769,
                535
              ],
              "weight": 0.9729114770889282
            },
            {
              "type": "tomate",
              "rectangle": [
                506,
                802,
                707,
                1012
              ],
              "weight": 0.9725397825241089
            },
            {
              "type": "tomate",
              "rectangle": [
                84,
                507,
                286,
                696
              ],
              "weight": 0.9711729288101196
            },
            {
              "type": "tomate",
              "rectangle": [
                106,
                255,
                311,
                459
              ],
              "weight": 0.9572656750679016
            },
            {
              "type": "tomate",
              "rectangle": [
                356,
                331,
                557,
                503
              ],
              "weight": 0.9566817879676819
            },
            {
              "type": "tomate",
              "rectangle": [
                399,
                551,
                579,
                745
              ],
              "weight": 0.9313859939575195
            },
            {
              "type": "tomate",
              "rectangle": [
                755,
                294,
                958,
                496
              ],
              "weight": 0.9210544228553772
            },
            {
              "type": "tomate",
              "rectangle": [
                270,
                101,
                471,
                281
              ],
              "weight": 0.872936487197876
            },
            {
              "type": "tomate",
              "rectangle": [
                560,
                79,
                751,
                268
              ],
              "weight": 0.8349964618682861
            },
            {
              "type": "pepperoni",
              "rectangle": [
                463,
                108,
                571,
                235
              ],
              "weight": 0.6454411745071411
            },
            {
              "type": "pollo",
              "rectangle": [
                603,
                718,
                706,
                825
              ],
              "weight": 0.5423341989517212
            },
            {
              "type": "pepperoni",
              "rectangle": [
                368,
                243,
                476,
                359
              ],
              "weight": 0.5048190951347351
            },
            {
              "type": "pepperoni",
              "rectangle": [
                540,
                301,
                643,
                387
              ],
              "weight": 0.4312286078929901
            },
            {
              "type": "choclo",
              "rectangle": [
                536,
                451,
                564,
                480
              ],
              "weight": 0.4236563444137573
            },
            {
              "type": "pepperoni",
              "rectangle": [
                635,
                223,
                711,
                281
              ],
              "weight": 0.4193895161151886
            },
            {
              "type": "pepperoni",
              "rectangle": [
                473,
                272,
                562,
                358
              ],
              "weight": 0.3932616114616394
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "64821388d2626a97b4a6cc45",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "39": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0,
                "3": 0.1,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 2.7833333333333328,
            "specialty_check": false,
            "score_adjusted": 2.7833333333333328
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648215e9b44b9c59c3571496",
        "REPORT_capture_start_ts": "2023/06/08 17:41:37"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 49,
            "REPORT_localized_capture_start_ts": "13:42:09 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "napolitana",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:41:37_v9p1-ssd-dense_id0049(None)-fr0260-00:32.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:41:37_v9p1-ssd-dense_id0049(None)-fr0260-00:32.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1594,
                1278,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 3.320039922982687e-7,
              "occ": 0.0198186207562685
            },
            "optimal_frame": 260,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          },
          {
            "id": 52,
            "REPORT_localized_capture_start_ts": "13:43:09 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "napolitana",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:41:37_v9p1-ssd-dense_id0052(None)-fr0739-01:32.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:41:37_v9p1-ssd-dense_id0052(None)-fr0739-01:32.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1103,
                1363,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 2.941905847819726e-7,
              "occ": 0.00047269914648495615
            },
            "optimal_frame": 739,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 53,
            "REPORT_localized_capture_start_ts": "13:44:46 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:41:37_v9p1-ssd-dense_id0053(None)-fr1514-03:09.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:41:37_v9p1-ssd-dense_id0053(None)-fr1514-03:09.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1629,
                1345,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.000006912438493600348,
              "occ": 0.006807869765907526
            },
            "optimal_frame": 1514,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 54,
            "REPORT_localized_capture_start_ts": "13:47:37 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "hawaiana",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:41:37_v9p1-ssd-dense_id0054(None)-fr2878-06:00.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:41:37_v9p1-ssd-dense_id0054(None)-fr2878-06:00.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1596,
                1223,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 2.7073514541342547e-9,
              "occ": 0.07909000664949417
            },
            "optimal_frame": 2878,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 55,
            "REPORT_localized_capture_start_ts": "13:48:30 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "napolitana",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:41:37_v9p1-ssd-dense_id0055(None)-fr3304-06:53.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:41:37_v9p1-ssd-dense_id0055(None)-fr3304-06:53.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1187,
                1295,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00006159424810903147,
              "occ": 0.05289917439222336
            },
            "optimal_frame": 3304,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "64821c4012502ec08c1d80c8",
        "optimal_snap_ingredients": {
          "49": [
            {
              "type": "tomate",
              "rectangle": [
                313,
                211,
                493,
                409
              ],
              "weight": 0.9654333591461182
            },
            {
              "type": "tomate",
              "rectangle": [
                478,
                591,
                690,
                807
              ],
              "weight": 0.9574557542800903
            },
            {
              "type": "tomate",
              "rectangle": [
                209,
                485,
                409,
                668
              ],
              "weight": 0.9395111203193665
            },
            {
              "type": "tomate",
              "rectangle": [
                575,
                421,
                781,
                611
              ],
              "weight": 0.835750162601471
            },
            {
              "type": "pepperoni",
              "rectangle": [
                555,
                176,
                759,
                363
              ],
              "weight": 0.8067050576210022
            },
            {
              "type": "pepperoni",
              "rectangle": [
                376,
                400,
                589,
                602
              ],
              "weight": 0.799699604511261
            },
            {
              "type": "tomate",
              "rectangle": [
                663,
                612,
                865,
                816
              ],
              "weight": 0.6967535614967346
            },
            {
              "type": "pepperoni",
              "rectangle": [
                740,
                384,
                903,
                565
              ],
              "weight": 0.6322858333587646
            },
            {
              "type": "pepperoni",
              "rectangle": [
                721,
                605,
                849,
                675
              ],
              "weight": 0.5367690920829773
            },
            {
              "type": "pepperoni",
              "rectangle": [
                393,
                733,
                595,
                950
              ],
              "weight": 0.33093148469924927
            }
          ],
          "52": [
            {
              "type": "tomate",
              "rectangle": [
                419,
                569,
                580,
                746
              ],
              "weight": 0.9326205253601074
            },
            {
              "type": "pepperoni",
              "rectangle": [
                520,
                729,
                678,
                900
              ],
              "weight": 0.9246508479118347
            },
            {
              "type": "tomate",
              "rectangle": [
                399,
                382,
                595,
                556
              ],
              "weight": 0.9110658764839172
            },
            {
              "type": "pepperoni",
              "rectangle": [
                743,
                393,
                897,
                550
              ],
              "weight": 0.8826377987861633
            },
            {
              "type": "pepperoni",
              "rectangle": [
                704,
                682,
                887,
                806
              ],
              "weight": 0.8601990938186646
            },
            {
              "type": "pollo",
              "rectangle": [
                210,
                554,
                269,
                602
              ],
              "weight": 0.6581131219863892
            },
            {
              "type": "tomate",
              "rectangle": [
                675,
                499,
                743,
                563
              ],
              "weight": 0.6092455387115479
            },
            {
              "type": "pepperoni",
              "rectangle": [
                606,
                502,
                744,
                664
              ],
              "weight": 0.5821126103401184
            },
            {
              "type": "pepperoni",
              "rectangle": [
                590,
                298,
                725,
                410
              ],
              "weight": 0.5449922680854797
            },
            {
              "type": "pepperoni",
              "rectangle": [
                261,
                535,
                366,
                597
              ],
              "weight": 0.538833498954773
            },
            {
              "type": "tomate",
              "rectangle": [
                256,
                611,
                445,
                821
              ],
              "weight": 0.5343522429466248
            },
            {
              "type": "tocino",
              "rectangle": [
                443,
                237,
                503,
                265
              ],
              "weight": 0.4421655237674713
            },
            {
              "type": "pepperoni",
              "rectangle": [
                788,
                281,
                905,
                380
              ],
              "weight": 0.3731961250305176
            },
            {
              "type": "pepperoni",
              "rectangle": [
                442,
                238,
                562,
                360
              ],
              "weight": 0.3663473427295685
            }
          ],
          "54": [
            {
              "type": "anana",
              "rectangle": [
                758,
                534,
                788,
                573
              ],
              "weight": 0.9781439900398254
            },
            {
              "type": "anana",
              "rectangle": [
                670,
                203,
                726,
                254
              ],
              "weight": 0.9780827760696411
            },
            {
              "type": "anana",
              "rectangle": [
                380,
                571,
                427,
                638
              ],
              "weight": 0.9675812125205994
            },
            {
              "type": "anana",
              "rectangle": [
                269,
                461,
                331,
                535
              ],
              "weight": 0.9665937423706055
            },
            {
              "type": "anana",
              "rectangle": [
                744,
                461,
                788,
                536
              ],
              "weight": 0.9584183096885681
            },
            {
              "type": "anana",
              "rectangle": [
                785,
                470,
                818,
                533
              ],
              "weight": 0.9574346542358398
            },
            {
              "type": "anana",
              "rectangle": [
                342,
                642,
                417,
                672
              ],
              "weight": 0.9539421796798706
            },
            {
              "type": "anana",
              "rectangle": [
                470,
                485,
                526,
                538
              ],
              "weight": 0.9509316086769104
            },
            {
              "type": "anana",
              "rectangle": [
                437,
                678,
                477,
                747
              ],
              "weight": 0.9476785659790039
            },
            {
              "type": "anana",
              "rectangle": [
                226,
                652,
                291,
                698
              ],
              "weight": 0.9340172410011292
            },
            {
              "type": "pepperoni",
              "rectangle": [
                427,
                265,
                541,
                377
              ],
              "weight": 0.9102084040641785
            },
            {
              "type": "anana",
              "rectangle": [
                288,
                649,
                333,
                709
              ],
              "weight": 0.8987927436828613
            },
            {
              "type": "anana",
              "rectangle": [
                588,
                686,
                649,
                732
              ],
              "weight": 0.8984254002571106
            },
            {
              "type": "anana",
              "rectangle": [
                598,
                760,
                641,
                807
              ],
              "weight": 0.8948504328727722
            },
            {
              "type": "pepperoni",
              "rectangle": [
                794,
                634,
                897,
                745
              ],
              "weight": 0.8937492370605469
            },
            {
              "type": "pepperoni",
              "rectangle": [
                438,
                781,
                564,
                883
              ],
              "weight": 0.8830516934394836
            },
            {
              "type": "anana",
              "rectangle": [
                416,
                766,
                478,
                826
              ],
              "weight": 0.8771111965179443
            },
            {
              "type": "pepperoni",
              "rectangle": [
                467,
                663,
                591,
                767
              ],
              "weight": 0.8665581941604614
            },
            {
              "type": "anana",
              "rectangle": [
                433,
                454,
                469,
                515
              ],
              "weight": 0.8598514795303345
            },
            {
              "type": "anana",
              "rectangle": [
                268,
                583,
                327,
                628
              ],
              "weight": 0.8427863121032715
            },
            {
              "type": "jamón",
              "rectangle": [
                450,
                415,
                557,
                494
              ],
              "weight": 0.8423232436180115
            },
            {
              "type": "pepperoni",
              "rectangle": [
                215,
                694,
                334,
                799
              ],
              "weight": 0.818803608417511
            },
            {
              "type": "anana",
              "rectangle": [
                885,
                548,
                920,
                608
              ],
              "weight": 0.8115350604057312
            },
            {
              "type": "anana",
              "rectangle": [
                554,
                338,
                595,
                374
              ],
              "weight": 0.8042582869529724
            },
            {
              "type": "anana",
              "rectangle": [
                253,
                206,
                323,
                239
              ],
              "weight": 0.7842761874198914
            },
            {
              "type": "anana",
              "rectangle": [
                213,
                444,
                262,
                543
              ],
              "weight": 0.7675014138221741
            },
            {
              "type": "anana",
              "rectangle": [
                421,
                571,
                458,
                636
              ],
              "weight": 0.7446426749229431
            },
            {
              "type": "anana",
              "rectangle": [
                396,
                496,
                449,
                559
              ],
              "weight": 0.7438790202140808
            },
            {
              "type": "pepperoni",
              "rectangle": [
                230,
                278,
                316,
                389
              ],
              "weight": 0.7324697971343994
            },
            {
              "type": "carne",
              "rectangle": [
                138,
                536,
                215,
                583
              ],
              "weight": 0.7258556485176086
            },
            {
              "type": "anana",
              "rectangle": [
                418,
                651,
                468,
                686
              ],
              "weight": 0.7081770896911621
            },
            {
              "type": "pepperoni",
              "rectangle": [
                712,
                260,
                790,
                370
              ],
              "weight": 0.6911426782608032
            },
            {
              "type": "pepperoni",
              "rectangle": [
                554,
                348,
                666,
                461
              ],
              "weight": 0.6727337837219238
            },
            {
              "type": "pepperoni",
              "rectangle": [
                676,
                778,
                772,
                887
              ],
              "weight": 0.6726492047309875
            },
            {
              "type": "anana",
              "rectangle": [
                371,
                370,
                407,
                430
              ],
              "weight": 0.654937744140625
            },
            {
              "type": "jamón",
              "rectangle": [
                354,
                142,
                434,
                223
              ],
              "weight": 0.5320619940757751
            },
            {
              "type": "jamón",
              "rectangle": [
                796,
                412,
                890,
                504
              ],
              "weight": 0.5187325477600098
            },
            {
              "type": "anana",
              "rectangle": [
                532,
                271,
                566,
                360
              ],
              "weight": 0.5185366272926331
            },
            {
              "type": "tocino",
              "rectangle": [
                258,
                555,
                366,
                588
              ],
              "weight": 0.5020896792411804
            },
            {
              "type": "anana",
              "rectangle": [
                640,
                304,
                681,
                352
              ],
              "weight": 0.45624375343322754
            },
            {
              "type": "pepperoni",
              "rectangle": [
                776,
                318,
                892,
                417
              ],
              "weight": 0.436102956533432
            },
            {
              "type": "carne",
              "rectangle": [
                230,
                368,
                268,
                412
              ],
              "weight": 0.4237707257270813
            },
            {
              "type": "carne",
              "rectangle": [
                538,
                191,
                579,
                259
              ],
              "weight": 0.4231092035770416
            },
            {
              "type": "pepperoni",
              "rectangle": [
                545,
                165,
                644,
                251
              ],
              "weight": 0.4005461633205414
            },
            {
              "type": "anana",
              "rectangle": [
                709,
                425,
                781,
                459
              ],
              "weight": 0.3819289207458496
            },
            {
              "type": "anana",
              "rectangle": [
                581,
                602,
                609,
                663
              ],
              "weight": 0.38053369522094727
            },
            {
              "type": "anana",
              "rectangle": [
                361,
                671,
                416,
                718
              ],
              "weight": 0.3670504093170166
            },
            {
              "type": "anana",
              "rectangle": [
                532,
                461,
                643,
                595
              ],
              "weight": 0.3430572748184204
            },
            {
              "type": "carne",
              "rectangle": [
                164,
                491,
                214,
                527
              ],
              "weight": 0.34199678897857666
            },
            {
              "type": "carne",
              "rectangle": [
                628,
                434,
                685,
                483
              ],
              "weight": 0.3035312294960022
            },
            {
              "type": "anana",
              "rectangle": [
                770,
                641,
                800,
                704
              ],
              "weight": 0.30062219500541687
            }
          ],
          "55": [
            {
              "type": "tomate",
              "rectangle": [
                438,
                587,
                649,
                787
              ],
              "weight": 0.9366437792778015
            },
            {
              "type": "tomate",
              "rectangle": [
                523,
                148,
                717,
                347
              ],
              "weight": 0.9146316051483154
            },
            {
              "type": "tomate",
              "rectangle": [
                534,
                397,
                729,
                592
              ],
              "weight": 0.901468813419342
            },
            {
              "type": "tomate",
              "rectangle": [
                335,
                456,
                540,
                621
              ],
              "weight": 0.6625851988792419
            },
            {
              "type": "pepperoni",
              "rectangle": [
                162,
                506,
                297,
                680
              ],
              "weight": 0.647544801235199
            },
            {
              "type": "pepperoni",
              "rectangle": [
                749,
                403,
                856,
                496
              ],
              "weight": 0.5975708961486816
            },
            {
              "type": "choclo",
              "rectangle": [
                719,
                638,
                745,
                664
              ],
              "weight": 0.5756233334541321
            },
            {
              "type": "pepperoni",
              "rectangle": [
                367,
                281,
                429,
                350
              ],
              "weight": 0.4174340069293976
            },
            {
              "type": "tomate",
              "rectangle": [
                666,
                575,
                731,
                637
              ],
              "weight": 0.40652740001678467
            },
            {
              "type": "anana",
              "rectangle": [
                708,
                717,
                755,
                758
              ],
              "weight": 0.3890277147293091
            },
            {
              "type": "pepperoni",
              "rectangle": [
                613,
                606,
                664,
                746
              ],
              "weight": 0.37677836418151855
            },
            {
              "type": "pepperoni",
              "rectangle": [
                558,
                866,
                675,
                929
              ],
              "weight": 0.30841949582099915
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "648215e9b44b9c59c3571496",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "49": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0,
                "3": 0.25,
                "4": 0.25,
                "5": 0,
                "6": 0,
                "7": 0.25,
                "8": 0
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Limpieza": {
                "1": 0,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0,
                "7": 0,
                "8": 0
              }
            },
            "score_final": 7.55,
            "specialty_check": false,
            "score_adjusted": 7.549999999999999
          },
          "52": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0
              },
              "Bordes": {
                "1": 0,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0,
                "8": 0
              },
              "Sello de queso": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0,
                "8": 0
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 7,
            "specialty_check": false,
            "score_adjusted": 6.999999999999998
          },
          "54": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0,
                "7": 0,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0,
                "2": 0,
                "3": 0.1,
                "4": 0,
                "5": 0,
                "6": 0.1,
                "7": 0,
                "8": 0
              },
              "Sello de queso": {
                "1": 0,
                "2": 0,
                "3": 0.1,
                "4": 0,
                "5": 0,
                "6": 0.1,
                "7": 0,
                "8": 0
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 7.7,
            "specialty_check": false,
            "score_adjusted": 7.700000000000001
          },
          "55": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0.25,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0
              },
              "Bordes": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0.1,
                "6": 0,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 7.45,
            "specialty_check": false,
            "score_adjusted": 7.4499999999999975
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "6482184936ceb219deb8c15b",
        "REPORT_capture_start_ts": "2023/06/08 17:51:38"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 56,
            "REPORT_localized_capture_start_ts": "13:54:20 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "pepperoni",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:51:38_v9p1-ssd-dense_id0056(None)-fr1297-02:42.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:51:38_v9p1-ssd-dense_id0056(None)-fr1297-02:42.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1887,
                1301,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00011778523912653327,
              "occ": 0.0009003818267956376
            },
            "optimal_frame": 1297,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 57,
            "REPORT_localized_capture_start_ts": "13:54:07 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "napolitana",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:51:38_v9p1-ssd-dense_id0057(None)-fr1195-02:29.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:51:38_v9p1-ssd-dense_id0057(None)-fr1195-02:29.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                758,
                1343,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.14471930265426636,
              "occ": 0.009364339523017406
            },
            "optimal_frame": 1195,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 58,
            "REPORT_localized_capture_start_ts": "13:55:20 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "canadian_bacon",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:51:38_v9p1-ssd-dense_id0058(None)-fr1775-03:42.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:51:38_v9p1-ssd-dense_id0058(None)-fr1775-03:42.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1573,
                1259,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.0037190504372119904,
              "occ": 0.02147592604160309
            },
            "optimal_frame": 1775,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 59,
            "REPORT_localized_capture_start_ts": "13:57:12 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:51:38_v9p1-ssd-dense_id0059(None)-fr2674-05:34.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:51:38_v9p1-ssd-dense_id0059(None)-fr2674-05:34.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1587,
                1348,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.1454443335533142,
              "occ": 0.7712518572807312
            },
            "optimal_frame": 2674,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "REPORT_localized_capture_start_ts": "13:58:49 2023/06/08",
            "optimal_frame_quality": {
              "blur": 0.00014397774066310376,
              "occ": 0.0009947232902050018
            },
            "bubbles": null,
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:51:38_v9p1-ssd-dense_id0060(None)-fr3445-07:11.jpg",
            "snap_quality": "buena",
            "multisize": false,
            "raw_score": false,
            "snap_quality_trusted": true,
            "original_data": null,
            "overbaked": null,
            "id": 60,
            "standard": "not_to_standard",
            "category": "super_pepperoni",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:51:38_v9p1-ssd-dense_id0060(None)-fr3445-07:11.jpg",
            "REPORT_frame_detection_summary": {},
            "censored": null,
            "attributes": null,
            "omit_soft": true,
            "bad_cut": null,
            "optimal_figure": {
              "coords": [
                1464,
                1368,
                506
              ],
              "__type__": "Circle"
            },
            "REPORT_detections_categ_summary": [],
            "optimal_frame": 3445,
            "reviewer": "rodrigo.pulcha",
            "crust": "delgada"
          },
          {
            "id": 61,
            "REPORT_localized_capture_start_ts": "14:00:54 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "canadian_bacon",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:51:38_v9p1-ssd-dense_id0061(None)-fr4451-09:16.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:51:38_v9p1-ssd-dense_id0061(None)-fr4451-09:16.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1302,
                1311,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00023033337492961437,
              "occ": 0.0007527127163484693
            },
            "optimal_frame": 4451,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          },
          {
            "REPORT_localized_capture_start_ts": "14:01:37 2023/06/08",
            "optimal_frame_quality": {
              "blur": 0.012681422755122185,
              "occ": 0.0022978659253567457
            },
            "bubbles": null,
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_13:51:38_v9p1-ssd-dense_id0062(None)-fr4789-09:59.jpg",
            "snap_quality": "buena",
            "multisize": false,
            "raw_score": false,
            "snap_quality_trusted": true,
            "original_data": null,
            "overbaked": null,
            "id": 62,
            "standard": "not_to_standard",
            "category": "super_pepperoni",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_13:51:38_v9p1-ssd-dense_id0062(None)-fr4789-09:59.jpg",
            "REPORT_frame_detection_summary": {},
            "censored": null,
            "attributes": null,
            "omit_soft": true,
            "bad_cut": null,
            "optimal_figure": {
              "coords": [
                1174,
                1286,
                506
              ],
              "__type__": "Circle"
            },
            "REPORT_detections_categ_summary": [],
            "optimal_frame": 4789,
            "reviewer": "rodrigo.pulcha",
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "648222c012502ec08c1d80c9",
        "optimal_snap_ingredients": {
          "57": [
            {
              "type": "tomate",
              "rectangle": [
                364,
                464,
                568,
                684
              ],
              "weight": 0.9757947325706482
            },
            {
              "type": "pepperoni",
              "rectangle": [
                231,
                228,
                392,
                406
              ],
              "weight": 0.8819324374198914
            },
            {
              "type": "pepperoni",
              "rectangle": [
                501,
                449,
                588,
                530
              ],
              "weight": 0.8622487783432007
            },
            {
              "type": "pepperoni",
              "rectangle": [
                575,
                677,
                760,
                868
              ],
              "weight": 0.7295482158660889
            },
            {
              "type": "pepperoni",
              "rectangle": [
                159,
                504,
                256,
                670
              ],
              "weight": 0.6707099080085754
            },
            {
              "type": "pepperoni",
              "rectangle": [
                591,
                185,
                718,
                267
              ],
              "weight": 0.6593175530433655
            },
            {
              "type": "pepperoni",
              "rectangle": [
                702,
                424,
                846,
                529
              ],
              "weight": 0.6545498967170715
            },
            {
              "type": "pepperoni",
              "rectangle": [
                797,
                319,
                872,
                463
              ],
              "weight": 0.5591192245483398
            },
            {
              "type": "pepperoni",
              "rectangle": [
                513,
                389,
                598,
                464
              ],
              "weight": 0.480519562959671
            },
            {
              "type": "pepperoni",
              "rectangle": [
                534,
                210,
                604,
                313
              ],
              "weight": 0.333003968000412
            }
          ],
          "58": [
            {
              "type": "tocino",
              "rectangle": [
                234,
                510,
                268,
                612
              ],
              "weight": 0.9908181428909302
            },
            {
              "type": "tocino",
              "rectangle": [
                402,
                687,
                440,
                782
              ],
              "weight": 0.9883936047554016
            },
            {
              "type": "tocino",
              "rectangle": [
                836,
                524,
                904,
                616
              ],
              "weight": 0.9695689678192139
            },
            {
              "type": "tocino",
              "rectangle": [
                439,
                404,
                491,
                506
              ],
              "weight": 0.9677984714508057
            },
            {
              "type": "tocino",
              "rectangle": [
                632,
                271,
                713,
                353
              ],
              "weight": 0.9457129240036011
            },
            {
              "type": "tocino",
              "rectangle": [
                447,
                542,
                500,
                623
              ],
              "weight": 0.9454938769340515
            },
            {
              "type": "tocino",
              "rectangle": [
                523,
                381,
                606,
                410
              ],
              "weight": 0.9428381323814392
            },
            {
              "type": "tocino",
              "rectangle": [
                332,
                749,
                379,
                858
              ],
              "weight": 0.9374927282333374
            },
            {
              "type": "tocino",
              "rectangle": [
                490,
                791,
                572,
                871
              ],
              "weight": 0.9256778359413147
            },
            {
              "type": "tocino",
              "rectangle": [
                475,
                191,
                525,
                248
              ],
              "weight": 0.9187951683998108
            },
            {
              "type": "tocino",
              "rectangle": [
                256,
                438,
                300,
                478
              ],
              "weight": 0.9044880867004395
            },
            {
              "type": "tocino",
              "rectangle": [
                549,
                676,
                574,
                738
              ],
              "weight": 0.8942840695381165
            },
            {
              "type": "tocino",
              "rectangle": [
                701,
                468,
                727,
                539
              ],
              "weight": 0.8858322501182556
            },
            {
              "type": "tocino",
              "rectangle": [
                243,
                640,
                290,
                712
              ],
              "weight": 0.8549870252609253
            },
            {
              "type": "tocino",
              "rectangle": [
                377,
                317,
                406,
                374
              ],
              "weight": 0.8369609713554382
            },
            {
              "type": "tocino",
              "rectangle": [
                605,
                364,
                627,
                413
              ],
              "weight": 0.82736736536026
            },
            {
              "type": "tocino",
              "rectangle": [
                650,
                476,
                675,
                532
              ],
              "weight": 0.8181474208831787
            },
            {
              "type": "carne",
              "rectangle": [
                335,
                647,
                383,
                699
              ],
              "weight": 0.808670699596405
            },
            {
              "type": "tocino",
              "rectangle": [
                501,
                521,
                534,
                633
              ],
              "weight": 0.7987879514694214
            },
            {
              "type": "tocino",
              "rectangle": [
                112,
                633,
                218,
                673
              ],
              "weight": 0.7766265869140625
            },
            {
              "type": "tocino",
              "rectangle": [
                508,
                650,
                538,
                710
              ],
              "weight": 0.7760661840438843
            },
            {
              "type": "carne",
              "rectangle": [
                206,
                298,
                276,
                361
              ],
              "weight": 0.7019050717353821
            },
            {
              "type": "carne",
              "rectangle": [
                595,
                525,
                647,
                566
              ],
              "weight": 0.6786307096481323
            },
            {
              "type": "tocino",
              "rectangle": [
                274,
                280,
                362,
                325
              ],
              "weight": 0.6622654795646667
            },
            {
              "type": "carne",
              "rectangle": [
                432,
                271,
                470,
                313
              ],
              "weight": 0.6484239101409912
            },
            {
              "type": "carne",
              "rectangle": [
                386,
                199,
                424,
                240
              ],
              "weight": 0.6324000954627991
            },
            {
              "type": "tocino",
              "rectangle": [
                302,
                484,
                345,
                529
              ],
              "weight": 0.6286551356315613
            },
            {
              "type": "choclo",
              "rectangle": [
                537,
                353,
                560,
                381
              ],
              "weight": 0.5982648134231567
            },
            {
              "type": "tocino",
              "rectangle": [
                473,
                732,
                523,
                757
              ],
              "weight": 0.564115583896637
            },
            {
              "type": "choclo",
              "rectangle": [
                762,
                596,
                787,
                622
              ],
              "weight": 0.5296981930732727
            },
            {
              "type": "carne",
              "rectangle": [
                585,
                738,
                632,
                778
              ],
              "weight": 0.5287054777145386
            },
            {
              "type": "tocino",
              "rectangle": [
                578,
                231,
                605,
                294
              ],
              "weight": 0.5037791132926941
            },
            {
              "type": "tocino",
              "rectangle": [
                584,
                456,
                606,
                505
              ],
              "weight": 0.49361246824264526
            },
            {
              "type": "carne",
              "rectangle": [
                609,
                234,
                652,
                270
              ],
              "weight": 0.4560106098651886
            },
            {
              "type": "tocino",
              "rectangle": [
                410,
                361,
                451,
                382
              ],
              "weight": 0.4550963342189789
            },
            {
              "type": "tocino",
              "rectangle": [
                363,
                235,
                451,
                284
              ],
              "weight": 0.44897329807281494
            },
            {
              "type": "tocino",
              "rectangle": [
                832,
                609,
                902,
                689
              ],
              "weight": 0.4429769217967987
            },
            {
              "type": "pepperoni",
              "rectangle": [
                345,
                835,
                406,
                900
              ],
              "weight": 0.44104814529418945
            },
            {
              "type": "carne",
              "rectangle": [
                792,
                384,
                828,
                415
              ],
              "weight": 0.42980146408081055
            },
            {
              "type": "carne",
              "rectangle": [
                430,
                765,
                473,
                822
              ],
              "weight": 0.40998685359954834
            },
            {
              "type": "tocino",
              "rectangle": [
                755,
                555,
                827,
                591
              ],
              "weight": 0.3977668285369873
            },
            {
              "type": "carne",
              "rectangle": [
                362,
                586,
                417,
                622
              ],
              "weight": 0.3939853310585022
            },
            {
              "type": "carne",
              "rectangle": [
                535,
                451,
                585,
                497
              ],
              "weight": 0.37523916363716125
            },
            {
              "type": "tocino",
              "rectangle": [
                170,
                338,
                259,
                410
              ],
              "weight": 0.3661532402038574
            },
            {
              "type": "tocino",
              "rectangle": [
                590,
                811,
                615,
                885
              ],
              "weight": 0.32281649112701416
            }
          ],
          "60": [
            {
              "type": "pepperoni",
              "rectangle": [
                568,
                184,
                678,
                295
              ],
              "weight": 0.9994250535964966
            },
            {
              "type": "pepperoni",
              "rectangle": [
                500,
                656,
                614,
                772
              ],
              "weight": 0.9992184638977051
            },
            {
              "type": "pepperoni",
              "rectangle": [
                750,
                417,
                859,
                514
              ],
              "weight": 0.9990952014923096
            },
            {
              "type": "pepperoni",
              "rectangle": [
                578,
                307,
                686,
                412
              ],
              "weight": 0.9990931749343872
            },
            {
              "type": "pepperoni",
              "rectangle": [
                430,
                751,
                546,
                853
              ],
              "weight": 0.9990288019180298
            },
            {
              "type": "pepperoni",
              "rectangle": [
                207,
                623,
                320,
                732
              ],
              "weight": 0.9990034699440002
            },
            {
              "type": "pepperoni",
              "rectangle": [
                369,
                326,
                484,
                435
              ],
              "weight": 0.9989282488822937
            },
            {
              "type": "pepperoni",
              "rectangle": [
                871,
                531,
                989,
                645
              ],
              "weight": 0.9988986253738403
            },
            {
              "type": "pepperoni",
              "rectangle": [
                752,
                309,
                852,
                418
              ],
              "weight": 0.9988056421279907
            },
            {
              "type": "pepperoni",
              "rectangle": [
                687,
                208,
                790,
                310
              ],
              "weight": 0.998793363571167
            },
            {
              "type": "pepperoni",
              "rectangle": [
                534,
                818,
                632,
                922
              ],
              "weight": 0.998772919178009
            },
            {
              "type": "pepperoni",
              "rectangle": [
                428,
                563,
                535,
                664
              ],
              "weight": 0.9987713694572449
            },
            {
              "type": "pepperoni",
              "rectangle": [
                770,
                499,
                877,
                598
              ],
              "weight": 0.9987232089042664
            },
            {
              "type": "pepperoni",
              "rectangle": [
                663,
                394,
                764,
                502
              ],
              "weight": 0.9986422657966614
            },
            {
              "type": "pepperoni",
              "rectangle": [
                476,
                304,
                575,
                421
              ],
              "weight": 0.9985742568969727
            },
            {
              "type": "pepperoni",
              "rectangle": [
                401,
                448,
                497,
                540
              ],
              "weight": 0.9985042810440063
            },
            {
              "type": "pepperoni",
              "rectangle": [
                543,
                79,
                655,
                180
              ],
              "weight": 0.9984633922576904
            },
            {
              "type": "pepperoni",
              "rectangle": [
                487,
                417,
                588,
                520
              ],
              "weight": 0.9984334111213684
            },
            {
              "type": "pepperoni",
              "rectangle": [
                196,
                440,
                297,
                540
              ],
              "weight": 0.9984098672866821
            },
            {
              "type": "pepperoni",
              "rectangle": [
                806,
                209,
                905,
                313
              ],
              "weight": 0.9983319640159607
            },
            {
              "type": "pepperoni",
              "rectangle": [
                752,
                597,
                861,
                699
              ],
              "weight": 0.9982818365097046
            },
            {
              "type": "pepperoni",
              "rectangle": [
                178,
                550,
                279,
                643
              ],
              "weight": 0.9982423782348633
            },
            {
              "type": "pepperoni",
              "rectangle": [
                312,
                588,
                426,
                690
              ],
              "weight": 0.9981895089149475
            },
            {
              "type": "pepperoni",
              "rectangle": [
                261,
                251,
                380,
                364
              ],
              "weight": 0.9981334805488586
            },
            {
              "type": "pepperoni",
              "rectangle": [
                688,
                674,
                803,
                786
              ],
              "weight": 0.9980409741401672
            },
            {
              "type": "pepperoni",
              "rectangle": [
                478,
                184,
                566,
                288
              ],
              "weight": 0.9980351328849792
            },
            {
              "type": "pepperoni",
              "rectangle": [
                326,
                101,
                426,
                207
              ],
              "weight": 0.9979866743087769
            },
            {
              "type": "pepperoni",
              "rectangle": [
                315,
                724,
                415,
                830
              ],
              "weight": 0.9979435801506042
            },
            {
              "type": "pepperoni",
              "rectangle": [
                880,
                435,
                1002,
                530
              ],
              "weight": 0.9979377388954163
            },
            {
              "type": "pepperoni",
              "rectangle": [
                646,
                78,
                759,
                187
              ],
              "weight": 0.9978975057601929
            },
            {
              "type": "pepperoni",
              "rectangle": [
                309,
                494,
                425,
                592
              ],
              "weight": 0.9978837370872498
            },
            {
              "type": "pepperoni",
              "rectangle": [
                604,
                604,
                700,
                677
              ],
              "weight": 0.9978432655334473
            },
            {
              "type": "pepperoni",
              "rectangle": [
                835,
                689,
                953,
                783
              ],
              "weight": 0.9977643489837646
            },
            {
              "type": "pepperoni",
              "rectangle": [
                350,
                866,
                469,
                984
              ],
              "weight": 0.9976084232330322
            },
            {
              "type": "pepperoni",
              "rectangle": [
                458,
                903,
                569,
                1015
              ],
              "weight": 0.9974607229232788
            },
            {
              "type": "pepperoni",
              "rectangle": [
                304,
                399,
                406,
                501
              ],
              "weight": 0.997431218624115
            },
            {
              "type": "pepperoni",
              "rectangle": [
                216,
                128,
                328,
                245
              ],
              "weight": 0.9971725940704346
            },
            {
              "type": "pepperoni",
              "rectangle": [
                369,
                197,
                487,
                312
              ],
              "weight": 0.9971014857292175
            },
            {
              "type": "pepperoni",
              "rectangle": [
                77,
                426,
                190,
                526
              ],
              "weight": 0.9968673586845398
            },
            {
              "type": "pepperoni",
              "rectangle": [
                57,
                530,
                177,
                644
              ],
              "weight": 0.9967053532600403
            },
            {
              "type": "pepperoni",
              "rectangle": [
                643,
                495,
                763,
                571
              ],
              "weight": 0.9964699745178223
            },
            {
              "type": "pepperoni",
              "rectangle": [
                136,
                748,
                255,
                865
              ],
              "weight": 0.9964646100997925
            },
            {
              "type": "pepperoni",
              "rectangle": [
                116,
                295,
                229,
                387
              ],
              "weight": 0.9964559674263
            },
            {
              "type": "pepperoni",
              "rectangle": [
                82,
                643,
                191,
                758
              ],
              "weight": 0.9961537718772888
            },
            {
              "type": "pepperoni",
              "rectangle": [
                777,
                801,
                887,
                919
              ],
              "weight": 0.9961082339286804
            },
            {
              "type": "pepperoni",
              "rectangle": [
                245,
                814,
                342,
                931
              ],
              "weight": 0.9959851503372192
            },
            {
              "type": "pepperoni",
              "rectangle": [
                691,
                848,
                783,
                944
              ],
              "weight": 0.9958509206771851
            },
            {
              "type": "pepperoni",
              "rectangle": [
                615,
                740,
                735,
                837
              ],
              "weight": 0.9957971572875977
            },
            {
              "type": "pepperoni",
              "rectangle": [
                408,
                75,
                514,
                180
              ],
              "weight": 0.9954363703727722
            },
            {
              "type": "pepperoni",
              "rectangle": [
                129,
                197,
                243,
                298
              ],
              "weight": 0.9950000047683716
            },
            {
              "type": "pepperoni",
              "rectangle": [
                865,
                628,
                970,
                688
              ],
              "weight": 0.9939735531806946
            },
            {
              "type": "pepperoni",
              "rectangle": [
                727,
                134,
                827,
                216
              ],
              "weight": 0.9897955060005188
            },
            {
              "type": "pepperoni",
              "rectangle": [
                601,
                911,
                697,
                1025
              ],
              "weight": 0.9826596975326538
            },
            {
              "type": "pepperoni",
              "rectangle": [
                399,
                655,
                494,
                751
              ],
              "weight": 0.982216477394104
            },
            {
              "type": "pepperoni",
              "rectangle": [
                544,
                504,
                644,
                574
              ],
              "weight": 0.9105024933815002
            },
            {
              "type": "pepperoni",
              "rectangle": [
                577,
                415,
                661,
                515
              ],
              "weight": 0.8550567030906677
            },
            {
              "type": "pepperoni",
              "rectangle": [
                79,
                383,
                128,
                439
              ],
              "weight": 0.4936220645904541
            },
            {
              "type": "pepperoni",
              "rectangle": [
                519,
                555,
                584,
                627
              ],
              "weight": 0.40050220489501953
            }
          ],
          "61": [
            {
              "type": "tocino",
              "rectangle": [
                467,
                575,
                529,
                642
              ],
              "weight": 0.9836576581001282
            },
            {
              "type": "tocino",
              "rectangle": [
                270,
                540,
                320,
                612
              ],
              "weight": 0.9817826151847839
            },
            {
              "type": "tocino",
              "rectangle": [
                641,
                385,
                723,
                419
              ],
              "weight": 0.97468101978302
            },
            {
              "type": "tocino",
              "rectangle": [
                587,
                298,
                639,
                373
              ],
              "weight": 0.9715733528137207
            },
            {
              "type": "tocino",
              "rectangle": [
                673,
                344,
                774,
                374
              ],
              "weight": 0.9674499034881592
            },
            {
              "type": "tocino",
              "rectangle": [
                376,
                770,
                471,
                818
              ],
              "weight": 0.9673998951911926
            },
            {
              "type": "tocino",
              "rectangle": [
                949,
                592,
                1015,
                670
              ],
              "weight": 0.9508313536643982
            },
            {
              "type": "tocino",
              "rectangle": [
                629,
                416,
                675,
                489
              ],
              "weight": 0.9499347805976868
            },
            {
              "type": "tocino",
              "rectangle": [
                658,
                503,
                735,
                537
              ],
              "weight": 0.9418089985847473
            },
            {
              "type": "tocino",
              "rectangle": [
                681,
                565,
                747,
                651
              ],
              "weight": 0.9416070580482483
            },
            {
              "type": "tocino",
              "rectangle": [
                483,
                283,
                532,
                348
              ],
              "weight": 0.9369101524353027
            },
            {
              "type": "tocino",
              "rectangle": [
                691,
                700,
                747,
                807
              ],
              "weight": 0.9368820786476135
            },
            {
              "type": "tocino",
              "rectangle": [
                791,
                323,
                825,
                369
              ],
              "weight": 0.9291717410087585
            },
            {
              "type": "tocino",
              "rectangle": [
                333,
                212,
                397,
                263
              ],
              "weight": 0.9275021553039551
            },
            {
              "type": "tocino",
              "rectangle": [
                402,
                193,
                459,
                255
              ],
              "weight": 0.9174432158470154
            },
            {
              "type": "tocino",
              "rectangle": [
                368,
                863,
                443,
                939
              ],
              "weight": 0.9173673391342163
            },
            {
              "type": "tocino",
              "rectangle": [
                831,
                583,
                881,
                616
              ],
              "weight": 0.9171804785728455
            },
            {
              "type": "carne",
              "rectangle": [
                330,
                344,
                375,
                389
              ],
              "weight": 0.9148411154747009
            },
            {
              "type": "tocino",
              "rectangle": [
                351,
                725,
                435,
                771
              ],
              "weight": 0.9135207533836365
            },
            {
              "type": "tocino",
              "rectangle": [
                466,
                924,
                545,
                989
              ],
              "weight": 0.9122488498687744
            },
            {
              "type": "tocino",
              "rectangle": [
                302,
                705,
                359,
                788
              ],
              "weight": 0.9096265435218811
            },
            {
              "type": "tocino",
              "rectangle": [
                172,
                585,
                247,
                609
              ],
              "weight": 0.9064198732376099
            },
            {
              "type": "carne",
              "rectangle": [
                577,
                746,
                620,
                780
              ],
              "weight": 0.9040206074714661
            },
            {
              "type": "tocino",
              "rectangle": [
                802,
                475,
                843,
                532
              ],
              "weight": 0.8974353075027466
            },
            {
              "type": "tocino",
              "rectangle": [
                812,
                250,
                847,
                305
              ],
              "weight": 0.8963066935539246
            },
            {
              "type": "tocino",
              "rectangle": [
                224,
                474,
                296,
                546
              ],
              "weight": 0.8941977620124817
            },
            {
              "type": "carne",
              "rectangle": [
                320,
                264,
                378,
                315
              ],
              "weight": 0.8941442370414734
            },
            {
              "type": "tocino",
              "rectangle": [
                372,
                368,
                422,
                467
              ],
              "weight": 0.8756016492843628
            },
            {
              "type": "tocino",
              "rectangle": [
                622,
                626,
                657,
                702
              ],
              "weight": 0.8626936674118042
            },
            {
              "type": "tocino",
              "rectangle": [
                329,
                593,
                409,
                621
              ],
              "weight": 0.8595262765884399
            },
            {
              "type": "tocino",
              "rectangle": [
                887,
                443,
                931,
                517
              ],
              "weight": 0.8581501245498657
            },
            {
              "type": "tocino",
              "rectangle": [
                531,
                731,
                588,
                761
              ],
              "weight": 0.8549124598503113
            },
            {
              "type": "tocino",
              "rectangle": [
                881,
                543,
                933,
                641
              ],
              "weight": 0.8457187414169312
            },
            {
              "type": "carne",
              "rectangle": [
                769,
                407,
                828,
                458
              ],
              "weight": 0.8418735265731812
            },
            {
              "type": "tocino",
              "rectangle": [
                215,
                754,
                249,
                831
              ],
              "weight": 0.8339274525642395
            },
            {
              "type": "tocino",
              "rectangle": [
                794,
                653,
                859,
                726
              ],
              "weight": 0.8224341869354248
            },
            {
              "type": "tocino",
              "rectangle": [
                408,
                483,
                437,
                574
              ],
              "weight": 0.7990169525146484
            },
            {
              "type": "carne",
              "rectangle": [
                282,
                312,
                309,
                365
              ],
              "weight": 0.7923092842102051
            },
            {
              "type": "carne",
              "rectangle": [
                356,
                805,
                407,
                842
              ],
              "weight": 0.7888116240501404
            },
            {
              "type": "tocino",
              "rectangle": [
                884,
                309,
                957,
                365
              ],
              "weight": 0.7649245858192444
            },
            {
              "type": "carne",
              "rectangle": [
                433,
                165,
                487,
                230
              ],
              "weight": 0.7579200267791748
            },
            {
              "type": "carne",
              "rectangle": [
                172,
                624,
                206,
                656
              ],
              "weight": 0.7418839335441589
            },
            {
              "type": "tocino",
              "rectangle": [
                711,
                624,
                804,
                693
              ],
              "weight": 0.7363313436508179
            },
            {
              "type": "tocino",
              "rectangle": [
                369,
                839,
                436,
                864
              ],
              "weight": 0.7287535071372986
            },
            {
              "type": "carne",
              "rectangle": [
                749,
                308,
                788,
                354
              ],
              "weight": 0.7132577300071716
            },
            {
              "type": "carne",
              "rectangle": [
                261,
                396,
                304,
                447
              ],
              "weight": 0.6921893954277039
            },
            {
              "type": "tocino",
              "rectangle": [
                494,
                888,
                587,
                916
              ],
              "weight": 0.637140154838562
            },
            {
              "type": "tocino",
              "rectangle": [
                484,
                835,
                549,
                865
              ],
              "weight": 0.6270747184753418
            },
            {
              "type": "carne",
              "rectangle": [
                348,
                545,
                398,
                581
              ],
              "weight": 0.6088008284568787
            },
            {
              "type": "carne",
              "rectangle": [
                480,
                392,
                533,
                443
              ],
              "weight": 0.5994399785995483
            },
            {
              "type": "tocino",
              "rectangle": [
                676,
                883,
                756,
                952
              ],
              "weight": 0.5948218107223511
            },
            {
              "type": "pollo",
              "rectangle": [
                178,
                338,
                233,
                419
              ],
              "weight": 0.5679259300231934
            },
            {
              "type": "tocino",
              "rectangle": [
                802,
                779,
                870,
                888
              ],
              "weight": 0.5663195252418518
            },
            {
              "type": "carne",
              "rectangle": [
                491,
                862,
                531,
                899
              ],
              "weight": 0.547701895236969
            },
            {
              "type": "carne",
              "rectangle": [
                925,
                547,
                967,
                590
              ],
              "weight": 0.49598076939582825
            },
            {
              "type": "carne",
              "rectangle": [
                424,
                259,
                465,
                296
              ],
              "weight": 0.46144258975982666
            },
            {
              "type": "carne",
              "rectangle": [
                923,
                384,
                971,
                428
              ],
              "weight": 0.45395392179489136
            },
            {
              "type": "pepperoni",
              "rectangle": [
                687,
                829,
                830,
                933
              ],
              "weight": 0.41887417435646057
            },
            {
              "type": "carne",
              "rectangle": [
                700,
                224,
                748,
                267
              ],
              "weight": 0.4070828855037689
            },
            {
              "type": "tocino",
              "rectangle": [
                472,
                782,
                506,
                842
              ],
              "weight": 0.4056382477283478
            },
            {
              "type": "tocino",
              "rectangle": [
                310,
                631,
                344,
                670
              ],
              "weight": 0.4029417932033539
            },
            {
              "type": "carne",
              "rectangle": [
                523,
                570,
                559,
                616
              ],
              "weight": 0.3674721121788025
            },
            {
              "type": "carne",
              "rectangle": [
                130,
                497,
                169,
                563
              ],
              "weight": 0.3633155822753906
            },
            {
              "type": "carne",
              "rectangle": [
                672,
                431,
                717,
                472
              ],
              "weight": 0.34312671422958374
            },
            {
              "type": "carne",
              "rectangle": [
                736,
                204,
                776,
                238
              ],
              "weight": 0.32310712337493896
            }
          ],
          "62": [
            {
              "type": "pepperoni",
              "rectangle": [
                770,
                633,
                879,
                736
              ],
              "weight": 0.9988163709640503
            },
            {
              "type": "pepperoni",
              "rectangle": [
                220,
                648,
                336,
                766
              ],
              "weight": 0.9982545971870422
            },
            {
              "type": "pepperoni",
              "rectangle": [
                594,
                705,
                713,
                814
              ],
              "weight": 0.9981203675270081
            },
            {
              "type": "pepperoni",
              "rectangle": [
                701,
                319,
                815,
                432
              ],
              "weight": 0.9981083869934082
            },
            {
              "type": "pepperoni",
              "rectangle": [
                282,
                518,
                390,
                623
              ],
              "weight": 0.9980752468109131
            },
            {
              "type": "pepperoni",
              "rectangle": [
                145,
                399,
                253,
                487
              ],
              "weight": 0.997816801071167
            },
            {
              "type": "pepperoni",
              "rectangle": [
                354,
                460,
                434,
                517
              ],
              "weight": 0.9971030354499817
            },
            {
              "type": "pepperoni",
              "rectangle": [
                527,
                114,
                635,
                223
              ],
              "weight": 0.9970775842666626
            },
            {
              "type": "pepperoni",
              "rectangle": [
                744,
                425,
                845,
                531
              ],
              "weight": 0.9970491528511047
            },
            {
              "type": "pepperoni",
              "rectangle": [
                653,
                166,
                764,
                273
              ],
              "weight": 0.9969600439071655
            },
            {
              "type": "pepperoni",
              "rectangle": [
                679,
                565,
                800,
                664
              ],
              "weight": 0.9968929290771484
            },
            {
              "type": "pepperoni",
              "rectangle": [
                611,
                808,
                719,
                902
              ],
              "weight": 0.9968274235725403
            },
            {
              "type": "pepperoni",
              "rectangle": [
                833,
                357,
                929,
                440
              ],
              "weight": 0.9966104626655579
            },
            {
              "type": "pepperoni",
              "rectangle": [
                144,
                483,
                249,
                585
              ],
              "weight": 0.9965386390686035
            },
            {
              "type": "pepperoni",
              "rectangle": [
                411,
                365,
                504,
                473
              ],
              "weight": 0.9964423775672913
            },
            {
              "type": "pepperoni",
              "rectangle": [
                817,
                535,
                939,
                628
              ],
              "weight": 0.9958070516586304
            },
            {
              "type": "pepperoni",
              "rectangle": [
                390,
                527,
                483,
                630
              ],
              "weight": 0.995198667049408
            },
            {
              "type": "pepperoni",
              "rectangle": [
                327,
                147,
                434,
                263
              ],
              "weight": 0.9951308965682983
            },
            {
              "type": "pepperoni",
              "rectangle": [
                343,
                280,
                442,
                384
              ],
              "weight": 0.9949098229408264
            },
            {
              "type": "pepperoni",
              "rectangle": [
                407,
                611,
                531,
                707
              ],
              "weight": 0.9948831796646118
            },
            {
              "type": "pepperoni",
              "rectangle": [
                527,
                283,
                602,
                380
              ],
              "weight": 0.9939151406288147
            },
            {
              "type": "pepperoni",
              "rectangle": [
                795,
                271,
                908,
                376
              ],
              "weight": 0.993887722492218
            },
            {
              "type": "pepperoni",
              "rectangle": [
                764,
                722,
                864,
                814
              ],
              "weight": 0.9932297468185425
            },
            {
              "type": "pepperoni",
              "rectangle": [
                837,
                427,
                956,
                538
              ],
              "weight": 0.9930291771888733
            },
            {
              "type": "pepperoni",
              "rectangle": [
                415,
                116,
                532,
                231
              ],
              "weight": 0.9927257299423218
            },
            {
              "type": "pepperoni",
              "rectangle": [
                370,
                835,
                471,
                937
              ],
              "weight": 0.9923611283302307
            },
            {
              "type": "pepperoni",
              "rectangle": [
                742,
                211,
                835,
                311
              ],
              "weight": 0.9919588565826416
            },
            {
              "type": "pepperoni",
              "rectangle": [
                252,
                367,
                405,
                501
              ],
              "weight": 0.982266366481781
            },
            {
              "type": "pepperoni",
              "rectangle": [
                264,
                222,
                371,
                322
              ],
              "weight": 0.97648024559021
            },
            {
              "type": "pepperoni",
              "rectangle": [
                489,
                815,
                587,
                937
              ],
              "weight": 0.9753495454788208
            },
            {
              "type": "pepperoni",
              "rectangle": [
                626,
                466,
                733,
                554
              ],
              "weight": 0.9696729779243469
            },
            {
              "type": "pepperoni",
              "rectangle": [
                170,
                320,
                276,
                418
              ],
              "weight": 0.9450570344924927
            },
            {
              "type": "pepperoni",
              "rectangle": [
                593,
                311,
                653,
                401
              ],
              "weight": 0.9435684084892273
            },
            {
              "type": "pepperoni",
              "rectangle": [
                583,
                395,
                664,
                472
              ],
              "weight": 0.9101230502128601
            },
            {
              "type": "pepperoni",
              "rectangle": [
                561,
                562,
                657,
                641
              ],
              "weight": 0.906263530254364
            },
            {
              "type": "pepperoni",
              "rectangle": [
                343,
                668,
                436,
                772
              ],
              "weight": 0.9015337228775024
            },
            {
              "type": "pepperoni",
              "rectangle": [
                477,
                711,
                567,
                813
              ],
              "weight": 0.7310253977775574
            },
            {
              "type": "pepperoni",
              "rectangle": [
                565,
                894,
                675,
                955
              ],
              "weight": 0.608052670955658
            },
            {
              "type": "pepperoni",
              "rectangle": [
                550,
                621,
                593,
                710
              ],
              "weight": 0.5883651375770569
            },
            {
              "type": "pepperoni",
              "rectangle": [
                513,
                472,
                580,
                539
              ],
              "weight": 0.4276367127895355
            },
            {
              "type": "pepperoni",
              "rectangle": [
                636,
                625,
                713,
                686
              ],
              "weight": 0.32489991188049316
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "6482184936ceb219deb8c15b",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "57": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 7.1,
            "specialty_check": false,
            "score_adjusted": 7.099999999999998
          },
          "58": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0.1,
                "8": 0
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 8,
            "specialty_check": false,
            "score_adjusted": 8
          },
          "60": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0,
                "4": 0.25,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0,
                "2": 0.1,
                "3": 0,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0.1,
                "8": 0
              }
            },
            "score_final": 8.2,
            "specialty_check": true,
            "score_adjusted": 8.199999999999998
          },
          "61": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0,
                "7": 0.1,
                "8": 0
              },
              "Limpieza": {
                "1": 0,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0
              }
            },
            "score_final": 8.7,
            "specialty_check": false,
            "score_adjusted": 8.7
          },
          "62": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0,
                "2": 0,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0,
                "5": 0,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0,
                "7": 0,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 8.2,
            "specialty_check": true,
            "score_adjusted": 8.199999999999998
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64821aaa6b373f72769fea6a",
        "REPORT_capture_start_ts": "2023/06/08 18:01:38"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 63,
            "REPORT_localized_capture_start_ts": "14:01:49 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "italiana",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_14:01:38_v9p1-ssd-dense_id0063(None)-fr0086-00:11.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_14:01:38_v9p1-ssd-dense_id0063(None)-fr0086-00:11.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                2010,
                1332,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 4.3533435700737755e-7,
              "occ": 0.000014510492292174604
            },
            "optimal_frame": 86,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          },
          {
            "id": 64,
            "REPORT_localized_capture_start_ts": "14:02:25 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "italiana",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_14:01:38_v9p1-ssd-dense_id0064(None)-fr0377-00:47.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_14:01:38_v9p1-ssd-dense_id0064(None)-fr0377-00:47.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1510,
                1223,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 3.173036500214721e-9,
              "occ": 0.024114063009619713
            },
            "optimal_frame": 377,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          },
          {
            "id": 66,
            "REPORT_localized_capture_start_ts": "14:03:27 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "todas_las_carnes",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_14:01:38_v9p1-ssd-dense_id0066(None)-fr0874-01:49.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_14:01:38_v9p1-ssd-dense_id0066(None)-fr0874-01:49.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                921,
                1302,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.050082772970199585,
              "occ": 0.0006758923991583288
            },
            "optimal_frame": 874,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          },
          {
            "id": 68,
            "REPORT_localized_capture_start_ts": "14:06:06 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_14:01:38_v9p1-ssd-dense_id0068(None)-fr2146-04:28.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_14:01:38_v9p1-ssd-dense_id0068(None)-fr2146-04:28.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1934,
                1114,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00013709647464565933,
              "occ": 0.04543742537498474
            },
            "optimal_frame": 2146,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 69,
            "REPORT_localized_capture_start_ts": "14:06:10 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_14:01:38_v9p1-ssd-dense_id0069(None)-fr2174-04:32.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_14:01:38_v9p1-ssd-dense_id0069(None)-fr2174-04:32.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1062,
                1098,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 6.202809004207666e-7,
              "occ": 0.06727451086044312
            },
            "optimal_frame": 2174,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "648225be12502ec08c1d80ca",
        "optimal_snap_ingredients": {
          "63": [
            {
              "type": "aceituna",
              "rectangle": [
                751,
                491,
                798,
                539
              ],
              "weight": 0.996290922164917
            },
            {
              "type": "aceituna",
              "rectangle": [
                874,
                563,
                928,
                609
              ],
              "weight": 0.9934509992599487
            },
            {
              "type": "pepperoni",
              "rectangle": [
                334,
                637,
                457,
                750
              ],
              "weight": 0.992917537689209
            },
            {
              "type": "pepperoni",
              "rectangle": [
                765,
                264,
                883,
                372
              ],
              "weight": 0.9906699657440186
            },
            {
              "type": "aceituna",
              "rectangle": [
                368,
                584,
                425,
                642
              ],
              "weight": 0.9868521094322205
            },
            {
              "type": "aceituna",
              "rectangle": [
                732,
                340,
                784,
                397
              ],
              "weight": 0.9866488575935364
            },
            {
              "type": "aceituna",
              "rectangle": [
                383,
                877,
                421,
                926
              ],
              "weight": 0.9826791286468506
            },
            {
              "type": "aceituna",
              "rectangle": [
                641,
                612,
                693,
                666
              ],
              "weight": 0.9756805896759033
            },
            {
              "type": "aceituna",
              "rectangle": [
                620,
                247,
                680,
                286
              ],
              "weight": 0.973457932472229
            },
            {
              "type": "aceituna",
              "rectangle": [
                394,
                295,
                428,
                334
              ],
              "weight": 0.9718319773674011
            },
            {
              "type": "aceituna",
              "rectangle": [
                371,
                826,
                404,
                873
              ],
              "weight": 0.9677437543869019
            },
            {
              "type": "aceituna",
              "rectangle": [
                554,
                628,
                597,
                672
              ],
              "weight": 0.9657903909683228
            },
            {
              "type": "aceituna",
              "rectangle": [
                512,
                387,
                555,
                418
              ],
              "weight": 0.965388834476471
            },
            {
              "type": "aceituna",
              "rectangle": [
                669,
                659,
                722,
                712
              ],
              "weight": 0.954861581325531
            },
            {
              "type": "pepperoni",
              "rectangle": [
                519,
                686,
                614,
                804
              ],
              "weight": 0.9545599818229675
            },
            {
              "type": "pepperoni",
              "rectangle": [
                684,
                523,
                795,
                606
              ],
              "weight": 0.954235315322876
            },
            {
              "type": "aceituna",
              "rectangle": [
                767,
                624,
                800,
                667
              ],
              "weight": 0.9538495540618896
            },
            {
              "type": "pepperoni",
              "rectangle": [
                509,
                848,
                619,
                963
              ],
              "weight": 0.949884295463562
            },
            {
              "type": "aceituna",
              "rectangle": [
                120,
                632,
                163,
                680
              ],
              "weight": 0.9438555836677551
            },
            {
              "type": "aceituna",
              "rectangle": [
                414,
                254,
                453,
                286
              ],
              "weight": 0.9413976669311523
            },
            {
              "type": "aceituna",
              "rectangle": [
                708,
                312,
                746,
                352
              ],
              "weight": 0.9384105801582336
            },
            {
              "type": "carne",
              "rectangle": [
                231,
                219,
                286,
                259
              ],
              "weight": 0.9280678629875183
            },
            {
              "type": "pepperoni",
              "rectangle": [
                472,
                550,
                573,
                662
              ],
              "weight": 0.9149379134178162
            },
            {
              "type": "aceituna",
              "rectangle": [
                679,
                354,
                706,
                389
              ],
              "weight": 0.8932391405105591
            },
            {
              "type": "pepperoni",
              "rectangle": [
                263,
                483,
                367,
                563
              ],
              "weight": 0.8927701115608215
            },
            {
              "type": "aceituna",
              "rectangle": [
                567,
                677,
                613,
                718
              ],
              "weight": 0.8912217617034912
            },
            {
              "type": "carne",
              "rectangle": [
                582,
                179,
                629,
                229
              ],
              "weight": 0.8905413150787354
            },
            {
              "type": "pepperoni",
              "rectangle": [
                588,
                150,
                698,
                239
              ],
              "weight": 0.8868229985237122
            },
            {
              "type": "carne",
              "rectangle": [
                295,
                670,
                342,
                727
              ],
              "weight": 0.8766855597496033
            },
            {
              "type": "carne",
              "rectangle": [
                551,
                257,
                602,
                309
              ],
              "weight": 0.8725019693374634
            },
            {
              "type": "carne",
              "rectangle": [
                538,
                405,
                588,
                453
              ],
              "weight": 0.8676241636276245
            },
            {
              "type": "aceituna",
              "rectangle": [
                633,
                406,
                677,
                450
              ],
              "weight": 0.8670411109924316
            },
            {
              "type": "aceituna",
              "rectangle": [
                455,
                662,
                499,
                701
              ],
              "weight": 0.8636637330055237
            },
            {
              "type": "carne",
              "rectangle": [
                239,
                268,
                290,
                317
              ],
              "weight": 0.8409571647644043
            },
            {
              "type": "aceituna",
              "rectangle": [
                691,
                915,
                733,
                953
              ],
              "weight": 0.8336997628211975
            },
            {
              "type": "pepperoni",
              "rectangle": [
                839,
                637,
                958,
                700
              ],
              "weight": 0.8020724654197693
            },
            {
              "type": "carne",
              "rectangle": [
                589,
                424,
                642,
                476
              ],
              "weight": 0.78587806224823
            },
            {
              "type": "aceituna",
              "rectangle": [
                815,
                817,
                856,
                865
              ],
              "weight": 0.7766929864883423
            },
            {
              "type": "carne",
              "rectangle": [
                177,
                602,
                224,
                647
              ],
              "weight": 0.7704855799674988
            },
            {
              "type": "champiñón",
              "rectangle": [
                719,
                389,
                811,
                455
              ],
              "weight": 0.752797544002533
            },
            {
              "type": "aceituna",
              "rectangle": [
                132,
                309,
                178,
                357
              ],
              "weight": 0.7376890182495117
            },
            {
              "type": "carne",
              "rectangle": [
                600,
                875,
                654,
                925
              ],
              "weight": 0.7319043278694153
            },
            {
              "type": "pepperoni",
              "rectangle": [
                503,
                490,
                626,
                568
              ],
              "weight": 0.7238491773605347
            },
            {
              "type": "carne",
              "rectangle": [
                400,
                759,
                456,
                826
              ],
              "weight": 0.7201089859008789
            },
            {
              "type": "aceituna",
              "rectangle": [
                515,
                217,
                563,
                277
              ],
              "weight": 0.71773362159729
            },
            {
              "type": "pepperoni",
              "rectangle": [
                588,
                324,
                683,
                419
              ],
              "weight": 0.7120141983032227
            },
            {
              "type": "pepperoni",
              "rectangle": [
                430,
                445,
                503,
                528
              ],
              "weight": 0.7003234624862671
            },
            {
              "type": "aceituna",
              "rectangle": [
                725,
                723,
                767,
                769
              ],
              "weight": 0.6995351910591125
            },
            {
              "type": "carne",
              "rectangle": [
                238,
                581,
                291,
                639
              ],
              "weight": 0.6934959292411804
            },
            {
              "type": "champiñón",
              "rectangle": [
                454,
                856,
                509,
                916
              ],
              "weight": 0.6814071536064148
            },
            {
              "type": "carne",
              "rectangle": [
                102,
                360,
                166,
                409
              ],
              "weight": 0.6554468870162964
            },
            {
              "type": "champiñón",
              "rectangle": [
                630,
                425,
                721,
                504
              ],
              "weight": 0.6414977312088013
            },
            {
              "type": "aceituna",
              "rectangle": [
                645,
                572,
                671,
                613
              ],
              "weight": 0.613534152507782
            },
            {
              "type": "aceituna",
              "rectangle": [
                694,
                517,
                735,
                553
              ],
              "weight": 0.5911093950271606
            },
            {
              "type": "pepperoni",
              "rectangle": [
                288,
                832,
                389,
                937
              ],
              "weight": 0.566723644733429
            },
            {
              "type": "carne",
              "rectangle": [
                730,
                251,
                770,
                295
              ],
              "weight": 0.5542708039283752
            },
            {
              "type": "carne",
              "rectangle": [
                612,
                713,
                716,
                799
              ],
              "weight": 0.5534789562225342
            },
            {
              "type": "aceituna",
              "rectangle": [
                768,
                264,
                800,
                290
              ],
              "weight": 0.5399252772331238
            },
            {
              "type": "aceituna",
              "rectangle": [
                582,
                230,
                626,
                271
              ],
              "weight": 0.5319213271141052
            },
            {
              "type": "pepperoni",
              "rectangle": [
                838,
                464,
                964,
                532
              ],
              "weight": 0.4932185709476471
            },
            {
              "type": "aceituna",
              "rectangle": [
                294,
                563,
                324,
                604
              ],
              "weight": 0.4905402958393097
            },
            {
              "type": "carne",
              "rectangle": [
                415,
                863,
                465,
                913
              ],
              "weight": 0.48944854736328125
            },
            {
              "type": "carne",
              "rectangle": [
                203,
                681,
                280,
                770
              ],
              "weight": 0.47716453671455383
            },
            {
              "type": "carne",
              "rectangle": [
                717,
                767,
                771,
                827
              ],
              "weight": 0.4505250155925751
            },
            {
              "type": "carne",
              "rectangle": [
                230,
                513,
                279,
                557
              ],
              "weight": 0.43489232659339905
            },
            {
              "type": "aceituna",
              "rectangle": [
                676,
                452,
                718,
                500
              ],
              "weight": 0.43218794465065
            },
            {
              "type": "choclo",
              "rectangle": [
                706,
                721,
                731,
                751
              ],
              "weight": 0.4226074516773224
            },
            {
              "type": "pepperoni",
              "rectangle": [
                412,
                346,
                493,
                434
              ],
              "weight": 0.41573208570480347
            },
            {
              "type": "pepperoni",
              "rectangle": [
                208,
                306,
                282,
                367
              ],
              "weight": 0.4155217409133911
            },
            {
              "type": "champiñón",
              "rectangle": [
                476,
                200,
                561,
                277
              ],
              "weight": 0.39698052406311035
            },
            {
              "type": "carne",
              "rectangle": [
                787,
                440,
                846,
                501
              ],
              "weight": 0.38346970081329346
            },
            {
              "type": "carne",
              "rectangle": [
                812,
                553,
                861,
                609
              ],
              "weight": 0.36541226506233215
            },
            {
              "type": "champiñón",
              "rectangle": [
                141,
                446,
                221,
                514
              ],
              "weight": 0.36118996143341064
            },
            {
              "type": "champiñón",
              "rectangle": [
                386,
                525,
                439,
                564
              ],
              "weight": 0.35553038120269775
            },
            {
              "type": "pepperoni",
              "rectangle": [
                167,
                518,
                230,
                614
              ],
              "weight": 0.33502236008644104
            },
            {
              "type": "pepperoni",
              "rectangle": [
                320,
                139,
                426,
                245
              ],
              "weight": 0.3339235782623291
            },
            {
              "type": "carne",
              "rectangle": [
                350,
                199,
                404,
                243
              ],
              "weight": 0.3275100588798523
            },
            {
              "type": "carne",
              "rectangle": [
                258,
                628,
                296,
                663
              ],
              "weight": 0.3140147626399994
            },
            {
              "type": "aceituna",
              "rectangle": [
                718,
                177,
                756,
                208
              ],
              "weight": 0.3100704848766327
            },
            {
              "type": "aceituna",
              "rectangle": [
                485,
                872,
                513,
                896
              ],
              "weight": 0.30327948927879333
            }
          ],
          "64": [
            {
              "type": "aceituna",
              "rectangle": [
                757,
                548,
                803,
                598
              ],
              "weight": 0.9824470281600952
            },
            {
              "type": "pepperoni",
              "rectangle": [
                852,
                366,
                964,
                476
              ],
              "weight": 0.9818252325057983
            },
            {
              "type": "aceituna",
              "rectangle": [
                847,
                677,
                905,
                724
              ],
              "weight": 0.9749683141708374
            },
            {
              "type": "aceituna",
              "rectangle": [
                350,
                524,
                408,
                596
              ],
              "weight": 0.9733759164810181
            },
            {
              "type": "pepperoni",
              "rectangle": [
                280,
                580,
                392,
                689
              ],
              "weight": 0.9633449912071228
            },
            {
              "type": "aceituna",
              "rectangle": [
                689,
                749,
                733,
                784
              ],
              "weight": 0.9536697864532471
            },
            {
              "type": "aceituna",
              "rectangle": [
                613,
                669,
                665,
                718
              ],
              "weight": 0.9535998106002808
            },
            {
              "type": "champiñón",
              "rectangle": [
                637,
                475,
                706,
                560
              ],
              "weight": 0.9412769079208374
            },
            {
              "type": "pepperoni",
              "rectangle": [
                429,
                563,
                536,
                671
              ],
              "weight": 0.9408366680145264
            },
            {
              "type": "aceituna",
              "rectangle": [
                522,
                227,
                556,
                260
              ],
              "weight": 0.9315558075904846
            },
            {
              "type": "aceituna",
              "rectangle": [
                727,
                689,
                755,
                739
              ],
              "weight": 0.9281270503997803
            },
            {
              "type": "aceituna",
              "rectangle": [
                576,
                399,
                623,
                430
              ],
              "weight": 0.9266095161437988
            },
            {
              "type": "aceituna",
              "rectangle": [
                727,
                404,
                767,
                439
              ],
              "weight": 0.9244445562362671
            },
            {
              "type": "aceituna",
              "rectangle": [
                623,
                716,
                666,
                765
              ],
              "weight": 0.9160125255584717
            },
            {
              "type": "pepperoni",
              "rectangle": [
                734,
                160,
                823,
                285
              ],
              "weight": 0.8997196555137634
            },
            {
              "type": "carne",
              "rectangle": [
                171,
                479,
                215,
                524
              ],
              "weight": 0.8956661820411682
            },
            {
              "type": "aceituna",
              "rectangle": [
                267,
                794,
                307,
                844
              ],
              "weight": 0.8941945433616638
            },
            {
              "type": "carne",
              "rectangle": [
                652,
                273,
                701,
                326
              ],
              "weight": 0.8918576836585999
            },
            {
              "type": "pepperoni",
              "rectangle": [
                453,
                434,
                541,
                498
              ],
              "weight": 0.8872447609901428
            },
            {
              "type": "aceituna",
              "rectangle": [
                497,
                269,
                527,
                310
              ],
              "weight": 0.8865054249763489
            },
            {
              "type": "aceituna",
              "rectangle": [
                780,
                375,
                823,
                414
              ],
              "weight": 0.8778173923492432
            },
            {
              "type": "pepperoni",
              "rectangle": [
                448,
                677,
                526,
                803
              ],
              "weight": 0.8502042889595032
            },
            {
              "type": "aceituna",
              "rectangle": [
                555,
                940,
                594,
                981
              ],
              "weight": 0.8472614884376526
            },
            {
              "type": "aceituna",
              "rectangle": [
                692,
                245,
                740,
                294
              ],
              "weight": 0.8451085090637207
            },
            {
              "type": "carne",
              "rectangle": [
                357,
                134,
                410,
                179
              ],
              "weight": 0.8442978858947754
            },
            {
              "type": "aceituna",
              "rectangle": [
                786,
                410,
                838,
                455
              ],
              "weight": 0.8352372646331787
            },
            {
              "type": "carne",
              "rectangle": [
                359,
                185,
                402,
                226
              ],
              "weight": 0.8234296441078186
            },
            {
              "type": "carne",
              "rectangle": [
                306,
                794,
                355,
                850
              ],
              "weight": 0.8130565881729126
            },
            {
              "type": "pepperoni",
              "rectangle": [
                675,
                588,
                782,
                682
              ],
              "weight": 0.8113198280334473
            },
            {
              "type": "carne",
              "rectangle": [
                320,
                692,
                375,
                771
              ],
              "weight": 0.8110260367393494
            },
            {
              "type": "carne",
              "rectangle": [
                238,
                603,
                283,
                649
              ],
              "weight": 0.8102842569351196
            },
            {
              "type": "champiñón",
              "rectangle": [
                359,
                801,
                466,
                860
              ],
              "weight": 0.8031307458877563
            },
            {
              "type": "pepperoni",
              "rectangle": [
                832,
                533,
                976,
                637
              ],
              "weight": 0.7850348949432373
            },
            {
              "type": "aceituna",
              "rectangle": [
                720,
                290,
                784,
                330
              ],
              "weight": 0.7825135588645935
            },
            {
              "type": "pepperoni",
              "rectangle": [
                363,
                861,
                530,
                986
              ],
              "weight": 0.764251708984375
            },
            {
              "type": "carne",
              "rectangle": [
                790,
                649,
                847,
                699
              ],
              "weight": 0.764106035232544
            },
            {
              "type": "carne",
              "rectangle": [
                473,
                873,
                537,
                916
              ],
              "weight": 0.7369595170021057
            },
            {
              "type": "carne",
              "rectangle": [
                181,
                565,
                219,
                618
              ],
              "weight": 0.7334789633750916
            },
            {
              "type": "aceituna",
              "rectangle": [
                225,
                190,
                274,
                238
              ],
              "weight": 0.7077387571334839
            },
            {
              "type": "carne",
              "rectangle": [
                226,
                480,
                284,
                539
              ],
              "weight": 0.7076926827430725
            },
            {
              "type": "carne",
              "rectangle": [
                316,
                457,
                368,
                499
              ],
              "weight": 0.7048037648200989
            },
            {
              "type": "pepperoni",
              "rectangle": [
                189,
                712,
                277,
                838
              ],
              "weight": 0.692100465297699
            },
            {
              "type": "carne",
              "rectangle": [
                179,
                232,
                242,
                279
              ],
              "weight": 0.6842297315597534
            },
            {
              "type": "pepperoni",
              "rectangle": [
                108,
                363,
                242,
                479
              ],
              "weight": 0.6815773844718933
            },
            {
              "type": "aceituna",
              "rectangle": [
                620,
                627,
                658,
                673
              ],
              "weight": 0.6399914026260376
            },
            {
              "type": "carne",
              "rectangle": [
                579,
                429,
                635,
                480
              ],
              "weight": 0.6388665437698364
            },
            {
              "type": "carne",
              "rectangle": [
                144,
                702,
                181,
                747
              ],
              "weight": 0.6332175135612488
            },
            {
              "type": "carne",
              "rectangle": [
                737,
                765,
                789,
                818
              ],
              "weight": 0.6289812922477722
            },
            {
              "type": "carne",
              "rectangle": [
                203,
                332,
                253,
                391
              ],
              "weight": 0.6285292506217957
            },
            {
              "type": "carne",
              "rectangle": [
                780,
                492,
                841,
                541
              ],
              "weight": 0.6174994707107544
            },
            {
              "type": "carne",
              "rectangle": [
                831,
                544,
                880,
                610
              ],
              "weight": 0.5983856320381165
            },
            {
              "type": "pepperoni",
              "rectangle": [
                287,
                193,
                376,
                280
              ],
              "weight": 0.5831927061080933
            },
            {
              "type": "carne",
              "rectangle": [
                714,
                902,
                752,
                950
              ],
              "weight": 0.5715639591217041
            },
            {
              "type": "champiñón",
              "rectangle": [
                585,
                206,
                678,
                268
              ],
              "weight": 0.5491154789924622
            },
            {
              "type": "aceituna",
              "rectangle": [
                469,
                521,
                504,
                569
              ],
              "weight": 0.5429865121841431
            },
            {
              "type": "carne",
              "rectangle": [
                702,
                195,
                761,
                249
              ],
              "weight": 0.5415597558021545
            },
            {
              "type": "carne",
              "rectangle": [
                103,
                485,
                150,
                533
              ],
              "weight": 0.5259758830070496
            },
            {
              "type": "carne",
              "rectangle": [
                689,
                312,
                730,
                356
              ],
              "weight": 0.5207364559173584
            },
            {
              "type": "carne",
              "rectangle": [
                679,
                705,
                733,
                751
              ],
              "weight": 0.5007855892181396
            },
            {
              "type": "champiñón",
              "rectangle": [
                501,
                127,
                549,
                193
              ],
              "weight": 0.4963918924331665
            },
            {
              "type": "pepperoni",
              "rectangle": [
                457,
                81,
                568,
                201
              ],
              "weight": 0.48746058344841003
            },
            {
              "type": "carne",
              "rectangle": [
                276,
                290,
                330,
                339
              ],
              "weight": 0.4754052460193634
            },
            {
              "type": "carne",
              "rectangle": [
                276,
                741,
                308,
                788
              ],
              "weight": 0.446189820766449
            },
            {
              "type": "pepperoni",
              "rectangle": [
                785,
                748,
                904,
                802
              ],
              "weight": 0.4395424425601959
            },
            {
              "type": "aceituna",
              "rectangle": [
                642,
                227,
                677,
                262
              ],
              "weight": 0.42826735973358154
            },
            {
              "type": "aceituna",
              "rectangle": [
                687,
                580,
                713,
                604
              ],
              "weight": 0.3667357265949249
            },
            {
              "type": "pepperoni",
              "rectangle": [
                635,
                392,
                705,
                469
              ],
              "weight": 0.35302862524986267
            },
            {
              "type": "carne",
              "rectangle": [
                247,
                418,
                297,
                458
              ],
              "weight": 0.35248255729675293
            },
            {
              "type": "carne",
              "rectangle": [
                210,
                443,
                248,
                489
              ],
              "weight": 0.3359959125518799
            },
            {
              "type": "carne",
              "rectangle": [
                547,
                742,
                631,
                822
              ],
              "weight": 0.33455222845077515
            },
            {
              "type": "carne",
              "rectangle": [
                556,
                593,
                598,
                629
              ],
              "weight": 0.31168022751808167
            },
            {
              "type": "aceituna",
              "rectangle": [
                395,
                626,
                444,
                673
              ],
              "weight": 0.30410677194595337
            }
          ],
          "66": [
            {
              "type": "jamón",
              "rectangle": [
                808,
                438,
                906,
                539
              ],
              "weight": 0.9728989601135254
            },
            {
              "type": "carne",
              "rectangle": [
                754,
                556,
                803,
                597
              ],
              "weight": 0.9257785081863403
            },
            {
              "type": "pepperoni",
              "rectangle": [
                430,
                888,
                538,
                994
              ],
              "weight": 0.9181857109069824
            },
            {
              "type": "carne",
              "rectangle": [
                286,
                680,
                335,
                722
              ],
              "weight": 0.9149020910263062
            },
            {
              "type": "carne",
              "rectangle": [
                583,
                479,
                639,
                545
              ],
              "weight": 0.9060378074645996
            },
            {
              "type": "carne",
              "rectangle": [
                321,
                627,
                379,
                696
              ],
              "weight": 0.8969097137451172
            },
            {
              "type": "jamón",
              "rectangle": [
                475,
                384,
                571,
                488
              ],
              "weight": 0.894134521484375
            },
            {
              "type": "carne",
              "rectangle": [
                849,
                660,
                910,
                715
              ],
              "weight": 0.8907749652862549
            },
            {
              "type": "carne",
              "rectangle": [
                553,
                682,
                619,
                737
              ],
              "weight": 0.8884747624397278
            },
            {
              "type": "carne",
              "rectangle": [
                821,
                538,
                877,
                577
              ],
              "weight": 0.880625307559967
            },
            {
              "type": "jamón",
              "rectangle": [
                322,
                480,
                421,
                587
              ],
              "weight": 0.8794993162155151
            },
            {
              "type": "pepperoni",
              "rectangle": [
                776,
                588,
                878,
                700
              ],
              "weight": 0.8623237609863281
            },
            {
              "type": "pepperoni",
              "rectangle": [
                221,
                347,
                298,
                463
              ],
              "weight": 0.8594391942024231
            },
            {
              "type": "carne",
              "rectangle": [
                273,
                404,
                337,
                466
              ],
              "weight": 0.8495683073997498
            },
            {
              "type": "pepperoni",
              "rectangle": [
                450,
                536,
                538,
                625
              ],
              "weight": 0.8415389060974121
            },
            {
              "type": "carne",
              "rectangle": [
                363,
                590,
                423,
                648
              ],
              "weight": 0.8409702777862549
            },
            {
              "type": "carne",
              "rectangle": [
                679,
                649,
                723,
                685
              ],
              "weight": 0.8351811766624451
            },
            {
              "type": "jamón",
              "rectangle": [
                59,
                536,
                141,
                643
              ],
              "weight": 0.8273578882217407
            },
            {
              "type": "carne",
              "rectangle": [
                765,
                180,
                815,
                229
              ],
              "weight": 0.8259221315383911
            },
            {
              "type": "carne",
              "rectangle": [
                329,
                271,
                369,
                318
              ],
              "weight": 0.8253087997436523
            },
            {
              "type": "carne",
              "rectangle": [
                678,
                542,
                761,
                612
              ],
              "weight": 0.8214060068130493
            },
            {
              "type": "pepperoni",
              "rectangle": [
                575,
                147,
                680,
                265
              ],
              "weight": 0.8111458420753479
            },
            {
              "type": "pepperoni",
              "rectangle": [
                167,
                237,
                250,
                335
              ],
              "weight": 0.8085542321205139
            },
            {
              "type": "pepperoni",
              "rectangle": [
                597,
                312,
                703,
                421
              ],
              "weight": 0.8077195882797241
            },
            {
              "type": "pepperoni",
              "rectangle": [
                374,
                377,
                457,
                465
              ],
              "weight": 0.7980437874794006
            },
            {
              "type": "pepperoni",
              "rectangle": [
                558,
                873,
                654,
                986
              ],
              "weight": 0.7979118824005127
            },
            {
              "type": "carne",
              "rectangle": [
                645,
                228,
                695,
                292
              ],
              "weight": 0.7912270426750183
            },
            {
              "type": "pepperoni",
              "rectangle": [
                668,
                824,
                762,
                946
              ],
              "weight": 0.7852450609207153
            },
            {
              "type": "carne",
              "rectangle": [
                899,
                439,
                939,
                505
              ],
              "weight": 0.7823159694671631
            },
            {
              "type": "carne",
              "rectangle": [
                157,
                413,
                204,
                471
              ],
              "weight": 0.7405893206596375
            },
            {
              "type": "carne",
              "rectangle": [
                454,
                342,
                521,
                407
              ],
              "weight": 0.7401073575019836
            },
            {
              "type": "carne",
              "rectangle": [
                261,
                465,
                322,
                514
              ],
              "weight": 0.7355723977088928
            },
            {
              "type": "jamón",
              "rectangle": [
                745,
                220,
                812,
                296
              ],
              "weight": 0.7157142758369446
            },
            {
              "type": "carne",
              "rectangle": [
                487,
                199,
                536,
                239
              ],
              "weight": 0.7103082537651062
            },
            {
              "type": "carne",
              "rectangle": [
                438,
                828,
                498,
                884
              ],
              "weight": 0.704735517501831
            },
            {
              "type": "pepperoni",
              "rectangle": [
                291,
                834,
                417,
                931
              ],
              "weight": 0.6972084045410156
            },
            {
              "type": "jamón",
              "rectangle": [
                517,
                82,
                582,
                168
              ],
              "weight": 0.6818227767944336
            },
            {
              "type": "carne",
              "rectangle": [
                568,
                780,
                634,
                842
              ],
              "weight": 0.6757499575614929
            },
            {
              "type": "pepperoni",
              "rectangle": [
                780,
                318,
                877,
                435
              ],
              "weight": 0.5898802280426025
            },
            {
              "type": "pepperoni",
              "rectangle": [
                406,
                465,
                495,
                547
              ],
              "weight": 0.5442276000976562
            },
            {
              "type": "carne",
              "rectangle": [
                572,
                569,
                610,
                631
              ],
              "weight": 0.5308377146720886
            },
            {
              "type": "carne",
              "rectangle": [
                706,
                417,
                743,
                454
              ],
              "weight": 0.5076854228973389
            },
            {
              "type": "carne",
              "rectangle": [
                419,
                878,
                460,
                924
              ],
              "weight": 0.4667862355709076
            },
            {
              "type": "tocino",
              "rectangle": [
                121,
                304,
                148,
                391
              ],
              "weight": 0.45157408714294434
            },
            {
              "type": "jamón",
              "rectangle": [
                368,
                270,
                434,
                364
              ],
              "weight": 0.4490351676940918
            },
            {
              "type": "carne",
              "rectangle": [
                249,
                589,
                338,
                685
              ],
              "weight": 0.44682320952415466
            },
            {
              "type": "pepperoni",
              "rectangle": [
                116,
                647,
                233,
                739
              ],
              "weight": 0.40644946694374084
            },
            {
              "type": "carne",
              "rectangle": [
                322,
                185,
                373,
                228
              ],
              "weight": 0.39480432868003845
            },
            {
              "type": "carne",
              "rectangle": [
                389,
                787,
                433,
                817
              ],
              "weight": 0.3772878646850586
            },
            {
              "type": "pepperoni",
              "rectangle": [
                502,
                748,
                562,
                840
              ],
              "weight": 0.36791691184043884
            },
            {
              "type": "carne",
              "rectangle": [
                767,
                366,
                800,
                424
              ],
              "weight": 0.3628576993942261
            },
            {
              "type": "jamón",
              "rectangle": [
                178,
                518,
                288,
                577
              ],
              "weight": 0.36066770553588867
            },
            {
              "type": "pepperoni",
              "rectangle": [
                268,
                110,
                378,
                229
              ],
              "weight": 0.3503924310207367
            },
            {
              "type": "carne",
              "rectangle": [
                404,
                551,
                458,
                611
              ],
              "weight": 0.3382345736026764
            },
            {
              "type": "carne",
              "rectangle": [
                435,
                416,
                479,
                475
              ],
              "weight": 0.3374442458152771
            },
            {
              "type": "carne",
              "rectangle": [
                753,
                667,
                793,
                725
              ],
              "weight": 0.31878045201301575
            },
            {
              "type": "carne",
              "rectangle": [
                280,
                267,
                315,
                312
              ],
              "weight": 0.31862932443618774
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "64821aaa6b373f72769fea6a",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "63": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0,
                "8": 0
              },
              "Limpieza": {
                "1": 0,
                "2": 0.1,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0.1
              }
            },
            "score_final": 8.899999999999999,
            "specialty_check": false,
            "score_adjusted": 8.899999999999999
          },
          "64": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Limpieza": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0
              }
            },
            "score_final": 8.899999999999999,
            "specialty_check": true,
            "score_adjusted": 8.899999999999999
          },
          "66": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 9.399999999999999,
            "specialty_check": true,
            "score_adjusted": 9.399999999999997
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64821d09b76785dcdc50580c",
        "REPORT_capture_start_ts": "2023/06/08 18:11:39"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "6482286012502ec08c1d80cb",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64821d09b76785dcdc50580c",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64821f6c8444cd15b48bd4a6",
        "REPORT_capture_start_ts": "2023/06/08 18:21:40"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 72,
            "REPORT_localized_capture_start_ts": "14:27:27 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "chicken_garlic",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_14:21:40_v9p1-ssd-dense_id0072(None)-fr2776-05:47.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_14:21:40_v9p1-ssd-dense_id0072(None)-fr2776-05:47.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1376,
                1285,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.000001594772697899316,
              "occ": 0.004005654249340296
            },
            "optimal_frame": 2776,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "REPORT_localized_capture_start_ts": "14:28:05 2023/06/08",
            "optimal_frame_quality": {
              "blur": 0.005882370751351118,
              "occ": 0.01624656654894352
            },
            "bubbles": 1,
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_14:21:40_v9p1-ssd-dense_id0073(None)-fr3078-06:25.jpg",
            "snap_quality": "buena",
            "multisize": false,
            "raw_score": false,
            "snap_quality_trusted": true,
            "original_data": {
              "result": {
                "score_details": {
                  "Ingredientes interior": {
                    "1": 0.25,
                    "2": 0.25,
                    "3": 0.25,
                    "4": 0.25,
                    "5": 0.25,
                    "6": 0.25,
                    "7": 0.25,
                    "8": 0.25
                  },
                  "Queso interior": {
                    "1": 0.1,
                    "2": 0.1,
                    "3": 0.1,
                    "4": 0.1,
                    "5": 0.1,
                    "6": 0.1,
                    "7": 0.1,
                    "8": 0.1
                  },
                  "Ingredientes exterior": {
                    "1": 0.25,
                    "2": 0.25,
                    "3": 0.25,
                    "4": 0,
                    "5": 0.25,
                    "6": 0.25,
                    "7": 0.25,
                    "8": 0.25
                  },
                  "Bordes": {
                    "1": 0.1,
                    "2": 0.1,
                    "3": 0.1,
                    "4": 0.1,
                    "5": 0.1,
                    "6": 0,
                    "7": 0,
                    "8": 0.1
                  },
                  "Cocción queso": {
                    "1": 0.1,
                    "2": 0.1,
                    "3": 0.1,
                    "4": 0.1,
                    "5": 0.1,
                    "6": 0.1,
                    "7": 0.1,
                    "8": 0.1
                  },
                  "Queso exterior": {
                    "1": 0.1,
                    "2": 0.1,
                    "3": 0.1,
                    "4": 0,
                    "5": 0.1,
                    "6": 0.1,
                    "7": 0.1,
                    "8": 0.1
                  },
                  "Sello de queso": {
                    "1": 0,
                    "2": 0.1,
                    "3": 0,
                    "4": 0,
                    "5": 0.1,
                    "6": 0.1,
                    "7": 0.1,
                    "8": 0.1
                  },
                  "Limpieza": {
                    "1": 0.1,
                    "2": 0.1,
                    "3": 0.1,
                    "4": 0.1,
                    "5": 0.1,
                    "6": 0.1,
                    "7": 0.1,
                    "8": 0.1
                  }
                },
                "score_final": 9.149999999999999,
                "specialty_check": false,
                "score_adjusted": 9.149999999999997
              }
            },
            "overbaked": 1,
            "id": 73,
            "standard": "maybe_to_standard",
            "category": "todas_las_carnes",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_14:21:40_v9p1-ssd-dense_id0073(None)-fr3078-06:25.jpg",
            "REPORT_frame_detection_summary": {},
            "censored": null,
            "attributes": null,
            "omit_soft": true,
            "bad_cut": null,
            "optimal_figure": {
              "coords": [
                881,
                1210,
                506
              ],
              "__type__": "Circle"
            },
            "REPORT_detections_categ_summary": [],
            "optimal_frame": 3078,
            "reviewer": "rodrigo.pulcha",
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "64822b1712502ec08c1d80cc",
        "optimal_snap_ingredients": {
          "72": [
            {
              "type": "pollo",
              "rectangle": [
                519,
                430,
                578,
                521
              ],
              "weight": 0.9836854934692383
            },
            {
              "type": "pollo",
              "rectangle": [
                761,
                509,
                835,
                559
              ],
              "weight": 0.9771136045455933
            },
            {
              "type": "pollo",
              "rectangle": [
                414,
                219,
                483,
                293
              ],
              "weight": 0.9683535099029541
            },
            {
              "type": "pollo",
              "rectangle": [
                620,
                549,
                668,
                623
              ],
              "weight": 0.9499934911727905
            },
            {
              "type": "pollo",
              "rectangle": [
                483,
                540,
                545,
                610
              ],
              "weight": 0.9497430324554443
            },
            {
              "type": "pollo",
              "rectangle": [
                501,
                875,
                546,
                919
              ],
              "weight": 0.9393048882484436
            },
            {
              "type": "carne",
              "rectangle": [
                312,
                569,
                370,
                641
              ],
              "weight": 0.9226118326187134
            },
            {
              "type": "carne",
              "rectangle": [
                425,
                794,
                472,
                850
              ],
              "weight": 0.9118228554725647
            },
            {
              "type": "carne",
              "rectangle": [
                812,
                620,
                857,
                670
              ],
              "weight": 0.8712233304977417
            },
            {
              "type": "carne",
              "rectangle": [
                487,
                500,
                545,
                545
              ],
              "weight": 0.8685477375984192
            },
            {
              "type": "carne",
              "rectangle": [
                600,
                153,
                646,
                213
              ],
              "weight": 0.8544121980667114
            },
            {
              "type": "pollo",
              "rectangle": [
                684,
                289,
                722,
                353
              ],
              "weight": 0.8296261429786682
            },
            {
              "type": "pollo",
              "rectangle": [
                613,
                490,
                661,
                548
              ],
              "weight": 0.8254668712615967
            },
            {
              "type": "carne",
              "rectangle": [
                465,
                668,
                528,
                723
              ],
              "weight": 0.801527738571167
            },
            {
              "type": "carne",
              "rectangle": [
                669,
                390,
                737,
                467
              ],
              "weight": 0.8014070987701416
            },
            {
              "type": "pollo",
              "rectangle": [
                360,
                360,
                421,
                436
              ],
              "weight": 0.7912527322769165
            },
            {
              "type": "pollo",
              "rectangle": [
                598,
                711,
                661,
                810
              ],
              "weight": 0.7808881998062134
            },
            {
              "type": "pollo",
              "rectangle": [
                651,
                710,
                701,
                774
              ],
              "weight": 0.7800356149673462
            },
            {
              "type": "carne",
              "rectangle": [
                722,
                209,
                778,
                258
              ],
              "weight": 0.7797272205352783
            },
            {
              "type": "pollo",
              "rectangle": [
                637,
                286,
                686,
                336
              ],
              "weight": 0.7777727842330933
            },
            {
              "type": "carne",
              "rectangle": [
                875,
                711,
                961,
                787
              ],
              "weight": 0.7738655209541321
            },
            {
              "type": "carne",
              "rectangle": [
                665,
                587,
                714,
                637
              ],
              "weight": 0.7664204239845276
            },
            {
              "type": "carne",
              "rectangle": [
                604,
                577,
                634,
                634
              ],
              "weight": 0.7500374913215637
            },
            {
              "type": "pollo",
              "rectangle": [
                418,
                437,
                462,
                495
              ],
              "weight": 0.7378532886505127
            },
            {
              "type": "carne",
              "rectangle": [
                692,
                455,
                741,
                490
              ],
              "weight": 0.7064288854598999
            },
            {
              "type": "carne",
              "rectangle": [
                900,
                562,
                968,
                630
              ],
              "weight": 0.6895344257354736
            },
            {
              "type": "carne",
              "rectangle": [
                524,
                585,
                573,
                648
              ],
              "weight": 0.6864254474639893
            },
            {
              "type": "carne",
              "rectangle": [
                719,
                583,
                813,
                655
              ],
              "weight": 0.6793724894523621
            },
            {
              "type": "carne",
              "rectangle": [
                794,
                802,
                875,
                877
              ],
              "weight": 0.6197459697723389
            },
            {
              "type": "pollo",
              "rectangle": [
                597,
                247,
                641,
                310
              ],
              "weight": 0.5167258381843567
            },
            {
              "type": "carne",
              "rectangle": [
                865,
                364,
                945,
                449
              ],
              "weight": 0.5066649317741394
            },
            {
              "type": "carne",
              "rectangle": [
                627,
                713,
                665,
                746
              ],
              "weight": 0.48705822229385376
            },
            {
              "type": "carne",
              "rectangle": [
                553,
                731,
                581,
                762
              ],
              "weight": 0.4789785146713257
            },
            {
              "type": "pollo",
              "rectangle": [
                520,
                698,
                554,
                760
              ],
              "weight": 0.4772443473339081
            },
            {
              "type": "carne",
              "rectangle": [
                665,
                838,
                690,
                874
              ],
              "weight": 0.47349244356155396
            },
            {
              "type": "carne",
              "rectangle": [
                331,
                728,
                391,
                772
              ],
              "weight": 0.4553554356098175
            },
            {
              "type": "carne",
              "rectangle": [
                217,
                719,
                365,
                841
              ],
              "weight": 0.45478564500808716
            },
            {
              "type": "carne",
              "rectangle": [
                462,
                718,
                516,
                762
              ],
              "weight": 0.42313477396965027
            },
            {
              "type": "pepperoni",
              "rectangle": [
                877,
                445,
                991,
                549
              ],
              "weight": 0.40948814153671265
            },
            {
              "type": "pollo",
              "rectangle": [
                619,
                340,
                676,
                483
              ],
              "weight": 0.4079186022281647
            },
            {
              "type": "carne",
              "rectangle": [
                315,
                433,
                410,
                524
              ],
              "weight": 0.40655606985092163
            },
            {
              "type": "carne",
              "rectangle": [
                731,
                657,
                777,
                708
              ],
              "weight": 0.36538833379745483
            },
            {
              "type": "pollo",
              "rectangle": [
                706,
                638,
                749,
                673
              ],
              "weight": 0.3650707006454468
            },
            {
              "type": "carne",
              "rectangle": [
                446,
                622,
                473,
                654
              ],
              "weight": 0.31824395060539246
            },
            {
              "type": "pollo",
              "rectangle": [
                410,
                861,
                468,
                906
              ],
              "weight": 0.31815096735954285
            }
          ],
          "73": [
            {
              "type": "pepperoni",
              "rectangle": [
                666,
                803,
                786,
                914
              ],
              "weight": 0.9594449996948242
            },
            {
              "type": "carne",
              "rectangle": [
                662,
                778,
                702,
                832
              ],
              "weight": 0.9305823445320129
            },
            {
              "type": "pepperoni",
              "rectangle": [
                434,
                650,
                535,
                767
              ],
              "weight": 0.9110956788063049
            },
            {
              "type": "carne",
              "rectangle": [
                336,
                424,
                388,
                475
              ],
              "weight": 0.905067503452301
            },
            {
              "type": "pepperoni",
              "rectangle": [
                666,
                695,
                765,
                789
              ],
              "weight": 0.9028127193450928
            },
            {
              "type": "pepperoni",
              "rectangle": [
                290,
                589,
                387,
                699
              ],
              "weight": 0.8984784483909607
            },
            {
              "type": "pepperoni",
              "rectangle": [
                349,
                353,
                427,
                459
              ],
              "weight": 0.8865313529968262
            },
            {
              "type": "carne",
              "rectangle": [
                250,
                499,
                307,
                559
              ],
              "weight": 0.878906786441803
            },
            {
              "type": "pepperoni",
              "rectangle": [
                743,
                300,
                841,
                413
              ],
              "weight": 0.8674957156181335
            },
            {
              "type": "pepperoni",
              "rectangle": [
                476,
                445,
                624,
                564
              ],
              "weight": 0.7880796790122986
            },
            {
              "type": "carne",
              "rectangle": [
                380,
                463,
                427,
                514
              ],
              "weight": 0.7784147262573242
            },
            {
              "type": "carne",
              "rectangle": [
                771,
                642,
                852,
                714
              ],
              "weight": 0.7611818909645081
            },
            {
              "type": "carne",
              "rectangle": [
                589,
                313,
                649,
                379
              ],
              "weight": 0.7519354224205017
            },
            {
              "type": "pepperoni",
              "rectangle": [
                530,
                801,
                661,
                905
              ],
              "weight": 0.7467943429946899
            },
            {
              "type": "pepperoni",
              "rectangle": [
                289,
                729,
                394,
                807
              ],
              "weight": 0.7300014495849609
            },
            {
              "type": "carne",
              "rectangle": [
                619,
                707,
                669,
                755
              ],
              "weight": 0.7035838961601257
            },
            {
              "type": "pepperoni",
              "rectangle": [
                285,
                454,
                385,
                537
              ],
              "weight": 0.684720516204834
            },
            {
              "type": "carne",
              "rectangle": [
                479,
                798,
                548,
                866
              ],
              "weight": 0.6606014370918274
            },
            {
              "type": "carne",
              "rectangle": [
                698,
                467,
                785,
                552
              ],
              "weight": 0.6519186496734619
            },
            {
              "type": "pepperoni",
              "rectangle": [
                414,
                195,
                538,
                289
              ],
              "weight": 0.635581910610199
            },
            {
              "type": "carne",
              "rectangle": [
                564,
                387,
                633,
                452
              ],
              "weight": 0.6339736580848694
            },
            {
              "type": "carne",
              "rectangle": [
                259,
                325,
                335,
                425
              ],
              "weight": 0.560154378414154
            },
            {
              "type": "carne",
              "rectangle": [
                334,
                221,
                399,
                270
              ],
              "weight": 0.5250142216682434
            },
            {
              "type": "pepperoni",
              "rectangle": [
                513,
                706,
                588,
                782
              ],
              "weight": 0.5218215584754944
            },
            {
              "type": "carne",
              "rectangle": [
                601,
                246,
                652,
                300
              ],
              "weight": 0.515764594078064
            },
            {
              "type": "carne",
              "rectangle": [
                419,
                209,
                477,
                279
              ],
              "weight": 0.4302026629447937
            },
            {
              "type": "pepperoni",
              "rectangle": [
                776,
                423,
                890,
                551
              ],
              "weight": 0.4287678301334381
            },
            {
              "type": "pepperoni",
              "rectangle": [
                583,
                137,
                667,
                284
              ],
              "weight": 0.3865553140640259
            },
            {
              "type": "pollo",
              "rectangle": [
                788,
                573,
                924,
                615
              ],
              "weight": 0.380825012922287
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "64821f6c8444cd15b48bd4a6",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "72": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0.1,
                "7": 0,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0.25,
                "3": 0,
                "4": 0,
                "5": 0.25,
                "6": 0.25,
                "7": 0,
                "8": 0
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0,
                "8": 0
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0.1,
                "8": 0
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 7.05,
            "specialty_check": false,
            "score_adjusted": 7.050000000000001
          },
          "73": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0,
                "7": 0,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0,
                "2": 0.1,
                "3": 0,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 3.0499999999999994,
            "specialty_check": false,
            "score_adjusted": 3.0499999999999994
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648221cb1931c73b03dcb43d",
        "REPORT_capture_start_ts": "2023/06/08 18:31:40"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 74,
            "REPORT_localized_capture_start_ts": "14:36:46 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_14:31:40_v9p1-ssd-dense_id0074(None)-fr2451-05:06.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_14:31:40_v9p1-ssd-dense_id0074(None)-fr2451-05:06.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1486,
                1377,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 3.002858628420313e-9,
              "occ": 0.00048386151320300996
            },
            "optimal_frame": 2451,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 75,
            "REPORT_localized_capture_start_ts": "14:39:35 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_14:31:40_v9p1-ssd-dense_id0075(None)-fr3800-07:55.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_14:31:40_v9p1-ssd-dense_id0075(None)-fr3800-07:55.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1024,
                1020,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.010251784697175026,
              "occ": 0.03415130451321602
            },
            "optimal_frame": 3800,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "64822df012502ec08c1d80cd",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "648221cb1931c73b03dcb43d",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "6482242a59294586fb700a15",
        "REPORT_capture_start_ts": "2023/06/08 18:41:41"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "6482309412502ec08c1d80ce",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "6482242a59294586fb700a15",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "6482268ad94f259e5ab15a1b",
        "REPORT_capture_start_ts": "2023/06/08 18:51:42"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 78,
            "REPORT_localized_capture_start_ts": "14:52:08 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "sweet_blue_cheese",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_14:51:42_v9p1-ssd-dense_id0078(None)-fr0206-00:26.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_14:51:42_v9p1-ssd-dense_id0078(None)-fr0206-00:26.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1582,
                1358,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.000012620166671695188,
              "occ": 0.000004140240434935549
            },
            "optimal_frame": 206,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          }
        ],
        "REPORT_id": "6482334e12502ec08c1d80cf",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "6482268ad94f259e5ab15a1b",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648228ec737fc1604de4e335",
        "REPORT_capture_start_ts": "2023/06/08 19:01:42"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 80,
            "REPORT_localized_capture_start_ts": "15:06:28 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "todas_las_carnes",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_15:01:42_v9p1-ssd-dense_id0080(None)-fr2290-04:46.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_15:01:42_v9p1-ssd-dense_id0080(None)-fr2290-04:46.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1527,
                1197,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.0000063849761318124365,
              "occ": 0.011957266367971897
            },
            "optimal_frame": 2290,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 81,
            "REPORT_localized_capture_start_ts": "15:08:21 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "todas_las_carnes",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_15:01:42_v9p1-ssd-dense_id0081(None)-fr3190-06:39.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_15:01:42_v9p1-ssd-dense_id0081(None)-fr3190-06:39.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1316,
                1199,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.0019861639011651278,
              "occ": 0.000810423691291362
            },
            "optimal_frame": 3190,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 82,
            "REPORT_localized_capture_start_ts": "15:11:41 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_15:01:42_v9p1-ssd-dense_id0082(None)-fr4790-09:59.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_15:01:42_v9p1-ssd-dense_id0082(None)-fr4790-09:59.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1392,
                1320,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00006290491728577763,
              "occ": 0.02025221660733223
            },
            "optimal_frame": 4790,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "6482361112502ec08c1d80d0",
        "optimal_snap_ingredients": {
          "80": [
            {
              "type": "carne",
              "rectangle": [
                604,
                385,
                664,
                439
              ],
              "weight": 0.9424185752868652
            },
            {
              "type": "carne",
              "rectangle": [
                409,
                686,
                464,
                729
              ],
              "weight": 0.8995640873908997
            },
            {
              "type": "carne",
              "rectangle": [
                611,
                716,
                686,
                789
              ],
              "weight": 0.8856595754623413
            },
            {
              "type": "carne",
              "rectangle": [
                475,
                362,
                531,
                417
              ],
              "weight": 0.8678594827651978
            },
            {
              "type": "pepperoni",
              "rectangle": [
                238,
                429,
                352,
                537
              ],
              "weight": 0.8322757482528687
            },
            {
              "type": "pepperoni",
              "rectangle": [
                618,
                205,
                699,
                325
              ],
              "weight": 0.7761073112487793
            },
            {
              "type": "pepperoni",
              "rectangle": [
                448,
                833,
                544,
                927
              ],
              "weight": 0.762606143951416
            },
            {
              "type": "jamón",
              "rectangle": [
                499,
                397,
                588,
                500
              ],
              "weight": 0.7617575526237488
            },
            {
              "type": "pepperoni",
              "rectangle": [
                684,
                337,
                798,
                449
              ],
              "weight": 0.7203917503356934
            },
            {
              "type": "carne",
              "rectangle": [
                719,
                619,
                769,
                678
              ],
              "weight": 0.6844693422317505
            },
            {
              "type": "carne",
              "rectangle": [
                334,
                818,
                398,
                886
              ],
              "weight": 0.6757876873016357
            },
            {
              "type": "carne",
              "rectangle": [
                233,
                331,
                286,
                390
              ],
              "weight": 0.6366933584213257
            },
            {
              "type": "pepperoni",
              "rectangle": [
                368,
                249,
                419,
                365
              ],
              "weight": 0.5946807861328125
            },
            {
              "type": "pepperoni",
              "rectangle": [
                622,
                547,
                685,
                656
              ],
              "weight": 0.5944964289665222
            },
            {
              "type": "jamón",
              "rectangle": [
                610,
                462,
                692,
                549
              ],
              "weight": 0.5939477682113647
            },
            {
              "type": "tocino",
              "rectangle": [
                603,
                454,
                668,
                499
              ],
              "weight": 0.5827377438545227
            },
            {
              "type": "pepperoni",
              "rectangle": [
                499,
                173,
                578,
                303
              ],
              "weight": 0.5791264176368713
            },
            {
              "type": "pepperoni",
              "rectangle": [
                329,
                547,
                445,
                652
              ],
              "weight": 0.5737106800079346
            },
            {
              "type": "carne",
              "rectangle": [
                303,
                670,
                351,
                707
              ],
              "weight": 0.566969096660614
            },
            {
              "type": "pepperoni",
              "rectangle": [
                412,
                395,
                503,
                490
              ],
              "weight": 0.564099907875061
            },
            {
              "type": "pepperoni",
              "rectangle": [
                242,
                649,
                302,
                748
              ],
              "weight": 0.5637235045433044
            },
            {
              "type": "pepperoni",
              "rectangle": [
                240,
                564,
                291,
                661
              ],
              "weight": 0.5229820013046265
            },
            {
              "type": "carne",
              "rectangle": [
                611,
                540,
                648,
                582
              ],
              "weight": 0.4030618965625763
            },
            {
              "type": "pepperoni",
              "rectangle": [
                293,
                669,
                373,
                743
              ],
              "weight": 0.37577301263809204
            },
            {
              "type": "carne",
              "rectangle": [
                336,
                780,
                404,
                825
              ],
              "weight": 0.36737656593322754
            }
          ],
          "81": [
            {
              "type": "pepperoni",
              "rectangle": [
                561,
                637,
                662,
                757
              ],
              "weight": 0.985828161239624
            },
            {
              "type": "carne",
              "rectangle": [
                829,
                704,
                881,
                754
              ],
              "weight": 0.9472237825393677
            },
            {
              "type": "pepperoni",
              "rectangle": [
                516,
                822,
                619,
                908
              ],
              "weight": 0.9169202446937561
            },
            {
              "type": "carne",
              "rectangle": [
                561,
                230,
                607,
                295
              ],
              "weight": 0.8771694302558899
            },
            {
              "type": "carne",
              "rectangle": [
                884,
                557,
                936,
                599
              ],
              "weight": 0.8638940453529358
            },
            {
              "type": "pepperoni",
              "rectangle": [
                658,
                233,
                769,
                380
              ],
              "weight": 0.8259958028793335
            },
            {
              "type": "carne",
              "rectangle": [
                473,
                808,
                521,
                868
              ],
              "weight": 0.8149943351745605
            },
            {
              "type": "carne",
              "rectangle": [
                762,
                753,
                816,
                796
              ],
              "weight": 0.7616506218910217
            },
            {
              "type": "pepperoni",
              "rectangle": [
                796,
                394,
                919,
                476
              ],
              "weight": 0.7422753572463989
            },
            {
              "type": "pepperoni",
              "rectangle": [
                378,
                604,
                471,
                714
              ],
              "weight": 0.6955804824829102
            },
            {
              "type": "carne",
              "rectangle": [
                442,
                498,
                531,
                593
              ],
              "weight": 0.6544163227081299
            },
            {
              "type": "carne",
              "rectangle": [
                298,
                296,
                344,
                351
              ],
              "weight": 0.6372165679931641
            },
            {
              "type": "pepperoni",
              "rectangle": [
                611,
                777,
                708,
                896
              ],
              "weight": 0.6300424337387085
            },
            {
              "type": "pepperoni",
              "rectangle": [
                829,
                486,
                915,
                598
              ],
              "weight": 0.6247979998588562
            },
            {
              "type": "pepperoni",
              "rectangle": [
                207,
                479,
                322,
                592
              ],
              "weight": 0.6082507967948914
            },
            {
              "type": "carne",
              "rectangle": [
                603,
                758,
                650,
                799
              ],
              "weight": 0.5907312631607056
            },
            {
              "type": "pepperoni",
              "rectangle": [
                618,
                483,
                766,
                567
              ],
              "weight": 0.552911102771759
            },
            {
              "type": "pepperoni",
              "rectangle": [
                294,
                323,
                382,
                414
              ],
              "weight": 0.4689359664916992
            },
            {
              "type": "carne",
              "rectangle": [
                373,
                710,
                455,
                810
              ],
              "weight": 0.4599602222442627
            },
            {
              "type": "pepperoni",
              "rectangle": [
                490,
                383,
                605,
                491
              ],
              "weight": 0.44954511523246765
            },
            {
              "type": "carne",
              "rectangle": [
                769,
                600,
                827,
                669
              ],
              "weight": 0.4422503113746643
            },
            {
              "type": "carne",
              "rectangle": [
                835,
                348,
                891,
                407
              ],
              "weight": 0.4401691257953644
            },
            {
              "type": "carne",
              "rectangle": [
                212,
                578,
                311,
                631
              ],
              "weight": 0.42096424102783203
            },
            {
              "type": "carne",
              "rectangle": [
                381,
                192,
                478,
                254
              ],
              "weight": 0.417291522026062
            },
            {
              "type": "pepperoni",
              "rectangle": [
                322,
                499,
                438,
                614
              ],
              "weight": 0.41099050641059875
            },
            {
              "type": "carne",
              "rectangle": [
                663,
                251,
                698,
                315
              ],
              "weight": 0.36217111349105835
            },
            {
              "type": "anana",
              "rectangle": [
                923,
                634,
                966,
                713
              ],
              "weight": 0.3358466625213623
            },
            {
              "type": "carne",
              "rectangle": [
                511,
                396,
                561,
                460
              ],
              "weight": 0.31811240315437317
            },
            {
              "type": "carne",
              "rectangle": [
                502,
                230,
                562,
                293
              ],
              "weight": 0.3009087145328522
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "648228ec737fc1604de4e335",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "80": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0,
                "3": 0.25,
                "4": 0.25,
                "5": 0,
                "6": 0.25,
                "7": 0.25,
                "8": 0
              },
              "Bordes": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 8.299999999999999,
            "specialty_check": true,
            "score_adjusted": 8.299999999999997
          },
          "81": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 9.799999999999999,
            "specialty_check": false,
            "score_adjusted": 9.799999999999995
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64822b502e08b8db9e3f1c5f",
        "REPORT_capture_start_ts": "2023/06/08 19:11:43"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 83,
            "REPORT_localized_capture_start_ts": "15:13:03 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "chicken_garlic",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_15:11:43_v9p1-ssd-dense_id0083(None)-fr0644-01:20.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_15:11:43_v9p1-ssd-dense_id0083(None)-fr0644-01:20.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1829,
                1134,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00001810291905712802,
              "occ": 0.06364002078771591
            },
            "optimal_frame": 644,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          }
        ],
        "REPORT_id": "648238c112502ec08c1d80d1",
        "optimal_snap_ingredients": {
          "83": [
            {
              "type": "pollo",
              "rectangle": [
                359,
                293,
                445,
                389
              ],
              "weight": 0.9871422052383423
            },
            {
              "type": "pollo",
              "rectangle": [
                338,
                732,
                419,
                858
              ],
              "weight": 0.9851040244102478
            },
            {
              "type": "pollo",
              "rectangle": [
                231,
                474,
                320,
                544
              ],
              "weight": 0.977182924747467
            },
            {
              "type": "pollo",
              "rectangle": [
                507,
                213,
                635,
                270
              ],
              "weight": 0.9664352536201477
            },
            {
              "type": "pollo",
              "rectangle": [
                529,
                365,
                579,
                464
              ],
              "weight": 0.9209250807762146
            },
            {
              "type": "pollo",
              "rectangle": [
                635,
                442,
                720,
                524
              ],
              "weight": 0.9205436706542969
            },
            {
              "type": "pollo",
              "rectangle": [
                602,
                634,
                656,
                683
              ],
              "weight": 0.9180740714073181
            },
            {
              "type": "pollo",
              "rectangle": [
                200,
                570,
                259,
                675
              ],
              "weight": 0.9152812957763672
            },
            {
              "type": "pollo",
              "rectangle": [
                654,
                618,
                704,
                672
              ],
              "weight": 0.9116612076759338
            },
            {
              "type": "pollo",
              "rectangle": [
                742,
                728,
                793,
                776
              ],
              "weight": 0.9052331447601318
            },
            {
              "type": "pollo",
              "rectangle": [
                598,
                829,
                657,
                943
              ],
              "weight": 0.8973497748374939
            },
            {
              "type": "pollo",
              "rectangle": [
                284,
                295,
                359,
                411
              ],
              "weight": 0.8825502395629883
            },
            {
              "type": "pollo",
              "rectangle": [
                652,
                730,
                730,
                795
              ],
              "weight": 0.8762672543525696
            },
            {
              "type": "carne",
              "rectangle": [
                470,
                645,
                524,
                705
              ],
              "weight": 0.8600645661354065
            },
            {
              "type": "pollo",
              "rectangle": [
                570,
                585,
                614,
                650
              ],
              "weight": 0.8523765206336975
            },
            {
              "type": "pollo",
              "rectangle": [
                343,
                407,
                391,
                441
              ],
              "weight": 0.8449187278747559
            },
            {
              "type": "pollo",
              "rectangle": [
                807,
                502,
                918,
                547
              ],
              "weight": 0.8356179594993591
            },
            {
              "type": "carne",
              "rectangle": [
                523,
                268,
                559,
                325
              ],
              "weight": 0.8270376324653625
            },
            {
              "type": "pollo",
              "rectangle": [
                848,
                456,
                920,
                505
              ],
              "weight": 0.8079403042793274
            },
            {
              "type": "pollo",
              "rectangle": [
                706,
                293,
                763,
                354
              ],
              "weight": 0.7454659342765808
            },
            {
              "type": "pollo",
              "rectangle": [
                429,
                611,
                490,
                659
              ],
              "weight": 0.7318621873855591
            },
            {
              "type": "carne",
              "rectangle": [
                294,
                702,
                339,
                737
              ],
              "weight": 0.7141094207763672
            },
            {
              "type": "carne",
              "rectangle": [
                528,
                661,
                563,
                699
              ],
              "weight": 0.6765481233596802
            },
            {
              "type": "pollo",
              "rectangle": [
                507,
                702,
                547,
                787
              ],
              "weight": 0.6679762601852417
            },
            {
              "type": "pollo",
              "rectangle": [
                281,
                574,
                324,
                628
              ],
              "weight": 0.6626157164573669
            },
            {
              "type": "pollo",
              "rectangle": [
                557,
                679,
                653,
                712
              ],
              "weight": 0.6084279417991638
            },
            {
              "type": "pollo",
              "rectangle": [
                796,
                541,
                870,
                586
              ],
              "weight": 0.605660080909729
            },
            {
              "type": "pollo",
              "rectangle": [
                788,
                797,
                843,
                884
              ],
              "weight": 0.5969770550727844
            },
            {
              "type": "pollo",
              "rectangle": [
                675,
                411,
                720,
                456
              ],
              "weight": 0.584097683429718
            },
            {
              "type": "pollo",
              "rectangle": [
                451,
                348,
                521,
                405
              ],
              "weight": 0.5812748670578003
            },
            {
              "type": "pollo",
              "rectangle": [
                493,
                838,
                558,
                890
              ],
              "weight": 0.5561392307281494
            },
            {
              "type": "carne",
              "rectangle": [
                201,
                506,
                259,
                573
              ],
              "weight": 0.5294249653816223
            },
            {
              "type": "pollo",
              "rectangle": [
                585,
                529,
                637,
                575
              ],
              "weight": 0.5260463953018188
            },
            {
              "type": "pollo",
              "rectangle": [
                372,
                524,
                412,
                571
              ],
              "weight": 0.519497275352478
            },
            {
              "type": "carne",
              "rectangle": [
                621,
                713,
                657,
                748
              ],
              "weight": 0.518227219581604
            },
            {
              "type": "carne",
              "rectangle": [
                642,
                551,
                710,
                621
              ],
              "weight": 0.5070421695709229
            },
            {
              "type": "carne",
              "rectangle": [
                664,
                279,
                702,
                328
              ],
              "weight": 0.48034849762916565
            },
            {
              "type": "pollo",
              "rectangle": [
                571,
                341,
                620,
                408
              ],
              "weight": 0.4461330473423004
            },
            {
              "type": "carne",
              "rectangle": [
                386,
                361,
                426,
                407
              ],
              "weight": 0.44201964139938354
            },
            {
              "type": "carne",
              "rectangle": [
                800,
                348,
                834,
                398
              ],
              "weight": 0.43708235025405884
            },
            {
              "type": "carne",
              "rectangle": [
                427,
                707,
                470,
                751
              ],
              "weight": 0.43667134642601013
            },
            {
              "type": "pollo",
              "rectangle": [
                532,
                554,
                577,
                654
              ],
              "weight": 0.4359588921070099
            },
            {
              "type": "pollo",
              "rectangle": [
                329,
                542,
                393,
                625
              ],
              "weight": 0.43018803000450134
            },
            {
              "type": "carne",
              "rectangle": [
                255,
                410,
                315,
                456
              ],
              "weight": 0.388482928276062
            },
            {
              "type": "carne",
              "rectangle": [
                241,
                272,
                291,
                326
              ],
              "weight": 0.3852166533470154
            },
            {
              "type": "carne",
              "rectangle": [
                436,
                290,
                477,
                322
              ],
              "weight": 0.3791191577911377
            },
            {
              "type": "pollo",
              "rectangle": [
                858,
                610,
                911,
                640
              ],
              "weight": 0.34705814719200134
            },
            {
              "type": "pollo",
              "rectangle": [
                529,
                321,
                586,
                368
              ],
              "weight": 0.32814261317253113
            },
            {
              "type": "pollo",
              "rectangle": [
                696,
                664,
                725,
                720
              ],
              "weight": 0.326089084148407
            },
            {
              "type": "pollo",
              "rectangle": [
                717,
                425,
                770,
                533
              ],
              "weight": 0.3192509114742279
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "64822b502e08b8db9e3f1c5f",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "83": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Limpieza": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0
              }
            },
            "score_final": 8.799999999999999,
            "specialty_check": false,
            "score_adjusted": 8.799999999999999
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64822daf6c4472cb0976b305",
        "REPORT_capture_start_ts": "2023/06/08 19:21:43"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "64823b6a12502ec08c1d80d2",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64822daf6c4472cb0976b305",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "6482300fa3133f100e50bf62",
        "REPORT_capture_start_ts": "2023/06/08 19:31:44"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "64823e0a12502ec08c1d80d3",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "6482300fa3133f100e50bf62",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "6482326f77a2117e82d95eee",
        "REPORT_capture_start_ts": "2023/06/08 19:41:45"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "648240ae12502ec08c1d80d4",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "6482326f77a2117e82d95eee",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648234d18044d77e5947911c",
        "REPORT_capture_start_ts": "2023/06/08 19:51:45"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "6482435812502ec08c1d80d5",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "648234d18044d77e5947911c",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64823733ced73c9f5b1b816f",
        "REPORT_capture_start_ts": "2023/06/08 20:01:46"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "648245fc12502ec08c1d80d6",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64823733ced73c9f5b1b816f",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "6482386621cf33fec9ef8290",
        "REPORT_capture_start_ts": "2023/06/08 20:11:47"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 88,
            "REPORT_localized_capture_start_ts": "16:20:27 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "pepperoni",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_16:11:47_v9p1-ssd-dense_id0088(None)-fr4164-08:40.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_16:11:47_v9p1-ssd-dense_id0088(None)-fr4164-08:40.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1195,
                1221,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.007652954664081335,
              "occ": 0.0014452043687924743
            },
            "optimal_frame": 4164,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "648248a212502ec08c1d80d7",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "6482386621cf33fec9ef8290",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64823ac619d4e4cf99958f5d",
        "REPORT_capture_start_ts": "2023/06/08 20:21:48"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 89,
            "REPORT_localized_capture_start_ts": "16:26:12 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "pepperoni",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_16:21:48_v9p1-ssd-dense_id0089(None)-fr2112-04:24.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_16:21:48_v9p1-ssd-dense_id0089(None)-fr2112-04:24.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1174,
                1161,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00017590232891961932,
              "occ": 0.004933273885399103
            },
            "optimal_frame": 2112,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "64824b5a12502ec08c1d80d8",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64823ac619d4e4cf99958f5d",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64823d2744ba479fa3143dff",
        "REPORT_capture_start_ts": "2023/06/08 20:31:48"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "64824e0712502ec08c1d80d9",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64823d2744ba479fa3143dff",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64823f8738ed543311fa83af",
        "REPORT_capture_start_ts": "2023/06/08 20:41:49"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 90,
            "REPORT_localized_capture_start_ts": "16:48:51 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "sweet_blue_cheese",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_16:41:49_v9p1-ssd-dense_id0090(None)-fr3380-07:02.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_16:41:49_v9p1-ssd-dense_id0090(None)-fr3380-07:02.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1333,
                1117,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 9.974567092285724e-7,
              "occ": 0.0011463708942756057
            },
            "optimal_frame": 3380,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "648250ce12502ec08c1d80da",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64823f8738ed543311fa83af",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648241e82c95f0c9ebc1f7cc",
        "REPORT_capture_start_ts": "2023/06/08 20:51:50"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "REPORT_localized_capture_start_ts": "16:56:11 2023/06/08",
            "optimal_frame_quality": {
              "blur": 1.1968658952810074e-8,
              "occ": 0.0002459027455188334
            },
            "bubbles": null,
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_16:51:50_v9p1-ssd-dense_id0091(None)-fr2086-04:21.jpg",
            "snap_quality": "buena",
            "multisize": false,
            "raw_score": false,
            "snap_quality_trusted": true,
            "original_data": null,
            "overbaked": null,
            "id": 91,
            "standard": "not_to_standard",
            "category": "super_pepperoni",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_16:51:50_v9p1-ssd-dense_id0091(None)-fr2086-04:21.jpg",
            "REPORT_frame_detection_summary": {},
            "censored": null,
            "attributes": null,
            "omit_soft": true,
            "bad_cut": null,
            "optimal_figure": {
              "coords": [
                1490,
                1285,
                506
              ],
              "__type__": "Circle"
            },
            "REPORT_detections_categ_summary": [],
            "optimal_frame": 2086,
            "reviewer": "rodrigo.pulcha",
            "crust": "delgada"
          },
          {
            "id": 92,
            "REPORT_localized_capture_start_ts": "16:59:42 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "chicken_garlic",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_16:51:50_v9p1-ssd-dense_id0092(None)-fr3772-07:52.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_16:51:50_v9p1-ssd-dense_id0092(None)-fr3772-07:52.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1306,
                1375,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00002051199226116296,
              "occ": 0.00603035744279623
            },
            "optimal_frame": 3772,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          }
        ],
        "REPORT_id": "648253ba12502ec08c1d80db",
        "optimal_snap_ingredients": {
          "91": [
            {
              "type": "pepperoni",
              "rectangle": [
                303,
                691,
                409,
                801
              ],
              "weight": 0.9991856217384338
            },
            {
              "type": "pepperoni",
              "rectangle": [
                583,
                658,
                694,
                768
              ],
              "weight": 0.9990203380584717
            },
            {
              "type": "pepperoni",
              "rectangle": [
                490,
                86,
                605,
                199
              ],
              "weight": 0.9989808201789856
            },
            {
              "type": "pepperoni",
              "rectangle": [
                333,
                592,
                445,
                696
              ],
              "weight": 0.9989666938781738
            },
            {
              "type": "pepperoni",
              "rectangle": [
                875,
                441,
                984,
                556
              ],
              "weight": 0.9989497065544128
            },
            {
              "type": "pepperoni",
              "rectangle": [
                204,
                803,
                313,
                916
              ],
              "weight": 0.9988486766815186
            },
            {
              "type": "pepperoni",
              "rectangle": [
                475,
                792,
                580,
                896
              ],
              "weight": 0.9987990856170654
            },
            {
              "type": "pepperoni",
              "rectangle": [
                728,
                353,
                832,
                464
              ],
              "weight": 0.9987764954566956
            },
            {
              "type": "pepperoni",
              "rectangle": [
                174,
                357,
                281,
                456
              ],
              "weight": 0.9987016916275024
            },
            {
              "type": "pepperoni",
              "rectangle": [
                175,
                656,
                287,
                764
              ],
              "weight": 0.998645007610321
            },
            {
              "type": "pepperoni",
              "rectangle": [
                162,
                459,
                276,
                571
              ],
              "weight": 0.9986351132392883
            },
            {
              "type": "pepperoni",
              "rectangle": [
                627,
                392,
                743,
                494
              ],
              "weight": 0.9985941052436829
            },
            {
              "type": "pepperoni",
              "rectangle": [
                261,
                148,
                375,
                260
              ],
              "weight": 0.9985179305076599
            },
            {
              "type": "pepperoni",
              "rectangle": [
                244,
                546,
                350,
                631
              ],
              "weight": 0.9984779953956604
            },
            {
              "type": "pepperoni",
              "rectangle": [
                36,
                518,
                146,
                639
              ],
              "weight": 0.9984771609306335
            },
            {
              "type": "pepperoni",
              "rectangle": [
                591,
                288,
                695,
                395
              ],
              "weight": 0.9984714388847351
            },
            {
              "type": "pepperoni",
              "rectangle": [
                422,
                658,
                514,
                767
              ],
              "weight": 0.9984568357467651
            },
            {
              "type": "pepperoni",
              "rectangle": [
                512,
                362,
                612,
                466
              ],
              "weight": 0.9984498023986816
            },
            {
              "type": "pepperoni",
              "rectangle": [
                163,
                204,
                271,
                315
              ],
              "weight": 0.9983893632888794
            },
            {
              "type": "pepperoni",
              "rectangle": [
                656,
                492,
                764,
                602
              ],
              "weight": 0.9983799457550049
            },
            {
              "type": "pepperoni",
              "rectangle": [
                294,
                280,
                400,
                391
              ],
              "weight": 0.9983705878257751
            },
            {
              "type": "pepperoni",
              "rectangle": [
                755,
                572,
                869,
                672
              ],
              "weight": 0.9983698725700378
            },
            {
              "type": "pepperoni",
              "rectangle": [
                375,
                193,
                485,
                311
              ],
              "weight": 0.9983595013618469
            },
            {
              "type": "pepperoni",
              "rectangle": [
                484,
                203,
                584,
                300
              ],
              "weight": 0.9983526468276978
            },
            {
              "type": "pepperoni",
              "rectangle": [
                779,
                246,
                896,
                363
              ],
              "weight": 0.9983164072036743
            },
            {
              "type": "pepperoni",
              "rectangle": [
                912,
                547,
                1015,
                649
              ],
              "weight": 0.9982336759567261
            },
            {
              "type": "pepperoni",
              "rectangle": [
                69,
                386,
                183,
                499
              ],
              "weight": 0.9981004595756531
            },
            {
              "type": "pepperoni",
              "rectangle": [
                577,
                764,
                682,
                865
              ],
              "weight": 0.9980533123016357
            },
            {
              "type": "pepperoni",
              "rectangle": [
                440,
                311,
                538,
                426
              ],
              "weight": 0.998046875
            },
            {
              "type": "pepperoni",
              "rectangle": [
                289,
                443,
                393,
                557
              ],
              "weight": 0.9979560375213623
            },
            {
              "type": "pepperoni",
              "rectangle": [
                373,
                755,
                476,
                858
              ],
              "weight": 0.997810423374176
            },
            {
              "type": "pepperoni",
              "rectangle": [
                361,
                390,
                473,
                479
              ],
              "weight": 0.9977409839630127
            },
            {
              "type": "pepperoni",
              "rectangle": [
                118,
                567,
                229,
                675
              ],
              "weight": 0.9976919889450073
            },
            {
              "type": "pepperoni",
              "rectangle": [
                775,
                471,
                880,
                570
              ],
              "weight": 0.9975661039352417
            },
            {
              "type": "pepperoni",
              "rectangle": [
                575,
                561,
                672,
                640
              ],
              "weight": 0.9974159002304077
            },
            {
              "type": "pepperoni",
              "rectangle": [
                770,
                798,
                881,
                907
              ],
              "weight": 0.9973160624504089
            },
            {
              "type": "pepperoni",
              "rectangle": [
                668,
                717,
                776,
                822
              ],
              "weight": 0.9973146319389343
            },
            {
              "type": "pepperoni",
              "rectangle": [
                92,
                279,
                201,
                390
              ],
              "weight": 0.9971007704734802
            },
            {
              "type": "pepperoni",
              "rectangle": [
                569,
                452,
                671,
                546
              ],
              "weight": 0.9970619082450867
            },
            {
              "type": "pepperoni",
              "rectangle": [
                440,
                550,
                532,
                649
              ],
              "weight": 0.9969010353088379
            },
            {
              "type": "pepperoni",
              "rectangle": [
                880,
                352,
                986,
                438
              ],
              "weight": 0.9968225955963135
            },
            {
              "type": "pepperoni",
              "rectangle": [
                376,
                71,
                473,
                176
              ],
              "weight": 0.996764063835144
            },
            {
              "type": "pepperoni",
              "rectangle": [
                656,
                612,
                758,
                699
              ],
              "weight": 0.9967106580734253
            },
            {
              "type": "pepperoni",
              "rectangle": [
                888,
                654,
                993,
                754
              ],
              "weight": 0.9964603781700134
            },
            {
              "type": "pepperoni",
              "rectangle": [
                114,
                734,
                222,
                848
              ],
              "weight": 0.9964287877082825
            },
            {
              "type": "pepperoni",
              "rectangle": [
                509,
                636,
                585,
                733
              ],
              "weight": 0.9963359832763672
            },
            {
              "type": "pepperoni",
              "rectangle": [
                367,
                869,
                481,
                987
              ],
              "weight": 0.9960344433784485
            },
            {
              "type": "pepperoni",
              "rectangle": [
                306,
                837,
                382,
                960
              ],
              "weight": 0.9955223798751831
            },
            {
              "type": "pepperoni",
              "rectangle": [
                684,
                853,
                796,
                967
              ],
              "weight": 0.9953084588050842
            },
            {
              "type": "pepperoni",
              "rectangle": [
                736,
                661,
                837,
                755
              ],
              "weight": 0.9950566291809082
            },
            {
              "type": "pepperoni",
              "rectangle": [
                629,
                112,
                723,
                220
              ],
              "weight": 0.9947316646575928
            },
            {
              "type": "pepperoni",
              "rectangle": [
                29,
                646,
                144,
                759
              ],
              "weight": 0.9935517311096191
            },
            {
              "type": "pepperoni",
              "rectangle": [
                578,
                903,
                650,
                1021
              ],
              "weight": 0.9931690692901611
            },
            {
              "type": "pepperoni",
              "rectangle": [
                348,
                527,
                447,
                596
              ],
              "weight": 0.9926139116287231
            },
            {
              "type": "pepperoni",
              "rectangle": [
                861,
                759,
                973,
                851
              ],
              "weight": 0.991273820400238
            },
            {
              "type": "pepperoni",
              "rectangle": [
                464,
                899,
                560,
                1004
              ],
              "weight": 0.9887759685516357
            },
            {
              "type": "pepperoni",
              "rectangle": [
                492,
                724,
                581,
                806
              ],
              "weight": 0.9831816554069519
            },
            {
              "type": "pepperoni",
              "rectangle": [
                736,
                141,
                844,
                248
              ],
              "weight": 0.9735935926437378
            },
            {
              "type": "pepperoni",
              "rectangle": [
                695,
                284,
                785,
                337
              ],
              "weight": 0.761768102645874
            },
            {
              "type": "pepperoni",
              "rectangle": [
                431,
                448,
                539,
                531
              ],
              "weight": 0.7270216941833496
            },
            {
              "type": "pepperoni",
              "rectangle": [
                847,
                540,
                920,
                619
              ],
              "weight": 0.6375520825386047
            },
            {
              "type": "pepperoni",
              "rectangle": [
                262,
                709,
                315,
                786
              ],
              "weight": 0.5400622487068176
            },
            {
              "type": "pepperoni",
              "rectangle": [
                247,
                625,
                313,
                691
              ],
              "weight": 0.3450886011123657
            }
          ],
          "92": [
            {
              "type": "pollo",
              "rectangle": [
                454,
                649,
                511,
                722
              ],
              "weight": 0.9876904487609863
            },
            {
              "type": "pollo",
              "rectangle": [
                701,
                404,
                758,
                523
              ],
              "weight": 0.9851595759391785
            },
            {
              "type": "pollo",
              "rectangle": [
                870,
                503,
                913,
                617
              ],
              "weight": 0.972247838973999
            },
            {
              "type": "pollo",
              "rectangle": [
                609,
                453,
                673,
                494
              ],
              "weight": 0.9704151153564453
            },
            {
              "type": "pollo",
              "rectangle": [
                633,
                592,
                725,
                664
              ],
              "weight": 0.9637593030929565
            },
            {
              "type": "pollo",
              "rectangle": [
                297,
                774,
                408,
                828
              ],
              "weight": 0.9545092582702637
            },
            {
              "type": "pollo",
              "rectangle": [
                309,
                582,
                411,
                637
              ],
              "weight": 0.9411731958389282
            },
            {
              "type": "pollo",
              "rectangle": [
                394,
                208,
                473,
                330
              ],
              "weight": 0.9159556031227112
            },
            {
              "type": "pollo",
              "rectangle": [
                383,
                491,
                456,
                550
              ],
              "weight": 0.8931385278701782
            },
            {
              "type": "pollo",
              "rectangle": [
                486,
                215,
                532,
                260
              ],
              "weight": 0.8862290978431702
            },
            {
              "type": "pollo",
              "rectangle": [
                500,
                355,
                538,
                436
              ],
              "weight": 0.8771994113922119
            },
            {
              "type": "pollo",
              "rectangle": [
                406,
                550,
                462,
                590
              ],
              "weight": 0.8194706439971924
            },
            {
              "type": "pollo",
              "rectangle": [
                608,
                238,
                660,
                332
              ],
              "weight": 0.8151733875274658
            },
            {
              "type": "pollo",
              "rectangle": [
                686,
                254,
                803,
                318
              ],
              "weight": 0.7668010592460632
            },
            {
              "type": "pollo",
              "rectangle": [
                599,
                823,
                657,
                878
              ],
              "weight": 0.6309089660644531
            },
            {
              "type": "pollo",
              "rectangle": [
                456,
                520,
                513,
                571
              ],
              "weight": 0.6280577182769775
            },
            {
              "type": "carne",
              "rectangle": [
                641,
                652,
                695,
                712
              ],
              "weight": 0.6073858141899109
            },
            {
              "type": "pollo",
              "rectangle": [
                542,
                562,
                601,
                611
              ],
              "weight": 0.5795556306838989
            },
            {
              "type": "pollo",
              "rectangle": [
                563,
                327,
                605,
                363
              ],
              "weight": 0.5735548734664917
            },
            {
              "type": "pollo",
              "rectangle": [
                263,
                399,
                317,
                435
              ],
              "weight": 0.5456495881080627
            },
            {
              "type": "pollo",
              "rectangle": [
                162,
                486,
                265,
                527
              ],
              "weight": 0.4898504316806793
            },
            {
              "type": "pollo",
              "rectangle": [
                667,
                218,
                747,
                257
              ],
              "weight": 0.4518243372440338
            },
            {
              "type": "pollo",
              "rectangle": [
                178,
                627,
                253,
                682
              ],
              "weight": 0.43062081933021545
            },
            {
              "type": "pollo",
              "rectangle": [
                325,
                626,
                377,
                683
              ],
              "weight": 0.402546226978302
            },
            {
              "type": "pollo",
              "rectangle": [
                760,
                353,
                812,
                394
              ],
              "weight": 0.37788787484169006
            },
            {
              "type": "carne",
              "rectangle": [
                727,
                300,
                800,
                345
              ],
              "weight": 0.373003751039505
            },
            {
              "type": "pollo",
              "rectangle": [
                776,
                535,
                821,
                587
              ],
              "weight": 0.36494559049606323
            },
            {
              "type": "pollo",
              "rectangle": [
                414,
                590,
                470,
                642
              ],
              "weight": 0.3479441702365875
            },
            {
              "type": "carne",
              "rectangle": [
                654,
                278,
                699,
                320
              ],
              "weight": 0.34661751985549927
            },
            {
              "type": "pollo",
              "rectangle": [
                283,
                242,
                336,
                303
              ],
              "weight": 0.34092554450035095
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "648241e82c95f0c9ebc1f7cc",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "91": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0.1,
                "8": 0
              }
            },
            "score_final": 8.6,
            "specialty_check": true,
            "score_adjusted": 8.599999999999998
          },
          "92": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0.1
              }
            },
            "score_final": 9.1,
            "specialty_check": false,
            "score_adjusted": 9.099999999999998
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64824448092e04eaed636f9f",
        "REPORT_capture_start_ts": "2023/06/08 21:01:50"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 95,
            "REPORT_localized_capture_start_ts": "17:03:53 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_17:01:50_v9p1-ssd-dense_id0095(None)-fr0982-02:03.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_17:01:50_v9p1-ssd-dense_id0095(None)-fr0982-02:03.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1246,
                1337,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00018786924192681909,
              "occ": 0.00199407571926713
            },
            "optimal_frame": 982,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "6482566b12502ec08c1d80dc",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64824448092e04eaed636f9f",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648246a8d941fab0ab3cf3f1",
        "REPORT_capture_start_ts": "2023/06/08 21:11:51"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "6482591712502ec08c1d80dd",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "648246a8d941fab0ab3cf3f1",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "6482490af9b419a7912089a5",
        "REPORT_capture_start_ts": "2023/06/08 21:21:52"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 96,
            "REPORT_localized_capture_start_ts": "17:26:36 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "napolitana_cherry",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_17:21:52_v9p1-ssd-dense_id0096(None)-fr2269-04:44.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_17:21:52_v9p1-ssd-dense_id0096(None)-fr2269-04:44.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1491,
                1223,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.000011641786841209978,
              "occ": 0.013704093173146248
            },
            "optimal_frame": 2269,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "64825bcb12502ec08c1d80de",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "6482490af9b419a7912089a5",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64824b6ae05129d36316bb85",
        "REPORT_capture_start_ts": "2023/06/08 21:31:53"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "64825e6112502ec08c1d80df",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64824b6ae05129d36316bb85",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64824dcad7a3d7cf358d863f",
        "REPORT_capture_start_ts": "2023/06/08 21:41:53"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 97,
            "REPORT_localized_capture_start_ts": "17:43:59 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_17:41:53_v9p1-ssd-dense_id0097(None)-fr1011-02:06.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_17:41:53_v9p1-ssd-dense_id0097(None)-fr1011-02:06.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1393,
                1262,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.01868586800992489,
              "occ": 0.008869610726833344
            },
            "optimal_frame": 1011,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "6482611212502ec08c1d80e0",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64824dcad7a3d7cf358d863f",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "6482502be94ab89d1cbc3a6f",
        "REPORT_capture_start_ts": "2023/06/08 21:51:54"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "648263b812502ec08c1d80e1",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "6482502be94ab89d1cbc3a6f",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "6482528ecdb69773e1be1f8b",
        "REPORT_capture_start_ts": "2023/06/08 22:01:55"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "6482665112502ec08c1d80e2",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "6482528ecdb69773e1be1f8b",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648254f09168d194eac3754a",
        "REPORT_capture_start_ts": "2023/06/08 22:11:55"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 100,
            "REPORT_localized_capture_start_ts": "18:12:22 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_18:11:55_v9p1-ssd-dense_id0100(None)-fr0215-00:27.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_18:11:55_v9p1-ssd-dense_id0100(None)-fr0215-00:27.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1533,
                1217,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.6450915336608887,
              "occ": 0.5532131791114807
            },
            "optimal_frame": 215,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 101,
            "REPORT_localized_capture_start_ts": "18:16:01 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "napolitana",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_18:11:55_v9p1-ssd-dense_id0101(None)-fr1968-04:06.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_18:11:55_v9p1-ssd-dense_id0101(None)-fr1968-04:06.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                636,
                1366,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.000001302061605201743,
              "occ": 0.0856289342045784
            },
            "optimal_frame": 1968,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 102,
            "REPORT_localized_capture_start_ts": "18:19:42 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "napolitana",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_18:11:55_v9p1-ssd-dense_id0102(None)-fr3737-07:47.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_18:11:55_v9p1-ssd-dense_id0102(None)-fr3737-07:47.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1430,
                1384,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 8.03402855353852e-9,
              "occ": 0.3884833753108978
            },
            "optimal_frame": 3737,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "6482698212502ec08c1d80e3",
        "optimal_snap_ingredients": {
          "101": [
            {
              "type": "pepperoni",
              "rectangle": [
                467,
                420,
                570,
                536
              ],
              "weight": 0.9752753973007202
            },
            {
              "type": "tomate",
              "rectangle": [
                378,
                735,
                591,
                931
              ],
              "weight": 0.9578986763954163
            },
            {
              "type": "tomate",
              "rectangle": [
                593,
                154,
                805,
                365
              ],
              "weight": 0.9536376595497131
            },
            {
              "type": "pepperoni",
              "rectangle": [
                390,
                550,
                540,
                646
              ],
              "weight": 0.8738635778427124
            },
            {
              "type": "pepperoni",
              "rectangle": [
                301,
                163,
                489,
                367
              ],
              "weight": 0.8265364766120911
            },
            {
              "type": "pepperoni",
              "rectangle": [
                172,
                538,
                364,
                721
              ],
              "weight": 0.7554782032966614
            },
            {
              "type": "pollo",
              "rectangle": [
                465,
                632,
                531,
                719
              ],
              "weight": 0.729703426361084
            },
            {
              "type": "pepperoni",
              "rectangle": [
                563,
                579,
                694,
                767
              ],
              "weight": 0.7278134822845459
            },
            {
              "type": "pepperoni",
              "rectangle": [
                399,
                449,
                474,
                505
              ],
              "weight": 0.7226370573043823
            },
            {
              "type": "tomate",
              "rectangle": [
                755,
                436,
                953,
                616
              ],
              "weight": 0.7097069621086121
            },
            {
              "type": "pepperoni",
              "rectangle": [
                690,
                672,
                884,
                876
              ],
              "weight": 0.6279767155647278
            },
            {
              "type": "pepperoni",
              "rectangle": [
                574,
                392,
                684,
                509
              ],
              "weight": 0.5598896145820618
            },
            {
              "type": "pepperoni",
              "rectangle": [
                176,
                432,
                294,
                532
              ],
              "weight": 0.5001658201217651
            },
            {
              "type": "tomate",
              "rectangle": [
                529,
                637,
                612,
                736
              ],
              "weight": 0.34379875659942627
            }
          ],
          "102": [
            {
              "type": "tomate",
              "rectangle": [
                170,
                674,
                372,
                891
              ],
              "weight": 0.9702584147453308
            },
            {
              "type": "tomate",
              "rectangle": [
                105,
                445,
                299,
                643
              ],
              "weight": 0.955948531627655
            },
            {
              "type": "pimiento",
              "rectangle": [
                434,
                301,
                492,
                330
              ],
              "weight": 0.9494223594665527
            },
            {
              "type": "pepperoni",
              "rectangle": [
                429,
                328,
                573,
                456
              ],
              "weight": 0.9256492257118225
            },
            {
              "type": "pepperoni",
              "rectangle": [
                465,
                154,
                598,
                290
              ],
              "weight": 0.9083472490310669
            },
            {
              "type": "pimiento",
              "rectangle": [
                629,
                950,
                677,
                972
              ],
              "weight": 0.9047636985778809
            },
            {
              "type": "tomate",
              "rectangle": [
                626,
                249,
                813,
                445
              ],
              "weight": 0.8979043960571289
            },
            {
              "type": "jamón",
              "rectangle": [
                770,
                701,
                842,
                804
              ],
              "weight": 0.8805539011955261
            },
            {
              "type": "pepperoni",
              "rectangle": [
                717,
                593,
                841,
                711
              ],
              "weight": 0.8451334834098816
            },
            {
              "type": "pepperoni",
              "rectangle": [
                801,
                433,
                926,
                566
              ],
              "weight": 0.837811291217804
            },
            {
              "type": "pepperoni",
              "rectangle": [
                296,
                448,
                401,
                520
              ],
              "weight": 0.831855833530426
            },
            {
              "type": "tomate",
              "rectangle": [
                314,
                385,
                359,
                432
              ],
              "weight": 0.6977744102478027
            },
            {
              "type": "tomate",
              "rectangle": [
                658,
                728,
                859,
                946
              ],
              "weight": 0.5951312184333801
            },
            {
              "type": "anana",
              "rectangle": [
                410,
                701,
                458,
                795
              ],
              "weight": 0.5905808210372925
            },
            {
              "type": "tocino",
              "rectangle": [
                799,
                408,
                868,
                459
              ],
              "weight": 0.586632251739502
            },
            {
              "type": "pepperoni",
              "rectangle": [
                823,
                603,
                938,
                724
              ],
              "weight": 0.5733243823051453
            },
            {
              "type": "pepperoni",
              "rectangle": [
                550,
                413,
                700,
                505
              ],
              "weight": 0.526461124420166
            },
            {
              "type": "pepperoni",
              "rectangle": [
                659,
                716,
                735,
                835
              ],
              "weight": 0.4613913893699646
            },
            {
              "type": "pepperoni",
              "rectangle": [
                550,
                681,
                638,
                803
              ],
              "weight": 0.42236223816871643
            },
            {
              "type": "jamón",
              "rectangle": [
                317,
                517,
                395,
                580
              ],
              "weight": 0.4048994481563568
            },
            {
              "type": "jamón",
              "rectangle": [
                266,
                784,
                377,
                889
              ],
              "weight": 0.37600740790367126
            },
            {
              "type": "anana",
              "rectangle": [
                553,
                302,
                604,
                385
              ],
              "weight": 0.36584362387657166
            },
            {
              "type": "jamón",
              "rectangle": [
                758,
                346,
                822,
                420
              ],
              "weight": 0.3174619674682617
            },
            {
              "type": "tocino",
              "rectangle": [
                682,
                596,
                743,
                639
              ],
              "weight": 0.3135976493358612
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "648254f09168d194eac3754a",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "101": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0.25,
                "5": 0,
                "6": 0.25,
                "7": 0,
                "8": 0.25
              },
              "Bordes": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 7.05,
            "specialty_check": false,
            "score_adjusted": 7.050000000000001
          },
          "102": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0,
                "3": 0,
                "4": 0.25,
                "5": 0.25,
                "6": 0,
                "7": 0.25,
                "8": 0
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 8.2,
            "specialty_check": false,
            "score_adjusted": 8.199999999999998
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648257518e523d5e19aa609f",
        "REPORT_capture_start_ts": "2023/06/08 22:21:56"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 103,
            "REPORT_localized_capture_start_ts": "18:26:03 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "vegetariana",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_18:21:56_v9p1-ssd-dense_id0103(None)-fr1978-04:07.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_18:21:56_v9p1-ssd-dense_id0103(None)-fr1978-04:07.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1101,
                1384,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.0785464197397232,
              "occ": 0.03699318692088127
            },
            "optimal_frame": 1978,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          },
          {
            "id": 107,
            "REPORT_localized_capture_start_ts": "18:27:45 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_18:21:56_v9p1-ssd-dense_id0107(None)-fr2792-05:49.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_18:21:56_v9p1-ssd-dense_id0107(None)-fr2792-05:49.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                991,
                1372,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 7.683867124796961e-7,
              "occ": 0.0056608556769788265
            },
            "optimal_frame": 2792,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "64826c3b12502ec08c1d80e4",
        "optimal_snap_ingredients": {
          "103": [
            {
              "type": "aceituna",
              "rectangle": [
                816,
                630,
                867,
                682
              ],
              "weight": 0.9928451180458069
            },
            {
              "type": "aceituna",
              "rectangle": [
                753,
                415,
                796,
                467
              ],
              "weight": 0.9838220477104187
            },
            {
              "type": "pimiento",
              "rectangle": [
                529,
                358,
                568,
                475
              ],
              "weight": 0.9814955592155457
            },
            {
              "type": "pimiento",
              "rectangle": [
                737,
                273,
                833,
                374
              ],
              "weight": 0.9786599278450012
            },
            {
              "type": "aceituna",
              "rectangle": [
                672,
                456,
                718,
                503
              ],
              "weight": 0.9766175746917725
            },
            {
              "type": "tomate",
              "rectangle": [
                535,
                177,
                725,
                381
              ],
              "weight": 0.9712786078453064
            },
            {
              "type": "tomate",
              "rectangle": [
                780,
                389,
                990,
                591
              ],
              "weight": 0.9696173071861267
            },
            {
              "type": "tomate",
              "rectangle": [
                459,
                642,
                654,
                836
              ],
              "weight": 0.9684590101242065
            },
            {
              "type": "aceituna",
              "rectangle": [
                666,
                418,
                704,
                457
              ],
              "weight": 0.9573089480400085
            },
            {
              "type": "tomate",
              "rectangle": [
                164,
                713,
                368,
                899
              ],
              "weight": 0.9531674981117249
            },
            {
              "type": "tomate",
              "rectangle": [
                71,
                421,
                269,
                613
              ],
              "weight": 0.949940025806427
            },
            {
              "type": "pimiento",
              "rectangle": [
                395,
                883,
                460,
                938
              ],
              "weight": 0.9294499158859253
            },
            {
              "type": "pimiento",
              "rectangle": [
                383,
                776,
                478,
                867
              ],
              "weight": 0.9275493621826172
            },
            {
              "type": "tomate",
              "rectangle": [
                296,
                335,
                476,
                559
              ],
              "weight": 0.9093979001045227
            },
            {
              "type": "aceituna",
              "rectangle": [
                791,
                585,
                833,
                625
              ],
              "weight": 0.8804933428764343
            },
            {
              "type": "aceituna",
              "rectangle": [
                702,
                723,
                736,
                779
              ],
              "weight": 0.8688772916793823
            },
            {
              "type": "aceituna",
              "rectangle": [
                449,
                282,
                503,
                335
              ],
              "weight": 0.8470704555511475
            },
            {
              "type": "aceituna",
              "rectangle": [
                779,
                373,
                836,
                419
              ],
              "weight": 0.8363425135612488
            },
            {
              "type": "tomate",
              "rectangle": [
                431,
                822,
                619,
                1004
              ],
              "weight": 0.8191618323326111
            },
            {
              "type": "aceituna",
              "rectangle": [
                391,
                627,
                450,
                683
              ],
              "weight": 0.8076574206352234
            },
            {
              "type": "pimiento",
              "rectangle": [
                460,
                474,
                494,
                526
              ],
              "weight": 0.8003413081169128
            },
            {
              "type": "pimiento",
              "rectangle": [
                157,
                632,
                240,
                734
              ],
              "weight": 0.7789403200149536
            },
            {
              "type": "pimiento",
              "rectangle": [
                815,
                695,
                866,
                727
              ],
              "weight": 0.7720280885696411
            },
            {
              "type": "pimiento",
              "rectangle": [
                198,
                345,
                303,
                431
              ],
              "weight": 0.7571892142295837
            },
            {
              "type": "aceituna",
              "rectangle": [
                297,
                683,
                345,
                727
              ],
              "weight": 0.7541022896766663
            },
            {
              "type": "pimiento",
              "rectangle": [
                271,
                631,
                389,
                692
              ],
              "weight": 0.7537679672241211
            },
            {
              "type": "tomate",
              "rectangle": [
                702,
                569,
                774,
                635
              ],
              "weight": 0.7301763892173767
            },
            {
              "type": "pepperoni",
              "rectangle": [
                719,
                666,
                894,
                832
              ],
              "weight": 0.7281597256660461
            },
            {
              "type": "pimiento",
              "rectangle": [
                646,
                523,
                754,
                626
              ],
              "weight": 0.725284993648529
            },
            {
              "type": "aceituna",
              "rectangle": [
                624,
                138,
                691,
                185
              ],
              "weight": 0.7212626338005066
            },
            {
              "type": "aceituna",
              "rectangle": [
                686,
                383,
                742,
                440
              ],
              "weight": 0.7166390419006348
            },
            {
              "type": "aceituna",
              "rectangle": [
                108,
                661,
                164,
                706
              ],
              "weight": 0.6908352375030518
            },
            {
              "type": "pimiento",
              "rectangle": [
                327,
                543,
                373,
                588
              ],
              "weight": 0.6866205930709839
            },
            {
              "type": "tomate",
              "rectangle": [
                735,
                489,
                769,
                526
              ],
              "weight": 0.586820662021637
            },
            {
              "type": "pepperoni",
              "rectangle": [
                516,
                481,
                645,
                609
              ],
              "weight": 0.5585549473762512
            },
            {
              "type": "pimiento",
              "rectangle": [
                394,
                605,
                455,
                630
              ],
              "weight": 0.5529263615608215
            },
            {
              "type": "tomate",
              "rectangle": [
                398,
                356,
                449,
                412
              ],
              "weight": 0.5352557897567749
            },
            {
              "type": "pimiento",
              "rectangle": [
                492,
                196,
                526,
                233
              ],
              "weight": 0.5295939445495605
            },
            {
              "type": "aceituna",
              "rectangle": [
                711,
                652,
                741,
                681
              ],
              "weight": 0.5105108618736267
            },
            {
              "type": "choclo",
              "rectangle": [
                619,
                418,
                647,
                447
              ],
              "weight": 0.49881139397621155
            },
            {
              "type": "aceituna",
              "rectangle": [
                426,
                534,
                450,
                568
              ],
              "weight": 0.4562404453754425
            },
            {
              "type": "tomate",
              "rectangle": [
                500,
                473,
                579,
                564
              ],
              "weight": 0.45434924960136414
            },
            {
              "type": "pepperoni",
              "rectangle": [
                234,
                180,
                436,
                356
              ],
              "weight": 0.4453798234462738
            },
            {
              "type": "pimiento",
              "rectangle": [
                187,
                594,
                236,
                632
              ],
              "weight": 0.43094491958618164
            },
            {
              "type": "pimiento",
              "rectangle": [
                709,
                311,
                727,
                331
              ],
              "weight": 0.41978710889816284
            },
            {
              "type": "aceituna",
              "rectangle": [
                434,
                727,
                460,
                776
              ],
              "weight": 0.4047328531742096
            },
            {
              "type": "pimiento",
              "rectangle": [
                621,
                480,
                648,
                509
              ],
              "weight": 0.40466153621673584
            },
            {
              "type": "aceituna",
              "rectangle": [
                264,
                338,
                310,
                386
              ],
              "weight": 0.3790702223777771
            },
            {
              "type": "aceituna",
              "rectangle": [
                231,
                352,
                269,
                390
              ],
              "weight": 0.37225818634033203
            },
            {
              "type": "tomate",
              "rectangle": [
                646,
                492,
                694,
                541
              ],
              "weight": 0.35121041536331177
            },
            {
              "type": "aceituna",
              "rectangle": [
                416,
                299,
                459,
                345
              ],
              "weight": 0.3324373960494995
            },
            {
              "type": "pepperoni",
              "rectangle": [
                300,
                433,
                458,
                555
              ],
              "weight": 0.332300066947937
            },
            {
              "type": "carne",
              "rectangle": [
                817,
                226,
                861,
                275
              ],
              "weight": 0.3305374085903168
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "648257518e523d5e19aa609f",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "103": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0,
                "5": 0.1,
                "6": 0,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 9.599999999999998,
            "specialty_check": false,
            "score_adjusted": 9.599999999999996
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648259b2bde1b80242cf3abd",
        "REPORT_capture_start_ts": "2023/06/08 22:31:57"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 112,
            "REPORT_localized_capture_start_ts": "18:40:11 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "pepperoni",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_18:31:57_v9p1-ssd-dense_id0112(None)-fr3952-08:14.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_18:31:57_v9p1-ssd-dense_id0112(None)-fr3952-08:14.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1904,
                1388,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00010141752864001319,
              "occ": 0.004624478053301573
            },
            "optimal_frame": 3952,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "64826ee512502ec08c1d80e5",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "648259b2bde1b80242cf3abd",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64825c1208d3700f4228a3aa",
        "REPORT_capture_start_ts": "2023/06/08 22:41:58"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 113,
            "REPORT_localized_capture_start_ts": "18:44:36 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "super_pepperoni",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_18:41:58_v9p1-ssd-dense_id0113(None)-fr1260-02:38.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_18:41:58_v9p1-ssd-dense_id0113(None)-fr1260-02:38.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1376,
                1280,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.06901132315397263,
              "occ": 0.0014508054591715336
            },
            "optimal_frame": 1260,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 114,
            "REPORT_localized_capture_start_ts": "18:48:51 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_18:41:58_v9p1-ssd-dense_id0114(None)-fr3306-06:53.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_18:41:58_v9p1-ssd-dense_id0114(None)-fr3306-06:53.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1355,
                1362,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00019988324493169785,
              "occ": 0.03419443219900131
            },
            "optimal_frame": 3306,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 115,
            "REPORT_localized_capture_start_ts": "18:49:50 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": null,
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_18:41:58_v9p1-ssd-dense_id0115(None)-fr3774-07:52.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_18:41:58_v9p1-ssd-dense_id0115(None)-fr3774-07:52.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "mala",
            "optimal_figure": {
              "coords": [
                1131,
                1384,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.011681420728564262,
              "occ": 0.9990911483764648
            },
            "optimal_frame": 3774,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true
          }
        ],
        "REPORT_id": "6482719c12502ec08c1d80e6",
        "optimal_snap_ingredients": {
          "113": [
            {
              "type": "pepperoni",
              "rectangle": [
                439,
                723,
                533,
                825
              ],
              "weight": 0.9987351298332214
            },
            {
              "type": "pepperoni",
              "rectangle": [
                273,
                396,
                377,
                494
              ],
              "weight": 0.9983524084091187
            },
            {
              "type": "pepperoni",
              "rectangle": [
                597,
                407,
                698,
                505
              ],
              "weight": 0.9982894062995911
            },
            {
              "type": "pepperoni",
              "rectangle": [
                761,
                448,
                880,
                539
              ],
              "weight": 0.9982179999351501
            },
            {
              "type": "pepperoni",
              "rectangle": [
                574,
                780,
                692,
                897
              ],
              "weight": 0.9981263279914856
            },
            {
              "type": "pepperoni",
              "rectangle": [
                385,
                445,
                493,
                538
              ],
              "weight": 0.9980744123458862
            },
            {
              "type": "pepperoni",
              "rectangle": [
                278,
                588,
                374,
                680
              ],
              "weight": 0.9980019927024841
            },
            {
              "type": "pepperoni",
              "rectangle": [
                541,
                602,
                635,
                721
              ],
              "weight": 0.9979447722434998
            },
            {
              "type": "pepperoni",
              "rectangle": [
                141,
                434,
                248,
                527
              ],
              "weight": 0.9975624084472656
            },
            {
              "type": "pepperoni",
              "rectangle": [
                669,
                735,
                782,
                845
              ],
              "weight": 0.9974353909492493
            },
            {
              "type": "pepperoni",
              "rectangle": [
                214,
                313,
                321,
                431
              ],
              "weight": 0.9973716735839844
            },
            {
              "type": "pepperoni",
              "rectangle": [
                428,
                556,
                529,
                647
              ],
              "weight": 0.9973481893539429
            },
            {
              "type": "pepperoni",
              "rectangle": [
                613,
                638,
                734,
                758
              ],
              "weight": 0.9972326159477234
            },
            {
              "type": "pepperoni",
              "rectangle": [
                779,
                606,
                895,
                723
              ],
              "weight": 0.9972302317619324
            },
            {
              "type": "pepperoni",
              "rectangle": [
                307,
                789,
                423,
                895
              ],
              "weight": 0.9970541000366211
            },
            {
              "type": "pepperoni",
              "rectangle": [
                179,
                610,
                303,
                727
              ],
              "weight": 0.9969801306724548
            },
            {
              "type": "pepperoni",
              "rectangle": [
                752,
                337,
                872,
                454
              ],
              "weight": 0.9968571662902832
            },
            {
              "type": "pepperoni",
              "rectangle": [
                157,
                527,
                263,
                625
              ],
              "weight": 0.9965483546257019
            },
            {
              "type": "pepperoni",
              "rectangle": [
                544,
                186,
                641,
                285
              ],
              "weight": 0.9962888956069946
            },
            {
              "type": "pepperoni",
              "rectangle": [
                603,
                510,
                714,
                610
              ],
              "weight": 0.9958512783050537
            },
            {
              "type": "pepperoni",
              "rectangle": [
                412,
                828,
                500,
                930
              ],
              "weight": 0.9954171180725098
            },
            {
              "type": "pepperoni",
              "rectangle": [
                505,
                413,
                587,
                499
              ],
              "weight": 0.9951069355010986
            },
            {
              "type": "pepperoni",
              "rectangle": [
                690,
                548,
                789,
                657
              ],
              "weight": 0.9934259653091431
            },
            {
              "type": "pepperoni",
              "rectangle": [
                240,
                728,
                329,
                816
              ],
              "weight": 0.9931179285049438
            },
            {
              "type": "pepperoni",
              "rectangle": [
                671,
                458,
                777,
                549
              ],
              "weight": 0.9909951090812683
            },
            {
              "type": "pepperoni",
              "rectangle": [
                618,
                228,
                724,
                327
              ],
              "weight": 0.9897230863571167
            },
            {
              "type": "pepperoni",
              "rectangle": [
                357,
                174,
                456,
                287
              ],
              "weight": 0.9868467450141907
            },
            {
              "type": "pepperoni",
              "rectangle": [
                557,
                280,
                650,
                395
              ],
              "weight": 0.9834701418876648
            },
            {
              "type": "pepperoni",
              "rectangle": [
                635,
                324,
                744,
                428
              ],
              "weight": 0.9830943942070007
            },
            {
              "type": "pepperoni",
              "rectangle": [
                251,
                497,
                361,
                596
              ],
              "weight": 0.9752078056335449
            },
            {
              "type": "pepperoni",
              "rectangle": [
                270,
                243,
                353,
                330
              ],
              "weight": 0.9608424305915833
            },
            {
              "type": "pepperoni",
              "rectangle": [
                552,
                727,
                624,
                813
              ],
              "weight": 0.9267423152923584
            },
            {
              "type": "pepperoni",
              "rectangle": [
                310,
                324,
                402,
                407
              ],
              "weight": 0.711469292640686
            },
            {
              "type": "pepperoni",
              "rectangle": [
                791,
                267,
                863,
                341
              ],
              "weight": 0.6435220837593079
            },
            {
              "type": "pepperoni",
              "rectangle": [
                538,
                856,
                595,
                960
              ],
              "weight": 0.5884561538696289
            },
            {
              "type": "pepperoni",
              "rectangle": [
                490,
                215,
                563,
                331
              ],
              "weight": 0.463801771402359
            },
            {
              "type": "pepperoni",
              "rectangle": [
                466,
                330,
                523,
                450
              ],
              "weight": 0.43091055750846863
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "64825c1208d3700f4228a3aa",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "113": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0,
                "5": 0.25,
                "6": 0,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0
              },
              "Bordes": {
                "1": 0.1,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0,
                "2": 0.1,
                "3": 0,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 7.75,
            "specialty_check": true,
            "score_adjusted": 7.7499999999999964
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64825e75fc892b0c2c1e7278",
        "REPORT_capture_start_ts": "2023/06/08 22:51:58"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 116,
            "REPORT_localized_capture_start_ts": "19:00:42 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "pepperoni",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_18:51:58_v9p1-ssd-dense_id0116(None)-fr4194-08:44.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_18:51:58_v9p1-ssd-dense_id0116(None)-fr4194-08:44.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1637,
                1378,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.02857876755297184,
              "occ": 0.023953767493367195
            },
            "optimal_frame": 4194,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 117,
            "REPORT_localized_capture_start_ts": "19:01:43 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_18:51:58_v9p1-ssd-dense_id0117(None)-fr4683-09:45.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_18:51:58_v9p1-ssd-dense_id0117(None)-fr4683-09:45.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1268,
                1195,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.003264101454988122,
              "occ": 0.029788274317979813
            },
            "optimal_frame": 4683,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "6482744c12502ec08c1d80e7",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64825e75fc892b0c2c1e7278",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648260d41717a9e15a00afbe",
        "REPORT_capture_start_ts": "2023/06/08 23:01:59"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 118,
            "REPORT_localized_capture_start_ts": "19:05:03 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "chicken_garlic",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_19:01:59_v9p1-ssd-dense_id0118(None)-fr1475-03:04.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_19:01:59_v9p1-ssd-dense_id0118(None)-fr1475-03:04.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1798,
                1115,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00006656007462879643,
              "occ": 0.032571785151958466
            },
            "optimal_frame": 1475,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 119,
            "REPORT_localized_capture_start_ts": "19:05:34 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_19:01:59_v9p1-ssd-dense_id0119(None)-fr1721-03:35.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_19:01:59_v9p1-ssd-dense_id0119(None)-fr1721-03:35.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1631,
                1072,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.0009186874958686531,
              "occ": 0.004600014537572861
            },
            "optimal_frame": 1721,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 120,
            "REPORT_localized_capture_start_ts": "19:08:53 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_19:01:59_v9p1-ssd-dense_id0120(None)-fr3313-06:54.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_19:01:59_v9p1-ssd-dense_id0120(None)-fr3313-06:54.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1475,
                1080,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.015358064323663712,
              "occ": 0.003211385104805231
            },
            "optimal_frame": 3313,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 121,
            "REPORT_localized_capture_start_ts": "19:11:27 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_19:01:59_v9p1-ssd-dense_id0121(None)-fr4540-09:28.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_19:01:59_v9p1-ssd-dense_id0121(None)-fr4540-09:28.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1594,
                1161,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 2.2221947318292606e-13,
              "occ": 0.00416963454335928
            },
            "optimal_frame": 4540,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 122,
            "REPORT_localized_capture_start_ts": "19:11:55 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "sweet_blue_cheese",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_19:01:59_v9p1-ssd-dense_id0122(None)-fr4769-09:56.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_19:01:59_v9p1-ssd-dense_id0122(None)-fr4769-09:56.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1652,
                1205,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 1.3448467939269904e-7,
              "occ": 0.04519059881567955
            },
            "optimal_frame": 4769,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "6482776f12502ec08c1d80e8",
        "optimal_snap_ingredients": {
          "118": [
            {
              "type": "pollo",
              "rectangle": [
                784,
                420,
                844,
                494
              ],
              "weight": 0.8380626440048218
            },
            {
              "type": "pollo",
              "rectangle": [
                322,
                674,
                391,
                711
              ],
              "weight": 0.7100009322166443
            },
            {
              "type": "pollo",
              "rectangle": [
                708,
                437,
                785,
                477
              ],
              "weight": 0.6381344795227051
            },
            {
              "type": "pollo",
              "rectangle": [
                419,
                515,
                492,
                568
              ],
              "weight": 0.6209160089492798
            },
            {
              "type": "pollo",
              "rectangle": [
                584,
                477,
                653,
                526
              ],
              "weight": 0.5725966691970825
            },
            {
              "type": "carne",
              "rectangle": [
                225,
                388,
                281,
                440
              ],
              "weight": 0.45560336112976074
            },
            {
              "type": "choclo",
              "rectangle": [
                157,
                524,
                181,
                546
              ],
              "weight": 0.4217785596847534
            },
            {
              "type": "carne",
              "rectangle": [
                134,
                487,
                191,
                532
              ],
              "weight": 0.4119567573070526
            },
            {
              "type": "pollo",
              "rectangle": [
                670,
                660,
                733,
                690
              ],
              "weight": 0.34273862838745117
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "648260d41717a9e15a00afbe",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "118": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 7.7,
            "specialty_check": false,
            "score_adjusted": 7.6999999999999975
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64826334e14b174f8e341543",
        "REPORT_capture_start_ts": "2023/06/08 23:12:00"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 123,
            "REPORT_localized_capture_start_ts": "19:17:32 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "pepperoni",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_19:12:00_v9p1-ssd-dense_id0123(None)-fr2656-05:32.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_19:12:00_v9p1-ssd-dense_id0123(None)-fr2656-05:32.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1617,
                1170,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 9.849703701547696e-7,
              "occ": 0.0018080325098708272
            },
            "optimal_frame": 2656,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 124,
            "REPORT_localized_capture_start_ts": "19:21:56 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "pepperoni",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_19:12:00_v9p1-ssd-dense_id0124(None)-fr4771-09:56.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_19:12:00_v9p1-ssd-dense_id0124(None)-fr4771-09:56.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1328,
                1140,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.0014750402187928557,
              "occ": 0.004789088387042284
            },
            "optimal_frame": 4771,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "64827a3112502ec08c1d80e9",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64826334e14b174f8e341543",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64826593b493752349ab6614",
        "REPORT_capture_start_ts": "2023/06/08 23:22:00"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 125,
            "REPORT_localized_capture_start_ts": "19:24:06 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "pepperoni",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_19:22:00_v9p1-ssd-dense_id0125(None)-fr1009-02:06.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_19:22:00_v9p1-ssd-dense_id0125(None)-fr1009-02:06.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1246,
                1385,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00025562228984199464,
              "occ": 0.00016316788969561458
            },
            "optimal_frame": 1009,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "64827ce112502ec08c1d80ea",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64826593b493752349ab6614",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648267f2b29b36fb7673bbad",
        "REPORT_capture_start_ts": "2023/06/08 23:32:01"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 126,
            "REPORT_localized_capture_start_ts": "19:33:55 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_19:32:01_v9p1-ssd-dense_id0126(None)-fr0908-01:54.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_19:32:01_v9p1-ssd-dense_id0126(None)-fr0908-01:54.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1611,
                1378,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 1.144765814260762e-12,
              "occ": 0.03469095006585121
            },
            "optimal_frame": 908,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 129,
            "REPORT_localized_capture_start_ts": "19:35:05 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "sweet_blue_cheese",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_19:32:01_v9p1-ssd-dense_id0129(None)-fr1468-03:04.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_19:32:01_v9p1-ssd-dense_id0129(None)-fr1468-03:04.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1641,
                1136,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.000008099813385342713,
              "occ": 0.1046934500336647
            },
            "optimal_frame": 1468,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 130,
            "REPORT_localized_capture_start_ts": "19:35:40 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "sweet_blue_cheese",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_19:32:01_v9p1-ssd-dense_id0130(None)-fr1752-03:39.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_19:32:01_v9p1-ssd-dense_id0130(None)-fr1752-03:39.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1627,
                1275,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.0008666681824252009,
              "occ": 0.13312608003616333
            },
            "optimal_frame": 1752,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          },
          {
            "id": 131,
            "REPORT_localized_capture_start_ts": "19:41:01 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "napolitana",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_19:32:01_v9p1-ssd-dense_id0131(None)-fr4324-09:00.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_19:32:01_v9p1-ssd-dense_id0131(None)-fr4324-09:00.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1573,
                1198,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00011072831694036722,
              "occ": 0.0008647935464978218
            },
            "optimal_frame": 4324,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 132,
            "REPORT_localized_capture_start_ts": "19:41:33 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_19:32:01_v9p1-ssd-dense_id0132(None)-fr4577-09:32.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_19:32:01_v9p1-ssd-dense_id0132(None)-fr4577-09:32.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1490,
                1148,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00013912930444348603,
              "occ": 0.013013187795877457
            },
            "optimal_frame": 4577,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "6482805212502ec08c1d80eb",
        "optimal_snap_ingredients": {
          "131": [
            {
              "type": "pepperoni",
              "rectangle": [
                634,
                201,
                837,
                400
              ],
              "weight": 0.9373136758804321
            },
            {
              "type": "tomate",
              "rectangle": [
                496,
                611,
                684,
                797
              ],
              "weight": 0.8911089897155762
            },
            {
              "type": "tomate",
              "rectangle": [
                195,
                449,
                371,
                637
              ],
              "weight": 0.8878857493400574
            },
            {
              "type": "tomate",
              "rectangle": [
                269,
                682,
                466,
                869
              ],
              "weight": 0.8772708773612976
            },
            {
              "type": "pepperoni",
              "rectangle": [
                405,
                147,
                638,
                337
              ],
              "weight": 0.8388946652412415
            },
            {
              "type": "pepperoni",
              "rectangle": [
                486,
                421,
                676,
                613
              ],
              "weight": 0.8209877610206604
            },
            {
              "type": "pepperoni",
              "rectangle": [
                589,
                133,
                689,
                229
              ],
              "weight": 0.7822798490524292
            },
            {
              "type": "pepperoni",
              "rectangle": [
                273,
                267,
                457,
                450
              ],
              "weight": 0.741046130657196
            },
            {
              "type": "tomate",
              "rectangle": [
                660,
                409,
                852,
                589
              ],
              "weight": 0.7393865585327148
            },
            {
              "type": "pepperoni",
              "rectangle": [
                536,
                794,
                730,
                983
              ],
              "weight": 0.5904917120933533
            },
            {
              "type": "pepperoni",
              "rectangle": [
                447,
                606,
                541,
                712
              ],
              "weight": 0.49349480867385864
            },
            {
              "type": "pepperoni",
              "rectangle": [
                659,
                401,
                771,
                471
              ],
              "weight": 0.4885377883911133
            },
            {
              "type": "pepperoni",
              "rectangle": [
                476,
                803,
                543,
                914
              ],
              "weight": 0.3512892425060272
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "648267f2b29b36fb7673bbad",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "131": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0.25,
                "5": 0.25,
                "6": 0,
                "7": 0,
                "8": 0
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0.1,
                "8": 0
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 7.8,
            "specialty_check": false,
            "score_adjusted": 7.799999999999997
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64826a53faffde7d1db3fbc2",
        "REPORT_capture_start_ts": "2023/06/08 23:42:02"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 134,
            "REPORT_localized_capture_start_ts": "19:46:44 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "super_pepperoni",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_19:42:02_v9p1-ssd-dense_id0134(None)-fr2252-04:42.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_19:42:02_v9p1-ssd-dense_id0134(None)-fr2252-04:42.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                607,
                1194,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.03421464189887047,
              "occ": 0.0013182956026867032
            },
            "optimal_frame": 2252,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 135,
            "REPORT_localized_capture_start_ts": "19:51:25 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "napolitana",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_19:42:02_v9p1-ssd-dense_id0135(None)-fr4507-09:23.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_19:42:02_v9p1-ssd-dense_id0135(None)-fr4507-09:23.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1593,
                1120,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 4.659393357253627e-11,
              "occ": 0.001803708029910922
            },
            "optimal_frame": 4507,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 136,
            "REPORT_localized_capture_start_ts": "19:52:01 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "todas_las_carnes",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_19:42:02_v9p1-ssd-dense_id0136(None)-fr4795-09:59.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_19:42:02_v9p1-ssd-dense_id0136(None)-fr4795-09:59.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1547,
                1239,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 1.2927241499482989e-8,
              "occ": 0.00002030494943028316
            },
            "optimal_frame": 4795,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "6482830c12502ec08c1d80ec",
        "optimal_snap_ingredients": {
          "134": [
            {
              "type": "pepperoni",
              "rectangle": [
                672,
                673,
                785,
                776
              ],
              "weight": 0.9990320205688477
            },
            {
              "type": "pepperoni",
              "rectangle": [
                379,
                588,
                489,
                691
              ],
              "weight": 0.9989739656448364
            },
            {
              "type": "pepperoni",
              "rectangle": [
                604,
                261,
                715,
                366
              ],
              "weight": 0.9989732503890991
            },
            {
              "type": "pepperoni",
              "rectangle": [
                264,
                406,
                367,
                518
              ],
              "weight": 0.9988299012184143
            },
            {
              "type": "pepperoni",
              "rectangle": [
                693,
                535,
                809,
                651
              ],
              "weight": 0.9986357092857361
            },
            {
              "type": "pepperoni",
              "rectangle": [
                516,
                266,
                608,
                366
              ],
              "weight": 0.9985766410827637
            },
            {
              "type": "pepperoni",
              "rectangle": [
                767,
                276,
                875,
                384
              ],
              "weight": 0.99857497215271
            },
            {
              "type": "pepperoni",
              "rectangle": [
                303,
                650,
                400,
                748
              ],
              "weight": 0.9984241724014282
            },
            {
              "type": "pepperoni",
              "rectangle": [
                447,
                822,
                541,
                925
              ],
              "weight": 0.99832683801651
            },
            {
              "type": "pepperoni",
              "rectangle": [
                604,
                168,
                714,
                266
              ],
              "weight": 0.9983105659484863
            },
            {
              "type": "pepperoni",
              "rectangle": [
                168,
                644,
                282,
                748
              ],
              "weight": 0.9982255101203918
            },
            {
              "type": "pepperoni",
              "rectangle": [
                266,
                531,
                384,
                649
              ],
              "weight": 0.9980072379112244
            },
            {
              "type": "pepperoni",
              "rectangle": [
                566,
                580,
                683,
                687
              ],
              "weight": 0.9979573488235474
            },
            {
              "type": "pepperoni",
              "rectangle": [
                344,
                346,
                437,
                455
              ],
              "weight": 0.9976069927215576
            },
            {
              "type": "pepperoni",
              "rectangle": [
                516,
                153,
                610,
                265
              ],
              "weight": 0.9974907636642456
            },
            {
              "type": "pepperoni",
              "rectangle": [
                659,
                765,
                771,
                876
              ],
              "weight": 0.9974059462547302
            },
            {
              "type": "pepperoni",
              "rectangle": [
                267,
                749,
                371,
                860
              ],
              "weight": 0.9972876310348511
            },
            {
              "type": "pepperoni",
              "rectangle": [
                711,
                191,
                800,
                288
              ],
              "weight": 0.9972192049026489
            },
            {
              "type": "pepperoni",
              "rectangle": [
                444,
                277,
                528,
                377
              ],
              "weight": 0.9963358640670776
            },
            {
              "type": "pepperoni",
              "rectangle": [
                127,
                329,
                228,
                444
              ],
              "weight": 0.9959695339202881
            },
            {
              "type": "pepperoni",
              "rectangle": [
                357,
                491,
                462,
                572
              ],
              "weight": 0.9958042502403259
            },
            {
              "type": "pepperoni",
              "rectangle": [
                501,
                648,
                601,
                751
              ],
              "weight": 0.9957787990570068
            },
            {
              "type": "pepperoni",
              "rectangle": [
                125,
                453,
                214,
                560
              ],
              "weight": 0.995025098323822
            },
            {
              "type": "pepperoni",
              "rectangle": [
                390,
                710,
                471,
                810
              ],
              "weight": 0.9940145611763
            },
            {
              "type": "pepperoni",
              "rectangle": [
                146,
                576,
                255,
                649
              ],
              "weight": 0.9935279488563538
            },
            {
              "type": "pepperoni",
              "rectangle": [
                621,
                409,
                705,
                515
              ],
              "weight": 0.9935082197189331
            },
            {
              "type": "pepperoni",
              "rectangle": [
                454,
                424,
                557,
                527
              ],
              "weight": 0.9934113621711731
            },
            {
              "type": "pepperoni",
              "rectangle": [
                420,
                177,
                511,
                284
              ],
              "weight": 0.993110179901123
            },
            {
              "type": "pepperoni",
              "rectangle": [
                553,
                851,
                623,
                968
              ],
              "weight": 0.9930362105369568
            },
            {
              "type": "pepperoni",
              "rectangle": [
                235,
                270,
                321,
                364
              ],
              "weight": 0.9918076395988464
            },
            {
              "type": "pepperoni",
              "rectangle": [
                825,
                649,
                935,
                754
              ],
              "weight": 0.9906947612762451
            },
            {
              "type": "pepperoni",
              "rectangle": [
                501,
                738,
                614,
                853
              ],
              "weight": 0.9850065112113953
            },
            {
              "type": "pepperoni",
              "rectangle": [
                309,
                845,
                409,
                959
              ],
              "weight": 0.9836416244506836
            },
            {
              "type": "pepperoni",
              "rectangle": [
                206,
                435,
                275,
                543
              ],
              "weight": 0.9526589512825012
            },
            {
              "type": "pepperoni",
              "rectangle": [
                811,
                451,
                940,
                533
              ],
              "weight": 0.9441820383071899
            },
            {
              "type": "pepperoni",
              "rectangle": [
                523,
                496,
                620,
                592
              ],
              "weight": 0.8815198540687561
            },
            {
              "type": "pepperoni",
              "rectangle": [
                365,
                185,
                432,
                299
              ],
              "weight": 0.860921323299408
            },
            {
              "type": "pepperoni",
              "rectangle": [
                586,
                719,
                653,
                780
              ],
              "weight": 0.6642637252807617
            },
            {
              "type": "pepperoni",
              "rectangle": [
                434,
                364,
                542,
                442
              ],
              "weight": 0.40541380643844604
            },
            {
              "type": "pepperoni",
              "rectangle": [
                700,
                409,
                789,
                491
              ],
              "weight": 0.3106697201728821
            }
          ],
          "135": [
            {
              "type": "pepperoni",
              "rectangle": [
                471,
                637,
                612,
                761
              ],
              "weight": 0.9736385941505432
            },
            {
              "type": "pepperoni",
              "rectangle": [
                653,
                812,
                755,
                938
              ],
              "weight": 0.958400547504425
            },
            {
              "type": "pepperoni",
              "rectangle": [
                737,
                664,
                905,
                846
              ],
              "weight": 0.9180888533592224
            },
            {
              "type": "tomate",
              "rectangle": [
                532,
                448,
                592,
                524
              ],
              "weight": 0.8734092712402344
            },
            {
              "type": "pepperoni",
              "rectangle": [
                339,
                185,
                518,
                256
              ],
              "weight": 0.8127871751785278
            },
            {
              "type": "pepperoni",
              "rectangle": [
                638,
                250,
                869,
                462
              ],
              "weight": 0.7847879528999329
            },
            {
              "type": "pepperoni",
              "rectangle": [
                464,
                801,
                653,
                973
              ],
              "weight": 0.7383378744125366
            },
            {
              "type": "pepperoni",
              "rectangle": [
                601,
                553,
                667,
                607
              ],
              "weight": 0.6214195489883423
            },
            {
              "type": "pepperoni",
              "rectangle": [
                808,
                473,
                913,
                603
              ],
              "weight": 0.5480517745018005
            },
            {
              "type": "tomate",
              "rectangle": [
                335,
                194,
                531,
                388
              ],
              "weight": 0.40088966488838196
            }
          ],
          "136": [
            {
              "type": "pepperoni",
              "rectangle": [
                637,
                319,
                750,
                429
              ],
              "weight": 0.9952831864356995
            },
            {
              "type": "pepperoni",
              "rectangle": [
                747,
                723,
                857,
                842
              ],
              "weight": 0.988062858581543
            },
            {
              "type": "pepperoni",
              "rectangle": [
                722,
                236,
                849,
                351
              ],
              "weight": 0.9851778149604797
            },
            {
              "type": "pepperoni",
              "rectangle": [
                236,
                251,
                358,
                369
              ],
              "weight": 0.9659997820854187
            },
            {
              "type": "aceituna",
              "rectangle": [
                626,
                414,
                677,
                470
              ],
              "weight": 0.9629296660423279
            },
            {
              "type": "aceituna",
              "rectangle": [
                699,
                522,
                734,
                569
              ],
              "weight": 0.9487894177436829
            },
            {
              "type": "pepperoni",
              "rectangle": [
                696,
                625,
                810,
                696
              ],
              "weight": 0.9479009509086609
            },
            {
              "type": "pepperoni",
              "rectangle": [
                557,
                595,
                646,
                669
              ],
              "weight": 0.9470523595809937
            },
            {
              "type": "aceituna",
              "rectangle": [
                108,
                586,
                158,
                641
              ],
              "weight": 0.9403955936431885
            },
            {
              "type": "pimiento",
              "rectangle": [
                112,
                684,
                151,
                748
              ],
              "weight": 0.9395219087600708
            },
            {
              "type": "pepperoni",
              "rectangle": [
                391,
                163,
                496,
                262
              ],
              "weight": 0.933212399482727
            },
            {
              "type": "jamón",
              "rectangle": [
                513,
                292,
                600,
                398
              ],
              "weight": 0.9258064031600952
            },
            {
              "type": "pimiento",
              "rectangle": [
                486,
                230,
                539,
                298
              ],
              "weight": 0.9216974377632141
            },
            {
              "type": "pepperoni",
              "rectangle": [
                520,
                179,
                618,
                293
              ],
              "weight": 0.8568872809410095
            },
            {
              "type": "carne",
              "rectangle": [
                229,
                825,
                277,
                866
              ],
              "weight": 0.8494552969932556
            },
            {
              "type": "jamón",
              "rectangle": [
                452,
                730,
                552,
                840
              ],
              "weight": 0.8470031023025513
            },
            {
              "type": "aceituna",
              "rectangle": [
                570,
                522,
                612,
                553
              ],
              "weight": 0.8320622444152832
            },
            {
              "type": "carne",
              "rectangle": [
                287,
                464,
                329,
                503
              ],
              "weight": 0.7651324272155762
            },
            {
              "type": "pepperoni",
              "rectangle": [
                321,
                826,
                430,
                930
              ],
              "weight": 0.7573437094688416
            },
            {
              "type": "pepperoni",
              "rectangle": [
                327,
                484,
                456,
                605
              ],
              "weight": 0.7559973001480103
            },
            {
              "type": "aceituna",
              "rectangle": [
                710,
                685,
                754,
                718
              ],
              "weight": 0.7529118061065674
            },
            {
              "type": "carne",
              "rectangle": [
                273,
                369,
                329,
                406
              ],
              "weight": 0.752049446105957
            },
            {
              "type": "pepperoni",
              "rectangle": [
                220,
                742,
                307,
                830
              ],
              "weight": 0.7291500568389893
            },
            {
              "type": "carne",
              "rectangle": [
                212,
                482,
                271,
                527
              ],
              "weight": 0.6953813433647156
            },
            {
              "type": "carne",
              "rectangle": [
                247,
                418,
                299,
                463
              ],
              "weight": 0.674470067024231
            },
            {
              "type": "pepperoni",
              "rectangle": [
                481,
                853,
                547,
                940
              ],
              "weight": 0.6454030871391296
            },
            {
              "type": "pepperoni",
              "rectangle": [
                426,
                276,
                526,
                390
              ],
              "weight": 0.6344162225723267
            },
            {
              "type": "pepperoni",
              "rectangle": [
                491,
                434,
                551,
                543
              ],
              "weight": 0.6339053511619568
            },
            {
              "type": "pepperoni",
              "rectangle": [
                532,
                546,
                662,
                604
              ],
              "weight": 0.6119604110717773
            },
            {
              "type": "pepperoni",
              "rectangle": [
                604,
                192,
                685,
                306
              ],
              "weight": 0.5835371613502502
            },
            {
              "type": "pollo",
              "rectangle": [
                315,
                746,
                380,
                834
              ],
              "weight": 0.5663742423057556
            },
            {
              "type": "pepperoni",
              "rectangle": [
                665,
                775,
                778,
                913
              ],
              "weight": 0.5465359687805176
            },
            {
              "type": "tocino",
              "rectangle": [
                826,
                479,
                856,
                539
              ],
              "weight": 0.5310142636299133
            },
            {
              "type": "carne",
              "rectangle": [
                519,
                713,
                569,
                755
              ],
              "weight": 0.5283114910125732
            },
            {
              "type": "pepperoni",
              "rectangle": [
                810,
                431,
                925,
                517
              ],
              "weight": 0.5152391791343689
            },
            {
              "type": "carne",
              "rectangle": [
                480,
                574,
                519,
                609
              ],
              "weight": 0.5120973587036133
            },
            {
              "type": "champiñón",
              "rectangle": [
                586,
                381,
                644,
                428
              ],
              "weight": 0.5043405294418335
            },
            {
              "type": "pimiento",
              "rectangle": [
                471,
                918,
                508,
                952
              ],
              "weight": 0.47888579964637756
            },
            {
              "type": "champiñón",
              "rectangle": [
                731,
                479,
                812,
                558
              ],
              "weight": 0.46267038583755493
            },
            {
              "type": "pimiento",
              "rectangle": [
                589,
                135,
                644,
                159
              ],
              "weight": 0.46099990606307983
            },
            {
              "type": "aceituna",
              "rectangle": [
                694,
                442,
                729,
                495
              ],
              "weight": 0.4501277208328247
            },
            {
              "type": "carne",
              "rectangle": [
                758,
                373,
                799,
                406
              ],
              "weight": 0.44961339235305786
            },
            {
              "type": "pepperoni",
              "rectangle": [
                720,
                375,
                834,
                487
              ],
              "weight": 0.4305128753185272
            },
            {
              "type": "pimiento",
              "rectangle": [
                320,
                235,
                353,
                255
              ],
              "weight": 0.42821019887924194
            },
            {
              "type": "pepperoni",
              "rectangle": [
                567,
                741,
                662,
                856
              ],
              "weight": 0.39623159170150757
            },
            {
              "type": "pepperoni",
              "rectangle": [
                452,
                554,
                543,
                674
              ],
              "weight": 0.3757161498069763
            },
            {
              "type": "aceituna",
              "rectangle": [
                653,
                550,
                691,
                590
              ],
              "weight": 0.37258464097976685
            },
            {
              "type": "pepperoni",
              "rectangle": [
                808,
                323,
                908,
                416
              ],
              "weight": 0.3716330826282501
            },
            {
              "type": "carne",
              "rectangle": [
                581,
                425,
                623,
                468
              ],
              "weight": 0.36241596937179565
            },
            {
              "type": "pimiento",
              "rectangle": [
                835,
                308,
                859,
                334
              ],
              "weight": 0.3567492663860321
            },
            {
              "type": "carne",
              "rectangle": [
                591,
                801,
                642,
                858
              ],
              "weight": 0.3530117869377136
            },
            {
              "type": "champiñón",
              "rectangle": [
                250,
                859,
                316,
                961
              ],
              "weight": 0.32934632897377014
            },
            {
              "type": "jamón",
              "rectangle": [
                681,
                838,
                755,
                911
              ],
              "weight": 0.3230661153793335
            },
            {
              "type": "champiñón",
              "rectangle": [
                245,
                549,
                336,
                607
              ],
              "weight": 0.32291555404663086
            },
            {
              "type": "aceituna",
              "rectangle": [
                716,
                399,
                763,
                443
              ],
              "weight": 0.32113105058670044
            },
            {
              "type": "champiñón",
              "rectangle": [
                293,
                788,
                367,
                851
              ],
              "weight": 0.31016436219215393
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "64826a53faffde7d1db3fbc2",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "134": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0.25,
                "6": 0.25,
                "7": 0,
                "8": 0
              },
              "Bordes": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 7.5,
            "specialty_check": true,
            "score_adjusted": 7.4999999999999964
          },
          "135": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0,
                "3": 0.25,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0,
                "5": 0,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 7.55,
            "specialty_check": false,
            "score_adjusted": 7.549999999999997
          },
          "136": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0,
                "8": 0
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              }
            },
            "score_final": 8.95,
            "specialty_check": false,
            "score_adjusted": 8.949999999999998
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64826cb3028aec96c1188c91",
        "REPORT_capture_start_ts": "2023/06/08 23:52:02"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 137,
            "REPORT_localized_capture_start_ts": "19:53:01 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "todas_las_carnes",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_19:52:02_v9p1-ssd-dense_id0137(None)-fr0469-00:59.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_19:52:02_v9p1-ssd-dense_id0137(None)-fr0469-00:59.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1599,
                1191,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.0000024291953195643146,
              "occ": 0.00016023658099584281
            },
            "optimal_frame": 469,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "648285bb12502ec08c1d80ed",
        "optimal_snap_ingredients": {
          "137": [
            {
              "type": "pepperoni",
              "rectangle": [
                708,
                339,
                823,
                450
              ],
              "weight": 0.9950860142707825
            },
            {
              "type": "pepperoni",
              "rectangle": [
                335,
                183,
                458,
                294
              ],
              "weight": 0.9839547872543335
            },
            {
              "type": "pepperoni",
              "rectangle": [
                808,
                280,
                928,
                397
              ],
              "weight": 0.9807369709014893
            },
            {
              "type": "aceituna",
              "rectangle": [
                682,
                417,
                737,
                475
              ],
              "weight": 0.9667555093765259
            },
            {
              "type": "aceituna",
              "rectangle": [
                731,
                539,
                764,
                587
              ],
              "weight": 0.960290253162384
            },
            {
              "type": "pepperoni",
              "rectangle": [
                724,
                753,
                832,
                866
              ],
              "weight": 0.9580395817756653
            },
            {
              "type": "pepperoni",
              "rectangle": [
                573,
                576,
                669,
                657
              ],
              "weight": 0.9537749290466309
            },
            {
              "type": "pimiento",
              "rectangle": [
                121,
                558,
                165,
                613
              ],
              "weight": 0.9433415532112122
            },
            {
              "type": "pepperoni",
              "rectangle": [
                701,
                637,
                808,
                718
              ],
              "weight": 0.9423196911811829
            },
            {
              "type": "pepperoni",
              "rectangle": [
                619,
                176,
                733,
                287
              ],
              "weight": 0.9343223571777344
            },
            {
              "type": "jamón",
              "rectangle": [
                597,
                281,
                686,
                384
              ],
              "weight": 0.9186642169952393
            },
            {
              "type": "aceituna",
              "rectangle": [
                143,
                463,
                189,
                520
              ],
              "weight": 0.9106777310371399
            },
            {
              "type": "pepperoni",
              "rectangle": [
                502,
                134,
                612,
                228
              ],
              "weight": 0.9094755053520203
            },
            {
              "type": "jamón",
              "rectangle": [
                437,
                688,
                538,
                794
              ],
              "weight": 0.8167279362678528
            },
            {
              "type": "pepperoni",
              "rectangle": [
                292,
                750,
                399,
                856
              ],
              "weight": 0.8020617961883545
            },
            {
              "type": "carne",
              "rectangle": [
                356,
                294,
                407,
                331
              ],
              "weight": 0.7822409272193909
            },
            {
              "type": "carne",
              "rectangle": [
                514,
                684,
                562,
                725
              ],
              "weight": 0.780958890914917
            },
            {
              "type": "aceituna",
              "rectangle": [
                708,
                702,
                747,
                736
              ],
              "weight": 0.7696290016174316
            },
            {
              "type": "aceituna",
              "rectangle": [
                608,
                510,
                651,
                547
              ],
              "weight": 0.7544537782669067
            },
            {
              "type": "pimiento",
              "rectangle": [
                596,
                217,
                631,
                282
              ],
              "weight": 0.7128276824951172
            },
            {
              "type": "pepperoni",
              "rectangle": [
                439,
                812,
                514,
                903
              ],
              "weight": 0.7082326412200928
            },
            {
              "type": "carne",
              "rectangle": [
                320,
                336,
                369,
                382
              ],
              "weight": 0.6990283131599426
            },
            {
              "type": "pepperoni",
              "rectangle": [
                511,
                242,
                612,
                359
              ],
              "weight": 0.6916188597679138
            },
            {
              "type": "tocino",
              "rectangle": [
                864,
                527,
                898,
                589
              ],
              "weight": 0.6807540655136108
            },
            {
              "type": "carne",
              "rectangle": [
                347,
                388,
                384,
                428
              ],
              "weight": 0.6690088510513306
            },
            {
              "type": "champiñón",
              "rectangle": [
                212,
                756,
                278,
                857
              ],
              "weight": 0.6437562108039856
            },
            {
              "type": "champiñón",
              "rectangle": [
                283,
                456,
                373,
                522
              ],
              "weight": 0.6338381171226501
            },
            {
              "type": "carne",
              "rectangle": [
                205,
                721,
                252,
                761
              ],
              "weight": 0.6150044798851013
            },
            {
              "type": "aceituna",
              "rectangle": [
                775,
                431,
                822,
                470
              ],
              "weight": 0.6036990284919739
            },
            {
              "type": "pepperoni",
              "rectangle": [
                768,
                414,
                916,
                526
              ],
              "weight": 0.5851749777793884
            },
            {
              "type": "pimiento",
              "rectangle": [
                910,
                370,
                936,
                391
              ],
              "weight": 0.580064594745636
            },
            {
              "type": "carne",
              "rectangle": [
                420,
                868,
                456,
                900
              ],
              "weight": 0.5606622695922852
            },
            {
              "type": "pepperoni",
              "rectangle": [
                369,
                426,
                488,
                543
              ],
              "weight": 0.5186302065849304
            },
            {
              "type": "champiñón",
              "rectangle": [
                292,
                659,
                366,
                762
              ],
              "weight": 0.5019455552101135
            },
            {
              "type": "pepperoni",
              "rectangle": [
                467,
                512,
                557,
                631
              ],
              "weight": 0.4873487949371338
            },
            {
              "type": "aceituna",
              "rectangle": [
                745,
                463,
                776,
                522
              ],
              "weight": 0.4848060607910156
            },
            {
              "type": "pepperoni",
              "rectangle": [
                538,
                415,
                598,
                521
              ],
              "weight": 0.47268036007881165
            },
            {
              "type": "carne",
              "rectangle": [
                274,
                384,
                328,
                436
              ],
              "weight": 0.4672260880470276
            },
            {
              "type": "carne",
              "rectangle": [
                514,
                782,
                560,
                820
              ],
              "weight": 0.450476735830307
            },
            {
              "type": "carne",
              "rectangle": [
                825,
                413,
                862,
                445
              ],
              "weight": 0.4478553831577301
            },
            {
              "type": "aceituna",
              "rectangle": [
                682,
                556,
                720,
                593
              ],
              "weight": 0.4432738423347473
            },
            {
              "type": "aceituna",
              "rectangle": [
                797,
                521,
                814,
                560
              ],
              "weight": 0.4428635537624359
            },
            {
              "type": "pepperoni",
              "rectangle": [
                639,
                786,
                751,
                919
              ],
              "weight": 0.4420677125453949
            },
            {
              "type": "champiñón",
              "rectangle": [
                609,
                133,
                671,
                203
              ],
              "weight": 0.4289669394493103
            },
            {
              "type": "pepperoni",
              "rectangle": [
                527,
                722,
                635,
                845
              ],
              "weight": 0.41576623916625977
            },
            {
              "type": "pepperoni",
              "rectangle": [
                471,
                612,
                586,
                690
              ],
              "weight": 0.37755995988845825
            },
            {
              "type": "tocino",
              "rectangle": [
                640,
                498,
                717,
                541
              ],
              "weight": 0.3584824800491333
            },
            {
              "type": "carne",
              "rectangle": [
                508,
                541,
                544,
                574
              ],
              "weight": 0.3420746922492981
            },
            {
              "type": "pepperoni",
              "rectangle": [
                181,
                593,
                296,
                737
              ],
              "weight": 0.3264632225036621
            },
            {
              "type": "carne",
              "rectangle": [
                185,
                590,
                251,
                631
              ],
              "weight": 0.3178330659866333
            },
            {
              "type": "champiñón",
              "rectangle": [
                684,
                319,
                735,
                363
              ],
              "weight": 0.31141412258148193
            },
            {
              "type": "carne",
              "rectangle": [
                562,
                790,
                611,
                844
              ],
              "weight": 0.310393363237381
            },
            {
              "type": "choclo",
              "rectangle": [
                861,
                397,
                886,
                425
              ],
              "weight": 0.308184415102005
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "64826cb3028aec96c1188c91",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "137": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              }
            },
            "score_final": 9.1,
            "specialty_check": false,
            "score_adjusted": 9.099999999999998
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64826f13bd98a2f5a176ae75",
        "REPORT_capture_start_ts": "2023/06/09 00:02:03"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 141,
            "REPORT_localized_capture_start_ts": "20:04:10 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_20:02:03_v9p1-ssd-dense_id0141(None)-fr1017-02:07.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_20:02:03_v9p1-ssd-dense_id0141(None)-fr1017-02:07.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                959,
                1286,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.0007290206267498434,
              "occ": 0.04675213247537613
            },
            "optimal_frame": 1017,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          },
          {
            "id": 143,
            "REPORT_localized_capture_start_ts": "20:08:46 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_20:02:03_v9p1-ssd-dense_id0143(None)-fr3225-06:43.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_20:02:03_v9p1-ssd-dense_id0143(None)-fr3225-06:43.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1321,
                1184,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.20442329347133636,
              "occ": 0.5709394812583923
            },
            "optimal_frame": 3225,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "6482887012502ec08c1d80ee",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64826f13bd98a2f5a176ae75",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64827171a432c6536f01eb86",
        "REPORT_capture_start_ts": "2023/06/09 00:12:04"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 144,
            "REPORT_localized_capture_start_ts": "20:15:52 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "napolitana",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_20:12:04_v9p1-ssd-dense_id0144(None)-fr1824-03:48.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_20:12:04_v9p1-ssd-dense_id0144(None)-fr1824-03:48.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1722,
                1201,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 2.652196795516204e-10,
              "occ": 0.0028386698104441166
            },
            "optimal_frame": 1824,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          },
          {
            "id": 145,
            "REPORT_localized_capture_start_ts": "20:16:50 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_20:12:04_v9p1-ssd-dense_id0145(None)-fr2285-04:46.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_20:12:04_v9p1-ssd-dense_id0145(None)-fr2285-04:46.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1336,
                1176,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.0015300186350941658,
              "occ": 0.0007648526225239038
            },
            "optimal_frame": 2285,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          },
          {
            "id": 146,
            "REPORT_localized_capture_start_ts": "20:19:43 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "napolitana",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_20:12:04_v9p1-ssd-dense_id0146(None)-fr3673-07:39.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_20:12:04_v9p1-ssd-dense_id0146(None)-fr3673-07:39.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1269,
                1190,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.000009055395821633283,
              "occ": 0.7109189629554749
            },
            "optimal_frame": 3673,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          }
        ],
        "REPORT_id": "64828b5312502ec08c1d80ef",
        "optimal_snap_ingredients": {
          "144": [
            {
              "type": "tomate",
              "rectangle": [
                170,
                322,
                376,
                517
              ],
              "weight": 0.9829555749893188
            },
            {
              "type": "tomate",
              "rectangle": [
                821,
                518,
                1021,
                725
              ],
              "weight": 0.9689252376556396
            },
            {
              "type": "tomate",
              "rectangle": [
                628,
                788,
                827,
                993
              ],
              "weight": 0.9642319679260254
            },
            {
              "type": "tomate",
              "rectangle": [
                423,
                134,
                627,
                334
              ],
              "weight": 0.9547938704490662
            },
            {
              "type": "tomate",
              "rectangle": [
                520,
                664,
                687,
                859
              ],
              "weight": 0.9530313611030579
            },
            {
              "type": "tomate",
              "rectangle": [
                575,
                503,
                642,
                582
              ],
              "weight": 0.9225682616233826
            },
            {
              "type": "tomate",
              "rectangle": [
                298,
                765,
                473,
                951
              ],
              "weight": 0.852717399597168
            },
            {
              "type": "tomate",
              "rectangle": [
                726,
                231,
                931,
                425
              ],
              "weight": 0.8084656596183777
            },
            {
              "type": "tomate",
              "rectangle": [
                98,
                619,
                305,
                808
              ],
              "weight": 0.7248103618621826
            },
            {
              "type": "pepperoni",
              "rectangle": [
                441,
                484,
                581,
                634
              ],
              "weight": 0.626830518245697
            },
            {
              "type": "choclo",
              "rectangle": [
                198,
                304,
                224,
                331
              ],
              "weight": 0.6210302710533142
            },
            {
              "type": "choclo",
              "rectangle": [
                227,
                316,
                248,
                333
              ],
              "weight": 0.5774123668670654
            },
            {
              "type": "tomate",
              "rectangle": [
                629,
                399,
                819,
                585
              ],
              "weight": 0.5511455535888672
            },
            {
              "type": "pepperoni",
              "rectangle": [
                80,
                530,
                147,
                634
              ],
              "weight": 0.4497654438018799
            },
            {
              "type": "tomate",
              "rectangle": [
                586,
                593,
                639,
                628
              ],
              "weight": 0.4342299699783325
            },
            {
              "type": "pepperoni",
              "rectangle": [
                630,
                395,
                738,
                502
              ],
              "weight": 0.38761433959007263
            }
          ],
          "146": [
            {
              "type": "tomate",
              "rectangle": [
                603,
                399,
                805,
                559
              ],
              "weight": 0.9953307509422302
            },
            {
              "type": "tomate",
              "rectangle": [
                104,
                555,
                306,
                776
              ],
              "weight": 0.9870319366455078
            },
            {
              "type": "tomate",
              "rectangle": [
                409,
                368,
                619,
                541
              ],
              "weight": 0.9712949395179749
            },
            {
              "type": "tomate",
              "rectangle": [
                809,
                561,
                1029,
                755
              ],
              "weight": 0.9619240760803223
            },
            {
              "type": "pepperoni",
              "rectangle": [
                524,
                584,
                629,
                720
              ],
              "weight": 0.9597606062889099
            },
            {
              "type": "tomate",
              "rectangle": [
                310,
                553,
                486,
                724
              ],
              "weight": 0.8914585709571838
            },
            {
              "type": "tomate",
              "rectangle": [
                138,
                316,
                353,
                498
              ],
              "weight": 0.8817468285560608
            },
            {
              "type": "tomate",
              "rectangle": [
                341,
                785,
                524,
                1004
              ],
              "weight": 0.839363157749176
            },
            {
              "type": "pepperoni",
              "rectangle": [
                325,
                200,
                391,
                348
              ],
              "weight": 0.8306280374526978
            },
            {
              "type": "tomate",
              "rectangle": [
                609,
                135,
                813,
                333
              ],
              "weight": 0.8168846368789673
            },
            {
              "type": "tomate",
              "rectangle": [
                807,
                313,
                1022,
                512
              ],
              "weight": 0.7988743185997009
            },
            {
              "type": "pepperoni",
              "rectangle": [
                807,
                292,
                951,
                390
              ],
              "weight": 0.6982357501983643
            },
            {
              "type": "choclo",
              "rectangle": [
                360,
                623,
                385,
                647
              ],
              "weight": 0.4038209021091461
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "64827171a432c6536f01eb86",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "144": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0,
                "3": 0.1,
                "4": 0,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Limpieza": {
                "1": 0,
                "2": 0,
                "3": 0.1,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0
              }
            },
            "score_final": 8.749999999999998,
            "specialty_check": false,
            "score_adjusted": 8.749999999999998
          },
          "146": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0,
                "7": 0,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              }
            },
            "score_final": 8.25,
            "specialty_check": false,
            "score_adjusted": 8.249999999999998
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648273cfa95ea5199b33d95f",
        "REPORT_capture_start_ts": "2023/06/09 00:22:04"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 147,
            "REPORT_localized_capture_start_ts": "20:22:40 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": null,
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_20:22:04_v9p1-ssd-dense_id0147(None)-fr0292-00:36.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_20:22:04_v9p1-ssd-dense_id0147(None)-fr0292-00:36.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "mala",
            "optimal_figure": {
              "coords": [
                1841,
                1376,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.9448140263557434,
              "occ": 0.7027291655540466
            },
            "optimal_frame": 292,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true
          },
          {
            "id": 148,
            "REPORT_localized_capture_start_ts": "20:23:14 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "pepperoni",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_20:22:04_v9p1-ssd-dense_id0148(None)-fr0564-01:10.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_20:22:04_v9p1-ssd-dense_id0148(None)-fr0564-01:10.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1560,
                1321,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.000005812753443024121,
              "occ": 0.00008673496631672606
            },
            "optimal_frame": 564,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "64828e2212502ec08c1d80f0",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "648273cfa95ea5199b33d95f",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "6482762f746e63bbc29f9579",
        "REPORT_capture_start_ts": "2023/06/09 00:32:05"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 149,
            "REPORT_localized_capture_start_ts": "20:36:17 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_20:32:05_v9p1-ssd-dense_id0149(None)-fr2019-04:12.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_20:32:05_v9p1-ssd-dense_id0149(None)-fr2019-04:12.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1287,
                1265,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.7048367857933044,
              "occ": 0.6498754024505615
            },
            "optimal_frame": 2019,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 150,
            "REPORT_localized_capture_start_ts": "20:37:06 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_20:32:05_v9p1-ssd-dense_id0150(None)-fr2408-05:01.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_20:32:05_v9p1-ssd-dense_id0150(None)-fr2408-05:01.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1412,
                1305,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.0002760781499091536,
              "occ": 0.012886807322502136
            },
            "optimal_frame": 2408,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 151,
            "REPORT_localized_capture_start_ts": "20:38:05 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "napolitana_cherry",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_20:32:05_v9p1-ssd-dense_id0151(None)-fr2878-06:00.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_20:32:05_v9p1-ssd-dense_id0151(None)-fr2878-06:00.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1277,
                1221,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.02824106626212597,
              "occ": 0.0041261762380599976
            },
            "optimal_frame": 2878,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "6482912712502ec08c1d80f1",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "6482762f746e63bbc29f9579",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64827892bef01e2aa7e4b581",
        "REPORT_capture_start_ts": "2023/06/09 00:42:06"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 153,
            "REPORT_localized_capture_start_ts": "20:42:17 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": null,
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_20:42:06_v9p1-ssd-dense_id0153(None)-fr0090-00:11.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_20:42:06_v9p1-ssd-dense_id0153(None)-fr0090-00:11.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "mala",
            "optimal_figure": {
              "coords": [
                1285,
                1386,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.0003087110526394099,
              "occ": 0.8505650162696838
            },
            "optimal_frame": 90,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true
          },
          {
            "id": 154,
            "REPORT_localized_capture_start_ts": "20:46:12 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_20:42:06_v9p1-ssd-dense_id0154(None)-fr1966-04:06.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_20:42:06_v9p1-ssd-dense_id0154(None)-fr1966-04:06.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1650,
                942,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.0000015914531559246825,
              "occ": 0.011945763602852821
            },
            "optimal_frame": 1966,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          },
          {
            "id": 155,
            "REPORT_localized_capture_start_ts": "20:51:27 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "napolitana_cherry",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_20:42:06_v9p1-ssd-dense_id0155(None)-fr4489-09:21.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_20:42:06_v9p1-ssd-dense_id0155(None)-fr4489-09:21.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1697,
                1307,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 8.791538164132362e-8,
              "occ": 0.016640935093164444
            },
            "optimal_frame": 4489,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          }
        ],
        "REPORT_id": "6482941212502ec08c1d80f2",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64827892bef01e2aa7e4b581",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64827af15c89637b170c7708",
        "REPORT_capture_start_ts": "2023/06/09 00:52:06"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "648296c412502ec08c1d80f3",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64827af15c89637b170c7708",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64827d51959cce453bd1ffb2",
        "REPORT_capture_start_ts": "2023/06/09 01:02:07"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 156,
            "REPORT_localized_capture_start_ts": "21:08:24 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "napolitana_cherry",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_21:02:07_v9p1-ssd-dense_id0156(None)-fr3015-06:17.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_21:02:07_v9p1-ssd-dense_id0156(None)-fr3015-06:17.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1743,
                1020,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.00004319227809901349,
              "occ": 0.02040255255997181
            },
            "optimal_frame": 3015,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "6482997512502ec08c1d80f4",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64827d51959cce453bd1ffb2",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64827fb101ffcf68faa8c93a",
        "REPORT_capture_start_ts": "2023/06/09 01:12:08"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 161,
            "REPORT_localized_capture_start_ts": "21:15:18 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_21:12:08_v9p1-ssd-dense_id0161(None)-fr1521-03:10.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_21:12:08_v9p1-ssd-dense_id0161(None)-fr1521-03:10.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1799,
                1141,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 1.1883553696634408e-7,
              "occ": 0.07810477167367935
            },
            "optimal_frame": 1521,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          },
          {
            "id": 162,
            "REPORT_localized_capture_start_ts": "21:15:49 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_21:12:08_v9p1-ssd-dense_id0162(None)-fr1766-03:41.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_21:12:08_v9p1-ssd-dense_id0162(None)-fr1766-03:41.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1743,
                951,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 6.383183426272865e-10,
              "occ": 0.06419052183628082
            },
            "optimal_frame": 1766,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          }
        ],
        "REPORT_id": "64829c6d12502ec08c1d80f5",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64827fb101ffcf68faa8c93a",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648282115120ec9cc5fdec48",
        "REPORT_capture_start_ts": "2023/06/09 01:22:08"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 163,
            "REPORT_localized_capture_start_ts": "21:26:34 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_21:22:08_v9p1-ssd-dense_id0163(None)-fr2131-04:26.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_21:22:08_v9p1-ssd-dense_id0163(None)-fr2131-04:26.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1645,
                1123,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.0008177442359738052,
              "occ": 0.00409544026479125
            },
            "optimal_frame": 2131,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "64829f1412502ec08c1d80f6",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "648282115120ec9cc5fdec48",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648284717dc43f4a7c1bf4f7",
        "REPORT_capture_start_ts": "2023/06/09 01:32:09"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 164,
            "REPORT_localized_capture_start_ts": "21:36:58 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "unknown",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_21:32:09_v9p1-ssd-dense_id0164(None)-fr2310-04:49.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_21:32:09_v9p1-ssd-dense_id0164(None)-fr2310-04:49.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1509,
                1228,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.009421894326806068,
              "occ": 0.010046618990600109
            },
            "optimal_frame": 2310,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          },
          {
            "id": 165,
            "REPORT_localized_capture_start_ts": "21:38:05 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "napolitana",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_21:32:09_v9p1-ssd-dense_id0165(None)-fr2851-05:56.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_21:32:09_v9p1-ssd-dense_id0165(None)-fr2851-05:56.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1575,
                1125,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.000017407095583621413,
              "occ": 0.00035379931796342134
            },
            "optimal_frame": 2851,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          },
          {
            "id": 168,
            "REPORT_localized_capture_start_ts": "21:39:53 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "chicken_garlic",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_21:32:09_v9p1-ssd-dense_id0168(None)-fr3709-07:44.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_21:32:09_v9p1-ssd-dense_id0168(None)-fr3709-07:44.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1402,
                1153,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.000007765389455016702,
              "occ": 0.000050021553761325777
            },
            "optimal_frame": 3709,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "delgada"
          }
        ],
        "REPORT_id": "6482a1d412502ec08c1d80f7",
        "optimal_snap_ingredients": {
          "165": [
            {
              "type": "pepperoni",
              "rectangle": [
                448,
                498,
                608,
                645
              ],
              "weight": 0.9722940325737
            },
            {
              "type": "pepperoni",
              "rectangle": [
                308,
                355,
                378,
                480
              ],
              "weight": 0.9571464657783508
            },
            {
              "type": "tomate",
              "rectangle": [
                771,
                413,
                951,
                613
              ],
              "weight": 0.9129683375358582
            },
            {
              "type": "pepperoni",
              "rectangle": [
                608,
                463,
                721,
                660
              ],
              "weight": 0.9114965200424194
            },
            {
              "type": "pepperoni",
              "rectangle": [
                448,
                758,
                630,
                902
              ],
              "weight": 0.7670076489448547
            },
            {
              "type": "pepperoni",
              "rectangle": [
                702,
                686,
                899,
                814
              ],
              "weight": 0.5221763253211975
            },
            {
              "type": "pepperoni",
              "rectangle": [
                363,
                310,
                473,
                367
              ],
              "weight": 0.4373572766780853
            },
            {
              "type": "pollo",
              "rectangle": [
                285,
                624,
                424,
                699
              ],
              "weight": 0.3577103912830353
            }
          ],
          "168": [
            {
              "type": "pollo",
              "rectangle": [
                651,
                290,
                775,
                381
              ],
              "weight": 0.977403461933136
            },
            {
              "type": "pollo",
              "rectangle": [
                521,
                799,
                569,
                873
              ],
              "weight": 0.951801598072052
            },
            {
              "type": "pollo",
              "rectangle": [
                479,
                539,
                548,
                659
              ],
              "weight": 0.9476771950721741
            },
            {
              "type": "pollo",
              "rectangle": [
                217,
                511,
                292,
                635
              ],
              "weight": 0.9432550668716431
            },
            {
              "type": "pollo",
              "rectangle": [
                485,
                219,
                561,
                315
              ],
              "weight": 0.9264480471611023
            },
            {
              "type": "pollo",
              "rectangle": [
                451,
                321,
                504,
                433
              ],
              "weight": 0.9225791096687317
            },
            {
              "type": "pollo",
              "rectangle": [
                740,
                512,
                800,
                562
              ],
              "weight": 0.9211782813072205
            },
            {
              "type": "pollo",
              "rectangle": [
                306,
                340,
                387,
                421
              ],
              "weight": 0.9150696396827698
            },
            {
              "type": "pollo",
              "rectangle": [
                394,
                401,
                459,
                453
              ],
              "weight": 0.9130474328994751
            },
            {
              "type": "pollo",
              "rectangle": [
                550,
                408,
                594,
                479
              ],
              "weight": 0.9083494544029236
            },
            {
              "type": "pollo",
              "rectangle": [
                719,
                406,
                772,
                485
              ],
              "weight": 0.9036827087402344
            },
            {
              "type": "pollo",
              "rectangle": [
                565,
                500,
                617,
                564
              ],
              "weight": 0.864530086517334
            },
            {
              "type": "pollo",
              "rectangle": [
                687,
                249,
                756,
                289
              ],
              "weight": 0.8636004328727722
            },
            {
              "type": "pollo",
              "rectangle": [
                379,
                485,
                439,
                564
              ],
              "weight": 0.8240597248077393
            },
            {
              "type": "pollo",
              "rectangle": [
                287,
                494,
                358,
                536
              ],
              "weight": 0.820612907409668
            },
            {
              "type": "pollo",
              "rectangle": [
                299,
                681,
                408,
                736
              ],
              "weight": 0.8152335286140442
            },
            {
              "type": "pollo",
              "rectangle": [
                652,
                520,
                702,
                571
              ],
              "weight": 0.7959448099136353
            },
            {
              "type": "pollo",
              "rectangle": [
                590,
                763,
                625,
                814
              ],
              "weight": 0.7921244502067566
            },
            {
              "type": "pollo",
              "rectangle": [
                364,
                560,
                414,
                635
              ],
              "weight": 0.7828131318092346
            },
            {
              "type": "pollo",
              "rectangle": [
                671,
                753,
                747,
                797
              ],
              "weight": 0.7777957916259766
            },
            {
              "type": "pollo",
              "rectangle": [
                603,
                235,
                646,
                348
              ],
              "weight": 0.7770270705223083
            },
            {
              "type": "pollo",
              "rectangle": [
                471,
                714,
                526,
                808
              ],
              "weight": 0.7389347553253174
            },
            {
              "type": "pollo",
              "rectangle": [
                724,
                637,
                800,
                692
              ],
              "weight": 0.6875094771385193
            },
            {
              "type": "pollo",
              "rectangle": [
                795,
                409,
                840,
                483
              ],
              "weight": 0.657832682132721
            },
            {
              "type": "pollo",
              "rectangle": [
                485,
                858,
                530,
                930
              ],
              "weight": 0.5714964270591736
            },
            {
              "type": "pollo",
              "rectangle": [
                440,
                258,
                487,
                310
              ],
              "weight": 0.5381835699081421
            },
            {
              "type": "pollo",
              "rectangle": [
                470,
                448,
                521,
                497
              ],
              "weight": 0.5326306223869324
            },
            {
              "type": "pollo",
              "rectangle": [
                563,
                771,
                595,
                831
              ],
              "weight": 0.4515392482280731
            },
            {
              "type": "pollo",
              "rectangle": [
                403,
                721,
                469,
                881
              ],
              "weight": 0.4476780593395233
            },
            {
              "type": "pollo",
              "rectangle": [
                433,
                593,
                485,
                661
              ],
              "weight": 0.4190334379673004
            },
            {
              "type": "pollo",
              "rectangle": [
                651,
                216,
                695,
                285
              ],
              "weight": 0.4121885597705841
            },
            {
              "type": "pollo",
              "rectangle": [
                568,
                621,
                615,
                696
              ],
              "weight": 0.3963819146156311
            },
            {
              "type": "pollo",
              "rectangle": [
                556,
                839,
                616,
                925
              ],
              "weight": 0.39366793632507324
            },
            {
              "type": "carne",
              "rectangle": [
                490,
                393,
                534,
                448
              ],
              "weight": 0.39112207293510437
            },
            {
              "type": "pollo",
              "rectangle": [
                305,
                265,
                357,
                332
              ],
              "weight": 0.3816263973712921
            },
            {
              "type": "pollo",
              "rectangle": [
                697,
                580,
                769,
                650
              ],
              "weight": 0.37615254521369934
            },
            {
              "type": "pollo",
              "rectangle": [
                766,
                589,
                831,
                636
              ],
              "weight": 0.3587515354156494
            },
            {
              "type": "pollo",
              "rectangle": [
                235,
                461,
                292,
                506
              ],
              "weight": 0.35118573904037476
            },
            {
              "type": "pollo",
              "rectangle": [
                437,
                497,
                520,
                555
              ],
              "weight": 0.32957565784454346
            },
            {
              "type": "pollo",
              "rectangle": [
                472,
                671,
                528,
                722
              ],
              "weight": 0.32716694474220276
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "648284717dc43f4a7c1bf4f7",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "165": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0.25
              },
              "Bordes": {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              }
            },
            "score_final": 7.25,
            "specialty_check": false,
            "score_adjusted": 7.249999999999998
          },
          "168": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Bordes": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0
              },
              "Limpieza": {
                "1": 0,
                "2": 0.1,
                "3": 0.1,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0.1,
                "8": 0
              }
            },
            "score_final": 9.2,
            "specialty_check": false,
            "score_adjusted": 9.199999999999998
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648286d123258ac576025499",
        "REPORT_capture_start_ts": "2023/06/09 01:42:10"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "id": 169,
            "REPORT_localized_capture_start_ts": "21:43:01 2023/06/08",
            "REPORT_detections_categ_summary": [],
            "category": "pepperoni",
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_21:42:10_v9p1-ssd-dense_id0169(None)-fr0405-00:51.jpg",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_21:42:10_v9p1-ssd-dense_id0169(None)-fr0405-00:51.jpg",
            "REPORT_frame_detection_summary": {},
            "snap_quality": "buena",
            "optimal_figure": {
              "coords": [
                1702,
                1140,
                506
              ],
              "__type__": "Circle"
            },
            "optimal_frame_quality": {
              "blur": 0.0000015036927152323187,
              "occ": 0.00634379219263792
            },
            "optimal_frame": 405,
            "raw_score": false,
            "multisize": false,
            "snap_quality_trusted": true,
            "omit_soft": true,
            "crust": "tradicional"
          }
        ],
        "REPORT_id": "6482a47612502ec08c1d80f8",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "648286d123258ac576025499",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648289336f76be71727b8938",
        "REPORT_capture_start_ts": "2023/06/09 01:52:10"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "6482a6a912502ec08c1d80f9",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "648289336f76be71727b8938",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64828b94ccf49294629e7f6c",
        "REPORT_capture_start_ts": "2023/06/09 02:02:11"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "6482a8d812502ec08c1d80fa",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64828b94ccf49294629e7f6c",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64828df31a309331364c44e2",
        "REPORT_capture_start_ts": "2023/06/09 02:12:11"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "6482ab0712502ec08c1d80fb",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64828df31a309331364c44e2",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648290534b161a32cfce5a44",
        "REPORT_capture_start_ts": "2023/06/09 02:22:12"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "6482ad3312502ec08c1d80fc",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "648290534b161a32cfce5a44",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648292b3ccb31759bdcc1443",
        "REPORT_capture_start_ts": "2023/06/09 02:32:13"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "6482af5112502ec08c1d80fd",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "648292b3ccb31759bdcc1443",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648293e5d91929af98c6eef6",
        "REPORT_capture_start_ts": "2023/06/09 02:42:14"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "6482b17012502ec08c1d80fe",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "648293e5d91929af98c6eef6",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64829644ef9dcc7f1121569c",
        "REPORT_capture_start_ts": "2023/06/09 02:52:14"
      },
      "pipeline_result": {
        "trackeds": [
          {
            "REPORT_localized_capture_start_ts": "22:59:12 2023/06/08",
            "optimal_frame_quality": {
              "blur": 0.00004231821731082164,
              "occ": 0.0004200479306746274
            },
            "bubbles": null,
            "optimal_snap_path": "../../data_mnt/1_reviewable/2023-06-08/j4_2023-06-08_22:52:14_v9p1-ssd-dense_id0171(None)-fr3348-06:58.jpg",
            "snap_quality": "buena",
            "multisize": false,
            "raw_score": false,
            "snap_quality_trusted": true,
            "original_data": null,
            "overbaked": null,
            "id": 171,
            "standard": "not_to_standard",
            "category": "super_pepperoni",
            "REPORT_optimal_snap_path": "./from_edge/Bandera/2023-06-08/j4_2023-06-08_22:52:14_v9p1-ssd-dense_id0171(None)-fr3348-06:58.jpg",
            "REPORT_frame_detection_summary": {},
            "censored": null,
            "attributes": null,
            "omit_soft": true,
            "bad_cut": null,
            "optimal_figure": {
              "coords": [
                1342,
                1216,
                506
              ],
              "__type__": "Circle"
            },
            "REPORT_detections_categ_summary": [],
            "optimal_frame": 3348,
            "reviewer": "rodrigo.pulcha",
            "crust": "delgada"
          }
        ],
        "REPORT_id": "6482b39512502ec08c1d80ff",
        "optimal_snap_ingredients": {
          "171": [
            {
              "type": "pepperoni",
              "rectangle": [
                322,
                239,
                428,
                341
              ],
              "weight": 0.9992212057113647
            },
            {
              "type": "pepperoni",
              "rectangle": [
                634,
                700,
                751,
                814
              ],
              "weight": 0.9991850256919861
            },
            {
              "type": "pepperoni",
              "rectangle": [
                682,
                379,
                791,
                480
              ],
              "weight": 0.998971700668335
            },
            {
              "type": "pepperoni",
              "rectangle": [
                524,
                99,
                639,
                208
              ],
              "weight": 0.9989172220230103
            },
            {
              "type": "pepperoni",
              "rectangle": [
                204,
                280,
                312,
                388
              ],
              "weight": 0.9988920092582703
            },
            {
              "type": "pepperoni",
              "rectangle": [
                757,
                201,
                874,
                312
              ],
              "weight": 0.9987842440605164
            },
            {
              "type": "pepperoni",
              "rectangle": [
                889,
                491,
                1004,
                601
              ],
              "weight": 0.9986481070518494
            },
            {
              "type": "pepperoni",
              "rectangle": [
                868,
                378,
                966,
                477
              ],
              "weight": 0.998362123966217
            },
            {
              "type": "pepperoni",
              "rectangle": [
                287,
                349,
                395,
                459
              ],
              "weight": 0.9983270764350891
            },
            {
              "type": "pepperoni",
              "rectangle": [
                155,
                385,
                263,
                491
              ],
              "weight": 0.9983183145523071
            },
            {
              "type": "pepperoni",
              "rectangle": [
                672,
                120,
                770,
                224
              ],
              "weight": 0.9982941746711731
            },
            {
              "type": "pepperoni",
              "rectangle": [
                867,
                258,
                977,
                371
              ],
              "weight": 0.9982847571372986
            },
            {
              "type": "pepperoni",
              "rectangle": [
                289,
                681,
                391,
                785
              ],
              "weight": 0.9982631802558899
            },
            {
              "type": "pepperoni",
              "rectangle": [
                673,
                828,
                793,
                944
              ],
              "weight": 0.9982536435127258
            },
            {
              "type": "pepperoni",
              "rectangle": [
                383,
                420,
                497,
                530
              ],
              "weight": 0.9981389045715332
            },
            {
              "type": "pepperoni",
              "rectangle": [
                874,
                600,
                989,
                717
              ],
              "weight": 0.998084545135498
            },
            {
              "type": "pepperoni",
              "rectangle": [
                400,
                98,
                500,
                202
              ],
              "weight": 0.9979909658432007
            },
            {
              "type": "pepperoni",
              "rectangle": [
                214,
                573,
                330,
                690
              ],
              "weight": 0.9979621171951294
            },
            {
              "type": "pepperoni",
              "rectangle": [
                752,
                752,
                855,
                859
              ],
              "weight": 0.9977610111236572
            },
            {
              "type": "pepperoni",
              "rectangle": [
                532,
                205,
                632,
                309
              ],
              "weight": 0.9977169036865234
            },
            {
              "type": "pepperoni",
              "rectangle": [
                268,
                114,
                379,
                223
              ],
              "weight": 0.997611403465271
            },
            {
              "type": "pepperoni",
              "rectangle": [
                791,
                441,
                910,
                548
              ],
              "weight": 0.9975486397743225
            },
            {
              "type": "pepperoni",
              "rectangle": [
                618,
                459,
                720,
                537
              ],
              "weight": 0.9975183010101318
            },
            {
              "type": "pepperoni",
              "rectangle": [
                477,
                889,
                594,
                1001
              ],
              "weight": 0.9974566102027893
            },
            {
              "type": "pepperoni",
              "rectangle": [
                669,
                506,
                777,
                608
              ],
              "weight": 0.9973709583282471
            },
            {
              "type": "pepperoni",
              "rectangle": [
                431,
                230,
                525,
                323
              ],
              "weight": 0.9973467588424683
            },
            {
              "type": "pepperoni",
              "rectangle": [
                489,
                677,
                591,
                785
              ],
              "weight": 0.9972628355026245
            },
            {
              "type": "pepperoni",
              "rectangle": [
                711,
                632,
                820,
                729
              ],
              "weight": 0.9971827268600464
            },
            {
              "type": "pepperoni",
              "rectangle": [
                473,
                316,
                556,
                438
              ],
              "weight": 0.9971091151237488
            },
            {
              "type": "pepperoni",
              "rectangle": [
                438,
                767,
                551,
                885
              ],
              "weight": 0.9970288872718811
            },
            {
              "type": "pepperoni",
              "rectangle": [
                818,
                690,
                921,
                783
              ],
              "weight": 0.996915340423584
            },
            {
              "type": "pepperoni",
              "rectangle": [
                239,
                431,
                342,
                549
              ],
              "weight": 0.9968902468681335
            },
            {
              "type": "pepperoni",
              "rectangle": [
                449,
                597,
                551,
                707
              ],
              "weight": 0.9963384866714478
            },
            {
              "type": "pepperoni",
              "rectangle": [
                106,
                624,
                221,
                742
              ],
              "weight": 0.9963232278823853
            },
            {
              "type": "pepperoni",
              "rectangle": [
                763,
                552,
                867,
                656
              ],
              "weight": 0.9961789846420288
            },
            {
              "type": "pepperoni",
              "rectangle": [
                148,
                725,
                259,
                840
              ],
              "weight": 0.9961495399475098
            },
            {
              "type": "pepperoni",
              "rectangle": [
                651,
                251,
                758,
                366
              ],
              "weight": 0.9960730075836182
            },
            {
              "type": "pepperoni",
              "rectangle": [
                311,
                524,
                417,
                631
              ],
              "weight": 0.995302677154541
            },
            {
              "type": "pepperoni",
              "rectangle": [
                557,
                763,
                643,
                876
              ],
              "weight": 0.9943678975105286
            },
            {
              "type": "pepperoni",
              "rectangle": [
                162,
                536,
                269,
                584
              ],
              "weight": 0.9940153956413269
            },
            {
              "type": "pepperoni",
              "rectangle": [
                393,
                711,
                474,
                815
              ],
              "weight": 0.9936084747314453
            },
            {
              "type": "pepperoni",
              "rectangle": [
                248,
                807,
                365,
                925
              ],
              "weight": 0.99345463514328
            },
            {
              "type": "pepperoni",
              "rectangle": [
                113,
                464,
                221,
                555
              ],
              "weight": 0.9933092594146729
            },
            {
              "type": "pepperoni",
              "rectangle": [
                457,
                489,
                550,
                603
              ],
              "weight": 0.9922056794166565
            },
            {
              "type": "pepperoni",
              "rectangle": [
                174,
                162,
                274,
                271
              ],
              "weight": 0.991911768913269
            },
            {
              "type": "pepperoni",
              "rectangle": [
                532,
                533,
                623,
                618
              ],
              "weight": 0.9918187856674194
            },
            {
              "type": "pepperoni",
              "rectangle": [
                582,
                867,
                684,
                981
              ],
              "weight": 0.9916020035743713
            },
            {
              "type": "pepperoni",
              "rectangle": [
                582,
                306,
                683,
                402
              ],
              "weight": 0.9909833669662476
            },
            {
              "type": "pepperoni",
              "rectangle": [
                380,
                618,
                460,
                706
              ],
              "weight": 0.9901144504547119
            },
            {
              "type": "pepperoni",
              "rectangle": [
                38,
                527,
                139,
                627
              ],
              "weight": 0.9882974028587341
            },
            {
              "type": "pepperoni",
              "rectangle": [
                388,
                340,
                486,
                438
              ],
              "weight": 0.9868322610855103
            },
            {
              "type": "pepperoni",
              "rectangle": [
                83,
                290,
                208,
                416
              ],
              "weight": 0.9867027997970581
            },
            {
              "type": "pepperoni",
              "rectangle": [
                955,
                389,
                1023,
                497
              ],
              "weight": 0.9752752780914307
            },
            {
              "type": "pepperoni",
              "rectangle": [
                396,
                897,
                474,
                992
              ],
              "weight": 0.9718185067176819
            },
            {
              "type": "pepperoni",
              "rectangle": [
                523,
                440,
                596,
                528
              ],
              "weight": 0.9332842230796814
            },
            {
              "type": "pepperoni",
              "rectangle": [
                585,
                637,
                674,
                727
              ],
              "weight": 0.8764113187789917
            }
          ]
        },
        "tracking_ok": true,
        "REPORT_vid_id": "64829644ef9dcc7f1121569c",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {
          "171": {
            "score_details": {
              "Ingredientes interior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0.25
              },
              "Queso interior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Ingredientes exterior": {
                "1": 0.25,
                "2": 0.25,
                "3": 0.25,
                "4": 0.25,
                "5": 0.25,
                "6": 0.25,
                "7": 0.25,
                "8": 0
              },
              "Bordes": {
                "1": 0,
                "2": 0,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0,
                "8": 0
              },
              "Cocción queso": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Queso exterior": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Sello de queso": {
                "1": 0.1,
                "2": 0,
                "3": 0,
                "4": 0.1,
                "5": 0.1,
                "6": 0.1,
                "7": 0.1,
                "8": 0.1
              },
              "Limpieza": {
                "1": 0.1,
                "2": 0.1,
                "3": 0.1,
                "4": 0.1,
                "5": 0,
                "6": 0,
                "7": 0.1,
                "8": 0.1
              }
            },
            "score_final": 8.95,
            "specialty_check": true,
            "score_adjusted": 8.949999999999998
          }
        },
        "evaluation_ok": true
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "648298a3c5bfbe8fcfcf2c11",
        "REPORT_capture_start_ts": "2023/06/09 03:02:15"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "6482b5b512502ec08c1d8100",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "648298a3c5bfbe8fcfcf2c11",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64829b045e613e8396764713",
        "REPORT_capture_start_ts": "2023/06/09 03:12:15"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "6482b7d312502ec08c1d8101",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64829b045e613e8396764713",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64829d6526c7b328762a2467",
        "REPORT_capture_start_ts": "2023/06/09 03:22:16"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "6482b9f512502ec08c1d8102",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64829d6526c7b328762a2467",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "64829fc9ae334435601ef091",
        "REPORT_capture_start_ts": "2023/06/09 03:32:17"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "6482bc2e12502ec08c1d8103",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "64829fc9ae334435601ef091",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "6482a22a0839565aa8b4035d",
        "REPORT_capture_start_ts": "2023/06/09 03:42:18"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "6482be6412502ec08c1d8104",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "6482a22a0839565aa8b4035d",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    },
    {
      "video": {
        "venue": "Bandera",
        "REPORT_file_path": null,
        "REPORT_id": "6482a48ad4a26dcc385193e8",
        "REPORT_capture_start_ts": "2023/06/09 03:52:18"
      },
      "pipeline_result": {
        "trackeds": [],
        "REPORT_id": "6482c09712502ec08c1d8105",
        "optimal_snap_ingredients": {},
        "tracking_ok": true,
        "REPORT_vid_id": "6482a48ad4a26dcc385193e8",
        "pipeline_id": "v9p1-ssd-dense",
        "evaluation_results": {},
        "evaluation_ok": false
      }
    }
  ]
}

const PIZZA_SPEC = {
  "the_vegan_royal": {
    "id": "the_vegan_royal",
    "name": "The Vegan Royal"
  },
  "pollo_bbq": {
    "id": "pollo_bbq",
    "name": "Pollo BBQ"
  },
  "pepperoni_cups": {
    "id": "pepperoni_cups",
    "name": "Pepperoni Cups"
  },
  "super_pepperoni": {
    "id": "super_pepperoni",
    "name": "Super Pepperoni"
  },
  "philly_cheesesteak": {
    "id": "philly_cheesesteak",
    "name": "Philly Cheesesteak"
  },
  "chicken_garlic": {
    "id": "chicken_garlic",
    "name": "Chicken Garlic"
  },
  "napolitana_cherry": {
    "id": "napolitana_cherry",
    "name": "Napolitana Cherry"
  },
  "espinacas_crema": {
    "id": "espinacas_crema",
    "name": "Espinacas a la Crema"
  },
  "sweet_blue_cheese": {
    "id": "sweet_blue_cheese",
    "name": "Sweet Blue Cheese"
  },
  "pulled_pork": {
    "id": "pulled_pork",
    "name": "Pulled Pork BBQ"
  },
  "the_vegan_queen": {
    "id": "the_vegan_queen",
    "name": "The Vegan Queen"
  },
  "vegetariana": {
    "id": "vegetariana",
    "name": "Vegetariana"
  },
  "meatball_pepperoni": {
    "id": "meatball_pepperoni",
    "name": "Meatball Pepperoni"
  },
  "hawaiana": {
    "id": "hawaiana",
    "name": "Hawaiana"
  },
  "meatball_bbq": {
    "id": "meatball_bbq",
    "name": "Meatball BBQ"
  },
  "the_works": {
    "id": "the_works",
    "name": "The Works"
  },
  "custom": {
    "id": "custom",
    "name": "< A MEDIDA >"
  },
  "italiana": {
    "id": "italiana",
    "name": "Italiana"
  },
  "half": {
    "id": "half",
    "name": "< DOS MITADES >"
  },
  "napolitana_caprese": {
    "id": "napolitana_caprese",
    "name": "Napolitana Caprese"
  },
  "napolitana": {
    "id": "napolitana",
    "name": "Napolitana"
  },
  "pollo_caprese": {
    "id": "pollo_caprese",
    "name": "Pollo Caprese"
  },
  "canadian_bacon": {
    "id": "canadian_bacon",
    "name": "Canadian Bacon"
  },
  "todas_las_carnes": {
    "id": "todas_las_carnes",
    "name": "Todas las Carnes"
  },
  "unknown": {
    "id": "unknown",
    "name": "< NO IDENTIFICADA >"
  }
}

const UI_TEXT = {
  half: "2 Halves",
  custom: 'CYO',
  unknown: 'Unknown (CYO)',
  super_pepperoni: 'Super Pepperoni',
  pepperoni_cups: 'Pepperoni Cups',
  vegetariana: 'Vegetarian',
  italiana: 'Italian',
  todas_las_carnes: 'Meats',
  the_works: 'Works',
  pulled_pork: 'Pulled Pork BBQ',
  meatball_pepperoni: 'Meatball Pepperoni',
  pollo_bbq: 'Chicken BBQ',
  espinacas_crema: 'Spinach with Cream',
  meatball_bbq: 'Meatball BBQ',
  hawaiana: 'Hawaiian',
  napolitana: 'Napolitan',
  canadian_bacon: 'Canadian Bacon',
  chicken_garlic: 'Chicken Garlic',
  napolitana_cherry: 'Cherry Napolitan',
  the_vegan_royal: 'The Vegan Royal',
  the_vegan_queen: 'The Vegan Queen',
  sweet_blue_cheese: 'Sweet Blue Cheese',
  pollo_caprese: 'Caprese Chicken',
  philly_cheesesteak: 'Philly Cheesesteak',
  napolitana_caprese: 'Caprese Napolitan',
  bbq_chicken_bacon: 'BBQ Chicken Bacon',
  pepp_sausage_6_cheese: 'Pepperoni, Sausage and Six Cheese',
  meats: 'Meats',
  tuscan_6_cheese: 'Tuscan Six Cheese',
  garden_fresh: 'Garden Fresh',
  spinach_tomato_alfredo: 'Fresh Spinach & Tomato Alfredo',
  shaq_a_roni: 'Shaq-a-Roni',
  hawaiian_bbq_chicken: 'Hawaiian BBQ Chicken',
  zesty_italian_trio: 'Zesty Italian Trio',
  super_hawaiian: 'Super Hawaiian',
  works: 'Works',
  ultimate_pepperoni: 'Ultimate Pepperoni',
  fiery_buffalo_chicken: 'Fiery Buffalo Chicken',
  extra_cheesy_alfredo: 'Extra Cheesy Alfredo',
  cheese: 'Cheese',
  pepperoni: 'Pepperoni',
  double_cheeseburger: 'Double Cheeseburger'
};
const TEMP_IMG_URL = 'http://54.205.243.242:9791/static/';
const BASE_POINTS = 1.2;
const NON_PEPPERONI_ING_ADJUST = 2.5;
const PEPPERONI_SPECS = [
  'super_pepperoni',
  'ultimate_pepperoni',
  'pepperoni',
  'shaq_a_roni'
];

const CARDS = [
  {
      "index": 0,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_13:41:55_v9p1-ssd-dense-pizzahut-custom-snap-class_id0009(None)-fr1228-02:34.jpg",
      "ts": "2022-09-22T15:44:29.000Z",
      "legend": "10:44:29 2022/09/22",
      "specialty": "Custom",
      "category": "unknown",
      "score": "",
      "kwali_score": false,
      "evaluationResult": {},
      "crustType": "delgada",
      "tId": 9,
      "vidId": "632c939b83c36982d6491af8",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 1,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_14:11:57_v9p1-ssd-dense-pizzahut-custom-snap-class_id0013(None)-fr3955-08:14.jpg",
      "ts": "2022-09-22T16:20:11.000Z",
      "legend": "11:20:11 2022/09/22",
      "specialty": "Italian",
      "category": "italiana",
      "score": "8.54",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 8.545,
          "score_details": {
              "Ingredient Distrib": 9.85,
              "Size": 6.08,
              "Ingredient Count": 8.25,
              "Circularity": 10
          },
          "specialty_check": true,
          "score_final": 8.545,
          "raw": {
              "Ingredient Distrib": 0.691881839733846,
              "Size": 0.7540969020878204,
              "Ingredient Count": 0.5772946859903382,
              "Circularity": 0.000054979287809913024
          }
      },
      "crustType": "tradicional",
      "tId": 13,
      "vidId": "632c9ababda33676b081d103",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "Española",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 2,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_14:21:58_v9p1-ssd-dense-pizzahut-custom-snap-class_id0014(None)-fr1014-02:07.jpg",
      "ts": "2022-09-22T16:24:05.000Z",
      "legend": "11:24:05 2022/09/22",
      "specialty": "Pepperoni",
      "category": "pepperoni",
      "score": "8.44",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 8.44,
          "score_details": {
              "Ingredient Distrib": 10,
              "Size": 6.56,
              "Ingredient Count": 10,
              "Circularity": 7.2
          },
          "specialty_check": true,
          "score_final": 8.44,
          "raw": {
              "Ingredient Distrib": 0.7032196578617151,
              "Size": 0.7826960077673808,
              "Ingredient Count": 1,
              "Circularity": 0.0001388903554252622
          }
      },
      "crustType": "tradicional",
      "tId": 14,
      "vidId": "632c9d19d7c8e0a33b01266a",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "Pepperoni",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 3,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_14:21:58_v9p1-ssd-dense-pizzahut-custom-snap-class_id0015(None)-fr2284-04:46.jpg",
      "ts": "2022-09-22T16:26:44.000Z",
      "legend": "11:26:44 2022/09/22",
      "specialty": "Pepperoni",
      "category": "pepperoni",
      "score": "9.04",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 9.045,
          "score_details": {
              "Ingredient Distrib": 10,
              "Size": 6.48,
              "Ingredient Count": 10,
              "Circularity": 9.7
          },
          "specialty_check": true,
          "score_final": 9.045,
          "raw": {
              "Ingredient Distrib": 0.740375500547505,
              "Size": 0.7777168989929659,
              "Ingredient Count": 1,
              "Circularity": 0.00008724881329178518
          }
      },
      "crustType": "tradicional",
      "tId": 15,
      "vidId": "632c9d19d7c8e0a33b01266a",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 4,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_16:22:05_v9p1-ssd-dense-pizzahut-custom-snap-class_id0022(None)-fr0829-01:44.jpg",
      "ts": "2022-09-22T18:23:49.000Z",
      "legend": "13:23:49 2022/09/22",
      "specialty": "Custom",
      "category": "unknown",
      "score": "",
      "kwali_score": false,
      "evaluationResult": {},
      "crustType": "tradicional",
      "tId": 22,
      "vidId": "632cb86bef0577335e46a33c",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 5,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_16:22:05_v9p1-ssd-dense-pizzahut-custom-snap-class_id0026(None)-fr2592-05:24.jpg",
      "ts": "2022-09-22T18:27:29.000Z",
      "legend": "13:27:29 2022/09/22",
      "specialty": "Custom",
      "category": "unknown",
      "score": "",
      "kwali_score": false,
      "evaluationResult": {},
      "crustType": "tradicional",
      "tId": 26,
      "vidId": "632cb86bef0577335e46a33c",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 6,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_16:42:06_v9p1-ssd-dense-pizzahut-custom-snap-class_id0028(None)-fr4445-09:16.jpg",
      "ts": "2022-09-22T18:51:22.000Z",
      "legend": "13:51:22 2022/09/22",
      "specialty": "Italian",
      "category": "italiana",
      "score": "7.98",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 7.9825,
          "score_details": {
              "Ingredient Distrib": 9,
              "Size": 6.88,
              "Ingredient Count": 8.15,
              "Circularity": 7.9
          },
          "specialty_check": true,
          "score_final": 7.9825,
          "raw": {
              "Ingredient Distrib": 0.6494212595067721,
              "Size": 0.8017981316921958,
              "Ingredient Count": 0.5718599033816425,
              "Circularity": 0.00012476873007096734
          }
      },
      "crustType": "delgada",
      "tId": 28,
      "vidId": "632cbd2943e094908824e220",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 7,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_16:52:07_v9p1-ssd-dense-pizzahut-custom-snap-class_id0029(None)-fr0368-00:46.jpg",
      "ts": "2022-09-22T18:52:53.000Z",
      "legend": "13:52:53 2022/09/22",
      "specialty": "Custom",
      "category": "unknown",
      "score": "",
      "kwali_score": false,
      "evaluationResult": {},
      "crustType": "delgada",
      "tId": 29,
      "vidId": "632cbf8837d05ba240833dd0",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 8,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_16:52:07_v9p1-ssd-dense-pizzahut-custom-snap-class_id0030(None)-fr0919-01:55.jpg",
      "ts": "2022-09-22T18:54:02.000Z",
      "legend": "13:54:02 2022/09/22",
      "specialty": "Pepperoni Lovers",
      "category": "pepperoni_lovers",
      "score": "9.11",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 9.11,
          "score_details": {
              "Ingredient Distrib": 10,
              "Size": 6.44,
              "Ingredient Count": 10,
              "Circularity": 10
          },
          "specialty_check": true,
          "score_final": 9.11,
          "raw": {
              "Ingredient Distrib": 0.769715972261104,
              "Size": 0.7771574485688744,
              "Ingredient Count": 0.7777777777777778,
              "Circularity": 0.00007770868635036994
          }
      },
      "crustType": "tradicional",
      "tId": 30,
      "vidId": "632cbf8837d05ba240833dd0",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 9,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_16:52:07_v9p1-ssd-dense-pizzahut-custom-snap-class_id0031(None)-fr1605-03:21.jpg",
      "ts": "2022-09-22T18:55:28.000Z",
      "legend": "13:55:28 2022/09/22",
      "specialty": "Pepperoni Lovers",
      "category": "pepperoni_lovers",
      "score": "9.09",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 9.09,
          "score_details": {
              "Ingredient Distrib": 10,
              "Size": 6.36,
              "Ingredient Count": 10,
              "Circularity": 10
          },
          "specialty_check": true,
          "score_final": 9.09,
          "raw": {
              "Ingredient Distrib": 0.7301182908183793,
              "Size": 0.7724767133539749,
              "Ingredient Count": 0.7083333333333334,
              "Circularity": 0.0000774469166136485
          }
      },
      "crustType": "tradicional",
      "tId": 31,
      "vidId": "632cbf8837d05ba240833dd0",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 10,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_16:52:07_v9p1-ssd-dense-pizzahut-custom-snap-class_id0032(None)-fr4184-08:43.jpg",
      "ts": "2022-09-22T19:00:50.000Z",
      "legend": "14:00:50 2022/09/22",
      "specialty": "Custom",
      "category": "unknown",
      "score": "",
      "kwali_score": false,
      "evaluationResult": {},
      "crustType": "tradicional",
      "tId": 32,
      "vidId": "632cbf8837d05ba240833dd0",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 11,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_18:32:13_v9p1-ssd-dense-pizzahut-custom-snap-class_id0035(None)-fr1025-02:08.jpg",
      "ts": "2022-09-22T20:34:21.000Z",
      "legend": "15:34:21 2022/09/22",
      "specialty": "Veggie Lovers",
      "category": "veggie_lovers",
      "score": "7.56",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 7.5600000000000005,
          "score_details": {
              "Ingredient Distrib": 8.8,
              "Size": 6.8,
              "Ingredient Count": 4.640000000000001,
              "Circularity": 10
          },
          "specialty_check": false,
          "score_final": 7.5600000000000005,
          "raw": {
              "Ingredient Distrib": 0.5896974960467172,
              "Size": 0.7987758561789368,
              "Ingredient Count": 0.3851060116546842,
              "Circularity": 0.0000484059118710081
          }
      },
      "crustType": "tradicional",
      "tId": 35,
      "vidId": "632cd746211491f7a0da14e9",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 12,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_18:32:13_v9p1-ssd-dense-pizzahut-custom-snap-class_id0036(None)-fr1517-03:10.jpg",
      "ts": "2022-09-22T20:35:23.000Z",
      "legend": "15:35:23 2022/09/22",
      "specialty": "Veggie Lovers",
      "category": "veggie_lovers",
      "score": "7.59",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 7.595,
          "score_details": {
              "Ingredient Distrib": 9.059999999999999,
              "Size": 6.92,
              "Ingredient Count": 4.4,
              "Circularity": 10
          },
          "specialty_check": false,
          "score_final": 7.595,
          "raw": {
              "Ingredient Distrib": 0.6113142788233348,
              "Size": 0.8041304183490753,
              "Ingredient Count": 0.36678074465685084,
              "Circularity": 0.00007566251534419532
          }
      },
      "crustType": "tradicional",
      "tId": 36,
      "vidId": "632cd746211491f7a0da14e9",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 13,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_18:32:13_v9p1-ssd-dense-pizzahut-custom-snap-class_id0037(None)-fr2087-04:21.jpg",
      "ts": "2022-09-22T20:36:34.000Z",
      "legend": "15:36:34 2022/09/22",
      "specialty": "Pepperoni Lovers",
      "category": "pepperoni_lovers",
      "score": "8.93",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 8.925,
          "score_details": {
              "Ingredient Distrib": 10,
              "Size": 6.8,
              "Ingredient Count": 9.9,
              "Circularity": 9
          },
          "specialty_check": true,
          "score_final": 8.925,
          "raw": {
              "Ingredient Distrib": 0.7212048695825583,
              "Size": 0.7979777069072328,
              "Ingredient Count": 0.6944444444444444,
              "Circularity": 0.00010087571629409943
          }
      },
      "crustType": "tradicional",
      "tId": 37,
      "vidId": "632cd746211491f7a0da14e9",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 14,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_18:32:13_v9p1-ssd-dense-pizzahut-custom-snap-class_id0038(None)-fr2579-05:22.jpg",
      "ts": "2022-09-22T20:37:35.000Z",
      "legend": "15:37:35 2022/09/22",
      "specialty": "Pepperoni",
      "category": "pepperoni",
      "score": "8.59",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 8.585,
          "score_details": {
              "Ingredient Distrib": 10,
              "Size": 6.84,
              "Ingredient Count": 10,
              "Circularity": 7.5
          },
          "specialty_check": true,
          "score_final": 8.585,
          "raw": {
              "Ingredient Distrib": 0.6902167406962878,
              "Size": 0.7996548149563428,
              "Ingredient Count": 1,
              "Circularity": 0.00013301509398796696
          }
      },
      "crustType": "tradicional",
      "tId": 38,
      "vidId": "632cd746211491f7a0da14e9",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 15,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_18:32:13_v9p1-ssd-dense-pizzahut-custom-snap-class_id0039(None)-fr3204-06:40.jpg",
      "ts": "2022-09-22T20:38:53.000Z",
      "legend": "15:38:53 2022/09/22",
      "specialty": "Pepperoni",
      "category": "pepperoni",
      "score": "9.14",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 9.14,
          "score_details": {
              "Ingredient Distrib": 10,
              "Size": 6.56,
              "Ingredient Count": 10,
              "Circularity": 10
          },
          "specialty_check": true,
          "score_final": 9.14,
          "raw": {
              "Ingredient Distrib": 0.7234907676369942,
              "Size": 0.7836495599346657,
              "Ingredient Count": 1,
              "Circularity": 0.00007000850398882676
          }
      },
      "crustType": "tradicional",
      "tId": 39,
      "vidId": "632cd746211491f7a0da14e9",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 16,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_18:52:15_v9p1-ssd-dense-pizzahut-custom-snap-class_id0040(None)-fr1013-02:07.jpg",
      "ts": "2022-09-22T20:54:22.000Z",
      "legend": "15:54:22 2022/09/22",
      "specialty": "Milano",
      "category": "milano",
      "score": "6.49",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 6.493333333333334,
          "score_details": {
              "Ingredient Distrib": 5.366666666666667,
              "Size": 6.640000000000001,
              "Ingredient Count": 3.966666666666667,
              "Circularity": 10
          },
          "specialty_check": false,
          "score_final": 6.493333333333334,
          "raw": {
              "Ingredient Distrib": 0.361195435685739,
              "Size": 0.7875383619936843,
              "Ingredient Count": 0.2784171639670361,
              "Circularity": 0.00006157728809306378
          }
      },
      "crustType": "tradicional",
      "tId": 40,
      "vidId": "632cdc05d7fcdf5eb72b3c17",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 17,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_18:52:15_v9p1-ssd-dense-pizzahut-custom-snap-class_id0041(None)-fr1652-03:26.jpg",
      "ts": "2022-09-22T20:55:41.000Z",
      "legend": "15:55:41 2022/09/22",
      "specialty": "Pepperoni",
      "category": "pepperoni",
      "score": "8.48",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 8.485,
          "score_details": {
              "Ingredient Distrib": 10,
              "Size": 6.84,
              "Ingredient Count": 7.1,
              "Circularity": 10
          },
          "specialty_check": false,
          "score_final": 8.485,
          "raw": {
              "Ingredient Distrib": 0.7429898927824876,
              "Size": 0.8011218182906273,
              "Ingredient Count": 0.5,
              "Circularity": 0.00006589527733278549
          }
      },
      "crustType": "tradicional",
      "tId": 41,
      "vidId": "632cdc05d7fcdf5eb72b3c17",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 18,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_19:52:19_v9p1-ssd-dense-pizzahut-custom-snap-class_id0044(None)-fr4443-09:15.jpg",
      "ts": "2022-09-22T22:01:34.000Z",
      "legend": "17:01:34 2022/09/22",
      "specialty": "Española",
      "category": "espanola",
      "score": "8.04",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 8.037500000000001,
          "score_details": {
              "Ingredient Distrib": 9.3,
              "Size": 6.8,
              "Ingredient Count": 6.050000000000001,
              "Circularity": 10
          },
          "specialty_check": true,
          "score_final": 8.037500000000001,
          "raw": {
              "Ingredient Distrib": 0.6125784888029141,
              "Size": 0.796924696886776,
              "Ingredient Count": 0.430063424628642,
              "Circularity": 0.00007695812646801795
          }
      },
      "crustType": "tradicional",
      "tId": 44,
      "vidId": "632cea4786710992cee829d2",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 19,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_20:02:19_v9p1-ssd-dense-pizzahut-custom-snap-class_id0045(None)-fr1083-02:15.jpg",
      "ts": "2022-09-22T22:04:34.000Z",
      "legend": "17:04:34 2022/09/22",
      "specialty": "Pepperoni",
      "category": "pepperoni",
      "score": "8.18",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 8.175,
          "score_details": {
              "Ingredient Distrib": 10,
              "Size": 6,
              "Ingredient Count": 10,
              "Circularity": 6.7
          },
          "specialty_check": false,
          "score_final": 8.175,
          "raw": {
              "Ingredient Distrib": 0.6490243130197137,
              "Size": 0.7460457888735605,
              "Ingredient Count": 0.8611111111111112,
              "Circularity": 0.0001513738803374286
          }
      },
      "crustType": "tradicional",
      "tId": 45,
      "vidId": "632ceca64371d8f844c417f9",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 20,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_20:02:19_v9p1-ssd-dense-pizzahut-custom-snap-class_id0046(None)-fr3232-06:44.jpg",
      "ts": "2022-09-22T22:09:03.000Z",
      "legend": "17:09:03 2022/09/22",
      "specialty": "Pepperoni",
      "category": "pepperoni",
      "score": "9.06",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 9.06,
          "score_details": {
              "Ingredient Distrib": 10,
              "Size": 6.24,
              "Ingredient Count": 10,
              "Circularity": 10
          },
          "specialty_check": true,
          "score_final": 9.06,
          "raw": {
              "Ingredient Distrib": 0.716335447406462,
              "Size": 0.7637070171505487,
              "Ingredient Count": 1,
              "Circularity": 0.00007091451429262593
          }
      },
      "crustType": "tradicional",
      "tId": 46,
      "vidId": "632ceca64371d8f844c417f9",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 21,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_20:12:20_v9p1-ssd-dense-pizzahut-custom-snap-class_id0047(None)-fr2996-06:14.jpg",
      "ts": "2022-09-22T22:18:34.000Z",
      "legend": "17:18:34 2022/09/22",
      "specialty": "Pepperoni",
      "category": "pepperoni",
      "score": "8.86",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 8.86,
          "score_details": {
              "Ingredient Distrib": 10,
              "Size": 6.04,
              "Ingredient Count": 10,
              "Circularity": 9.4
          },
          "specialty_check": true,
          "score_final": 8.86,
          "raw": {
              "Ingredient Distrib": 0.6970395677448253,
              "Size": 0.7521661765131222,
              "Ingredient Count": 0.8333333333333334,
              "Circularity": 0.00009288967465287549
          }
      },
      "crustType": "tradicional",
      "tId": 47,
      "vidId": "632cef054069f0017351b243",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 22,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_20:42:22_v9p1-ssd-dense-pizzahut-custom-snap-class_id0048(None)-fr4387-09:08.jpg",
      "ts": "2022-09-22T22:51:30.000Z",
      "legend": "17:51:30 2022/09/22",
      "specialty": "Pepperoni",
      "category": "pepperoni",
      "score": "9.05",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 9.055,
          "score_details": {
              "Ingredient Distrib": 10,
              "Size": 6.32,
              "Ingredient Count": 10,
              "Circularity": 9.9
          },
          "specialty_check": true,
          "score_final": 9.055,
          "raw": {
              "Ingredient Distrib": 0.6950768364361358,
              "Size": 0.7682323494698671,
              "Ingredient Count": 1,
              "Circularity": 0.0000823271779715972
          }
      },
      "crustType": "tradicional",
      "tId": 48,
      "vidId": "632cf624ef73fb5813c755bd",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 23,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_20:42:22_v9p1-ssd-dense-pizzahut-custom-snap-class_id0049(None)-fr4719-09:50.jpg",
      "ts": "2022-09-22T22:52:12.000Z",
      "legend": "17:52:12 2022/09/22",
      "specialty": "Pepperoni",
      "category": "pepperoni",
      "score": "9.73",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 9.73,
          "score_details": {
              "Ingredient Distrib": 10,
              "Size": 8.92,
              "Ingredient Count": 10,
              "Circularity": 10
          },
          "specialty_check": true,
          "score_final": 9.73,
          "raw": {
              "Ingredient Distrib": 0.7213567815554274,
              "Size": 0.9248797114386675,
              "Ingredient Count": 0.9722222222222222,
              "Circularity": 0.00007433863960669769
          }
      },
      "crustType": "tradicional",
      "tId": 49,
      "vidId": "632cf624ef73fb5813c755bd",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "2 Halves",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 24,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_20:52:22_v9p1-ssd-dense-pizzahut-custom-snap-class_id0050(None)-fr0279-00:35.jpg",
      "ts": "2022-09-22T22:52:57.000Z",
      "legend": "17:52:57 2022/09/22",
      "specialty": "Pepperoni",
      "category": "pepperoni",
      "score": "9.09",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 9.09,
          "score_details": {
              "Ingredient Distrib": 10,
              "Size": 6.36,
              "Ingredient Count": 10,
              "Circularity": 10
          },
          "specialty_check": true,
          "score_final": 9.09,
          "raw": {
              "Ingredient Distrib": 0.6804243742672706,
              "Size": 0.7709388462993055,
              "Ingredient Count": 1,
              "Circularity": 0.00007139332552239158
          }
      },
      "crustType": "tradicional",
      "tId": 50,
      "vidId": "632cf8838086a7ff9898f3a9",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 25,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_20:52:22_v9p1-ssd-dense-pizzahut-custom-snap-class_id0051(None)-fr2408-05:01.jpg",
      "ts": "2022-09-22T22:57:23.000Z",
      "legend": "17:57:23 2022/09/22",
      "specialty": "4 Estaciones",
      "category": "4_estaciones",
      "score": "8.02",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 8.02,
          "score_details": {
              "Ingredient Distrib": 3.866666666666667,
              "Size": 8.68,
              "Ingredient Count": 9.533333333333333,
              "Circularity": 10
          },
          "specialty_check": false,
          "score_final": 8.02,
          "raw": {
              "Ingredient Distrib": 0.3130812628388418,
              "Size": 0.9100281675137837,
              "Ingredient Count": 0.7962184873949579,
              "Circularity": 0.00006631913351014142
          }
      },
      "crustType": "tradicional",
      "tId": 51,
      "vidId": "632cf8838086a7ff9898f3a9",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  },
  {
      "index": 26,
      "imgLink": "http://localhost:9791/static/from_edge/PizzaHutVitacura/2022-09-22/j4_2022-09-22_20:52:22_v9p1-ssd-dense-pizzahut-custom-snap-class_id0052(None)-fr2744-05:43.jpg",
      "ts": "2022-09-22T22:58:05.000Z",
      "legend": "17:58:05 2022/09/22",
      "specialty": "4 Estaciones",
      "category": "4_estaciones",
      "score": "6.30",
      "kwali_score": true,
      "evaluationResult": {
          "score_adjusted": 6.303333333333334,
          "score_details": {
              "Ingredient Distrib": 4.066666666666667,
              "Size": 6.28,
              "Ingredient Count": 9.266666666666667,
              "Circularity": 5.6
          },
          "specialty_check": false,
          "score_final": 6.303333333333334,
          "raw": {
              "Ingredient Distrib": 0.3224573328001114,
              "Size": 0.7678506799583201,
              "Ingredient Count": 0.8004201680672268,
              "Circularity": 0.0001745509232217154
          }
      },
      "crustType": "tradicional",
      "tId": 52,
      "vidId": "632cf8838086a7ff9898f3a9",
      "pipeId": "v9p1-ssd-dense-pizzahut-custom-snap-class",
      "split": 1,
      "omitSoft": true,
      "rawScore": false,
      "gtExtra": "",
      "gtOptimalFrame": "",
      "gtCategory": "",
      "gtProduct": "",
      "gtCrust": "",
      "gtSnapQuality": "",
      "gtScoreCriteria": ""
  }
]

const SCORES = [
  "8.60",
  "7.90",
  "8.00",
  "8.30",
  "8.00",
  "9.57",
  "8.50",
  "9.60",
  "8.90",
  "8.20",
  "7.94",
  "9.05",
  "8.20",
  "9.63",
  "8.30",
  "8.30",
  "7.25",
  "7.94",
  "8.50",
  "7.89",
  "8.95",
  "8.87",
  "7.42",
  "7.33",
  "8.12",
  "7.69",
  "7.70",
  "9.80",
  "9.90",
  "8.70",
  "8.81",
  "9.38",
  "8.96",
  "7.70",
  "9.50",
  "8.70",
  "9.22",
  "8.80",
  "8.97",
  "9.00",
  "6.70",
  "8.40",
  "8.10",
  "8.70",
  "9.30",
  "8.20",
  "8.25",
  "8.70",
  "9.50",
  "8.90",
  "8.50",
  "8.52",
  "9.47",
  "8.50",
  "7.90",
  "7.38",
  "7.50",
  "9.51",
  "9.44",
  "8.30",
  "9.70",
  "6.68",
  "7.70",
  "8.98",
  "8.40",
  "8.25",
  "7.55",
  "9.10",
  "8.44",
  "7.00",
  "9.00",
  "8.33",
  "8.60",
  "7.68"
]

const REPORT_TEST_DATA = {
  "performance_by_score": {
    "0.25": 0,
    "0.5": 0,
    "2.0": 0,
    "3.0": 6,
    "4.75": 149,
    "5.0": 132,
    "0.75": 0,
    "1.5": 0,
    "8.0": 290,
    "9.0": 193,
    "1.25": 0,
    "2.75": 0,
    "9.25": 131,
    "2.5": 0,
    "8.75": 189,
    "3.25": 20,
    "5.75": 46,
    "7.25": 134,
    "4.5": 226,
    "5.5": 56,
    "7.75": 224,
    "5.25": 67,
    "3.75": 68,
    "3.5": 46,
    "9.75": 38,
    "6.5": 55,
    "1.0": 0,
    "6.0": 48,
    "7.0": 97,
    "6.75": 70,
    "8.25": 238,
    "10.0": 16,
    "1.75": 0,
    "8.5": 244,
    "4.25": 182,
    "2.25": 0,
    "7.5": 180,
    "6.25": 73,
    "9.5": 97,
    "4.0": 183
  },
  "last_excellent_pizzas": {
    "290": [
      {
        "specialty": "pepperoni",
        "datetime": "11-01-2022 09:50:53PM",
        "crust": "tradicional",
        "score": "7.55"
      },
      {
        "specialty": "extra_cheesy_alfredo",
        "datetime": "11-01-2022 09:30:52PM",
        "crust": "delgada",
        "score": "7.3"
      },
      {
        "specialty": "extra_cheesy_alfredo",
        "datetime": "11-01-2022 09:20:51PM",
        "crust": "delgada",
        "score": "7.4"
      },
      {
        "specialty": "extra_cheesy_alfredo",
        "datetime": "11-01-2022 09:10:50PM",
        "crust": "delgada",
        "score": "8.5"
      },
      {
        "specialty": "pepperoni",
        "datetime": "11-01-2022 09:10:50PM",
        "crust": "delgada",
        "score": "7.3"
      },
      {
        "specialty": "pepperoni",
        "datetime": "11-01-2022 09:10:50PM",
        "crust": "delgada",
        "score": "8.95"
      },
      {
        "specialty": "pepperoni",
        "datetime": "11-01-2022 09:10:50PM",
        "crust": "delgada",
        "score": "8.65"
      },
      {
        "specialty": "pepperoni",
        "datetime": "11-01-2022 09:00:50PM",
        "crust": "delgada",
        "score": "8.45"
      },
      {
        "specialty": "extra_cheesy_alfredo",
        "datetime": "11-01-2022 09:00:50PM",
        "crust": "tradicional",
        "score": "8.2"
      },
      {
        "specialty": "extra_cheesy_alfredo",
        "datetime": "11-01-2022 09:00:50PM",
        "crust": "delgada",
        "score": "7.6"
      }
    ]
  },
  "data_monthly": {
    "12-2021": {
      "excellent": 1207,
      "avg_score": "6.64",
      "bads": 468,
      "goods": 585
    },
    "11-2021": {
      "excellent": 41,
      "avg_score": "6.84",
      "bads": 10,
      "goods": 18
    },
    "01-2022": {
      "excellent": 741,
      "avg_score": "7.01",
      "bads": 176,
      "goods": 252
    }
  },
  "performance_by_shift": {
    "Wednesday": {
      "PM": {
        "tradicional": {
          "meats": {
            "bads": 34,
            "excellent": 0,
            "goods": 15
          },
          "extra_cheesy_alfredo": {
            "bads": 0,
            "excellent": 32,
            "goods": 1
          },
          "works": {
            "bads": 10,
            "excellent": 0,
            "goods": 18
          },
          "bbq_chicken_bacon": {
            "bads": 10,
            "excellent": 0,
            "goods": 18
          },
          "garden_fresh": {
            "bads": 2,
            "excellent": 0,
            "goods": 4
          },
          "super_hawaiian": {
            "bads": 1,
            "excellent": 0,
            "goods": 1
          },
          "pepperoni": {
            "bads": 0,
            "excellent": 95,
            "goods": 26
          }
        },
        "delgada": {
          "meats": {
            "bads": 9,
            "excellent": 0,
            "goods": 5
          },
          "extra_cheesy_alfredo": {
            "bads": 0,
            "excellent": 32,
            "goods": 0
          },
          "works": {
            "bads": 9,
            "excellent": 0,
            "goods": 14
          },
          "bbq_chicken_bacon": {
            "bads": 6,
            "excellent": 0,
            "goods": 5
          },
          "garden_fresh": {
            "bads": 2,
            "excellent": 0,
            "goods": 3
          },
          "pepperoni": {
            "bads": 0,
            "excellent": 69,
            "goods": 18
          }
        }
      }
    },
    "Saturday": {
      "AM": {
        "tradicional": {
          "extra_cheesy_alfredo": {
            "bads": 0,
            "excellent": 3,
            "goods": 0
          },
          "meats": {
            "bads": 1,
            "excellent": 0,
            "goods": 0
          },
          "pepperoni": {
            "bads": 0,
            "excellent": 2,
            "goods": 0
          }
        },
        "delgada": {
          "extra_cheesy_alfredo": {
            "bads": 0,
            "excellent": 4,
            "goods": 0
          },
          "bbq_chicken_bacon": {
            "bads": 0,
            "excellent": 0,
            "goods": 1
          },
          "pepperoni": {
            "bads": 0,
            "excellent": 1,
            "goods": 0
          }
        }
      },
      "PM": {
        "tradicional": {
          "meats": {
            "bads": 30,
            "excellent": 0,
            "goods": 13
          },
          "cheese": {
            "bads": 0,
            "excellent": 1,
            "goods": 0
          },
          "meatball_pepperoni": {
            "bads": 1,
            "excellent": 0,
            "goods": 0
          },
          "extra_cheesy_alfredo": {
            "bads": 0,
            "excellent": 42,
            "goods": 0
          },
          "works": {
            "bads": 5,
            "excellent": 0,
            "goods": 10
          },
          "bbq_chicken_bacon": {
            "bads": 12,
            "excellent": 0,
            "goods": 8
          },
          "garden_fresh": {
            "bads": 4,
            "excellent": 0,
            "goods": 3
          },
          "super_hawaiian": {
            "bads": 4,
            "excellent": 0,
            "goods": 1
          },
          "pepperoni": {
            "bads": 1,
            "excellent": 130,
            "goods": 23
          }
        },
        "delgada": {
          "meats": {
            "bads": 28,
            "excellent": 0,
            "goods": 4
          },
          "extra_cheesy_alfredo": {
            "bads": 0,
            "excellent": 44,
            "goods": 1
          },
          "works": {
            "bads": 12,
            "excellent": 0,
            "goods": 8
          },
          "bbq_chicken_bacon": {
            "bads": 1,
            "excellent": 0,
            "goods": 5
          },
          "garden_fresh": {
            "bads": 2,
            "excellent": 0,
            "goods": 3
          },
          "super_hawaiian": {
            "bads": 1,
            "excellent": 0,
            "goods": 0
          },
          "pepperoni": {
            "bads": 0,
            "excellent": 97,
            "goods": 27
          }
        }
      }
    },
    "Monday": {
      "PM": {
        "tradicional": {
          "philly_cheesesteak": {
            "bads": 0,
            "excellent": 0,
            "goods": 1
          },
          "meats": {
            "bads": 31,
            "excellent": 0,
            "goods": 10
          },
          "cheese": {
            "bads": 0,
            "excellent": 5,
            "goods": 0
          },
          "extra_cheesy_alfredo": {
            "bads": 0,
            "excellent": 47,
            "goods": 1
          },
          "works": {
            "bads": 7,
            "excellent": 0,
            "goods": 15
          },
          "bbq_chicken_bacon": {
            "bads": 5,
            "excellent": 2,
            "goods": 16
          },
          "garden_fresh": {
            "bads": 4,
            "excellent": 0,
            "goods": 1
          },
          "super_hawaiian": {
            "bads": 1,
            "excellent": 0,
            "goods": 3
          },
          "pepperoni": {
            "bads": 0,
            "excellent": 124,
            "goods": 30
          }
        },
        "delgada": {
          "meats": {
            "bads": 30,
            "excellent": 0,
            "goods": 4
          },
          "extra_cheesy_alfredo": {
            "bads": 0,
            "excellent": 48,
            "goods": 1
          },
          "works": {
            "bads": 11,
            "excellent": 0,
            "goods": 21
          },
          "bbq_chicken_bacon": {
            "bads": 0,
            "excellent": 0,
            "goods": 8
          },
          "garden_fresh": {
            "bads": 3,
            "excellent": 0,
            "goods": 1
          },
          "super_hawaiian": {
            "bads": 0,
            "excellent": 0,
            "goods": 1
          },
          "pepperoni": {
            "bads": 1,
            "excellent": 122,
            "goods": 29
          }
        }
      }
    },
    "Thursday": {
      "PM": {
        "tradicional": {
          "philly_cheesesteak": {
            "bads": 0,
            "excellent": 0,
            "goods": 1
          },
          "meats": {
            "bads": 28,
            "excellent": 0,
            "goods": 23
          },
          "ultimate_pepperoni": {
            "bads": 0,
            "excellent": 0,
            "goods": 1
          },
          "extra_cheesy_alfredo": {
            "bads": 0,
            "excellent": 48,
            "goods": 0
          },
          "works": {
            "bads": 8,
            "excellent": 0,
            "goods": 9
          },
          "bbq_chicken_bacon": {
            "bads": 9,
            "excellent": 0,
            "goods": 11
          },
          "garden_fresh": {
            "bads": 1,
            "excellent": 0,
            "goods": 0
          },
          "cheese": {
            "bads": 0,
            "excellent": 1,
            "goods": 0
          },
          "pepperoni": {
            "bads": 0,
            "excellent": 113,
            "goods": 22
          }
        },
        "delgada": {
          "philly_cheesesteak": {
            "bads": 1,
            "excellent": 0,
            "goods": 0
          },
          "meats": {
            "bads": 22,
            "excellent": 0,
            "goods": 5
          },
          "extra_cheesy_alfredo": {
            "bads": 0,
            "excellent": 22,
            "goods": 0
          },
          "works": {
            "bads": 9,
            "excellent": 0,
            "goods": 13
          },
          "bbq_chicken_bacon": {
            "bads": 8,
            "excellent": 0,
            "goods": 7
          },
          "garden_fresh": {
            "bads": 3,
            "excellent": 0,
            "goods": 3
          },
          "super_hawaiian": {
            "bads": 1,
            "excellent": 0,
            "goods": 1
          },
          "pepperoni": {
            "bads": 1,
            "excellent": 83,
            "goods": 21
          }
        }
      }
    },
    "Friday": {
      "PM": {
        "tradicional": {
          "ultimate_pepperoni": {
            "bads": 0,
            "excellent": 0,
            "goods": 1
          },
          "meats": {
            "bads": 33,
            "excellent": 0,
            "goods": 17
          },
          "cheese": {
            "bads": 0,
            "excellent": 3,
            "goods": 0
          },
          "extra_cheesy_alfredo": {
            "bads": 0,
            "excellent": 59,
            "goods": 0
          },
          "works": {
            "bads": 9,
            "excellent": 0,
            "goods": 23
          },
          "bbq_chicken_bacon": {
            "bads": 13,
            "excellent": 0,
            "goods": 14
          },
          "philly_cheesesteak": {
            "bads": 0,
            "excellent": 0,
            "goods": 1
          },
          "garden_fresh": {
            "bads": 8,
            "excellent": 0,
            "goods": 1
          },
          "super_hawaiian": {
            "bads": 2,
            "excellent": 0,
            "goods": 2
          },
          "pepperoni": {
            "bads": 1,
            "excellent": 121,
            "goods": 35
          }
        },
        "delgada": {
          "philly_cheesesteak": {
            "bads": 0,
            "excellent": 0,
            "goods": 2
          },
          "meats": {
            "bads": 17,
            "excellent": 0,
            "goods": 8
          },
          "ultimate_pepperoni": {
            "bads": 0,
            "excellent": 1,
            "goods": 0
          },
          "extra_cheesy_alfredo": {
            "bads": 0,
            "excellent": 39,
            "goods": 0
          },
          "works": {
            "bads": 10,
            "excellent": 0,
            "goods": 14
          },
          "bbq_chicken_bacon": {
            "bads": 2,
            "excellent": 0,
            "goods": 2
          },
          "garden_fresh": {
            "bads": 4,
            "excellent": 0,
            "goods": 3
          },
          "super_hawaiian": {
            "bads": 1,
            "excellent": 0,
            "goods": 0
          },
          "pepperoni": {
            "bads": 2,
            "excellent": 113,
            "goods": 27
          }
        }
      }
    },
    "Tuesday": {
      "PM": {
        "tradicional": {
          "meats": {
            "bads": 16,
            "excellent": 0,
            "goods": 2
          },
          "cheese": {
            "bads": 0,
            "excellent": 1,
            "goods": 0
          },
          "extra_cheesy_alfredo": {
            "bads": 0,
            "excellent": 31,
            "goods": 0
          },
          "works": {
            "bads": 9,
            "excellent": 0,
            "goods": 12
          },
          "bbq_chicken_bacon": {
            "bads": 9,
            "excellent": 0,
            "goods": 8
          },
          "garden_fresh": {
            "bads": 6,
            "excellent": 0,
            "goods": 2
          },
          "super_hawaiian": {
            "bads": 0,
            "excellent": 0,
            "goods": 3
          },
          "pepperoni": {
            "bads": 0,
            "excellent": 67,
            "goods": 15
          }
        },
        "delgada": {
          "meats": {
            "bads": 12,
            "excellent": 0,
            "goods": 1
          },
          "extra_cheesy_alfredo": {
            "bads": 0,
            "excellent": 35,
            "goods": 0
          },
          "works": {
            "bads": 10,
            "excellent": 0,
            "goods": 4
          },
          "bbq_chicken_bacon": {
            "bads": 2,
            "excellent": 0,
            "goods": 5
          },
          "garden_fresh": {
            "bads": 3,
            "excellent": 0,
            "goods": 0
          },
          "pepperoni": {
            "bads": 3,
            "excellent": 70,
            "goods": 11
          }
        }
      }
    },
    "Sunday": {
      "AM": {
        "tradicional": {
          "extra_cheesy_alfredo": {
            "bads": 0,
            "excellent": 2,
            "goods": 0
          },
          "meats": {
            "bads": 1,
            "excellent": 0,
            "goods": 0
          },
          "pepperoni": {
            "bads": 0,
            "excellent": 3,
            "goods": 1
          }
        },
        "delgada": {
          "extra_cheesy_alfredo": {
            "bads": 0,
            "excellent": 0,
            "goods": 1
          },
          "pepperoni": {
            "bads": 0,
            "excellent": 4,
            "goods": 0
          }
        }
      },
      "PM": {
        "tradicional": {
          "philly_cheesesteak": {
            "bads": 1,
            "excellent": 0,
            "goods": 1
          },
          "meats": {
            "bads": 30,
            "excellent": 0,
            "goods": 18
          },
          "extra_cheesy_alfredo": {
            "bads": 0,
            "excellent": 37,
            "goods": 0
          },
          "works": {
            "bads": 9,
            "excellent": 0,
            "goods": 16
          },
          "bbq_chicken_bacon": {
            "bads": 9,
            "excellent": 0,
            "goods": 16
          },
          "garden_fresh": {
            "bads": 4,
            "excellent": 0,
            "goods": 0
          },
          "super_hawaiian": {
            "bads": 3,
            "excellent": 0,
            "goods": 3
          },
          "pepperoni": {
            "bads": 3,
            "excellent": 103,
            "goods": 38
          }
        },
        "delgada": {
          "meats": {
            "bads": 30,
            "excellent": 0,
            "goods": 12
          },
          "cheese": {
            "bads": 0,
            "excellent": 1,
            "goods": 0
          },
          "extra_cheesy_alfredo": {
            "bads": 0,
            "excellent": 37,
            "goods": 1
          },
          "works": {
            "bads": 10,
            "excellent": 0,
            "goods": 14
          },
          "bbq_chicken_bacon": {
            "bads": 9,
            "excellent": 1,
            "goods": 3
          },
          "garden_fresh": {
            "bads": 2,
            "excellent": 0,
            "goods": 3
          },
          "super_hawaiian": {
            "bads": 2,
            "excellent": 0,
            "goods": 1
          },
          "pepperoni": {
            "bads": 0,
            "excellent": 94,
            "goods": 20
          }
        }
      }
    }
  },
  "data_weekly": {
    "Wednesday": {
      "01 PM": {
        "bads": 2,
        "excellent": 2,
        "goods": 3
      },
      "09 PM": {
        "bads": 19,
        "excellent": 41,
        "goods": 31
      },
      "05 PM": {
        "bads": 2,
        "excellent": 8,
        "goods": 5
      },
      "08 PM": {
        "bads": 18,
        "excellent": 55,
        "goods": 20
      },
      "04 PM": {
        "bads": 4,
        "excellent": 12,
        "goods": 3
      },
      "07 PM": {
        "bads": 6,
        "excellent": 29,
        "goods": 10
      },
      "11 PM": {
        "bads": 6,
        "excellent": 20,
        "goods": 14
      },
      "03 PM": {
        "bads": 6,
        "excellent": 12,
        "goods": 4
      },
      "02 PM": {
        "bads": 2,
        "excellent": 11,
        "goods": 7
      },
      "10 PM": {
        "bads": 11,
        "excellent": 34,
        "goods": 25
      },
      "06 PM": {
        "bads": 7,
        "excellent": 4,
        "goods": 6
      }
    },
    "Saturday": {
      "12 AM": {
        "bads": 1,
        "excellent": 10,
        "goods": 1
      },
      "09 PM": {
        "bads": 18,
        "excellent": 54,
        "goods": 17
      },
      "06 PM": {
        "bads": 5,
        "excellent": 20,
        "goods": 5
      },
      "08 PM": {
        "bads": 13,
        "excellent": 54,
        "goods": 13
      },
      "11 PM": {
        "bads": 9,
        "excellent": 25,
        "goods": 7
      },
      "04 PM": {
        "bads": 6,
        "excellent": 23,
        "goods": 7
      },
      "02 PM": {
        "bads": 5,
        "excellent": 14,
        "goods": 4
      },
      "01 PM": {
        "bads": 3,
        "excellent": 23,
        "goods": 5
      },
      "05 PM": {
        "bads": 8,
        "excellent": 17,
        "goods": 3
      },
      "10 PM": {
        "bads": 17,
        "excellent": 48,
        "goods": 17
      },
      "07 PM": {
        "bads": 12,
        "excellent": 25,
        "goods": 18
      },
      "03 PM": {
        "bads": 5,
        "excellent": 11,
        "goods": 10
      }
    },
    "Monday": {
      "01 PM": {
        "bads": 2,
        "excellent": 4,
        "goods": 2
      },
      "09 PM": {
        "bads": 20,
        "excellent": 73,
        "goods": 33
      },
      "05 PM": {
        "bads": 2,
        "excellent": 18,
        "goods": 10
      },
      "08 PM": {
        "bads": 26,
        "excellent": 74,
        "goods": 25
      },
      "04 PM": {
        "bads": 4,
        "excellent": 19,
        "goods": 7
      },
      "07 PM": {
        "bads": 10,
        "excellent": 43,
        "goods": 12
      },
      "11 PM": {
        "bads": 3,
        "excellent": 15,
        "goods": 9
      },
      "03 PM": {
        "bads": 4,
        "excellent": 16,
        "goods": 9
      },
      "02 PM": {
        "bads": 3,
        "excellent": 12,
        "goods": 7
      },
      "10 PM": {
        "bads": 14,
        "excellent": 54,
        "goods": 23
      },
      "06 PM": {
        "bads": 5,
        "excellent": 20,
        "goods": 5
      }
    },
    "Thursday": {
      "01 PM": {
        "bads": 5,
        "excellent": 5,
        "goods": 2
      },
      "09 PM": {
        "bads": 19,
        "excellent": 37,
        "goods": 21
      },
      "05 PM": {
        "bads": 2,
        "excellent": 26,
        "goods": 5
      },
      "08 PM": {
        "bads": 13,
        "excellent": 39,
        "goods": 27
      },
      "04 PM": {
        "bads": 4,
        "excellent": 8,
        "goods": 4
      },
      "07 PM": {
        "bads": 11,
        "excellent": 41,
        "goods": 14
      },
      "11 PM": {
        "bads": 7,
        "excellent": 22,
        "goods": 8
      },
      "03 PM": {
        "bads": 1,
        "excellent": 11,
        "goods": 7
      },
      "02 PM": {
        "bads": 8,
        "excellent": 7,
        "goods": 7
      },
      "10 PM": {
        "bads": 20,
        "excellent": 49,
        "goods": 19
      },
      "06 PM": {
        "bads": 1,
        "excellent": 22,
        "goods": 3
      }
    },
    "Friday": {
      "01 PM": {
        "bads": 1,
        "excellent": 7,
        "goods": 1
      },
      "09 PM": {
        "bads": 12,
        "excellent": 60,
        "goods": 45
      },
      "05 PM": {
        "bads": 1,
        "excellent": 15,
        "goods": 3
      },
      "08 PM": {
        "bads": 25,
        "excellent": 69,
        "goods": 18
      },
      "04 PM": {
        "bads": 9,
        "excellent": 10,
        "goods": 6
      },
      "07 PM": {
        "bads": 17,
        "excellent": 39,
        "goods": 16
      },
      "11 PM": {
        "bads": 8,
        "excellent": 50,
        "goods": 15
      },
      "03 PM": {
        "bads": 3,
        "excellent": 9,
        "goods": 4
      },
      "02 PM": {
        "bads": 7,
        "excellent": 15,
        "goods": 5
      },
      "10 PM": {
        "bads": 18,
        "excellent": 44,
        "goods": 32
      },
      "06 PM": {
        "bads": 1,
        "excellent": 18,
        "goods": 5
      }
    },
    "Tuesday": {
      "01 PM": {
        "bads": 1,
        "excellent": 2,
        "goods": 0
      },
      "09 PM": {
        "bads": 15,
        "excellent": 43,
        "goods": 12
      },
      "05 PM": {
        "bads": 7,
        "excellent": 10,
        "goods": 1
      },
      "06 PM": {
        "bads": 2,
        "excellent": 9,
        "goods": 3
      },
      "04 PM": {
        "bads": 3,
        "excellent": 7,
        "goods": 4
      },
      "07 PM": {
        "bads": 12,
        "excellent": 27,
        "goods": 2
      },
      "08 PM": {
        "bads": 9,
        "excellent": 46,
        "goods": 23
      },
      "03 PM": {
        "bads": 8,
        "excellent": 13,
        "goods": 1
      },
      "11 PM": {
        "bads": 1,
        "excellent": 10,
        "goods": 8
      },
      "10 PM": {
        "bads": 10,
        "excellent": 25,
        "goods": 9
      },
      "02 PM": {
        "bads": 2,
        "excellent": 12,
        "goods": 0
      }
    },
    "Sunday": {
      "01 PM": {
        "bads": 1,
        "excellent": 4,
        "goods": 2
      },
      "09 PM": {
        "bads": 20,
        "excellent": 49,
        "goods": 28
      },
      "06 PM": {
        "bads": 11,
        "excellent": 26,
        "goods": 14
      },
      "08 PM": {
        "bads": 18,
        "excellent": 62,
        "goods": 14
      },
      "11 PM": {
        "bads": 6,
        "excellent": 17,
        "goods": 7
      },
      "04 PM": {
        "bads": 3,
        "excellent": 20,
        "goods": 16
      },
      "02 PM": {
        "bads": 6,
        "excellent": 12,
        "goods": 6
      },
      "12 AM": {
        "bads": 1,
        "excellent": 9,
        "goods": 2
      },
      "05 PM": {
        "bads": 4,
        "excellent": 6,
        "goods": 8
      },
      "10 PM": {
        "bads": 25,
        "excellent": 38,
        "goods": 21
      },
      "07 PM": {
        "bads": 12,
        "excellent": 21,
        "goods": 19
      },
      "03 PM": {
        "bads": 6,
        "excellent": 18,
        "goods": 11
      }
    }
  },
  "performance_by_specialty": {
    "tradicional": {
      "philly_cheesesteak": {
        "avg_score": "4.78"
      },
      "ultimate_pepperoni": {
        "avg_score": "5.97"
      },
      "meats": {
        "avg_score": "4.23"
      },
      "cheese": {
        "avg_score": "8.17"
      },
      "meatball_pepperoni": {
        "avg_score": "3.95"
      },
      "extra_cheesy_alfredo": {
        "avg_score": "8.14"
      },
      "works": {
        "avg_score": "4.61"
      },
      "bbq_chicken_bacon": {
        "avg_score": "4.84"
      },
      "garden_fresh": {
        "avg_score": "4.23"
      },
      "super_hawaiian": {
        "avg_score": "4.5"
      },
      "pepperoni": {
        "avg_score": "7.84"
      }
    },
    "delgada": {
      "philly_cheesesteak": {
        "avg_score": "4.63"
      },
      "meats": {
        "avg_score": "4.11"
      },
      "ultimate_pepperoni": {
        "avg_score": "7.0"
      },
      "cheese": {
        "avg_score": "7.7"
      },
      "extra_cheesy_alfredo": {
        "avg_score": "7.95"
      },
      "works": {
        "avg_score": "4.49"
      },
      "bbq_chicken_bacon": {
        "avg_score": "4.84"
      },
      "garden_fresh": {
        "avg_score": "4.33"
      },
      "super_hawaiian": {
        "avg_score": "4.17"
      },
      "pepperoni": {
        "avg_score": "7.9"
      }
    }
  }
}

export {
  TEMP_IMG_URL,
  BASE_POINTS,
  NON_PEPPERONI_ING_ADJUST,
  PEPPERONI_SPECS,
  TEST_DATA,
  PIZZA_SPEC,
  UI_TEXT,
  CARDS,
  SCORES,
  REPORT_TEST_DATA
}