import { getSpecialtiesByLanguage } from '../Constants';


const toSpecialtyName = (k, language) => {
  if (k === 'unknown') return 'Custom';
  if (k in getSpecialtiesByLanguage(language)) return getSpecialtiesByLanguage(language)[k];
  return null;
}

const getSpecialtyDisplayName = (category, language) => {
  let specialtyText = toSpecialtyName(category, language) || category;
  if (category === 'cat_pepperoni') specialtyText = 'Super Pepperoni';
  return specialtyText;
}

const setSpecialtiesOptions = (cardsFilters, pizzaSpec, language) => {
  let temp = [];
  
  if (((process.env.REACT_APP_DEMO || 'false').toLowerCase() === 'false')) {
    for (const key of Object.keys(pizzaSpec)) {
      if (pizzaSpec[key].id === 'unknown') continue;
      temp.push({ name: toSpecialtyName(key, language), value: getSpecialtyDisplayName(pizzaSpec[key].id, language), db_value: pizzaSpec[key].id });
    }
  }
  temp.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
  if (((process.env.REACT_APP_DEMO || 'false').toLowerCase() === 'false')) {
    cardsFilters['All specialties'].optionsList = temp;
  }
  return cardsFilters;
}

const buildFilters = (cardsFilters) => {
  let temp = [];
  for (const key of Object.keys(cardsFilters)) {
    temp.push(cardsFilters[key])
  }
  return temp;
}

export {
  getSpecialtyDisplayName,
  buildFilters,
  setSpecialtiesOptions
}