import { FetchService } from '../../../services/Api';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import Heatmap from '../heatmap/Heatmap';
import { useChartRendering } from '../../../hooks/useChartRendering';
import { HEATMAP_COLORSCALE } from '../Aux';
import reportStyle from '../Report.module.css';


const VenueBGEPizzasDistribution = (props) => {
  const { url, selectedVenue, title, subTitle, qualitynMetric, twinHeatmapArea, twinPointer, twinChartData, setTwinHeatmapArea, setTwinPointer, setTwinChartData, data } = props;
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const [completedUrl, setCompletedUrl] = useState(undefined);
  const [rawData, setRawData] = useState(undefined);
  const [chartData, setChartData] = useState(undefined);

  useChartRendering(chartData, props.onRendering);

  useEffect(() => {
    if (data !== undefined) setRawData(data.weekly_distribution);
    else setChartData(undefined);
  }, [data])

  const pointerRef = useRef(null);
  const [pointer, setPointer] = useState(undefined);
  const [heatmapArea, setHeatmapArea] = useState(undefined);

  useEffect(() => {
    if (setTwinHeatmapArea) setTwinHeatmapArea(heatmapArea);
  }, [heatmapArea, setTwinHeatmapArea])

  const hideTooltip = useCallback((pointer) => {
    pointer.style.display = 'none'
  }, [])

  const displayTooltip = useCallback((x, y, heatmapArea, heatmapData, pointer) => {
    let dragAreaProps = heatmapArea.getBoundingClientRect();
    let tileWidth = dragAreaProps.width / heatmapData.x.length;
    let tileHeight = dragAreaProps.height / heatmapData.y.length;
    let coordX = Math.min(Math.floor(x / tileWidth), heatmapData.x.length - 1);
    let coordY = Math.min(Math.floor(y / tileHeight), heatmapData.y.length - 1);
    
    pointer.style.display = 'block';
    pointer.style.width = `${tileWidth}px`;
    pointer.style.height = `${tileHeight}px`;
    pointer.style.left = `${dragAreaProps.left + coordX * tileWidth}px`;
    pointer.style.top = `${(dragAreaProps.top + coordY * tileHeight)}px`;
    pointer.getElementsByClassName('tooltipText')[0].innerHTML = `
      # produced pizzas<br>
      (avg) = ${parseFloat(heatmapData.data[heatmapData.data.length - 1 - coordY][coordX])}`;
  }, [])

  useEffect(() => {
    if (pointerRef === undefined) return;
    setPointer(pointerRef.current);
    if (setTwinPointer) setTwinPointer(pointerRef.current);
  }, [pointerRef, setTwinPointer])

  useEffect(() => {
    const handleMouseLeave = (event) => {
      hideTooltip(pointer);
      if (twinPointer) hideTooltip(twinPointer)
    }

    if (!pointer || !hideTooltip) return;
    let tooltipTexts = pointer.getElementsByClassName('tooltipText');
    Array.from(tooltipTexts).forEach(function (element) {
      element.addEventListener('mouseenter', handleMouseLeave);
    });
    pointer.addEventListener('mouseleave', handleMouseLeave);
    return () => {
      pointer.removeEventListener('mouseleave', handleMouseLeave);
      Array.from(tooltipTexts).forEach(function (element) {
        element.removeEventListener('mouseenter', handleMouseLeave);
      });
    }
  }, [pointer, hideTooltip, twinPointer])

  useEffect(() => {
    const handleMouseMoveHeatmap = (event) => {
      let x = parseInt(event.clientX - heatmapArea.getBoundingClientRect().left);
      let y = parseInt(event.clientY - heatmapArea.getBoundingClientRect().top)
      displayTooltip(x, y, heatmapArea, chartData, pointer);
      if (twinHeatmapArea && twinChartData && twinPointer) {
        displayTooltip(x, y, twinHeatmapArea, twinChartData, twinPointer);
      }
    }

    const handleMouseLeave = (event) => {
      hideTooltip(pointer);
      if (twinPointer) hideTooltip(twinPointer)
    }

    if (!heatmapArea) return;
    heatmapArea.addEventListener('mousemove', handleMouseMoveHeatmap);
    document.addEventListener('scroll', handleMouseLeave);
    return () => {
      heatmapArea.removeEventListener('mousemove', handleMouseMoveHeatmap);
      document.removeEventListener('scroll', handleMouseLeave);
    };
  }, [heatmapArea, chartData, displayTooltip, hideTooltip, twinChartData, twinHeatmapArea, twinPointer, pointer])

  useEffect(() => {
    if (rawData === undefined) return;
    let x = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    let y = ['11 AM', '12 AM']
    for (let i = 1; i <= 12; ++i) {
      y.push(`${i.toString().padStart(2, '0')} PM`)
    }
    y = y.reverse()
    let data = [];
    let percentage = [];
    for (let hour of y) {
      data.push([]);
      percentage.push([]);
      for (let day of x) {
        data[data.length - 1].push(rawData[day] && rawData[day][hour] ? rawData[day][hour][`count_${qualitynMetric.toLowerCase()}s`] : 0)
        percentage[data.length - 1].push(rawData[day] && rawData[day][hour] ? rawData[day][hour][`pct_${qualitynMetric.toLowerCase()}s`] : 0)
      }
    }
    setChartData({ x: x, y: y, data: data, percentage: percentage })
    if (setTwinChartData) setTwinChartData({ x: x, y: y, data: data, percentage: percentage });
  }, [rawData, qualitynMetric, setTwinChartData])

  useEffect(() => {
    if (url === undefined || selectedVenue === undefined) return;
    setCompletedUrl(`analytics/performance/distribution/venue/${selectedVenue}/${url}`)
  }, [url, selectedVenue])

  const fetchDistribution = useCallback(() => {
    if (completedUrl === undefined) return;
    setChartData(undefined);
    let requestConf = {
      endpoint: completedUrl,
      method: 'GET',
      data: {
      }
    }
    FetchService(requestConf).then(
      (response) => {
        if (!mountedRef.current) {
          return;
        }
        else {
          setRawData(response.data.data_weekly);
        }
      },
      (err) => {
      }
    ).catch(err => console.log(err));
  }, [completedUrl])

  useEffect(() => {
    fetchDistribution()
  }, [fetchDistribution])

  return (
    <div>
      <div className={reportStyle.tooltip} ref={pointerRef} style={{ display: 'none' }}>
        <span className={`${reportStyle.tooltiptext} tooltipText`}>
        </span>
      </div>
      {chartData ? (
        <Heatmap
          title={title}
          subTitle={subTitle}
          height={500 - 70}
          colorscale={HEATMAP_COLORSCALE[`${qualitynMetric.toLowerCase()}`]}
          data={chartData.data}
          x={chartData.x}
          y={chartData.y}
          whenRenderingHeatmap={setHeatmapArea}
          hover={false} />
      ) : (
        <React.Fragment />
      )}
    </div>
  )
}

export default VenueBGEPizzasDistribution;