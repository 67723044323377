import React, { useCallback } from 'react';
import { FetchService } from '../../../../services/Api';
import style from './StepSelector.module.css';


const StepSelector = (props) => {
  const { report, beforeChangeState, callback, errCallback } = props;

  const changeStep = useCallback((state) => {
    if (report.state === state) return;
    if (['generated', 'delivered'].includes(report.state) && state === 'complete') return;
    beforeChangeState();
    let requestConf = {
      endpoint: `pizza_tv/report/change_state/`,
      method: 'POST',
      data: {
        report_id: report._id,
        state: state,
      }
    }
    FetchService(requestConf)
      .then(response => {
        callback(response.data.report);
      })
      .catch(err => {
        console.log(`Err "${err}" occurred while trying to change step`);
        errCallback();
      })
      .finally(() => { })
  }, [report, beforeChangeState, callback, errCallback])

  return (
    report ? (
      report.standard_scoreless_report ? (
        <div className={style.steps}>
          <span className={`${style.step} ${report.state === 'data selection' ? style.activeStep : ''}`} onClick={() => { changeStep('data selection') }} >1</span>
          <div className={style.stepSeparator}></div>
          <span className={`${style.step} ${report.state === 'scoreless data selection' ? style.activeStep : ''}`} onClick={() => { changeStep('scoreless data selection') }} >2</span>
          <div className={style.stepSeparator}></div>
          <span className={`${style.step} ${['complete', 'generated', 'delivered'].includes(report.state) ? style.activeStep : ''}`} onClick={() => { changeStep('complete') }} >3</span>
        </div>
      ) : (
        report.scoreless_report ? (
          <div className={style.steps}>
            <span className={`${style.step} ${report.state === 'data selection' ? style.activeStep : ''}`} onClick={() => { changeStep('data selection') }} >1</span>
            <div className={style.stepSeparator}></div>
            <span className={`${style.step} ${['complete', 'generated', 'delivered'].includes(report.state) ? style.activeStep : ''}`} onClick={() => { changeStep('complete') }} >2</span>
          </div>
        ) : (
          <div className={style.steps}>
            <span className={`${style.step} ${report.state === 'data selection' ? style.activeStep : ''}`} onClick={() => { changeStep('data selection') }} >1</span>
            <div className={style.stepSeparator}></div>
            <span className={`${style.step} ${['complete', 'generated', 'delivered'].includes(report.state) ? style.activeStep : ''}`} onClick={() => { changeStep('complete') }} >2</span>
          </div>
        )
      )
    ) : (
      null
    )
  )
}

export default StepSelector;