import React, { useState, useEffect } from 'react';
import VenuePizzasDistribution from './VenuePizzasDistribution';
import VenueBGEPizzasDistribution from './VenueBGEPizzasDistribution';
import { useChartRendering } from '../../../hooks/useChartRendering';
import { getQualitySelector, parseDateRange } from '../Aux';
import reportStyle from '../Report.module.css';
import Select from '../../common/select/Select';


const VenuePizzasDistributionComparison = (props) => {
  const { dateRange, selectedVenue, data } = props;

  const [rendering, setRendering] = useState(undefined);

  const [qualitynMetric, setQualitynMetric] = useState('Bad');
  const [qualitynMetricSelector, setQualitynMetricSelector] = useState(undefined);
  useEffect(() => {
    let conf = getQualitySelector(qualitynMetric, setQualitynMetric);
    setQualitynMetricSelector({ ...conf })
  }, [qualitynMetric])

  const [venuePizzasDistributionUrl, setVenuePizzasDistributionUrl] = useState(undefined);

  const [renderingVenuePizzasDistribution, setRenderingVenuePizzasDistribution] = useState(undefined);
  const [renderingVenueBGEPizzasDistribution, setRenderingVenueBGEPizzasDistribution] = useState(undefined);

  useEffect(() => {
    setVenuePizzasDistributionUrl(`start_date/${parseDateRange(dateRange)[0]}/end_date/${parseDateRange(dateRange)[1]}/`);
  }, [dateRange])

  useEffect(() => {
    if (renderingVenueBGEPizzasDistribution && renderingVenuePizzasDistribution) setRendering(true)
    else setRendering(undefined)
  }, [renderingVenueBGEPizzasDistribution, renderingVenuePizzasDistribution])

  useChartRendering(rendering, props.onRendering);

  const [heatmapAreaA, setHeatmapAreaA] = useState(undefined);
  const [chartDataA, setChartDataA] = useState(undefined);
  const [pointerA, setPointerA] = useState(undefined);
  const [heatmapAreaB, setHeatmapAreaB] = useState(undefined);
  const [chartDataB, setChartDataB] = useState(undefined);
  const [pointerB, setPointerB] = useState(undefined);

  return (
    <div className={reportStyle.aggregatedChart}>
      <div>
        <div className={reportStyle.aggregatedChartHeader}>
          <span className={reportStyle.aggregatedChartTitle}>Production distribution ( % - whole period)</span>
          {
            qualitynMetricSelector ? (
              <Select {...qualitynMetricSelector} />
            ) : (
              <React.Fragment />
            )
          }
        </div>
        <div className={reportStyle.charts}>
          <VenuePizzasDistribution
            url={venuePizzasDistributionUrl}
            selectedVenue={selectedVenue}
            subTitle='Production heatmap'
            setTwinHeatmapArea={setHeatmapAreaA}
            setTwinChartData={setChartDataA}
            setTwinPointer={setPointerA}
            twinHeatmapArea={heatmapAreaB}
            twinChartData={chartDataB}
            twinPointer={pointerB}
            onRendering={setRenderingVenuePizzasDistribution} />
          <VenueBGEPizzasDistribution
            data={data}
            selectedVenue={selectedVenue}
            subTitle={`${qualitynMetric} pizzas heatmap`}
            qualitynMetric={qualitynMetric}
            setTwinHeatmapArea={setHeatmapAreaB}
            setTwinChartData={setChartDataB}
            setTwinPointer={setPointerB}
            twinHeatmapArea={heatmapAreaA}
            twinChartData={chartDataA}
            twinPointer={pointerA}
            onRendering={setRenderingVenueBGEPizzasDistribution} />
        </div>
      </div>
    </div>
  )
}

export default VenuePizzasDistributionComparison;