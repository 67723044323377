import React, { useEffect, useRef, useState } from 'react';
import Plot from 'react-plotly.js';
import style from './Heatmap.module.css';

const Heatmap = (props) => {
  const { title, subTitle, height, x, y, data, whenRenderingHeatmap, hover } = props;
  const heatmapRef = useRef(null);
  const [elem, setElem] = useState(undefined);
  const [zMin, setZMin] = useState(undefined);
  const [zMax, setZMax] = useState(undefined);
  
  useEffect(() => {
    if (data === undefined) return;
    let min = 0, max = 1;
    for (let row of data) {
      for (let col of row) {
        min = Math.min(min, col);
        max = Math.max(max, col);
      }
    }
    setZMin(min);
    setZMax(max);
  }, [data, zMax, zMin])

  const colorscale = props.colorscale || [
    ['0.0', '#FFDFDA'],
    ['0.2', '#FFC5BD'],
    ['0.4', '#FFABA0'],
    ['0.6', '#FF9283'],
    ['0.8', '#FF7865'],
    ['1.0', '#FF5E48']
  ]

  useEffect(() => {
    if (heatmapRef === undefined) return;
    setElem(heatmapRef.current);
  }, [heatmapRef])

  useEffect(() => {
    if (!elem || !whenRenderingHeatmap) return;
    const interval = setInterval(function(){
      if (elem.getElementsByClassName('nsewdrag')[0] !== undefined){
        whenRenderingHeatmap(elem.getElementsByClassName('nsewdrag')[0]);
        clearInterval(interval);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [elem, whenRenderingHeatmap])

  return (
    <div className={style.wrapper}>
      <span className={style.title}>
        {title}
      </span>
      <span className={style.subTitle}>
        {subTitle}
      </span>

      <div ref={heatmapRef}>
        <Plot
          data={[
            {
              z: data,
              x: x,
              y: y,
              type: 'heatmap',
              showscale: false,
              colorscale: colorscale,
              hoverongaps: false,
              xgap: 1,
              ygap: 1,
              zmin: zMin,
              zmax: zMax
            }
          ]}
          layout={{
            hovermode: window.innerWidth < 768 ? true: hover,
            height: height,
            autosize: true,
            x: 0.1,
            margin: {
              t: 55,
              l: 80,
              r: 20,
              b: 20
            },
            paper_bgcolor: 'rgba(255,255,255,1)',
            plot_bgcolor: 'rgba(255,255,255,1)',
            xaxis: {
              side: 'top',
              fixedrange: true
            },
            yaxis: {
              fixedrange: true
            }
          }}
          config={{ displayModeBar: false }}
          useResizeHandler={true}
          style={{ width: "100%", height: "100%", backgroundColor: "#234324" }}
        />
      </div>
    </div>
  )
}

export default Heatmap