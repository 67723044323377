import { FetchService } from '../../../../../services/Api';
import React, { useState, useEffect, useCallback } from 'react';
import style from './UserEditForm.module.css';
import Select from '../../../../common/select/Select'
import { MAIN_FILTERS } from '../../../../Constants';
import ModalWrapper from '../../../../common/modalWrapper/ModalWrapper';
import LoadingScreen from '../../../../common/loadingScreen/LoadingScreen';
import cantrash from '../../../../../../src/vectors/can-trash.svg';


const UserEditForm = (props) => {
  const { venues, regions, displayForm, setDisplayForm, user, selectedCompany, regionManager } = props;
  const [venueSelector, setVenueSelector] = useState(undefined);
  const [regionSelector, setRegionSelector] = useState(undefined);
  const [selectedVenues, setSelectedVenues] = useState(undefined);
  const [selectedRegions, setSelectedRegions] = useState(undefined);
  const [defaultUserRegionsMemberships, setDefaultUserRegionsMemberships] = useState(undefined);
  const [defaultUserVenuesMemberships, setDefaultUserVenuesMemberships] = useState(undefined);
  const [venuesOptionsList, setVenuesOptionsList] = useState([]);
  const [regionsOptionsList, setRegionsOptionsList] = useState([]);
  const [venuePrettyName, setVenuePrettyName] = useState({});
  const [regionPrettyName, setRegionPrettyName] = useState({});
  const [loadingDefaultVenues, setLoadingDefaultVenues] = useState(false);
  const [selectedRegionsToChangeManager, setSelectedRegionsToChangeManager] = useState(undefined);
  const [venueMembershipsCreated, setVenueMembershipsCreated] = useState(false);
  const [regionMembershipsCreated, setRegionMembershipsCreated] = useState(false);

  const onDelete = () => {
    setVenueSelector(undefined);
    setRegionSelector(undefined);
    setDefaultUserRegionsMemberships(undefined);
    setDefaultUserVenuesMemberships(undefined);
  }

  const getUserVenuesMemberships = useCallback(() => {
    setLoadingDefaultVenues(true);
    let requestConf = {
      endpoint: `dashboard/venue-membership/${user.pk}/`,
      method: 'GET',
      data: {}
    }

    FetchService(requestConf).then(
      (response) => {
        setDefaultUserVenuesMemberships(response.data);
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }, [user])

  const getUserRegionsMemberships = useCallback(() => {
    setLoadingDefaultVenues(true);
    let requestConf = {
      endpoint: `dashboard/region-membership/${user.pk}/`,
      method: 'GET',
      data: {}
    }

    FetchService(requestConf).then(
      (response) => {
        setDefaultUserRegionsMemberships(response.data);
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }, [user])

  const getManagerRegionMembership = useCallback(() => {
    setLoadingDefaultVenues(true);
    let requestConf = {
      endpoint: `dashboard/region-manager/user/${user.pk}/`,
      method: 'GET',
      data: {}
    }

    FetchService(requestConf).then(
      (response) => {
        setDefaultUserRegionsMemberships(response.data);
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }, [user])

  useEffect(() => {
    if (defaultUserRegionsMemberships !== undefined && defaultUserVenuesMemberships !== undefined) setLoadingDefaultVenues(false);
  }, [defaultUserRegionsMemberships, defaultUserVenuesMemberships])

  const getRegionInfoBeforeChangeManager = () => {
    if (!regionManager || selectedRegions === undefined) {
      setDisplayForm(false);
      return;
    };
    let requestConf = {
      endpoint: `dashboard/regions-info/`,
      method: 'POST',
      data: { regions: selectedRegions },
      dataType: 'JSON'
    }

    FetchService(requestConf).then(
      (response) => {
        setSelectedRegionsToChangeManager(response.data);
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }

  const createRegionManager = useCallback((data) => {
    let requestConf = {
      endpoint: `dashboard/region-manager/user/${user.pk}/`,
      method: 'POST',
      data: data,
      dataType: 'JSON'
    }

    FetchService(requestConf).then(
      (response) => {
        if (setDisplayForm) setDisplayForm(false);
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }, [user, setDisplayForm])


  useEffect(() => {
    if (selectedRegionsToChangeManager === undefined) return;
    let alerts = 'Are you sure to continue?';
    let validRegions = [];
    for (let region of selectedRegionsToChangeManager) {
      if (region.manager) {
        let manager = region.manager.first_name && region.manager.last_name ? `${region.manager.first_name} ${region.manager.last_name}` : region.manager.username;
        alerts += `\nRegion ${region.name} belongs to ${manager}, ownership will change.`
        validRegions.push(region.name);
      }
      else {
        validRegions.push(region.name);
      }
    }
    if (alerts === 'Are you sure to continue?' || window.confirm(alerts)) {
      createRegionManager({ regions: validRegions })
    }
  }, [selectedRegionsToChangeManager, createRegionManager])

  const createNewMemberships = (data, spaceType) => {
    let requestConf = {
      endpoint: `dashboard/${spaceType}-membership/${user.pk}/`,
      method: 'PATCH',
      data: data,
      dataType: 'JSON'
    }

    FetchService(requestConf).then(
      (response) => {
        if (spaceType === 'venue') setVenueMembershipsCreated(true);
        else setRegionMembershipsCreated(true);
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }

  useEffect(() => {
    if (venueMembershipsCreated === true && regionMembershipsCreated === true) {
      if (setDisplayForm) setDisplayForm(false);
    }
  }, [venueMembershipsCreated, regionMembershipsCreated, setDisplayForm])

  const deleteRegionOwnership = (pk) => {
    if (window.confirm('Are you Sure')) {
      let requestConf = {
        endpoint: `dashboard/region-ownership/user/${user.pk}/region/${pk}/`,
        method: 'DELETE',
        data: {},
      }

      FetchService(requestConf).then(
        (response) => {
          if (onDelete) onDelete()
        },
        (err) => {
          console.log(err);
        }
      ).catch(err => console.log(err));
    }
  }

  const deleteMembership = (pk, spaceType) => {
    if (window.confirm('Are you Sure') === true) {
      let requestConf = {
        endpoint: `dashboard/${spaceType}-membership/${pk}/`,
        method: 'DELETE',
        data: {},
      }

      FetchService(requestConf).then(
        (response) => {
          if (onDelete) onDelete()
        },
        (err) => {
          console.log(err);
        }
      ).catch(err => console.log(err));
    }
  }

  const trashCanButtonPressed = (operation, pk, spaceType) => {
    if (operation === 'deleteMembership')
      deleteMembership(pk, spaceType);
    else if(operation === 'deleteRegionOwnership')
      deleteRegionOwnership(pk);
  }

  useEffect(() => {
    if (venues === undefined || regions === undefined) return;
    let requestConf = {
      endpoint: 'dashboard/venue_pretty_name/',
      method: 'POST',
      data: {
        venues: venues,
        regions: regions
      },
      dataType: 'JSON'
    }

    FetchService(requestConf).then(
      (response) => {
        setVenuePrettyName(response.data.venues);
        setRegionPrettyName(response.data.regions);
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }, [venues, regions])

  useEffect(() => {
    if (Object.keys(venuePrettyName).length > 0 && defaultUserVenuesMemberships) {
      let options = venues.filter((venue) => { return !defaultUserVenuesMemberships.map(m => m.space.name).includes(venue) }).map(venue => { return { name: venuePrettyName[venue] ?? venue, value: venue } });
      options.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
      setVenuesOptionsList(options);
    }
  }, [venues, defaultUserVenuesMemberships, venuePrettyName])

  useEffect(() => {
    if (Object.keys(regionPrettyName).length > 0 && defaultUserRegionsMemberships) {
      let options = regions.filter((region) => { return !defaultUserRegionsMemberships.map(m => m.space.name).includes(region) }).map(region => { return { name: regionPrettyName[region] ?? region, value: region } });
      options.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
      setRegionsOptionsList(options);
    }
  }, [regions, defaultUserRegionsMemberships, regionPrettyName])

  useEffect(() => {
    let venueSelector = {
      ...MAIN_FILTERS['Select store'],
      multiple: true,
      optionsList: venuesOptionsList,
      callback: (name, selection) => {
        let selectedVenues = [];
        for (let i = 0; i < selection.length; ++i) {
          selectedVenues.push(selection[i].value)
        }
        setSelectedVenues(selectedVenues);
      }
    }
    setVenueSelector(venueSelector);
  }, [venuesOptionsList])

  useEffect(() => {
    let regionSelector = {
      ...MAIN_FILTERS['Select store'],
      label: 'Select Region',
      name: 'Select Region',
      multiple: true,
      optionsList: regionsOptionsList,
      callback: (name, selection) => {
        let selectedRegions = [];
        for (let i = 0; i < selection.length; ++i) {
          selectedRegions.push(selection[i].value)
        }
        setSelectedRegions(selectedRegions);
      }
    }
    setRegionSelector(regionSelector);
  }, [regionsOptionsList])

  useEffect(() => {
    if (venues && regions && user && !defaultUserRegionsMemberships && !defaultUserVenuesMemberships) {
      getUserVenuesMemberships();
      if (regionManager) getManagerRegionMembership();
      else getUserRegionsMemberships();
    }
  }, [venues, regions, user, defaultUserRegionsMemberships, defaultUserVenuesMemberships, regionManager, getUserVenuesMemberships, getUserRegionsMemberships, getManagerRegionMembership])

  const closeForm = (save) => {
    document.body.style.overflow = 'auto';
    if (save) {
      if (regionManager) {
        getRegionInfoBeforeChangeManager();
        return;
      }
      createNewMemberships({ spaces: selectedVenues }, 'venue');
      createNewMemberships({ spaces: selectedRegions }, 'region');
    }
    else setDisplayForm(false);
  }

  return (
    <ModalWrapper
      allScreen={true}
      display={displayForm}
      setDisplay={setDisplayForm}>
      <div className={style.wrapper}>
        <LoadingScreen loading={loadingDefaultVenues} adaptable={true}>
          <div className={style.dialogBody}>
            <p className={style.title}>{regionManager ? 'Select a Region' : 'Add'}</p>
            <p>{user.email}</p>
            {
              venueSelector && !regionManager ? (
                <div style={{ marginTop: '15px', display: 'flex' }}>
                  <Select {...venueSelector} />
                </div>
              ) : (
                <React.Fragment />
              )
            }
            {
              regionSelector ? (
                <div style={{ marginTop: '15px', display: 'flex' }}>
                  <Select {...regionSelector} />
                </div>
              ) : (
                <React.Fragment />
              )
            }
            <div className={style.defaultVenuesWrapper}>
              {
                defaultUserVenuesMemberships && defaultUserVenuesMemberships.length > 0 && !regionManager ? (
                  <div>
                    <p style={{ fontWeight: 'bold' }}>Tiendas agregadas</p>
                    {

                      defaultUserVenuesMemberships.map((memberships, index) => {
                        return <div key={index} className={style.defaultVenue}>
                          <span>{memberships.space.pretty_name || memberships.space.name}</span>
                          {
                            selectedCompany.pk === memberships.space.company.pk ? (
                              <button onClick={() => deleteMembership(memberships.pk, 'venue')} className={style.deleteButton}><img src={cantrash} alt='close' /></button>
                            ) : (
                              <React.Fragment />
                            )
                          }
                        </div>
                      })
                    }
                  </div>
                ) : (
                  <React.Fragment />
                )
              }
              {
                defaultUserRegionsMemberships && defaultUserRegionsMemberships.length > 0 ? (
                  <div>
                    <p style={{ fontWeight: 'bold' }}>{regionManager ? 'Manager of' : 'Regiones agregadas'}</p>
                    {

                      defaultUserRegionsMemberships.map((memberships, index) => {
                        return <div key={index} className={style.defaultVenue}>
                          <span>{memberships.space.pretty_name || memberships.space.name}</span>
                          {
                            selectedCompany.pk === memberships.space.company.pk ? (
                              <button onClick={() => trashCanButtonPressed(regionManager? 'deleteRegionOwnership':'deleteMembership', regionManager? memberships.space.name: memberships.pk, 'region')} className={style.deleteButton}><img src={cantrash} alt='close' /></button>
                            ) : (
                              <React.Fragment />
                            )
                          }
                        </div>
                      })
                    }
                  </div>
                ) : (
                  <React.Fragment />
                )
              }
            </div>
          </div>
          <div className={style.dialogFooter}>
            <button onClick={() => closeForm()}>Cancel</button>
            <button onClick={() => closeForm(true)}>{regionManager ? 'Accept' : 'Create'}</button>
          </div>
        </LoadingScreen>
      </div>
    </ModalWrapper>
  )
}

export default UserEditForm;