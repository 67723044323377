import { FetchService } from '../../../services/Api';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import style from './Leaderboard.module.css';
import reportStyle from '../Report.module.css';
import 'react-input-range/lib/css/index.css';
import { parseDate } from '../Aux';
import Table from '../table/Table';
import leaderboardInsight from '../../../vectors/leaderboard-insight.svg';
import bestSpecialty from '../../../vectors/best-specialty.svg';
import badPizzas from '../../../vectors/bad-pizzas.svg';

const LeaderboardDetail = (props) => {
  const { rank, venueShift, meanScore, numPizzas, badPizzasCount } = props;

  return (
    <div className={style.leaderboardDetailWrapper}>
      <span className={style.detailTitle}>
        {`Rank ${rank} - ${venueShift}`}
      </span>
      <div className={style.featuresRow}>
        <div className={style.feature}>
          <div className={style.featureImg}>
            <img src={leaderboardInsight} alt='Score' />
          </div>
          <div className={style.featureDetail} style={{ alignItems: 'flex-start' }}>
            <span className={style.featureValue}>{meanScore}</span>
            <span className={style.featureLabel}>Score</span>
          </div>
        </div>
        <div className={style.feature}>
          <div className={style.featureImg}>
            <img src={bestSpecialty} alt='Pizzas' />
          </div>
          <div className={style.featureDetail} style={{ alignItems: 'flex-start' }}>
            <span className={style.featureValue}>{`${numPizzas} pizzas`}</span>
            <span className={style.featureLabel}># pizzas</span>
          </div>
        </div>
        <div className={style.feature}>
          <div className={style.featureImg}>
            <img src={badPizzas} alt='Bad pizzas' />
          </div>
          <div className={style.featureDetail} style={{ alignItems: 'flex-start' }}>
            <span className={style.featureValue}>{`${((badPizzasCount / numPizzas) * 100).commaRepresentation(2)}%`}</span>
            <span className={style.featureLabel}>Bad pizzas</span>
          </div>
        </div>
      </div>
    </div>
  )
}


const Leaderboard = (props) => {
  const { dateRangeMin, dateRangeMax, whenContentUpdateStarts, whenContentUpdateEnds, staffUser } = props;
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const [leaderboard, setLeaderboard] = useState(undefined);
  const [leaderboardChartData, setLeaderboardChartData] = useState(undefined);
  const [leaderboardDetail, setLeaderboardDetail] = useState([]);
  const [weekRanking, setWeekRanking] = useState(undefined);
  const [weekRankingChartData, setWeekRankingChartData] = useState(undefined);
  const [canFetchRankingData, setCanFetchRankingData] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(undefined);
  const [companies, setCompanies] = useState(undefined);
  const [companyMembership, setCompanyMembership] = useState(undefined);

  useEffect(() => {
    if (leaderboard === undefined) return;
    if (Object.keys(leaderboard).length === 0) {
      setLeaderboardChartData({ header: ['Rank', 'Shift', 'Points'], data: [] });
      return;
    }
    let data = [];
    let detail = [];
    let count = 1;
    for (let row of leaderboard.best_shifts) {
      data.push([row[0].split(' - ')[0], row[0].split(' - ')[1], row[1]]);
      detail.push({ rank: count, venueShift: row[0].replace(' - ', ' '), meanScore: row[1], numPizzas: row[3], badPizzasCount: row[2] })
      count += 1;
    }
    setLeaderboardDetail(detail);
    setLeaderboardChartData({ header: ['Rank', 'Shift', 'Points'], data: data })
  }, [leaderboard])

  const fetchLeaderboard = useCallback(() => {
    if (staffUser && !selectedCompany) return;
    whenContentUpdateStarts();
    let requestConf = {
      endpoint: `analytics/leaderboard/shift/start_date/${parseDate(dateRangeMin)}/end_date/${parseDate(dateRangeMax)}/`,
      method: 'GET',
      data: staffUser && selectedCompany ? {
        company: selectedCompany
      } : {}
    }
    FetchService(requestConf)
      .then(response => {
        if (!mountedRef.current) {
          return;
        }
        else {
          setLeaderboard(response.data);
        }
      })
      .catch(err => { })
      .finally(() => setCanFetchRankingData(true));
  }, [dateRangeMin, dateRangeMax, staffUser, selectedCompany, whenContentUpdateStarts])

  useEffect(() => {
    fetchLeaderboard();
  }, [fetchLeaderboard])

  useEffect(() => {
    if (weekRanking === undefined) return;
    let data = [];
    let count = 1;
    for (let row of weekRanking.week_ranking) {
      data.push([count, row[1], row[2].commaRepresentation(2)]);
      count += 1;
    }
    setWeekRankingChartData({ header: ['#', 'Store', 'Points'], data: data })
  }, [weekRanking])

  const fetchWeekRanking = useCallback(() => {
    if (canFetchRankingData === false || weekRanking !== undefined) return;
    let requestConf = {
      endpoint: `analytics/leaderboard/week_ranking/`,
      method: 'GET',
      data: staffUser && selectedCompany ? {
        company: selectedCompany
      } : {}
    }
    FetchService(requestConf)
      .then(response => {
        if (!mountedRef.current) {
          return;
        }
        else {
          setWeekRanking(response.data);
        }
      })
      .catch(err => whenContentUpdateEnds())
      .finally(() => {
        setCanFetchRankingData(false);
        whenContentUpdateEnds();
      });
  }, [weekRanking, whenContentUpdateEnds, canFetchRankingData, selectedCompany, staffUser])

  useEffect(() => {
    fetchWeekRanking();
  }, [fetchWeekRanking])

/*   useEffect(() => {
    if (whenContentUpdateStarts) whenContentUpdateStarts();
    if (dateRangeMin === undefined || dateRangeMax === undefined) return;
    setLeaderboardChartData(undefined);
    setLeaderboardDetail([]);
  }, [dateRangeMin, dateRangeMax, whenContentUpdateStarts]) */

  useEffect(() => {
    if (leaderboardChartData && weekRankingChartData && whenContentUpdateEnds) whenContentUpdateEnds();
  }, [leaderboardChartData, weekRankingChartData, whenContentUpdateEnds])

  useEffect(() => {
    if (companyMembership !== undefined || !staffUser) return;
    whenContentUpdateStarts();
    let requestConf = {
      endpoint: `dashboard/company-membership/`,
      method: 'GET',
      data: {}
    }

    FetchService(requestConf).then(
      (response) => {
        setCompanyMembership(response.data);
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }, [companyMembership, staffUser, whenContentUpdateStarts])

  useEffect(() => {
    if (companyMembership === undefined) return;
    if (companyMembership.length === 0) setSelectedCompany('null');
    else if (companyMembership.length === 1) setSelectedCompany(companyMembership[0].space.pk);
    else {
      let companies = companyMembership.map(m => { return { displayName: m.space.pretty_name || m.space.name, pk: m.space.pk } });
      companies = companies.sort((a, b) => (a.displayName > b.displayName) ? 1 : (b.displayName > a.displayName) ? -1 : 0);
      companies = [...new Set(companies.map(JSON.stringify))].map(JSON.parse);
      if (companies.length === 1) setSelectedCompany(companies[0].pk);
      else {
        whenContentUpdateEnds();
        setCompanies(companies);
      }
    }
  }, [companyMembership, whenContentUpdateEnds])

  return (
    <div>
      {
        companies && selectedCompany === undefined ? (
          <div className={style.companySelector}>
            {
              companies.map((company, index) => {
                return <button className={style.companyButton} key={index} onClick={() => setSelectedCompany(company.pk)}>{company.displayName}</button>
              })
            }
          </div>
        ) : (
          <React.Fragment />
        )
      }
      <div className={reportStyle.col2WithTitle}>
        <div>
          {
            leaderboardChartData ? (
              <React.Fragment>
                <span className={style.title}>Shift leaderboard</span>
                <Table
                  header={leaderboardChartData.header}
                  rank={true}
                  data={leaderboardChartData.data} />
              </React.Fragment>
            ) : (
              <React.Fragment />
            )
          }
        </div>
        <div>
          {
            weekRankingChartData ? (
              <React.Fragment>
                <span className={style.title}>Winner history last 10 (time frame)</span>
                <Table
                  header={weekRankingChartData.header}
                  data={weekRankingChartData.data} />
              </React.Fragment>
            ) : (
              <React.Fragment />
            )
          }
        </div>
      </div>
      {
        leaderboardChartData ? (
          <span className={style.insightsTitle}>
            Insights
          </span>
        ) : (
          <React.Fragment />
        )
      }
      {
        leaderboardDetail.map((venueDetail, index) => {
          return <LeaderboardDetail
            key={index}
            {...venueDetail}
          />
        })
      }
    </div>
  )
}

export default Leaderboard;