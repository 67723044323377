import { FetchService } from '../../../services/Api';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import BasicChart from '../basicChart/BasicChart';
import { getQualitySelector, getGranularityCats } from '../Aux';
import VenueSelector from '../../common/venueSelector/VenueSelector';


const VenueQualityComparison = (props) => {
  const { url, venues, preselectedVenue, companyQualityAvg, dateRangeMin, dateRangeMax } = props;
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const [completedUrl, setCompletedUrl] = useState(undefined);
  const [selectedVenue, setSelectedVenue] = useState(preselectedVenue);
  const [selectedVenueTitle, setSelectedVenueTitle] = useState(preselectedVenue);
  const [rawData, setRawData] = useState(undefined);
  const [chartData, setChartData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [qualityMetric, setQualityMetric] = useState('Bad');
  const [qualityMetricSelector, setQualityMetricSelector] = useState(undefined);
  const qualityGranularity = 'monthly';
  useEffect(() => {
    let conf = getQualitySelector(qualityMetric, setQualityMetric);
    conf.optionsList.splice(1, 1); // We are removing good from dropdown
    setQualityMetricSelector({ ...conf })
  }, [qualityMetric])

  const whenSelectingVenue = useCallback((name, selection) => {
    setSelectedVenue(selection[0].value);
    setSelectedVenueTitle(selection[0].name);
  }, [])

  useEffect(() => {
    if (rawData === undefined) return;
    let subcats = ['Bad', 'Good', 'Excellent'];
    let dictData = {};
    for (let period of rawData.data) {
      dictData[period.datetime] = parseFloat(period[`pct_${qualityMetric.toLowerCase()}s`]) / 100;
    }
    let conf = getGranularityCats({ min: dateRangeMin, max: dateRangeMax }, dictData, qualityGranularity)
    let subcatData = [];
    for (let cat of conf.data) {
      let pct = cat[0];
      subcatData.push(qualityMetric === 'Bad' ? [pct, 0, 0] : qualityMetric === 'Good' ? [0, pct, 0] : [0, 0, pct])
    }
    setChartData({ cats: conf.cats, subcats: subcats, data: subcatData, customXAxis: { tickVals: conf.tickVals, tickText: conf.tickText } })
    setLoading(false);
  }, [rawData, qualityMetric, qualityGranularity, dateRangeMin, dateRangeMax])

  useEffect(() => {
    if (url === undefined || selectedVenue === undefined || qualityGranularity === undefined) return;
    setCompletedUrl(`analytics/compare/quality/venue/${selectedVenue}/type/${qualityGranularity}/${url}`)
  }, [url, selectedVenue, qualityGranularity])

  const fetchVenueQuality = useCallback(() => {
    if (completedUrl === undefined) return;
    setChartData(undefined);
    setLoading(true);
    let requestConf = {
      endpoint: completedUrl,
      method: 'GET',
      data: {
      }
    }
    FetchService(requestConf).then(
      (response) => {
        if (!mountedRef.current) {
          setLoading(false);
          return;
        }
        else {
          setRawData(response.data);
        }
      },
      (err) => {
      }
    ).catch(err => console.log(err));
  }, [completedUrl])

  useEffect(() => {
    fetchVenueQuality();
  }, [fetchVenueQuality])

  return (
    <BasicChart
      title={`Evolution % ${qualityMetric} Pizzas ${selectedVenueTitle ? `- ${selectedVenueTitle}` : ''}`}
      type='bar'
      tickFormat=',.0%'
      height={500 - 70}
      showYTickLabels={true}
      yrange={[0, 1.05]}
      cats={chartData ? chartData.cats : undefined}
      subcats={chartData ? chartData.subcats : undefined}
      data={chartData ? chartData.data : undefined}
      avgLine={companyQualityAvg}
      subcatsColors={['#FC5245B2', '#FCD049B2', '#27CE2DB2']}
      filters={qualityMetricSelector !== undefined ? [qualityMetricSelector] : undefined}
      preBuiltFilters={[<VenueSelector venues={venues} multiple={false} callback={whenSelectingVenue} defaultVenue={selectedVenue} main={false} />]}
      legend={true}
      loading={loading} />
  )
}

export default VenueQualityComparison;