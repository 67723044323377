import { FetchService } from '../../../services/Api';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import BasicChart from '../basicChart/BasicChart';
import { getGranularitySelector, getGranularityCats } from '../Aux';
import { useChartRendering } from '../../../hooks/useChartRendering';


const VenueQualityDistribution = (props) => {
  const { url, selectedVenue, dateRange, data } = props;
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const [completedUrl, setCompletedUrl] = useState(undefined);
  const [rawData, setRawData] = useState(undefined);
  const [chartData, setChartData] = useState(undefined);
  const [granularity, setGranularity] = useState('weekly')
  const [loading, setLoading] = useState(false);
  const [granularitySelector, setGranularitySelector] = useState({});
  useEffect(() => {
    setGranularitySelector({ ...getGranularitySelector(granularity, setGranularity) })
  }, [granularity])

  useChartRendering(chartData, props.onRendering);

  useEffect(() => {
    if (data !== undefined) setRawData(data.quality_by_period);
    else setChartData(undefined);
  }, [data])

  useEffect(() => {
    if (rawData === undefined) return;
    let subcats = ['Bad', 'Good', 'Excellent'];
    let dictData = rawData[granularity];
    let conf = getGranularityCats(dateRange, dictData, granularity, subcats.map((subcat) => `pct_${subcat.toLowerCase()}s`), 'total')
    setChartData({ cats: conf.cats, subcats: subcats, data: conf.data, customXAxis: { tickVals: conf.tickVals, tickText: conf.tickText } })
    setLoading(false);
  }, [rawData, granularity, dateRange])

  useEffect(() => {
    if (url === undefined || selectedVenue === undefined || granularity === undefined) return;
    setCompletedUrl(`analytics/performance/quality/venue/${selectedVenue}/type/${granularity}/${url}`)
  }, [url, selectedVenue, granularity])
  
  useEffect(() => {
    if (url === undefined) return;
    setChartData(undefined);
  }, [url])

  const fetchQuality = useCallback(() => {
    if (completedUrl === undefined) return;
    setLoading(true);
    let requestConf = {
      endpoint: completedUrl,
      method: 'GET',
      data: {
      }
    }
    FetchService(requestConf).then(
      (response) => {
        if (!mountedRef.current) {
          return;
        }
        else {
          setRawData(response.data);
        }
      },
      (err) => {
      }
    ).catch(err => console.log(err));
  }, [completedUrl])

  useEffect(() => {
    fetchQuality();
  }, [fetchQuality])

  return (
    chartData ? (
      <BasicChart
        type='bar'
        title='Pizza quality distribution'
        yaxisTitle='# pizzas produced'
        height={500 - 70}
        customXAxis={chartData.customXAxis}
        cats={chartData.cats}
        subcats={chartData.subcats}
        subcatsColors={['#FC5245B2', '#FCD049B2', '#27CE2DB2']}
        data={chartData.data}
        filters={[granularitySelector]}
        loading={loading}
        showYTickLabels={true}
        legend={true}
        hoverDisplayPercentage={true} />
    ) : (
      <React.Fragment />
    )
  )
}

export default VenueQualityDistribution;