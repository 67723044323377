import { FetchService } from '../../../services/Api';
import React, { useState, useEffect } from 'react';
import style from './VariableAdministration.module.css';


const VariableAdministration = (props) => {
  const { qualityDefinition, onSubmit } = props;
  //const shiftRange = [{ name: 'AM', start: '8AM', end: '1PM' }, { name: 'PM', start: '1PM', end: '2AM' }];
  const [editQD, setEditQD] = useState(false);
  const [qDForm, setQDForm] = useState({ min: '', max: '' });

  useEffect(() => {
    if (qualityDefinition) setQDForm({ ...qualityDefinition })
  }, [qualityDefinition])

  const toggleEdit = (save) => {
    if (editQD && save) {
      updateQualityDefinition(qDForm);
    }
    setEditQD(!editQD);
  }

  const updateForm = (e) => {
    let newForm = { ...qDForm };
    newForm[e.target.name] = e.target.value;
    setQDForm(newForm);
  }

  const updateQualityDefinition = (data) => {
    let requestConf = {
      endpoint: `dashboard/quality-definition/${qualityDefinition.pk}/`,
      method: 'PATCH',
      data: data,
      dataType: 'JSON'
    }

    FetchService(requestConf).then(
      (response) => {
        if (onSubmit) onSubmit()
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }

  return (
    <div className={style.wrapper}>
      {
        qualityDefinition && qDForm ? (
          <div className={style.qualityDefinitions}>
            <div className={style.tableHeader}>
              <h4>Quality definition</h4>
              {
                editQD ? (
                  <div style={{ display: 'flex' }}>
                    <button style={{ fontWeight: 'normal' }} onClick={() => toggleEdit(false)}>Cancel</button>
                    <button onClick={() => toggleEdit(true)}>Save</button>
                  </div>
                ) : (
                  <button onClick={() => toggleEdit(false)}>Edit</button>
                )
              }
            </div>
            <div className={style.qualityDefinitionsRow}>
              <span>Status</span>
              <span>Min</span>
              <span>Max</span>
            </div>
            <div className={style.qualityDefinitionsRow}>
              <span className={style.qualityDefinitionsCel}>Bad pizza</span>
              <span className={style.qualityDefinitionsCel}>{0.00.commaRepresentation(2)}</span>
              <span className={style.qualityDefinitionsCel}>
                {
                  editQD ? (
                    <input type='text' value={qDForm.min} name='min' onChange={(e) => updateForm(e)} />
                  ) : (
                    qualityDefinition.min.commaRepresentation(2)
                  )
                }
              </span>
            </div>
            <div className={style.qualityDefinitionsRow}>
              <span className={style.qualityDefinitionsCel}>Good pizza</span>
              <span className={style.qualityDefinitionsCel}>{qualityDefinition.min.commaRepresentation(2)}</span>
              <span className={style.qualityDefinitionsCel}>
                {
                  editQD ? (
                    <input type='text' value={qDForm.max} name='max' onChange={(e) => updateForm(e)} />
                  ) : (
                    qualityDefinition.max.commaRepresentation(2)
                  )
                }
              </span>
            </div>
            <div className={style.qualityDefinitionsRow}>
              <span className={style.qualityDefinitionsCel}>Excelent pizza</span>
              <span className={style.qualityDefinitionsCel}>{qualityDefinition.max.commaRepresentation(2)}</span>
              <span className={style.qualityDefinitionsCel}>{10.00.commaRepresentation(2)}</span>
            </div>
          </div>
        ) : (
          <React.Fragment />
        )
      }
      {/* <div className={style.shiftRange}>
        <div className={style.tableHeader}>
          <h4>Shift range</h4>
        </div>
        <div className={style.shiftRangeRow}>
          <span>Shift</span>
          <span>Start</span>
          <span>End</span>
        </div>
        {
          shiftRange.map((shift, index) => {
            return <div key={index} className={style.shiftRangeRow}>
              <span className={style.shiftRangeCel}>{shift.name}</span>
              <span className={style.shiftRangeCel}>{shift.start}</span>
              <span className={style.shiftRangeCel}>{shift.end}</span>
            </div>
          })
        }
      </div> */}
    </div>
  )
}

export default VariableAdministration;