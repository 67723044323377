import React from 'react';
import Plot from 'react-plotly.js';
import style from './BasicChart.module.css';
import Select from '../../common/select/Select';
import LoadingScreen from '../../common/loadingScreen/LoadingScreen';

const BarChart = (props) => {
  const { type, title, cats, subcats, subcatsColors, dash, colorScale, catTotals, height, preBuiltFilters, filters, legend, loading, hoverDisplayPercentage, yrange, customXAxis, avgLine, avgLegend, tickFormat, tickPrefix, yaxisTitle } = props;
  const { tickSize, avgTextFormat } = props;
  const groups = props.groups || [''];
  const data = groups.length === 1 ? [props.data] : props.data;
  const percentage = type === 'Scater' ? false : props.percentage;
  const showYgrid = type === 'Scater' ? true : props.showYgrid !== undefined ? props.showYgrid : false;
  const showYTickLabels = type === 'Scater' ? true : props.showYTickLabels !== undefined ? props.showYTickLabels : false;
  const barMode = props.barMode || 'stack';
  const groupSeparatorSize = 0.025;

  const getPlotFormatData = () => {
    let plotData = [];
    let totals = catTotals || [];

    for (let [i, group] of groups.entries()) {
      for (let [j, subcat] of subcats.entries()) {
        let subcatBar = { type: type, x: cats, y: [], name: subcat + group, xaxis: `x${i + 1}` }
        if (hoverDisplayPercentage) {
          subcatBar.hovertemplate = '(%{x}, %{y} - %{customdata})';
          subcatBar.customdata = [];
          subcatBar.text = [];
        }
        if (subcatsColors) {
          subcatBar.marker = { color: subcatsColors[j] }
        }
        if (dash) {
          subcatBar.line = { dash: dash[j] }
        }
        if (colorScale) {
          subcatBar.marker = { color: cats, colorscale: colorScale }
        }
        plotData.push(subcatBar);
      }
    }
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        totals.push(data[i][j].reduce((a, b) => parseFloat(a) + parseFloat(b), 0))
      }
    }
    /* if (percentage && totals.length === 0) {
    } */
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        for (let k = 0; k < data[i][j].length; k++) {
          let factor = percentage ? totals[i * cats.length + j] : 1
          plotData[i * subcats.length + k].y.push(data[i][j][k] / factor)
          if (hoverDisplayPercentage) {
            plotData[i * subcats.length + k].customdata.push(`${((data[i][j][k] / totals[i * cats.length + j]) * 100).commaRepresentation(0)}%`)
            plotData[i * subcats.length + k].text.push(`${((data[i][j][k] / totals[i * cats.length + j]) * 100).commaRepresentation(0)}%`)
          }
        }
      }
    }
    if (avgLine) {
      plotData.push({
        x: [cats[cats.length - 1]],
        y: [avgLine * 1],
        hovertemplate: `(%{y} - ${avgLegend || 'Stores Average'})`,
        name: '',
        text: [`${avgLine.commaRepresentation(2)}`],
        texttemplate: avgTextFormat ? `${avgTextFormat}` : '',
        mode: 'text',
        textposition: 'top right',
        textfont: {
          size: 10,
        }
      })
    }
    return plotData
  }

  const getGroupAxisConf = () => {
    let xAxis = {}
    let totalSpace = (groups.length - 1) * groupSeparatorSize;
    let groupSize = (1 - totalSpace) / groups.length;
    let start = 0;
    for (let [i, group] of groups.entries()) {
      xAxis[`xaxis${i > 0 ? i + 1 : ''}`] = {
        showgrid: false,
        domain: [start, start + groupSize],
        anchor: group, title: groups.length > 1 ? group : '',
        exponentformat: 'e', showexponent: 'all',
        tickfont: {
          size: tickSize === 'large' ? 15 : 10
        }
      }
      if (customXAxis && customXAxis.tickVals.length > 0) {
        xAxis[`xaxis${i > 0 ? i + 1 : ''}`].tickmode = 'array';
        xAxis[`xaxis${i > 0 ? i + 1 : ''}`].tickvals = customXAxis.tickVals;
        xAxis[`xaxis${i > 0 ? i + 1 : ''}`].ticktext = customXAxis.tickText;
      }
      start = start + groupSize + groupSeparatorSize;
    }
    return xAxis;
  }

  return (
    <LoadingScreen loading={loading} adaptable={true}>
      <div className={style.wrapper}>
        <div className={style.plotHeader}>
          <span className={style.title}>
            {title}
          </span>
        </div>
        <div className={style.plotFilters}>
          {
            preBuiltFilters && preBuiltFilters.length > 0 ? (
              preBuiltFilters.map((filter, index) => {
                return <div key={index}>{filter}</div>;
              })
            ) : (
              <React.Fragment />
            )
          }
          {
            filters ? (
              filters.map((filter, index) => {
                return <Select key={index} {...filter} />
              })
            ) : (
              <React.Fragment />
            )
          }
        </div>
        <div>
          {
            cats && subcats && data ? (
              <Plot
                data={getPlotFormatData()}
                layout={{
                  height: height,
                  barmode: barMode,
                  autosize: true,
                  x: 0.1,
                  margin: {
                    t: type === 'Scater' ? 20 : 5,
                    l: yaxisTitle ? 60 : showYTickLabels ? 45 : 10,
                    r: type === 'Scater' ? 40 : 10,
                    b: groups.length > 1 ? 80 : 80
                  },
                  legend: {
                    x: .79,
                    y: 1.16,
                    orientation: 'h'
                  },
                  yaxis: {
                    ...(tickPrefix && { tickprefix: tickPrefix }),
                    ...(tickFormat && { tickformat: tickFormat }),
                    ...(yaxisTitle && { title: { text: yaxisTitle } }),
                    showline: false,
                    showticklabels: showYTickLabels,
                    showgrid: showYgrid,
                    zeroline: false,
                    range: yrange,
                    rangemode: 'tozero',
                  },
                  ...getGroupAxisConf(),
                  paper_bgcolor: 'rgba(255,255,255,1)',
                  plot_bgcolor: 'rgba(255,255,255,1)',
                  showlegend: false,
                  ...(avgLine && {
                    shapes: [
                      {
                        type: 'line',
                        xref: 'paper',
                        x0: 0,
                        y0: avgLine,
                        x1: 1,
                        y1: avgLine,
                        line: {
                          color: '#03022933',
                          width: 2,
                          dash: 'dot'
                        }
                      }
                    ]
                  })
                }}
                config={{ displayModeBar: false }}
                useResizeHandler={true}
                style={{ width: "100%", height: "100%", backgroundColor: "#234324" }}
              />
            ) : (
              <React.Fragment />
            )
          }
        </div>
        <div className={style.plotFooter}>
          {
            legend && subcats && subcats.length > 0 ? (
              <div className={style.legend}>
                {
                  !avgLine ? (
                    subcats.map((item, key) => {
                      return <div key={key} className={style.legendItem}>
                        <div className={style.legendItemColor} style={{ background: subcatsColors[key] }}></div>
                        {item}
                      </div>
                    })
                  ) : (
                    <React.Fragment />
                  )
                }
                {
                  avgLine ? (
                    <div className={style.legendItem}>
                      <div className={style.lineLegendItemColor}></div>{`${avgLegend || 'Stores Average'}`}</div>
                  ) : (
                    <React.Fragment />
                  )
                }
              </div>
            ) : (
              <React.Fragment></React.Fragment>
            )
          }
        </div>
      </div>
    </LoadingScreen>
  )
}

export default BarChart