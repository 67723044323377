import React from 'react';
import style from './UserDetailModal.module.css';
import ModalWrapper from '../../../common/modalWrapper/ModalWrapper';

const UserDetailModal = (props) => {
  const { user, displayModal, setDisplayModal } = props;

  return (
    displayModal ? (
      <ModalWrapper
        allScreen={true}
        display={displayModal}
        setDisplay={setDisplayModal}>
        <div className={style.dialogBody}>
          <p className={style.dialogTitle}>Login Credentials</p>
          <p>username: {user.username}</p>
          <p>password: {user.password}</p>
        </div>
        <div className={style.dialogFooter}>
          <button onClick={() => setDisplayModal(false)}>Accept</button>
        </div>
      </ModalWrapper>
    ) : (
      <React.Fragment />
    )
  )
}

export default UserDetailModal;