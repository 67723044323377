import { FetchService } from '../../../services/Api';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import reportStyle from '../Report.module.css';
import Select from '../../common/select/Select';
import Header from '../../header/Header';
import LoadingScreen from '../../common/loadingScreen/LoadingScreen';
import style from './Management.module.css';
import 'react-input-range/lib/css/index.css';
import { getSpecialtiesByLanguage } from '../../Constants';
import { parseDate } from '../Aux';
import ItemsQualityComparison from '../charts/ItemsQualityComparison';
import ItemsAvgScoreComparison from '../charts/ItemsAvgScoreComparison';
import RegionsPortionControl from '../charts/RegionsPortionControl';
import RegionsPerformanceEvolution from '../charts/RegionsPerformanceEvolution';


const Management = (props) => {
  const { language, selectedDateRange, whenSelectingDateRange, dateRangeMin, dateRangeMax } = props;
  const mountedRef = useRef(true);
  const [selectedCompany, setSelectedCompany] = useState(undefined);
  const [companies, setCompanies] = useState(undefined);
  const [companyMembership, setCompanyMembership] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const displayLoading = useCallback(() => {
    setLoading(true)
  }, [])

  const hideLoading = useCallback(() => {
    setLoading(false)
  }, [])

  useEffect(() => {
    if (companyMembership !== undefined) return;
    let requestConf = {
      endpoint: `dashboard/company-membership/`,
      method: 'GET',
      data: {}
    }

    FetchService(requestConf).then(
      (response) => {
        setCompanyMembership(response.data);
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }, [companyMembership])

  useEffect(() => {
    if (companyMembership === undefined) return;
    if (companyMembership.length === 0) setSelectedCompany({ pk: 'null' });
    else if (companyMembership.length === 1) setSelectedCompany(companyMembership[0].space);
    else {
      let companies = companyMembership.map(m => { return { displayName: m.space.pretty_name || m.space.name, pk: m.space.pk } });
      companies = companies.sort((a, b) => (a.displayName > b.displayName) ? 1 : (b.displayName > a.displayName) ? -1 : 0);
      companies = [...new Set(companies.map(JSON.stringify))].map(JSON.parse);
      setLoading(false);
      if (companies.length === 1) setSelectedCompany(companies[0]);
      else setCompanies(companies);
    }
  }, [companyMembership])

  useEffect(() => {
    if (selectedCompany !== undefined) setLoading(true);
  }, [selectedCompany])

  useEffect(() => {
    if (dateRangeMin === undefined || dateRangeMax === undefined || selectedCompany === undefined) return;
    setData(undefined);
    let requestConf = {
      endpoint: `analytics/management/start_date/${parseDate(dateRangeMin)}/end_date/${parseDate(dateRangeMax)}/company_pk/${selectedCompany.pk}/`,
      method: 'GET',
    }
    FetchService(requestConf)
      .then(response => {
        if (!mountedRef.current) {
          return;
        }
        else {
          setData(response.data);
        }
      })
      .catch(err => hideLoading());
  }, [dateRangeMin, dateRangeMax, selectedCompany, hideLoading, language])

  const [renderingRegionsQualityComparison, setRenderingRegionsQualityComparison] = useState(true);
  const [renderingRegionsAvgScoreComparison, setRenderingRegionsAvgScoreComparison] = useState(true);
  const [renderingRegionsPortionControl, setRenderingRegionsPortionControl] = useState(true);
  const [renderingRegionsPerformanceEvolution, setRenderingRegionsPerformanceEvolution] = useState(true);
  const [specialtySummaryChartData, setSpecialtySummaryChartData] = useState(undefined);
  const [specialtySummaryOptionList, setSpecialtySummaryOptionList] = useState(undefined);
  const [specialtySummarySelectedRegion, setSpecialtySummarySelectedRegion] = useState(undefined);
  const [displayPortionControl, setDisplayPortionControl] = useState(false);
  const [performanceByRegion, setPerformanceByRegion] = useState(undefined);
  const [avgScoreByRegion, setAvgScoreByRegion] = useState(undefined);
  const [performanceEvolution, setPerformanceEvolution] = useState(undefined);
  const [portionControl, setPortionControl] = useState(undefined);

  const onSpecialtySummarySelectedRegion = useCallback((name, selection) => {
    setSpecialtySummarySelectedRegion(selection[0].value);
  }, [])

  useEffect(() => {
    if (data === undefined) {
      setPerformanceByRegion(undefined);
      setAvgScoreByRegion(undefined);
      setPerformanceEvolution(undefined);
      setPortionControl(undefined);
      setSpecialtySummaryChartData(undefined);
      setSpecialtySummaryOptionList(undefined);
    }
  }, [data])

  useEffect(() => {
    if (data === undefined) return;
    setDisplayPortionControl(data.portion_control);
    let options = [];
    let performanceByRegion = { data: [], company_avg: {} };
    let avgScoreByRegion = { data: [] };
    let performanceEvolution = { data: {}, regions: [], company: {} };
    let portionControl = { regions: { data: [] } };
    for (let region of Object.keys(data.data)) {
      options.push({ name: region, value: region, selected: false });
      performanceByRegion.data.push({
        name: region,
        pct_bads: (data.data[region].pct_bads * 100).commaRepresentation(2),
        pct_excellents: (data.data[region].pct_excellents * 100).commaRepresentation(2)
      });
      avgScoreByRegion.data.push({
        name: region,
        avg_score: data.data[region].avg_score.commaRepresentation(2),
      });
      performanceEvolution.regions.push(region);
      performanceEvolution.data[region] = {};
      for (let timeFrame of Object.keys(data.data[region].time_frames)) {
        performanceEvolution.data[region][timeFrame] = [];
        for (let key of Object.keys(data.data[region].time_frames[timeFrame])) {
          performanceEvolution.data[region][timeFrame].push({
            datetime: key,
            total: data.data[region].time_frames[timeFrame][key].count,
            pct_bads: (data.data[region].time_frames[timeFrame][key].pct_bads * 100).commaRepresentation(2),
            pct_goods: (data.data[region].time_frames[timeFrame][key].pct_goods * 100).commaRepresentation(2),
            pct_excellents: (data.data[region].time_frames[timeFrame][key].pct_excellents * 100).commaRepresentation(2)
          });
        }
      }
      portionControl.regions.data.push({
        name: region,
        over_spent: data.data[region].over_spent
      })
    }
    for (let timeFrame of Object.keys(data.company.time_frames)) {
      performanceEvolution.company[timeFrame] = [];
      for (let key of Object.keys(data.company.time_frames[timeFrame])) {
        performanceEvolution.company[timeFrame].push({
          datetime: key,
          total: data.company.time_frames[timeFrame][key].count,
          pct_bads: (data.company.time_frames[timeFrame][key].pct_bads * 100).commaRepresentation(2),
          pct_goods: (data.company.time_frames[timeFrame][key].pct_goods * 100).commaRepresentation(2),
          pct_excellents: (data.company.time_frames[timeFrame][key].pct_excellents * 100).commaRepresentation(2)
        });
      }
    }
    performanceByRegion.company_avg.bads = (data.company.pct_bads * 100).commaRepresentation(2);
    performanceByRegion.company_avg.excellents = (data.company.pct_excellents * 100).commaRepresentation(2);
    avgScoreByRegion.all_venues_avg = data.company.avg_score.commaRepresentation(2);
    options = options.sort((a, b) => (a.name > b.name) ? 1 : (b.name > a.name) ? -1 : 0);
    options.unshift({ name: 'All', value: 'All', selected: true })
    portionControl.ingredients = data.company.portion_control;
    setSpecialtySummarySelectedRegion('All');
    setSpecialtySummaryOptionList(options);
    setPerformanceByRegion(performanceByRegion);
    setAvgScoreByRegion(avgScoreByRegion);
    setPerformanceEvolution(performanceEvolution);
    setPortionControl(portionControl);
  }, [data])

  useEffect(() => {
    if (data === undefined || specialtySummarySelectedRegion === undefined) return;
    let specialties = [];
    let selectedSpecialties;
    if (specialtySummarySelectedRegion === 'All') {
      selectedSpecialties = data.company.specialties;
    }
    else {
      selectedSpecialties = data.data[specialtySummarySelectedRegion].specialties;
    }
    let sortedSpecialties = Object.keys(selectedSpecialties).sort((a, b) => (selectedSpecialties[a].scored_pizzas > selectedSpecialties[b].scored_pizzas) ? -1 : ((selectedSpecialties[b].scored_pizzas > selectedSpecialties[a].scored_pizzas) ? 1 : 0));
    for (let specialty of sortedSpecialties) {
      specialties.push({
        scoredPizzas: `${(selectedSpecialties[specialty].scored_pizzas).commaRepresentation(0)}`,
        avgScore: `${(selectedSpecialties[specialty].avg_score).commaRepresentation(2)}`,
        pctBadPizzas: `${(selectedSpecialties[specialty].percentage_bad_pizzas * 100).commaRepresentation(0)}%`,
        dailyAvg: `${(selectedSpecialties[specialty].daily_avg).commaRepresentation(0)}`,
        overSpent: `$${(selectedSpecialties[specialty].over_spent).commaRepresentation(0)}`,
        specialty: getSpecialtiesByLanguage(language)[specialty],
      });
    }
    setSpecialtySummaryChartData(specialties);
  }, [specialtySummarySelectedRegion, language, data])

  useEffect(() => {
    if (hideLoading && renderingRegionsQualityComparison && renderingRegionsAvgScoreComparison && renderingRegionsPortionControl && renderingRegionsPerformanceEvolution) hideLoading();
  }, [hideLoading, renderingRegionsQualityComparison, renderingRegionsAvgScoreComparison, renderingRegionsPortionControl, renderingRegionsPerformanceEvolution])

  useEffect(() => {
    if (dateRangeMin === undefined || dateRangeMax === undefined || selectedCompany === undefined) return;
    if (displayLoading) displayLoading();
  }, [dateRangeMin, dateRangeMax, selectedCompany, displayLoading])

  return (
    <LoadingScreen loading={loading}>
      <div className={style.wrapper}>
        <Header
          breadcrumb={[{ name: 'Management' }]}
          pageTitle={'Company Summary'}
          selectedDateRange={selectedDateRange}
          whenSelectingDateRange={whenSelectingDateRange} />
        <div>
          {
            companies && selectedCompany === undefined ? (
              <div className={style.companySelector}>
                {
                  companies.map((company, index) => {
                    return <button className={style.companyButton} key={index} onClick={() => setSelectedCompany(company)}>{company.displayName}</button>
                  })
                }
              </div>
            ) : (
              <React.Fragment />
            )
          }
          {
            data !== undefined ? (
              <div>
                <div className={style.featuresRow}>
                  <div className={style.feature}>
                    <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                      <span className={style.featureValue} style={{ color: '#EEB406' }}>{(data.company.avg_score).commaRepresentation(2)}</span>
                      <span className={style.featureLabel}>Company Score</span>
                    </div>
                  </div>
                  <div className={style.feature}>
                    <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                      <span className={style.featureValue} style={{ color: '#F44336' }}>{(data.company.pct_bads * 100).commaRepresentation(0)}%</span>
                      <span className={style.featureLabel}>Company % Bad Pizzas</span>
                    </div>
                  </div>
                  <div className={style.feature}>
                    <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                      <span className={style.featureValue} style={{ color: '#00B807' }}>{(data.company.pct_excellents * 100).commaRepresentation(0)}%</span>
                      <span className={style.featureLabel}>Company % Excellent Pizzas</span>
                    </div>
                  </div>
                  <div className={style.feature}>
                    <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                      <span className={style.featureValue} style={{ color: '#2864C8' }}>{data.company.count.commaRepresentation(0)}</span>
                      <span className={style.featureLabel}>Company Scored Pizzas</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <React.Fragment />
            )
          }
          {
            performanceByRegion && avgScoreByRegion ? (
              <div className={reportStyle.col2}>
                <ItemsQualityComparison
                  data={performanceByRegion}
                  title={`Pizza - Company average vs Regional managers`}
                  avgLegend={`Company Average`}
                  onRendering={setRenderingRegionsQualityComparison} />
                <ItemsAvgScoreComparison
                  data={avgScoreByRegion}
                  title='Score - Company average vs Regional managers'
                  avgLegend={`Company Average`}
                  onRendering={setRenderingRegionsAvgScoreComparison} />
              </div>
            ) : (
              null
            )
          }
          {
            displayPortionControl && portionControl ? (
              <div className={reportStyle.col2}>
                <RegionsPortionControl
                  data={portionControl}
                  onRendering={setRenderingRegionsPortionControl} />
              </div>
            ) : (
              null
            )
          }
          {
            performanceEvolution ? (
              <div className={reportStyle.col1}>
                <RegionsPerformanceEvolution
                  data={performanceEvolution}
                  dateRangeMin={dateRangeMin}
                  dateRangeMax={dateRangeMax}
                  onRendering={setRenderingRegionsPerformanceEvolution} />
              </div>
            ) : (
              null
            )
          }
          {
            specialtySummaryChartData && specialtySummaryOptionList ? (
              <div className={style.specialtySummary}>
                <span className={style.specialtySummaryTitle}>Specialty summary</span>
                <Select multiple={false} callback={onSpecialtySummarySelectedRegion} main={false} optionsList={specialtySummaryOptionList} />
                <br></br>
                {
                  specialtySummaryChartData ? (
                    specialtySummaryChartData.map((specialty, index) => {
                      return (
                        <div className={style.specialtyFeaturesRow} key={index}>
                          <div className={`${style.feature} ${style.specialtyName}`}>
                            <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                              <span className={style.featureLabel}>{specialty.specialty}</span>
                            </div>
                          </div>
                          <div className={style.featuresBox}>
                            <div className={`${style.feature} ${style.specialtyFeature} ${!displayPortionControl ? style.specialtyFeatureNoPortionControl : ''}`}>
                              <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                                <span className={style.featureValue} style={{ color: '#11111B' }}>{specialty.scoredPizzas}</span>
                                <span className={style.featureLabel}>Total scored Pizzas</span>
                              </div>
                            </div>
                            <div className={`${style.feature} ${style.specialtyFeature} ${!displayPortionControl ? style.specialtyFeatureNoPortionControl : ''}`}>
                              <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                                <span className={style.featureValue} style={{ color: '#11111B' }}>{specialty.dailyAvg}</span>
                                <span className={style.featureLabel}>Average Daily</span>
                              </div>
                            </div>
                            <div className={`${style.feature} ${style.specialtyFeature} ${!displayPortionControl ? style.specialtyFeatureNoPortionControl : ''}`}>
                              <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                                <span className={style.featureValue} style={{ color: '#FFC329' }}>{specialty.avgScore}</span>
                                <span className={style.featureLabel}>Score</span>
                              </div>
                            </div>
                            <div className={`${style.feature} ${style.specialtyFeature} ${!displayPortionControl ? style.specialtyFeatureNoPortionControl : ''}`}>
                              <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                                <span className={style.featureValue} style={{ color: '#FF1E2A' }}>{specialty.pctBadPizzas}</span>
                                <span className={style.featureLabel}>% Bad Pizzas</span>
                              </div>
                            </div>
                            {
                              displayPortionControl ? (
                                <div className={`${style.feature} ${style.specialtyFeature}`}>
                                  <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                                    <span className={style.featureValue} style={{ color: '#FD6560' }}>{specialty.overSpent}</span>
                                    <span className={style.featureLabel}>$ Over spent</span>
                                  </div>
                                </div>
                              ) : (
                                <React.Fragment />
                              )
                            }
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <React.Fragment />
                  )
                }
              </div>
            ) : (
              <React.Fragment />
            )
          }
        </div>
      </div>
    </LoadingScreen>
  )
}

export default Management;