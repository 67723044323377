import React, { useState, useEffect, useCallback } from 'react';
import style from './Summary.module.css'
import WeekShift from './weekShift/WeekShift';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Select from '../../../../common/select/Select';
import { DAYS } from '../../../Utils';

const Summary = (props) => {
  const {
    showOnReport,
    featuredBest,
    featuredWorst,
    weekdayShift,
    specialties,
    changeReportState,
    fillReport,
    scorelessReport,
    state,
    reportSpecialtiesDefinition,
    reportStructure,
    mainProblem,
    totalNotToStandard,
    classifiedPizzas,
    trackOfDays,
    whenSelectingDay } = props;
  const [complete, setComplete] = useState(false);
  const [reds, setReds] = useState(false);
  const [daysReviewedOptions, setDaysReviewedOptions] = useState(false);
  const REPORT_PIZZAS_SCORELESS_GOAL = (reportStructure.REPORT_PIZZAS_SCORELESS_GOAL_PER_SHIFT ?? 10) * 2 * 7;
  const DEFAULT_REPORT_PIZZAS_GOAL_PER_SPECIALTY = Math.round((reportStructure.REPORT_PIZZAS_GOAL ?? 30) / Object.keys(specialties ?? [1]).length);

  useEffect(() => {
    if (weekdayShift === undefined) return;
    for (let shift of Object.keys(weekdayShift)) {
      for (let weekday of Object.keys(weekdayShift[shift])) {
        if (weekdayShift[shift][weekday] > reportStructure.REPORT_PIZZAS_SCORELESS_GOAL_PER_SHIFT) {
          setReds(true);
          return;
        }
      }
    }
    setReds(false);
  }, [weekdayShift, reportStructure])

  useEffect(() => {
    if (!scorelessReport) {
      if (showOnReport <= reportStructure.REPORT_PIZZAS_GOAL && featuredBest === 1 && featuredWorst === 1 && classifiedPizzas === totalNotToStandard) {
        setComplete(true);
      }
      else {
        setComplete(false);
      }
    }
    else {
      if (showOnReport <= REPORT_PIZZAS_SCORELESS_GOAL) {
        setComplete(true);
      }
      else {
        setComplete(false);
      }
    }
  }, [showOnReport, featuredWorst, featuredBest, scorelessReport, reportStructure, REPORT_PIZZAS_SCORELESS_GOAL, classifiedPizzas, totalNotToStandard])

  const nexState = useCallback(() => {
    if (!scorelessReport) {
      if (showOnReport === reportStructure.REPORT_PIZZAS_GOAL || (showOnReport < reportStructure.REPORT_PIZZAS_GOAL && window.confirm(`You are about to continue with less than ${reportStructure.REPORT_PIZZAS_GOAL} selected pizzas, do you want to continue?`))) {
        changeReportState('next');
      }
    }
    else {
      if (showOnReport === REPORT_PIZZAS_SCORELESS_GOAL) {
        if (reds) {
          if (window.confirm(`You are about to continue with shifts with more than ${reportStructure.REPORT_PIZZAS_SCORELESS_GOAL_PER_SHIFT} selected pizzas, do you want to continue?`)) {
            changeReportState('next');
          }
        }
        else {
          changeReportState('next');
        }
      }
      else {
        if (reds) {
          if (window.confirm(`You are about to continue with shifts with more than ${reportStructure.REPORT_PIZZAS_SCORELESS_GOAL_PER_SHIFT} selected pizzas, do you want to continue?`)) {
            changeReportState('next');
          }
        }
        else if (showOnReport < REPORT_PIZZAS_SCORELESS_GOAL && window.confirm(`You are about to continue with less than ${REPORT_PIZZAS_SCORELESS_GOAL} selected pizzas, do you want to continue?`)) {
          changeReportState('next');
        }
      }
    }
  }, [changeReportState, showOnReport, scorelessReport, reds, REPORT_PIZZAS_SCORELESS_GOAL, reportStructure])

  useEffect(() => {
    if ([undefined, null].includes(trackOfDays)) return;
    setDaysReviewedOptions(DAYS.map(day => ({ name: day, value: day, selected: trackOfDays[day].checked ? true : false })));
  }, [trackOfDays])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', float: 'right', top: `${daysReviewedOptions ? '10%' : '17%'}`, position: 'sticky', height: '850px', marginTop: '-100px', overflowY: 'auto' }}>
      <div className={style.wrapper}>
        {
          scorelessReport ? (
            null
          ) : (
            <React.Fragment>
              <span className={style.title}>On Report Summary</span>
              {
                specialties !== undefined ? (
                  Object.keys(specialties).map((specialty, index) => {
                    return (
                      <div className={style.row} key={index}>
                        <span>{specialty} <FontAwesomeIcon style={{ transform: 'rotate(45deg)', color: 'red' }} icon={faPlus} /></span><span>{`${specialties[specialty].notToStandardOnReport}/${Math.round(((specialties[specialty].notToStandard / specialties[specialty].notMaybeToStandard) || 0) * (reportSpecialtiesDefinition && reportSpecialtiesDefinition[specialty] ? reportSpecialtiesDefinition[specialty].show_goal || DEFAULT_REPORT_PIZZAS_GOAL_PER_SPECIALTY : DEFAULT_REPORT_PIZZAS_GOAL_PER_SPECIALTY))} (${specialties[specialty].notToStandard}/${specialties[specialty].notMaybeToStandard})`}</span>
                      </div>
                    )
                  })
                ) : (
                  null
                )
              }
              <div className={style.separator} style={{ marginTop: '5px' }}></div>
              {
                specialties !== undefined ? (
                  Object.keys(specialties).map((specialty, index) => {
                    return (
                      <div className={style.row} key={index}>
                        <span>{specialty}</span><span>{`${specialties[specialty].onReport}/${(reportSpecialtiesDefinition && reportSpecialtiesDefinition[specialty] ? reportSpecialtiesDefinition[specialty].show_goal || DEFAULT_REPORT_PIZZAS_GOAL_PER_SPECIALTY : DEFAULT_REPORT_PIZZAS_GOAL_PER_SPECIALTY)}`}</span>
                      </div>
                    )
                  })
                ) : (
                  null
                )
              }
              <div className={style.row}>
                <span>Featured Best</span><span>{`${featuredBest}/${1}`}</span>
              </div>
              <div className={style.row}>
                <span>Featured Worst</span><span>{`${featuredWorst}/${1}`}</span>
              </div>
            </React.Fragment>
          )
        }
        <span className={style.subtitle}>Weekday/Shift</span>
        <WeekShift weekdayShift={weekdayShift} scoreless={scorelessReport} REPORT_PIZZAS_SCORELESS_GOAL_PER_SHIFT={reportStructure.REPORT_PIZZAS_SCORELESS_GOAL_PER_SHIFT} />
        <br />
        <div className={style.condition}>
          <span>Pizzas Selected</span><span>{`(${showOnReport}/${scorelessReport ? REPORT_PIZZAS_SCORELESS_GOAL : reportStructure.REPORT_PIZZAS_GOAL})`}</span>
        </div>
        {
          scorelessReport ? (
            null
          ) : (
            <React.Fragment>
              <span className={style.title}>Problem Count</span>
              <React.Fragment>
                <div className={style.row}>
                  <span>Ingredient Distribution</span><span>{`${mainProblem.ingredient_distribution ?? 0}`}</span>
                </div>
                <div className={style.row}>
                  <span>Circularity</span><span>{`${mainProblem.circularity ?? 0}`}</span>
                </div>
                <div className={style.row}>
                  <span>Overbaked</span><span>{`${mainProblem.overbaked ?? 0}`}</span>
                </div>
                <div className={style.row}>
                  <span>Cut</span><span>{`${mainProblem.cut ?? 0}`}</span>
                </div>
                <div className={style.row}>
                  <span>Bubbles</span><span>{`${mainProblem.bubbles ?? 0}`}</span>
                </div>
                <div className={style.row}>
                  <span>Pizza Size</span><span>{`${mainProblem.pizza_size ?? 0}`}</span>
                </div>
                <div className={style.row}>
                  <span>Cheese Distribution</span><span>{`${mainProblem.cheese_distribution ?? 0}`}</span>
                </div>
                <br />
                <div className={style.condition}>
                  <span>Pizzas Classified</span><span>{`(${classifiedPizzas}/${totalNotToStandard})`}</span>
                </div>
              </React.Fragment>
            </React.Fragment>
          )
        }
        <div className={style.buttons}>
          <button
            className={style.button}
            onClick={() => {
              state === 'data selection' ? (
                window.location = '/pizza-tv-explorer'
              ) : (
                changeReportState('prev')
              )
            }
            }>{'< Exit'}</button>
          <button className={style.button} disabled={complete ? false : true} onClick={() => nexState()}>{'Continue >'}</button>
        </div>
        <React.Fragment>
          <br />
          <button className={style.button} style={{ width: 'inherit' }} onClick={() => {
            if (fillReport === undefined) return;
            fillReport()
          }}>Fill Show on Report</button>
        </React.Fragment>
      </div >
      {
        daysReviewedOptions ? (
          <div className={style.wrapper}>
            <Select
              name='Days reviewed'
              optionsList={daysReviewedOptions}
              multiple={true}
              checkboxes={true}
              callback={whenSelectingDay}
              optionsAlwaysVisible={true} />
          </div >
        ) : (
          null
        )
      }
    </div >
  )
}

export default Summary;