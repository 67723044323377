import { FetchService } from '../../../services/Api';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import BasicChart from '../basicChart/BasicChart';
import { getQualitySelector } from '../Aux';
import { useChartRendering } from '../../../hooks/useChartRendering';


const ItemsQualityComparison = (props) => {
  const { url, title, onCompanyAvg, avgLegend, data } = props;
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const [rawData, setRawData] = useState(undefined);
  const [chartData, setChartData] = useState(undefined);
  const [qualityMetric, setQualityMetric] = useState('Bad');
  const [qualityMetricSelector, setQualityMetricSelector] = useState({});
  useEffect(() => {
    let conf = getQualitySelector(qualityMetric, setQualityMetric);
    conf.optionsList.splice(1, 1); // We are removing good from dropdown
    setQualityMetricSelector({ ...conf })
  }, [qualityMetric])

  useChartRendering(chartData, props.onRendering);

  useEffect(() => {
    if (data !== undefined) setRawData(data);
    else setChartData(undefined);
  }, [data])

  useEffect(() => {
    if (rawData === undefined) return;
    let data = [];
    let cats = [];
    let key = `pct_${qualityMetric.toLowerCase()}s`;
    let sortedData = rawData.data.sort((a, b) => (parseFloat(a[key]) > parseFloat(b[key])) ? -1 : ((parseFloat(b[key]) > parseFloat(a[key])) ? 1 : 0));
    let avg = parseFloat(rawData.company_avg[`${qualityMetric.toLowerCase()}s`]) / 100;
    if (onCompanyAvg) onCompanyAvg(avg);
    let upperTreshold = avg * 1.05;
    let lowerTreshold = avg * 0.95;
    for (let item of sortedData) {
      let pct = parseFloat(item[key]) / 100;
      if (qualityMetric === 'Bad') {
        data.push(pct > upperTreshold ? [pct, 0, 0] : pct < upperTreshold && pct > lowerTreshold ? [0, pct, 0] : [0, 0, pct])
      }
      else {
        data.push(pct > upperTreshold ? [0, 0, pct] : pct <= upperTreshold && pct >= lowerTreshold ? [0, pct, 0] : [pct, 0, 0])
      }
      cats.push(item.name)
    }
    setChartData({ cats: cats, subcats: ['Bad', 'Good', 'Excellent'], data: data, avg: avg })
  }, [rawData, qualityMetric, onCompanyAvg])

  const fetchBadGodExcellent = useCallback(() => {
    if (url === undefined) return;
    setChartData(undefined);
    let requestConf = {
      endpoint: url,
      method: 'GET',
      data: {}
    }
    FetchService(requestConf).then(
      (response) => {
        if (!mountedRef.current) {
          return;
        }
        else {
          setRawData(response.data);
        }
      },
      (err) => {
      }
    ).catch(err => console.log(err));
  }, [url])

  useEffect(() => {
    fetchBadGodExcellent();
  }, [fetchBadGodExcellent])

  return (
    chartData ? (
      <BasicChart
        title={`% ${qualityMetric} ${title}`}
        type='bar'
        tickFormat=',.0%'
        avgTextFormat='%{y}'
        height={500 - 70}
        showYTickLabels={true}
        yrange={[0, 1.05]}
        cats={chartData.cats}
        subcats={chartData.subcats}
        data={chartData.data}
        avgLine={chartData.avg}
        avgLegend={avgLegend}
        subcatsColors={['#FC5245B2', '#FCD049B2', '#27CE2DB2']}
        filters={[qualityMetricSelector]}
        legend={true} />
    ) : (
      <React.Fragment />
    )
  )
}

export default ItemsQualityComparison;