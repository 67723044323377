import React, { useCallback, useEffect, useRef } from 'react';
import { useOutsideClick } from '../../../hooks/useOutisdeClick';
import { useEscPressed } from '../../../hooks/useEscPressed';
import style from './ModalWrapper.module.css';

const ModalWrapper = (props) => {
  const { display, setDisplay, allScreen, fitContent, customWrapperCss, callback, ignoreDisplaychanges } = props;
  const wrapperRef = useRef(null);
  const [outsideClick, setOutsideClick] = useOutsideClick(wrapperRef);

  useEffect(() => {
    if (outsideClick) {
      setOutsideClick(false)
      if (display) {
        if (callback) callback();
        if (ignoreDisplaychanges) return;
        setDisplay(false);
      }
    }
  }, [outsideClick, setOutsideClick, display, setDisplay, callback, ignoreDisplaychanges])

  const closeModal = useCallback(() => {
    if (callback) callback();
    if (ignoreDisplaychanges) return;
    setDisplay(false);
  }, [ignoreDisplaychanges, callback, setDisplay])

  useEscPressed(closeModal);

  return (
    allScreen ? (
      <div className={style.background}>
        <div ref={wrapperRef} className={style.wrapper} style={{...customWrapperCss, ...(fitContent ? { width: 'fit-content' } : {})}}>
          {props.children}
        </div>
      </div>
    ) : (
      <div ref={wrapperRef}>
        {props.children}
      </div>
    )
  )
}

export default ModalWrapper;


