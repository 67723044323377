import { FetchService } from '../../../../services/Api';
import React, { useState, useEffect } from 'react';
import style from './UsersTable.module.css';
import ModalWrapper from '../../../common/modalWrapper/ModalWrapper';
import UserEditForm from './userEditForm/UserEditForm';
import { ReactComponent as PaginationArrow } from '../../../../vectors/pagination-arrow.svg';
import UserDetailModal from '../userDetailModal/UserDetailModal';
import { USERS_PAGE_SIZE } from '../../../Constants';

const Options = (props) => {
  const { user, onDelete, onEdit, onRegionManager } = props;
  const [displayOptions, setDisplayOptions] = useState(false);
  const [editedUser, setEditedUser] = useState(undefined);
  const [displayEditedUserModal, setDisplayEditedUserModal] = useState(false);

  const onBClick = () => {
    console.log(user)
  }

  const resetPassword = (user) => {
    if (window.confirm('Are you Sure') === true) {
      let requestConf = {
        endpoint: `dashboard/update-user-password/${user.pk}/`,
        method: 'GET',
        data: {}
      }

      FetchService(requestConf).then(
        (response) => {
          setEditedUser(response.data);
          setDisplayEditedUserModal(true);
        },
        (err) => {
          console.log(err);
        }
      ).catch(err => console.log(err));
    }
  }

  const deleteUser = (user) => {
    if (window.confirm('Are you Sure') === true) {
      let requestConf = {
        endpoint: `dashboard/user/${user.pk}/`,
        method: 'DELETE',
        data: {}
      }

      FetchService(requestConf).then(
        (response) => {
          setDisplayOptions(false);
          if (onDelete) onDelete()
        },
        (err) => {
          console.log(err);
        }
      ).catch(err => console.log(err));
    }
  }

  const toggleDisplayOptions = () => {
    setDisplayOptions(!displayOptions);
  }

  return (
    <React.Fragment>
      <UserDetailModal user={editedUser} displayModal={displayEditedUserModal} setDisplayModal={setDisplayEditedUserModal} />
      <ModalWrapper
        display={displayOptions}
        setDisplay={setDisplayOptions}>
        <div>
          <button className={style.toggleButton} onClick={toggleDisplayOptions}>...</button>
          {
            displayOptions ? (
              <div className={style.buttonsWrapper}>
                <button onClick={() => resetPassword(user)}>Reset Password</button>
                <button onClick={() => onBClick(user)} disabled>Suspend Account</button>
                <button onClick={() => onEdit(user)}>Add Store/Add Region</button>
                <button onClick={() => onRegionManager(user)}>Set Region Manager</button>
                <button onClick={() => deleteUser(user)}>Delete</button>
              </div>
            ) : (
              <React.Fragment />
            )
          }
        </div>
      </ModalWrapper>
    </React.Fragment>
  )
}


const UsersTable = (props) => {
  const { users, venues, regions, onDelete, selectedCompany } = props;
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [displayEditionForm, setDisplayEditionForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [pageUsers, setPageUsers] = useState([]);
  const [regionManager, setRegionManager] = useState(undefined);

  const pageDown = () => {
    setCurrentPage(Math.max(currentPage - 1, 0));
  }

  const pageUp = () => {
    setCurrentPage(Math.min(currentPage + 1, totalPages));
  }

  useEffect(() => {
    let start = (currentPage - 1) * USERS_PAGE_SIZE;
    let temp = [...users].slice(start, start + USERS_PAGE_SIZE);
    setPageUsers(temp);
  }, [currentPage, users])

  useEffect(() => {
    if (users === undefined) return;
    setTotalUsers(users.length);
    setTotalPages(Math.floor(Math.max(users.length - 1, 0) / USERS_PAGE_SIZE) + 1);
  }, [users])

  const onPageChange = (direction) => {
    if (direction === 'up') {
      pageUp();
    }
    else if (direction === 'down') {
      pageDown();
    }
  }

  const openEditionForm = (user) => {
    setSelectedUser(user);
    setRegionManager(false);
    setDisplayEditionForm(true);
    document.body.style.overflow = 'hidden';
  }

  const onRegionManager = (user) => {
    setSelectedUser(user);
    setRegionManager(true);
    setDisplayEditionForm(true);
    document.body.style.overflow = 'hidden';
  }

  useEffect(() => {
    if (displayEditionForm) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [displayEditionForm])

  return (
    <div className={style.wrapper}>
      {
        displayEditionForm ? (
          <UserEditForm
            user={selectedUser}
            venues={venues}
            regions={regions}
            regionManager={regionManager}
            displayForm={displayEditionForm}
            selectedCompany={selectedCompany}
            setDisplayForm={setDisplayEditionForm} />
        ) : (
          <React.Fragment />
        )
      }

      <div className={style.usersTable}>
        <div className={style.pagination}>
          <span>{`${((currentPage - 1) * USERS_PAGE_SIZE) + 1}-${Math.min(totalUsers, (((currentPage - 1) * USERS_PAGE_SIZE) + USERS_PAGE_SIZE))} of ${totalUsers}`}</span>
          <div className={style.paginationButtons}>
            <button className={`${style.paginationButton} ${currentPage === 1 ? style.disabled : ''}`} onClick={() => { onPageChange('down') }} style={{ transform: 'scaleX(-1)' }}><PaginationArrow /></button>
            <span style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.1)', height: '22px' }}></span>
            <button className={`${style.paginationButton} ${currentPage === totalPages ? style.disabled : ''}`} onClick={() => { onPageChange('up') }}><PaginationArrow /></button>
          </div>
        </div>
        <div className={style.usersTableRow}>
          <span className={style.usersTableHeader}>Email</span>
          <span className={style.usersTableHeader}>Name</span>
          <span className={style.usersTableHeader}>Last name</span>
          <span className={`${style.usersTableHeader} ${style.right}`}></span>
        </div>
        {
          pageUsers.map((user, index) => {
            return <div key={index} className={style.usersTableRow}>
              <span className={style.usersTableCel}>{user.email}</span>
              <span className={style.usersTableCel}>{user.first_name}</span>
              <span className={style.usersTableCel}>{user.last_name}</span>
              <span className={`${style.usersTableCel} ${style.right}`}>
                <Options
                  user={user}
                  onEdit={openEditionForm}
                  onRegionManager={onRegionManager}
                  onDelete={onDelete} />
              </span>
            </div>
          })
        }
      </div>
    </div>
  )
}

export default UsersTable;