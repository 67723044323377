import React, { useState, useEffect, useRef } from 'react';
import { FetchService } from '../../../services/Api';
import style from './PizzaTv.module.css';
import LoadingScreen from '../../common/loadingScreen/LoadingScreen';
import { useParams } from 'react-router-dom';
import check from '../../../vectors/check.svg';
import mark from '../../../vectors/mark.svg';
import tipIcon from '../../../img/pizza_tv_tip.png';
import toStandardIcon from '../../../img/to_standard_icon.png';
import notToStandardIcon from '../../../img/not_to_standard_icon.png';
import { useSearchParams } from 'react-router-dom';
import firstPlace from '../../../vectors/first-place.svg';
import secondPlace from '../../../vectors/second-place.svg';
import thirdPlace from '../../../vectors/third-place.svg';
import kwaliLogoAnim from '../../../img/kwali_logo_anim.gif';
import { PROBLEMS } from '../Utils';


const LABELS = {
  'en': {
    'tip': "Tip of the week",
    'best_in_class': "BEST IN CLASS",
    'last_week': "LAST WEEK'S",
    'performance': "WEEKLY PERFORMANCE",
    'performance_info': "Pizzas to Standard",
    'ranking': "WEEKLY RANKING",
    'ranking_info': "Pizzas to Standard",
  },
  'es': {
    'tip': "Consejo Semanal",
    'best_in_class': "LAS ICÓNICAS",
    'last_week': "SEMANA PASADA",
    'performance': "DESEMPEÑO SEMANAL",
    'performance_info': "Pizzas según el estándar",
    'ranking': "RANKING SEMANAL",
    'ranking_info': "Pizzas según el estándar",
  },
}

const getI18nLabel = (key, lang) => {
  if (lang in LABELS && key in LABELS[lang]) {
    return LABELS[lang][key]
  }
  else if (key in LABELS['en']) {
    return LABELS['en'][key]
  }
  else {
    return key
  }
}

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const Image = ({ card, index, currentIndex }) => {
  return (
    <div className={style.card} key={index} style={index === currentIndex ? { transform: `translate(-${index}00%)` } : { transform: `translate(-${currentIndex}00%)` }}>
      <img
        src={card.completeLink || `${process.env.REACT_APP_IMAGE_SERVER}/api/static/${card.src}`}
        alt=""
        style={{ width: '100%' }} />
      {
        card.standard ? (
          <div className={`${style.cardInfo} ${card.standard === 'to_standard' ? style.infoGreen : style.infoRed}`}>
            <div className={style.cardLegend}>{`${card.weekday.slice(0, 3)} - ${card.timeStamp || (card.shift === 'AM' ? 'Lunch' : 'Dinner')}`}</div>
            <img src={card.standard === 'to_standard' ? check : mark} alt='Pizza' className={style.cardIcon} />
          </div>
        ) : (
          <div className={`${style.cardInfo}`}>
            <div className={style.cardLegend} style={{ opacity: '0' }}>BEST IN CLASS</div>
            <div className={style.cardIconCircle}>
              <img src={firstPlace} alt='' />
            </div>
          </div>
        )
      }
    </div>
  )
};

const isInteger = (str) => {
  let n = Math.floor(Number(str));
  return n !== Infinity && String(n) === str && n >= 0;
}

const PizzaTv = (props) => {
  const { venue } = useParams();
  const [searchParams] = useSearchParams();
  const [report, setReport] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [currentStoreImageIndex, setCurrentStoreImageIndex] = useState(0);
  const [storeImages, setStoreImages] = useState([]);
  const [currentBestInClassImageIndex, setCurrentBestInClassImageIndex] = useState(0);
  const [bestInClassImages, setBestInClassImages] = useState([]);
  const [mainTip, setMainTip] = useState('');
  const [toStandard, setToStandard] = useState('0');
  const [notToStandard, setNotToStandard] = useState('0');
  const [cards, setCards] = useState(undefined);
  const [ranking, setRanking] = useState([]);
  const [rankingPosition, setRankingPosition] = useState(-1);
  const [rankingFirstPart, setRankingFirstPart] = useState([]);
  const [rankingSecondPart, setRankingSecondPart] = useState([]);
  const [lastRankingStoreDisplayed, setLastRankingStoreDisplayed] = useState(0);
  const [language, setLanguage] = useState('en');
  const [labels, setLabels] = useState(LABELS['en']);

  useInterval(() => {
    window.location.reload(true)
  }, 30*60*1000);

  useEffect(() => {
    let lang = searchParams.get('lang') || 'en';
    setLanguage(lang);
  }, [searchParams])

  useEffect(() => {
    let zoom = searchParams.get('zoom');
    if (isInteger(zoom)) {
      let pizzaTvWrapper = document.getElementById("pizza_tv_wrapper");
      pizzaTvWrapper.style.zoom = zoom + '%';
    }
  }, [searchParams])

  useEffect(() => {
    let labelsTmp = {}
    for (let k of Object.keys(LABELS['en'])) {
      labelsTmp[k] = getI18nLabel(k, language)
    }
    setLabels(labelsTmp)
  }, [language])

  useEffect(() => {
    if (venue === undefined) return;
    let requestConf = {
      endpoint: `pizza_tv/pizza_tv/venue_pk/${venue}/`,
      method: 'GET',
      data: searchParams.get('week') ? ({
        week_stamp: searchParams.get('week')
      }) : ({})
    }

    setLoading(true);
    FetchService(requestConf).then(
      (response) => {
        setReport(response.data);
        setRanking(response.data.ranking);
        setBestInClassImages(response.data.best_in_class.map(img => { return { completeLink: `${process.env.REACT_APP_IMAGE_SERVER}/api/static/from_edge/_best_in_class_/${img}` } }))
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err)).finally((res) => setLoading(false));
  }, [venue, searchParams])

  useEffect(() => {
    for (let i = 0; i < ranking.length; i++) {
      ranking[i].position = i + 1;
      if (ranking[i].venue === venue)
        setRankingPosition(i);
    }
  }, [ranking, venue])

  useEffect(() => {
    if (rankingPosition < 0) return;
    if (rankingPosition <= 5) {
      let firstPart = ranking.slice(0, Math.max(rankingPosition + 2, 4))
      setRankingFirstPart(firstPart);
      setLastRankingStoreDisplayed(firstPart[firstPart.length - 1].position)
    }
    else {
      let secondPart = ranking.slice(rankingPosition - 1, rankingPosition + 2);
      setRankingFirstPart(ranking.slice(0, 4));
      setRankingSecondPart(secondPart);
      setLastRankingStoreDisplayed(secondPart[secondPart.length - 1].position)
    }
  }, [rankingPosition, ranking])

  useEffect(() => {
    if (report === undefined) return;
    let storeImages = [];
    let toStandard = 0;
    let tempCards = [];
    let showOnReport = 0;
    for (let pizza of report.pizzas) {
      if (pizza.show_on_report) {
        showOnReport += 1;
        tempCards.push({ main_problem: pizza.main_problem });
        storeImages.push({ src: pizza.img_url, weekday: pizza.weekday, shift: pizza.shift, standard: pizza.standard, timeStamp: pizza.time_stamp });
        if (pizza.standard === 'to_standard') {
          toStandard += 1;
        }
      }
    }
    setCards(tempCards);
    let toStandard_val = ((toStandard / showOnReport) * 100).toFixed(0);
    let notToStandard_val = (100 - (+toStandard_val)).toFixed(0);
    setToStandard(toStandard_val);
    setNotToStandard(notToStandard_val);
    setStoreImages(storeImages);
  }, [report])

  useEffect(() => {
    if (cards === undefined) return;
    let temp = [
      { count: cards.filter((card) => card.main_problem === 'ingredient_distribution').length, issue: 'ingredient_distribution' },
      { count: cards.filter((card) => card.main_problem === 'circularity').length, issue: 'circularity' },
      { count: cards.filter((card) => card.main_problem === 'overbaked').length, issue: 'overbaked' },
      { count: cards.filter((card) => card.main_problem === 'cut').length, issue: 'cut' },
      { count: cards.filter((card) => card.main_problem === 'bubbles').length, issue: 'bubbles' },
      { count: cards.filter((card) => card.main_problem === 'pizza_size').length, issue: 'pizza_size' },
      { count: cards.filter((card) => card.main_problem === 'cheese_distribution').length, issue: 'cheese_distribution' },
    ]
    temp = temp.sort((a, b) => (a.count > b.count) ? -1 : ((b.count > a.count) ? 1 : 0));
    setMainTip(language in PROBLEMS[temp[0].issue].tip? PROBLEMS[temp[0].issue].tip[language] : PROBLEMS[temp[0].issue].tip.en);
  }, [cards, language])

  useInterval(() => {
    if (storeImages.length !== 0) {
      setCurrentStoreImageIndex(
        currentStoreImageIndex === storeImages.length - 1 ? 0 : currentStoreImageIndex + 1
      );
    }
  }, 20000);

  useInterval(() => {
    if (bestInClassImages.length !== 0) {
      setCurrentBestInClassImageIndex(
        currentBestInClassImageIndex === bestInClassImages.length - 1 ? 0 : currentBestInClassImageIndex + 1
      );
    }
    let kwaliLogo = document.getElementById("kwali_logo");
    // Reset image source to trigger gif animation
    kwaliLogo.setAttribute("src", kwaliLogo.getAttribute("src"));
  }, 27000);

  return (
    <LoadingScreen loading={loading}>
      <div id="pizza_tv_wrapper" className={style.wrapper}>
        <div className={style.mainPanel}>
          <div>
            <img id="kwali_logo" src={kwaliLogoAnim} alt=''/>
            <span className={style.title}>TV</span>
          </div>
          <div className={style.carousels}>
            <div className={style.carouselWrapper}>
              <span className={style.carouselTitle}>{labels['best_in_class']}</span>
              {
                bestInClassImages !== undefined ? (
                  <div className={style.carousel}>
                    {bestInClassImages.map((card, index) => (
                      <Image key={index} card={card} index={index} currentIndex={currentBestInClassImageIndex} />
                    ))}
                  </div>
                ) : (
                  null
                )
              }
            </div>
            <div className={style.carouselWrapper}>
              <span className={style.carouselTitle}>{labels['last_week']}</span>
              {
                storeImages !== undefined ? (
                  <div className={style.carousel}>
                    {storeImages.map((card, index) => (
                      <Image key={index} card={card} index={index} currentIndex={currentStoreImageIndex} />
                    ))}
                  </div>
                ) : (
                  null
                )
              }
            </div>
          </div>
          <div className={style.tipWrapper}>
            <div className={style.tipIconWrapper}>
              <img className={style.tipIcon} src={tipIcon} alt='' />
              <span style={{fontSize: '50px'}}>{labels['tip']}</span>
            </div>
            <div className={style.tipTextWrapper}>
              <span className={style.tipText}>{mainTip}</span>
            </div>
          </div>
        </div>
        <div className={style.rightPanel}>
          <div>
            <span className={style.rightPanelTitle}>{labels['performance']}</span>
            <span className={style.rightPanelTitle2}>{labels['performance_info']}</span>
          </div>
          <div>
            <div className={style.rightPanelCardWrapper}>
              <img className={style.rightPanelIcon} src={toStandardIcon} alt='' />
              <div className={style.percentageBar} style={{ width: `${Math.min(toStandard / notToStandard, 1) * 500}px`, backgroundColor: '#118603' }}></div>
              <span className={style.rightPanelCardText}>{`${toStandard}%`}</span>
            </div>
            <br />
            <br />
            <div className={style.rightPanelCardWrapper}>
              <img className={style.rightPanelIcon} src={notToStandardIcon} alt='' />
              <div className={style.percentageBar} style={{ width: `${Math.min(notToStandard / toStandard, 1) * 500}px`, backgroundColor: '#FF0000' }}></div>
              <span className={style.rightPanelCardText}>{`${notToStandard}%`}</span>
            </div>
          </div>
          <div className={style.franchiseRanking}>
            <span className={style.rightPanelTitle}>{labels['ranking']}</span>
            <span className={style.rightPanelTitle2}>{labels['ranking_info']}</span>
            <div className={style.rankingTable}>
              {
                rankingFirstPart.map((report, index) => {
                  return <div
                    key={index}
                    className={`${style.rankingRow} ${report.venue === venue ? style.rankingPrincipal : ''} ${index > 2 ? style.rankingNotPrincipal : ''}`}>
                    <span className={style.rankingFirstCol}>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              {
                                report.position === 1 ? (
                                  <img src={firstPlace} alt='' style={{ height: '45px' }} />
                                ) : (
                                  report.position === 2 ? (
                                    <img src={secondPlace} alt='' style={{ height: '45px' }} />
                                  ) : (
                                    report.position === 3 ? (
                                      <img src={thirdPlace} alt='' style={{ height: '45px' }} />
                                    ) : (
                                      `${report.position}.`
                                    )
                                  )
                                )
                              }
                            </td>
                            <td>
                              {`${report.doc.venue || report.venue}`}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </span>
                    <span>
                      {`${report.to_standard_percentage}%`}
                    </span>
                  </div>
                })
              }
              {
                rankingSecondPart.length > 0 ? (
                  <React.Fragment>
                    <span
                      className={`${style.rankingRow} ${style.rankingNotPrincipal}`}>
                      {' ...'}
                    </span>
                    {
                      rankingSecondPart.map((report, index) => {
                        return <div
                          key={index}
                          className={`${style.rankingRow} ${style.rankingNotPrincipal} ${report.venue === venue ? style.rankingPrincipal : ''} `}>
                          <span className={style.rankingFirstCol}>
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    {`${report.position}.`}
                                  </td>
                                  <td>
                                    {`${report.doc.venue || report.venue}`}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </span>
                          <span>
                            {`${report.to_standard_percentage}%`}
                          </span>
                        </div>
                      })
                    }
                  </React.Fragment>
                ) : (
                  null
                )
              }
              {
                ranking.length > lastRankingStoreDisplayed ? (
                  <span
                    className={`${style.rankingRow} ${style.rankingNotPrincipal}`}>
                    {' ...'}
                  </span>
                ) : (
                  null
                )
              }
            </div>
          </div>
        </div>
      </div>
    </LoadingScreen>
  );
}

export default PizzaTv;