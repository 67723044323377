import React, { useState, useEffect } from 'react';
import style from './Table.module.css';

const Table = (props) => {
  const { name, scoreDetails, decimals } = props;
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let temp = 0;
    Object.keys(scoreDetails).forEach(function (key, index) {
      temp += scoreDetails[key]
    })
    setTotal(temp);
  }, [scoreDetails]);

  return (
    <div className={style.wrapper}>
      <div className={style.information}>
        <span className={style.name}>{name}</span>
        <span className={style.score}>{total.toFixed(decimals)}</span>
      </div>
      {
        Object.keys(scoreDetails).length > 1 ? (
          <table className={style.table}>
            <tbody>
              <tr>
                {
                  Object.keys(scoreDetails).map((key, index) => {
                    return <th key={index}>{key}</th>
                  })
                }
              </tr>
              <tr>
                {
                  Object.keys(scoreDetails).map((key, index) => {
                    return <td key={index}>{scoreDetails[key].toFixed(decimals)}</td>
                  })
                }
              </tr>
            </tbody>
          </table>
        ) : (
          <React.Fragment />
        )
      }
    </div>
  )
}

export default Table