import { FetchService } from '../../../services/Api';
import DateSelect from '../dateSelect/DateSelect';
import React, { useState, useEffect, useCallback, useRef } from 'react';

const VenueBaseDateSelect = (props) => {
  const { venue, date, callback, main } = props;
  const [selectedVenue, setSelectedVenue] = useState(undefined);
  const [yearMonth, setYearMonth] = useState(undefined);
  const [excludedDates, setExcludedDates] = useState(undefined);
  const [datesWithInformation, setDatesWithInformation] = useState({});
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => { 
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (venue) {
      setSelectedVenue(venue)
      setDatesWithInformation({});
    }
  }, [venue])

  const updateInspectedMonth = (year, month) => {
    setYearMonth([year, month]);
  }

  const updateExcludedDates = useCallback((daysWithData, update) => {
    let newDatesWithInformation = datesWithInformation;
    if (update) newDatesWithInformation[`${yearMonth[0]}-${yearMonth[1]}`] = daysWithData;
    let excludedDates = [];
    if (((process.env.REACT_APP_DEMO || 'false').toLowerCase() === 'false')) {
      for (let day = 1; day <= 31; ++day) {
        if (daysWithData.includes(day)) continue;
        excludedDates.push(new Date(yearMonth[0], yearMonth[1].toString().padStart(2, '0') - 1, day))
      }
    }
    setExcludedDates(excludedDates);
    setDatesWithInformation(newDatesWithInformation);
  }, [datesWithInformation, yearMonth])

  useEffect(() => {
    if (!selectedVenue || !yearMonth) return;
    setExcludedDates(undefined);

    if (datesWithInformation[`${yearMonth[0]}-${yearMonth[1]}`] !== undefined) {
      updateExcludedDates(datesWithInformation[`${yearMonth[0]}-${yearMonth[1]}`]);
      return;
    }

    let requestDetails = {
      endpoint: 'charts/dates/',
      method: 'GET',
      data: {
        venue: selectedVenue,
        year: yearMonth[0],
        month: yearMonth[1].toString().padStart(2, '0')
      }
    }

    FetchService(requestDetails).then(
      (response) => {
        if (response && response.data) {
          if (!mountedRef.current) {
            return;
          }
          else {
            updateExcludedDates(response.data.data, true);
          }
        }
      }
    ).catch(err => console.log(err));
  }, [selectedVenue, yearMonth, datesWithInformation, updateExcludedDates]);

  useEffect(() => {
    if (!date) return;
    if (!yearMonth)
      setYearMonth([date.year, date.month]);
  }, [date, yearMonth])

  return <DateSelect
    label='Select date'
    name='Select date'
    defStartDate={date ? new Date(date.year, date.month - 1, date.day) : undefined}
    onYearMonthChange={updateInspectedMonth}
    excludedDates={excludedDates}
    quickSelection={true}
    callback={callback}
    main={main} />
}

export default VenueBaseDateSelect;