import { FetchService } from '../../../services/Api';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import BasicChart from '../basicChart/BasicChart';
import { getGranularitySelector, getGranularityCats } from '../Aux';
import { useChartRendering } from '../../../hooks/useChartRendering';


const VenueTotalPizzas = (props) => {
  const { url, dateRange, selectedVenue } = props;
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const [completedUrl, setCompletedUrl] = useState(undefined);
  const [rawData, setRawData] = useState(undefined);
  const [chartData, setChartData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [totalPizzasGranularity, setTotalPizzasGranularity] = useState('weekly')
  const [totalPizzasGranularitySelector, setTotalPizzasGranularitySelector] = useState({});

  useChartRendering(chartData, props.onRendering);

  useEffect(() => {
    setTotalPizzasGranularitySelector({ ...getGranularitySelector(totalPizzasGranularity, setTotalPizzasGranularity) });
  }, [totalPizzasGranularity])

  useEffect(() => {
    if (rawData === undefined) return;
    let dictData = {};
    for (let period of rawData.data) {
      dictData[period._id] = period.total_pizzas;
    }
    let conf = getGranularityCats(dateRange, dictData, totalPizzasGranularity)
    setChartData({ cats: conf.cats, subcats: ['Count'], data: conf.data, customXAxis: { tickVals: conf.tickVals, tickText: conf.tickText } })
    setLoading(false);
  }, [rawData, totalPizzasGranularity, dateRange])

  useEffect(() => {
    if (url === undefined || selectedVenue === undefined || totalPizzasGranularity === undefined) return;
    setCompletedUrl(`analytics/production/total/venue/${selectedVenue}/type/${totalPizzasGranularity}/${url}`)
  }, [url, selectedVenue, totalPizzasGranularity])

  useEffect(() => {
    setChartData(undefined);
  }, [url, selectedVenue])

  const fetchTotal = useCallback(() => {
    if (completedUrl === undefined) return;
    setLoading(true);
    let requestConf = {
      endpoint: completedUrl,
      method: 'GET',
      data: {
      }
    }
    FetchService(requestConf).then(
      (response) => {
        if (!mountedRef.current) {
          return;
        }
        else {
          setRawData(response.data);
          setLoading(false);
        }
      },
      (err) => {
      }
    ).catch(err => console.log(err));
  }, [completedUrl])

  useEffect(() => {
    fetchTotal();
  }, [fetchTotal])

  return (
    chartData ? (
      <BasicChart
        type='Scater'
        title='Total production (whole period)'
        height={500 - 70}
        customXAxis={chartData.customXAxis}
        cats={chartData.cats}
        subcats={chartData.subcats}
        subcatsColors={['#5BC4FF']}
        data={chartData.data}
        filters={[totalPizzasGranularitySelector]}
        selection={[totalPizzasGranularity]}
        loading={loading} />
    ) : (
      <React.Fragment />
    )
  )
}

export default VenueTotalPizzas;