function getSpecialtyShowGoal(reportSpecialtiesDefinition, specialty) {
    return reportSpecialtiesDefinition && reportSpecialtiesDefinition[specialty] ? reportSpecialtiesDefinition[specialty].show_goal || 15 : 15;
}

function reportSpecialtyStandardCounts(report, specialty, reportStructure) {
    /*
    - report: object from ReportControl
    */
    let N = report.pizzas.filter(p => p.specialty === specialty && p.standard === 'not_to_standard').length,
        M = report.pizzas.filter(p => p.specialty === specialty && p.standard === 'maybe_to_standard').length,
        S = report.pizzas.filter(p => p.specialty === specialty && p.standard === 'to_standard').length,
        t = getSpecialtyShowGoal(reportStructure.REPORT_SPECIALTIES_DEFINITION, specialty);
    return { N, M, S, t }
}

function stressTestSpecialtyData(specialtyStandardCounts) {
    // specialtyStandardCounts: output object from reportSpecialtyStandardCounts

    let { N, M, S, t } = specialtyStandardCounts,
        /* see src/components/pizzaTv/pizzavalidation/pizzaValidationPart1/summary/Summary.js:
            ${Math.round(((specialties[specialty].notToStandard / specialties[specialty].notMaybeToStandard) || 0) * 15)}
        */
        Nt = Math.round(N / (N + S) * t); // 'not_to_standard' 'show_on_report' goal for specialty
   
    function sum(a, b) { return a + b; }
    function substract(a, b) { return a - b; }
    function leave(a, b) { return a; }

    function stressScoreDelta(iterEnd, nFun, sFun, nDelta) {
        let delta = Infinity,
            Nt_d;
        
        for(let d = 1; d <= iterEnd; d++) {
            Nt_d = Math.round(nFun(N,d) / (nFun(N,d) + sFun(S,d)) * t);
            if(Nt_d === Nt + nDelta) {
                delta = d;
                break;
            }
        }
        return delta;
    }
    
    // ------------------ Decrease score ...
    // Option 1: by "adding" bad pizzas (ie changing 'maybe_to_standard' -> 'not_to_standard')
    let d_a = stressScoreDelta(M, sum, leave, 1);

    // Option 2: by "removing" good pizzas (ie changing 'to_standard' -> 'maybe_to_standard')
    let d_r = stressScoreDelta(S, leave, substract, 1);

    // ------------------ Increase score ...
    // Option 1: by "adding" good pizzas (ie changing 'maybe_to_standard' -> 'to_standard')
    let i_a = stressScoreDelta(M, leave, sum, -1);
    
    // Option 2: by "removing" bad pizzas (ie changing 'not_to_standard' -> 'maybe_to_standard')
    let i_r = stressScoreDelta(N, substract, leave, -1);
    
    return { Nt, d_a, d_r, i_a, i_r, t }
}

function stressTestSpecialtyResolve(specialtyData){
    // specialtyData: output object from stressTestSpecialtyData

    let {Nt, d_a, d_r, i_a, i_r, t} = specialtyData;

    function resolve(opt_a, opt_r, nDelta){
        let info = null, // delta info
            score = null, // score after delta
            opt_m = Math.min(opt_a, opt_r);
        
        if(opt_m < Infinity) {
            score = Math.round((t - (Nt+nDelta)) / t * 100);
            info = {};
            info.delta = opt_m;
            //"removing" has priority over "adding"
            info.op = opt_m === opt_r ? 'remove' : 'add';
        }

        return { info, score }
    }

    // Decrease resolution
    let dec = resolve(d_a, d_r, 1),
        d_info = dec.info,
        d_score = dec.score;

    // Increase resolution
    let inc = resolve(i_a, i_r, -1),
        i_info = inc.info,
        i_score = inc.score;

    return { Nt, d_info, d_score, i_info, i_score };
}

function stressTestFormat(resolveData) {
    // resolveData: output object from stressTestSpecialtyResolve
    let { d_info, d_score, i_info, i_score } = resolveData;

    function format(info, score, addBad) {
        let info_s = '', // delta string
            score_s = '', // string representation of score after delta
            bad_s = 'B',
            good_s = 'G';

        if(info === null) {
            info_s = 'X';
            score_s = 'X';
        }
        else {
            score_s = `${score}%`;
            if(info.op === 'add') {
                info_s = `+${info.delta}${addBad ? bad_s : good_s}`;
            }
            else {
                info_s = `-${info.delta}${addBad ? good_s : bad_s}`;
            }
        }

        return { info_s, score_s }
    }

    // Decrease format
    let dec = format(d_info, d_score, true),
        d_info_s = dec.info_s,
        d_score_s = dec.score_s;

    // Increase format
    let inc = format(i_info, i_score, false),
        i_info_s = inc.info_s,
        i_score_s = inc.score_s;

    return { d_info_s, d_score_s, i_info_s, i_score_s }
}

function stressTest(standardCounts) {
    //standardCounts values: output objects from reportSpecialtyStandardCounts
    let specialties = Object.keys(standardCounts);
    let specsResolve = {};
    specialties.forEach(s => {
        specsResolve[s] = stressTestSpecialtyResolve(stressTestSpecialtyData(standardCounts[s]));
    });

    let formats = {};
    specialties.forEach(s => {
        formats[s] = stressTestFormat(specsResolve[s]);
    })

    let s_goals = specialties.map(s => {return standardCounts[s].t}),
        s_Nts = specialties.map(s => {return specsResolve[s].Nt}),
        s_d_infos = specialties.map(s => {return specsResolve[s].d_info}),
        s_i_infos = specialties.map(s => {return specsResolve[s].i_info});

    function resolveTotal(s_infos, nDelta){
        let info, // delta info
            score; // score after delta
        
        let s_infos_valid = s_infos.filter(s => s !== null);
        if (s_infos_valid.length === 0) {
            info = null;
            score = null;
        }
        else {
            info = {};
            let s_infos_sorted = s_infos_valid.sort((a, b) => a.delta - b.delta);
            let min_delta = s_infos_sorted[0].delta;
            info.delta = min_delta;
            let s_infos_min = s_infos_valid.filter(s => s.delta === min_delta && s.op === 'remove');
            info.op = s_infos_min.length >= 1 ? 'remove' : 'add';

            let total_goal = s_goals.reduce((s, g) => s + g, 0),
                total_Nt = s_Nts.reduce((s, n) => s + n, 0);
            score = Math.round((total_goal - (total_Nt+nDelta)) / total_goal * 100);
        }
        return { info, score }
    }

    // Total Decrease resolution
    let dec = resolveTotal(s_d_infos, 1),
        d_info = dec.info,
        d_score = dec.score;
    
    // Total Increase resolution
    let inc = resolveTotal(s_i_infos, -1),
        i_info = inc.info,
        i_score = inc.score;

    let totalResolve = { d_info, d_score, i_info, i_score }

    formats._total_ = stressTestFormat(totalResolve);
    return formats;
}

function stressTestReport(reportObject, specialties, reportStructure) {
    // reportObject: report object from ReportControl.js
    if (specialties.length < 2) return;
    let standardCounts = {}
    specialties.forEach(s => { standardCounts[s] = reportSpecialtyStandardCounts(reportObject, s, reportStructure) });
    return stressTest(standardCounts);
}


// To integrate in ReportControl.js
/*
let report; // report object from ReportControl.js
console.log(stressTestReport(report));
*/

// To test with mock data
/*
let pepStdCnts = { N: 20, M: 85, S: 120 },
    chsStdCnts = { N: 10, M: 6, S: 9 };
console.log(stressTest(pepStdCnts, chsStdCnts));

{
    "pepperoni": {
        "d_info_s": "+4B",
        "d_score_s": "80%",
        "i_info_s": "-7B",
        "i_score_s": "93%"
    },
    "cheese": {
        "d_info_s": "-2G",
        "d_score_s": "40%",
        "i_info_s": "-2B",
        "i_score_s": "53%"
    },
    "total": {
        "d_info_s": "-2G",
        "d_score_s": "63%",
        "i_info_s": "-2B",
        "i_score_s": "70%"
    }
}
*/

export default stressTestReport;