import { useState, useEffect, useCallback } from 'react';

export const useOutsideClick = (ref) => {
  const [clickedOutsie, setClickedOutsie] = useState();
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setClickedOutsie(true);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const customSetClickedOutsie = useCallback((newValue) => {
    setClickedOutsie(newValue);
  }, []);

  return [clickedOutsie, customSetClickedOutsie];
}