import React, { useState, useEffect, useCallback } from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import style from './Range.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ModalWrapper from '../../common/modalWrapper/ModalWrapper';

const Range = (props) => {
  const { name, min, max, callback, mustResetFilters } = props;
  const [value, setValue] = useState({ min: min, max: max });
  const [tempValue, setTempValue] = useState({ min: min, max: max });
  const [displayRange, setDisplayRange] = useState(false);

  const setNewRange = useCallback(() => {
    setValue(tempValue);
    if (callback)
      callback(name, tempValue);
    setDisplayRange(!displayRange);
  }, [tempValue, callback, displayRange, name])

  const clear = useCallback(() => {
    setTempValue({ min: min, max: max });
  }, [min, max])

  const updateRange = (value) => {
    setTempValue(value);
  }

  const toggleDisplayRange = () => {
    setDisplayRange(!displayRange);
  }

  const iconStyles = {
    transform: 'rotate(90deg)'
  }

  const formatLabel = (value) => {
    if (Math.trunc(value) % 12 === 0)
      return `12:${((value % 1) * 60).commaRepresentation(0).padStart(2, '0')}${value < 12 || Math.trunc(value) === 0 || Math.trunc(value) === 24 ? 'am' : 'pm'}`
    return `${Math.trunc(value) % 12}:${((value % 1) * 60).commaRepresentation(0).padStart(2, '0')}${value < 12 ? 'am' : 'pm'}`
  }

  useEffect(() => {
    if (mustResetFilters) {
      setTempValue({ min: min, max: max });
      setValue({ min: min, max: max });
      if (callback)
        callback(name, { min: min, max: max });
    }
  }, [mustResetFilters, callback, name, min, max])

  return (
    <ModalWrapper
      display={displayRange}
      setDisplay={setDisplayRange}>
      <div className={style.wrapper}>
        {/* <span className={style.boxWrapperLabel}>{name}</span> */}
        <div name={name} className={`${style.boxWrapper} ${value.min !== min || value.max !== max ? style.highlight : ''}`} onClick={toggleDisplayRange}>
          <span>
            {
              formatLabel(value.min) !== formatLabel(value.max) ? (
                `${formatLabel(value.min)} - ${formatLabel(value.max)}`
              ) : (
                `${name}`
              )
            }
          </span>
          <FontAwesomeIcon style={iconStyles} icon={faAngleRight} />
        </div>
        {
          displayRange ? (
            <div className={`${style.rangeWrapper} range-wrapper`}>
              <InputRange
                step={.5}
                maxValue={max}
                minValue={min}
                value={tempValue}
                onChange={value => updateRange(value)}
                formatLabel={formatLabel}>
              </InputRange>
              <span style={
                {
                  marginTop: '-33px',
                  marginLeft: 'calc(50% - 31px)',
                  left: '0',
                  position: 'absolute',
                  fontSize: '14px'
                }
              }>12:00pm</span>
              <div className={style.buttonsWrapper}>
                <div className={style.clearApply}>
                  <button onClick={clear}>Clear</button>
                  <button onClick={setNewRange} >Apply</button>
                </div>
                {/* <button onClick={() => { console.log(backup); console.log(options) }}>inspect</button> */}
              </div>
            </div>
          ) : (
            <div></div>
          )
        }
      </div>
    </ModalWrapper>
  )
}

export default Range;


