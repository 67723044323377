import { FetchService } from '../../services/Api';
import React, { useState, useEffect, useRef } from 'react';
import style from './Home.module.css';
import reportStyle from '../report/Report.module.css';
import LoadingScreen from '../common/loadingScreen/LoadingScreen';
import { UI_TEXT, getSpecialtiesByLanguage } from '../Constants';
import Header from '../header/Header';
import Table from '../report/table/Table';
import shiftInsight from '../../vectors/shift-insight.svg';
import bestSpecialty from '../../vectors/best-specialty.svg';
import worstSpecialty from '../../vectors/worst-specialty.svg';
import bestAttribute from '../../vectors/best-attribute.svg';
import worstAttribute from '../../vectors/worst-attribute.svg';
import excellentPizzas from '../../vectors/excellent-pizzas.svg';
import badPizzas from '../../vectors/bad-pizzas.svg';
import { INGREDIENT_ICON } from '../Constants';


const Home = (props) => {
  const { language, venues, selectedVenue, selectedDate, whenSelectingVenue, whenSelectingDate } = props;
  const [rawData, setRawData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [selectedVenuePrettyName, setSelectedVenuePrettyName] = useState(undefined);
  const [portionControl, setPortionControl] = useState(undefined);
  const [portionControlChartData, setPortionControlChartData] = useState(undefined);
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => { 
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (portionControl === undefined || Object.keys(portionControl).length === 0) return;
    let data = [];
    let sortedIngredients = Object.keys(portionControl).sort((a, b) => (a > b) ? -1 : (b > a) ? 1 : 0);
    for (let ingredient of sortedIngredients) {
      let overUsed = portionControl[ingredient].over_used * 100;
      let overSpent = portionControl[ingredient].over_spent;
      let overUsedColor = overUsed > 0 ? '#F44336' : '#00B807';
      data.push([{ img: INGREDIENT_ICON[ingredient], imgStyle: { width: '35px' }, text: ingredient, style: { color: '#11111B' } }, { text: `$ ${overSpent.commaRepresentation(2)}`, style: { color: overUsedColor } }, { text: `${overUsed.commaRepresentation(0)} %`, style: { color: overUsedColor } }])
    }
    setPortionControlChartData({ title: 'Food usage vs recipes', header: ['Ingredient', '$ Over spent', '% Over used'], data: data })
  }, [portionControl])

  useEffect(() => {
    if (selectedVenue === undefined || selectedDate === undefined) return;
    setPortionControlChartData(undefined);
    let requestConf = {
      endpoint: `analytics/portion_control/venue/${selectedVenue}/start_date/${selectedDate.day}-${selectedDate.month}-${selectedDate.year}/end_date/${selectedDate.day}-${selectedDate.month}-${selectedDate.year}/`,
      method: 'GET',
      data: {}
    }

    FetchService(requestConf).then(
      (response) => {
        if (!mountedRef.current) {
          return;
        }
        else {
          setPortionControl(response.data.ingredients);
        }
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }, [selectedDate, selectedVenue])

  useEffect(() => {
    if (selectedVenue === undefined || selectedDate === undefined) return;
    setLoading(true);
    let requestConf = {
      endpoint: `analytics/home/venue/${selectedVenue}/date/${selectedDate.day}-${selectedDate.month}-${selectedDate.year}/`,
      method: 'GET',
      data: {}
    }

    FetchService(requestConf).then(
      (response) => {
        if (!mountedRef.current) {
          return;
        }
        else {
          setRawData(response.data.data);
          setLoading(false);
        }
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }, [selectedDate, selectedVenue])

  useEffect(() => {
    if (venues && venues.length > 0) {
      let requestConf = {
        endpoint: 'dashboard/venue_pretty_name/',
        method: 'POST',
        data: {
          venues: [selectedVenue]
        },
        dataType: 'JSON'
      }

      FetchService(requestConf).then(
        (response) => {
          if (!mountedRef.current) {
            return;
          }
          else {
            setSelectedVenuePrettyName(response.data.venues[selectedVenue]);
          }
        },
        (err) => {
          console.log(err);
        }
      ).catch(err => console.log(err));
    }
  }, [selectedVenue, venues])

  return (
    <LoadingScreen loading={loading}>
      <div className={style.wrapper}>
        <Header breadcrumb={[{ name: 'Daily Insghts' }]} pageTitle={`${selectedVenuePrettyName ? selectedVenuePrettyName + ' ' : ''}daily insights`} venues={venues} selectedVenue={selectedVenue} selectedDate={selectedDate} whenSelectingVenue={whenSelectingVenue} whenSelectingDate={whenSelectingDate} />
        <div className={style.pageContent}>
          {
            rawData !== undefined ? (
              <div>
                <div className={style.summary}>
                  <h4>Summary</h4>
                </div>

                <div className={style.featuresRow}>
                  <div className={style.feature}>
                    <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                      <span className={style.featureValue} style={{ color: '#00B807' }}>{rawData.scored_pizzas.split('/')[0]}</span>
                      <span className={style.featureLabel}>Scored pizzas</span>
                    </div>
                  </div>
                  <div className={style.feature}>
                    <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                      <span className={style.featureValue} style={{ color: '#F44336' }}>{rawData.scored_pizzas.split('/')[1]}</span>
                      <span className={style.featureLabel}>Detected pizzas</span>
                    </div>
                  </div>
                  <div className={style.feature}>
                    <div className={style.featureDetail} style={{ alignItems: 'center' }}>
                      <span className={style.featureValue} style={{ color: '#EEB406' }}>{parseFloat(rawData.avg_score).commaRepresentation(2)}</span>
                      <span className={style.featureLabel}>Average score</span>
                    </div>
                  </div>
                </div>

                <div className={style.featuresRow}>
                  <div className={style.feature}>
                    <div className={style.featureImg}>
                      <img src={shiftInsight} alt='Best shift' />
                    </div>
                    <div className={style.featureDetail}>
                      <span className={style.featureValue}>{rawData.best_shift}</span>
                      <span className={style.featureLabel}>Best Shift</span>
                    </div>
                  </div>
                  <div className={style.feature}></div>
                  <div className={style.feature}></div>
                </div>

                <div className={style.featuresRow}>
                  <div className={style.feature}>
                    <div className={style.featureContent}>
                      <div className={style.featureImg}>
                        <img src={excellentPizzas} alt='Excellent pizzas' />
                      </div>
                      <div className={style.featureDetail}>
                        <span className={style.featureValue}>{`${rawData.num_excellent_pizzas} (${rawData.pct_excellent_pizza}%)`}</span>
                        <span className={style.featureLabel}>Excellent pizzas</span>
                      </div>
                    </div>
                  </div>
                  <div className={style.feature}>
                    <div className={style.featureContent}>
                      <div className={style.featureImg}>
                        <img src={bestSpecialty} alt='Best specialty' />
                      </div>
                      <div className={style.featureDetail}>
                        <span className={style.featureValue}>{getSpecialtiesByLanguage(language)[rawData.best_specialty]}</span>
                        <span className={style.featureLabel}>Best specialty</span>
                      </div>
                    </div>
                  </div>
                  <div className={style.feature}>
                    <div className={style.featureContent}>
                      <div className={style.featureImg}>
                        <img src={bestAttribute} alt='Best attribute' />
                      </div>
                      <div className={style.featureDetail}>
                        <span className={style.featureValue}>{UI_TEXT.evaluation[rawData.best_criteria]}</span>
                        <span className={style.featureLabel}>Best attribute</span>
                      </div>
                    </div>
                  </div>
                  <div className={style.feature}>
                    <div className={style.featureContent}>
                      <div className={style.featureImg}>
                        <img src={badPizzas} alt='Bad pizzas' />
                      </div>
                      <div className={style.featureDetail}>
                        <span className={style.featureValue}>{`${rawData.num_bad_pizzas} (${rawData.pct_bad_pizza}%)`}</span>
                        <span className={style.featureLabel}>Bad pizzas</span>
                      </div>
                    </div>
                  </div>
                  <div className={style.feature}>
                    <div className={style.featureContent}>
                      <div className={style.featureImg}>
                        <img src={worstSpecialty} alt='Worst specialty' />
                      </div>
                      <div className={style.featureDetail}>
                        <span className={style.featureValue}>{getSpecialtiesByLanguage(language)[rawData.worst_specialty]}</span>
                        <span className={style.featureLabel}>Worst specialty</span>
                      </div>
                    </div>
                  </div>
                  <div className={style.feature}>
                    <div className={style.featureContent}>
                      <div className={style.featureImg}>
                        <img src={worstAttribute} alt='Worst attribute' />
                      </div>
                      <div className={style.featureDetail}>
                        <span className={style.featureValue}>{UI_TEXT.evaluation[rawData.worst_criteria]}</span>
                        <span className={style.featureLabel}>Worst attribute</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={reportStyle.col2}>
                  {
                    portionControlChartData ? (
                      <Table
                        title={portionControlChartData.title}
                        header={portionControlChartData.header}
                        data={portionControlChartData.data} />
                    ) : (
                      <React.Fragment />
                    )
                  }
                </div>
              </div>
            ) : (
              <React.Fragment />
            )
          }
        </div>
      </div>
    </LoadingScreen>
  )
}

export default Home;