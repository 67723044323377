import React, { useState, useEffect, useCallback } from 'react';
import style from './PizzaValidationPart1.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faQuestion, faCheck, faRecycle } from '@fortawesome/free-solid-svg-icons';
import Summary from './summary/Summary';
import { FetchService } from '../../../../services/Api';
import LoadingScreen from '../../../common/loadingScreen/LoadingScreen';
import { getSpecialtiesByLanguage } from '../../../Constants';
import ModalWrapper from '../../../common/modalWrapper/ModalWrapper';
import SmartChange from '../../common/smartChange/SmartChange';
import StepSelector from '../../common/stepSelector/StepSelector';
import { getSimilarPizzas, PROBLEMS, DAYS } from '../../Utils';
import Select from '../../../common/select/Select';


const PizzaValidationPart1 = (props) => {
  const { report, language, filters, changeReportState, fillReport, whenSpecialtiesIdentified, changeState, specialtiesDefinition, reportStructure } = props;
  const [inspectedReport, setInspectedReport] = useState(undefined);
  const [cards, setCards] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [displayInspectedPicture, setDisplayInspectedPicture] = useState(false);
  const [inspectedPicture, setInspectedPicture] = useState(undefined);
  const [showOnReport, setShowOnReport] = useState(0);
  const [toStandard, setToStandard] = useState(0);
  const [notToStandard, setNotToStandard] = useState(0);
  const [featuredBest, setFeaturedBest] = useState(0);
  const [featuredWorst, setFeaturedWorst] = useState(0);
  const [specialties, setSpecialties] = useState({});
  const [reportSpecialties, setReportSpecialties] = useState(undefined);
  const [scorelessVersion, setScorelessVersion] = useState(false);
  const [weekdayShift, setWeekdayShift] = useState({
    AM: {
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0
    },
    PM: {
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
      Sunday: 0
    }
  });
  const [pizzaCandidates, setPizzaCandidates] = useState([]);
  const [pizzaToBeReplaced, setPizzaToBeReplaced] = useState(undefined);
  const [mainProblem, setMainProblem] = useState({});
  const [classifiedPizzas, setClassifiedPizzas] = useState(0);
  const [selectedPizzas, setSelectedPizzas] = useState({});
  const [displayActionOptions, setDisplayActionOptions] = useState(false);
  const [displaySpecialtiesOptions, setDisplaySpecialtiesOptions] = useState(false);
  const [displayStandardOptions, setDisplayStandardOptions] = useState(false);
  const [actionOptions, setActionOptions] = useState(undefined);
  const [standardOptions, setStandardOptions] = useState(undefined);

  useEffect(() => {
    return () => {
      setLoading(false);
    }
  }, [])

  useEffect(() => {
    if (report !== undefined) {
      setScorelessVersion(report.scoreless_report || (report.standard_scoreless_report && report.state === 'scoreless data selection'));
      setInspectedReport(report);
      setLoading(false);
    }
  }, [report])

  useEffect(() => {
    if (inspectedReport === undefined) return;
    let temp = [];
    let detectedSpecialties = {};
    let showOnReportCount = 0;
    let pizzas = inspectedReport.standard_scoreless_report && inspectedReport.state === 'scoreless data selection' ? inspectedReport.pizzas_scoreless : inspectedReport.pizzas;
    for (let [index, pizza] of pizzas.entries()) {
      showOnReportCount += pizza.show_on_report ?? 0;
      detectedSpecialties[pizza.specialty] = 1;
      temp.push({
        vid_id: pizza.vid_id,
        pizza_id: pizza.pizza_id,
        venue: inspectedReport.venue,
        week_stamp: inspectedReport.week_stamp,
        show_on_report: pizza.show_on_report ?? 0,
        featured: pizza.featured ?? 0,
        standard: pizza.standard,
        img_url: pizza.img_url,
        specialty: pizza.specialty,
        weekday: pizza.weekday,
        shift: pizza.shift,
        index: index,
        filtered: false,
        main_problem: Array.isArray(pizza.main_problem) ? pizza.main_problem : [undefined, null].includes(pizza.main_problem) ? [] : [pizza.main_problem]
      });
    }
    if (!detectedSpecialties['other']) detectedSpecialties['other'] = 1;
    setShowOnReport(showOnReportCount);
    setReportSpecialties(Object.keys(detectedSpecialties));
    whenSpecialtiesIdentified(Object.keys(detectedSpecialties));
    setCards(temp);
  }, [inspectedReport, whenSpecialtiesIdentified])

  const modifyPizzaCard = useCallback((e, card, attribute, value) => {
    e.preventDefault();
    let val = attribute === 'standard' ? value : value ? 1 : 0;
    let requestConf = {
      endpoint: `pizza_tv/report/update${attribute === 'featured' ? '_featured' : ''}/`,
      method: 'POST',
      dataType: 'JSON',
      data: {
        ...card,
        report_id: inspectedReport._id,
        attribute: attribute,
        value: val,
        state: inspectedReport.state,
        standard_scoreless_report: inspectedReport.standard_scoreless_report
      }
    }

    setLoading(true);
    FetchService(requestConf)
      .then(response => {
        let updateDict = JSON.parse(JSON.stringify(inspectedReport));
        if (attribute === 'featured') {
          for (let i = 0; i < updateDict.pizzas.length; ++i) {
            if (updateDict.pizzas[i].featured === 1 && card.standard === updateDict.pizzas[i].standard) updateDict.pizzas[i].featured = 0
          }
        }
        if (attribute === 'show_on_report') {
          if (val === 0) {
            if (updateDict.state === 'data selection') {
              updateDict.pizzas[card.index]['featured'] = 0;
            }
          }
        }
        if (attribute === 'standard') {
          if (val === 'maybe_to_standard') {
            updateDict.pizzas[card.index]['featured'] = 0;
            updateDict.pizzas[card.index]['show_on_report'] = 0;
          }
        }
        if (updateDict.state === 'scoreless data selection') updateDict.pizzas_scoreless[card.index][attribute] = val;
        else updateDict.pizzas[card.index][attribute] = val;
        setInspectedReport(updateDict);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, [inspectedReport])

  const whenSelectingDay = useCallback((name, selection) => {
    let requestConf = {
      endpoint: `pizza_tv/report/update_track_of_days/`,
      method: 'POST',
      dataType: 'JSON',
      data: {
        report_id: inspectedReport._id,
        value: selection.map(option => option.value)
      }
    }

    setLoading(true);
    FetchService(requestConf)
      .then(response => {
        let updateDict = JSON.parse(JSON.stringify(inspectedReport));
        for (let day of DAYS) {
          if (selection.map(option => option.value).includes(day)) updateDict.track_of_days[day].checked = 1;
          else updateDict.track_of_days[day].checked = 0
        }
        setInspectedReport(updateDict);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  }, [inspectedReport])

  useEffect(() => {
    if (cards === undefined || reportSpecialties === undefined) return;
    setToStandard(cards.filter(card => card.standard === 'to_standard' && card.show_on_report).length);
    setNotToStandard(cards.filter(card => card.standard === 'not_to_standard' && card.show_on_report).length);
    setFeaturedBest(cards.filter(card => card.featured && card.standard === 'to_standard' && card.show_on_report).length);
    setFeaturedWorst(cards.filter(card => card.featured && card.standard === 'not_to_standard' && card.show_on_report).length);
    const specialties = {};
    for (const specialty of reportSpecialties) {
      if (specialty === 'other') continue;
      specialties[specialty] = {
        notToStandard: cards.filter(card => (card.specialty === specialty && card.standard === 'not_to_standard')).length,
        notMaybeToStandard: cards.filter(card => (card.specialty === specialty && card.standard !== 'maybe_to_standard')).length,
        notToStandardOnReport: cards.filter(card => (card.specialty === specialty && card.standard === 'not_to_standard' && card.show_on_report)).length,
        onReport: cards.filter(card => (card.specialty === specialty && card.show_on_report)).length,
      }
    }
    setSpecialties(specialties);
    setWeekdayShift({
      AM: {
        Monday: cards.filter(card => card.shift === 'AM' && card.weekday === 'Monday' && card.show_on_report).length,
        Tuesday: cards.filter(card => card.shift === 'AM' && card.weekday === 'Tuesday' && card.show_on_report).length,
        Wednesday: cards.filter(card => card.shift === 'AM' && card.weekday === 'Wednesday' && card.show_on_report).length,
        Thursday: cards.filter(card => card.shift === 'AM' && card.weekday === 'Thursday' && card.show_on_report).length,
        Friday: cards.filter(card => card.shift === 'AM' && card.weekday === 'Friday' && card.show_on_report).length,
        Saturday: cards.filter(card => card.shift === 'AM' && card.weekday === 'Saturday' && card.show_on_report).length,
        Sunday: cards.filter(card => card.shift === 'AM' && card.weekday === 'Sunday' && card.show_on_report).length
      },
      PM: {
        Monday: cards.filter(card => card.shift === 'PM' && card.weekday === 'Monday' && card.show_on_report).length,
        Tuesday: cards.filter(card => card.shift === 'PM' && card.weekday === 'Tuesday' && card.show_on_report).length,
        Wednesday: cards.filter(card => card.shift === 'PM' && card.weekday === 'Wednesday' && card.show_on_report).length,
        Thursday: cards.filter(card => card.shift === 'PM' && card.weekday === 'Thursday' && card.show_on_report).length,
        Friday: cards.filter(card => card.shift === 'PM' && card.weekday === 'Friday' && card.show_on_report).length,
        Saturday: cards.filter(card => card.shift === 'PM' && card.weekday === 'Saturday' && card.show_on_report).length,
        Sunday: cards.filter(card => card.shift === 'PM' && card.weekday === 'Sunday' && card.show_on_report).length
      }
    })
  }, [cards, reportSpecialties])

  useEffect(() => {
    if (cards === undefined || filters === undefined) return;
    setSelectedPizzas({});
    let temp = cards.map(card => { return { ...card } });
    let cardsModified = false;
    for (let [i, card] of temp.entries()) {
      card.filtered = false;
      for (let i = 0; i < filters.length; ++i) {
        if (!filters[i].eval(card, filters[i].selection)) {
          card.filtered = true;
          break;
        }
      }
      if (cards[i].filtered !== card.filtered) cardsModified = true;
    }
    if (cardsModified) setCards(temp.slice(0));
  }, [cards, filters])

  const showPizzaCandidates = useCallback((card, type) => {
    setPizzaToBeReplaced(card);
    setPizzaCandidates(getSimilarPizzas(card, inspectedReport, type));
  }, [inspectedReport])

  const smartChangeSuccessCallback = useCallback((replace, target) => {
    let updateDict = JSON.parse(JSON.stringify(inspectedReport));
    if (replace.scoreless === 0) {
      updateDict[replace.pizzasField][replace.index].featured = target.featured ?? 0;
      updateDict[replace.pizzasField][target.index].featured = 0;
      if (replace.main_problem) {
        updateDict[replace.pizzasField][replace.index].main_problem = replace.main_problem
      }
    }
    updateDict[replace.pizzasField][target.index].show_on_report = 0;
    updateDict[replace.pizzasField][replace.index].show_on_report = 1;
    setInspectedReport(updateDict);
  }, [inspectedReport])

  const smartChangeEndCallback = useCallback(() => {
    setPizzaToBeReplaced(undefined);
  }, [])

  const mainProblemDropdown = useCallback((card) => {
    return {
      optionsList: Object.keys(PROBLEMS).map(id => { return { name: PROBLEMS[id].name, value: id, selected: Array.isArray(card.main_problem) ? (card.main_problem.filter(problem => problem === id).length > 0 ? true : false) : (card.main_problem === id ? true : false) } }),
      callback: (name, selection) => {
        let requestConf = {
          endpoint: `pizza_tv/report/update/`,
          method: 'POST',
          dataType: 'JSON',
          data: {
            ...card,
            report_id: inspectedReport._id,
            attribute: 'main_problem',
            value: selection.map(option => option.value)
          }
        }

        setLoading(true);
        FetchService(requestConf)
          .then(response => {
            let updateDict = JSON.parse(JSON.stringify(inspectedReport));
            updateDict.pizzas[card.index]['main_problem'] = selection.map(option => option.value)
            setInspectedReport(updateDict);
          })
          .catch(err => console.log(err))
          .finally(() => setLoading(false));
      }
    }
  }, [inspectedReport])

  const recipeDropdown = useCallback((card) => {
    return {
      optionsList: reportSpecialties.map(specialty => { return { name: specialty, value: specialty, selected: card.specialty === specialty } }),
      callback: (name, selection) => {
        let requestConf = {
          endpoint: `pizza_tv/report/update/`,
          method: 'POST',
          dataType: 'JSON',
          data: {
            ...card,
            report_id: inspectedReport._id,
            attribute: 'specialty',
            value: selection[0].value
          }
        }

        setLoading(true);
        FetchService(requestConf)
          .then(response => {
            let updateDict = JSON.parse(JSON.stringify(inspectedReport));
            updateDict.pizzas[card.index]['specialty'] = selection[0].value;
            if (selection[0].value === 'other') {
              updateDict.pizzas[card.index]['show_on_report'] = 0;
              updateDict.pizzas[card.index]['featured'] = 0;
            }
            setInspectedReport(updateDict);
          })
          .catch(err => console.log(err))
          .finally(() => setLoading(false));
      }
    }
  }, [inspectedReport, reportSpecialties])

  useEffect(() => {
    if (cards === undefined) return;
    let temp = {}
    for (let card of cards.filter(card => card.show_on_report === 1 && card.standard === 'not_to_standard' && card.main_problem)) {
      for (let problem of card.main_problem) {
        if (!(problem in temp)) temp[problem] = 0;
        temp[problem] += 1;
      }
    }
    setMainProblem(temp);
  }, [cards])

  useEffect(() => {
    if (cards === undefined) return;
    let classifiedPizzas = cards.filter(card => card.show_on_report === 1 && card.standard === 'not_to_standard' && card.main_problem.length > 0);
    setClassifiedPizzas(classifiedPizzas.length);
  }, [cards])

  const markPizzaCard = useCallback((card, value) => {
    setSelectedPizzas(prevData => {
      if (value === false) {
        const newData = { ...prevData };
        delete newData[card.index];
        return newData;
      } else {
        return {
          ...prevData,
          [card.index]: true
        };
      }
    });
  }, [])

  useEffect(() => {
    if (actionOptions === undefined) setActionOptions([
      { value: 'change_specialty', name: 'Change specialty' },
      { value: 'change_standard', name: 'Change standard' },
    ]);
  }, [actionOptions])

  useEffect(() => {
    if (standardOptions === undefined) setStandardOptions([
      { value: 'to_standard', name: 'To Standard' },
      { value: 'not_to_standard', name: 'Not to Standard' },
      { value: 'maybe_to_standard', name: 'Maybe to Standard' },
    ]);
  }, [standardOptions])

  const whenSelectingAction = useCallback((name, selection) => {
    let option = selection[0];
    let value, action;
    switch (option.value) {
      case 'change_specialty':
        setDisplaySpecialtiesOptions(true);
        setDisplayStandardOptions(false);
        return;
      case 'change_standard':
        setDisplayStandardOptions(true);
        setDisplaySpecialtiesOptions(false);
        return;
      default:
        if (name === 'Specialties') {
          action = 'specialty';
          value = option.value
        }
        else if (name === 'Standard') {
          action = 'standard';
          value = option.value
        }
        break;
    }
    const processResponse = (response) => {
      let updateDict = JSON.parse(JSON.stringify(inspectedReport));
      for (let i of Object.keys(selectedPizzas).map(index => Number(index))) {
        if (name === 'Specialties') {
          if (value === 'other') {
            updateDict.pizzas[i]['show_on_report'] = 0;
            updateDict.pizzas[i]['featured'] = 0;
          }
          updateDict.pizzas[i]['specialty'] = value;
        }
        else if (name === 'Standard') {
          if (value === 'maybe_to_standard') {
            updateDict.pizzas[i]['show_on_report'] = 0;
            updateDict.pizzas[i]['featured'] = 0;
          }
          updateDict.pizzas[i]['standard'] = value;
        }
      }
      setInspectedReport(updateDict);
    }
    let config = {
      endpoint: `pizza_tv/report/update_batch/`,
      method: 'POST',
      dataType: 'JSON',
      alertErrors: false,
      data: {
        report_id: inspectedReport._id,
        attribute: action,
        value: value,
        pizzas: Object.keys(selectedPizzas)
      }
    }
    setLoading(true);
    FetchService(config)
      .then(response => {
        processResponse(response);
      })
      .catch(err => {
        window.alert(`${err.cause.response.data.err} pizzas: ${Object.keys(selectedPizzas)}`);
      })
      .finally(() => {
        setLoading(false);
        setDisplayActionOptions(false);
        setDisplaySpecialtiesOptions(false);
        setDisplayStandardOptions(false);
        setSelectedPizzas({});
      });
  }, [selectedPizzas, inspectedReport])

  useEffect(() => {
    if (Object.keys(selectedPizzas).length > 0) setDisplayActionOptions(true);
    else {
      setDisplayActionOptions(false);
      setDisplaySpecialtiesOptions(false);
      setDisplayStandardOptions(false);
    }
  }, [selectedPizzas])

  return (
    <LoadingScreen loading={loading}>
      <SmartChange
        report={report}
        successCallback={smartChangeSuccessCallback}
        endCallback={smartChangeEndCallback}
        pizzaToBeReplaced={pizzaToBeReplaced}
        pizzaCandidates={pizzaCandidates}
      ></SmartChange>
      <div className={style.pageContent}>
        {
          displayInspectedPicture ? (
            <ModalWrapper
              allScreen={true}
              fitContent={true}
              display={displayInspectedPicture}
              setDisplay={setDisplayInspectedPicture} >
              <div className={style.inspectedPictureWrapper}>
                <img className={style.inspectedPicture} src={`${process.env.REACT_APP_IMAGE_SERVER}/api/static/${inspectedPicture}`} alt='' />
                <div className={style.closeInspectedPictureButton} onClick={() => setDisplayInspectedPicture(false)}><span><FontAwesomeIcon style={{ transform: 'rotate(45deg)', color: 'black' }} icon={faPlus} /></span></div>
              </div>
            </ModalWrapper>
          ) : (
            null
          )
        }
        {
          inspectedReport !== undefined && reportStructure !== undefined ? (
            <React.Fragment>
              <Summary
                changeReportState={changeReportState}
                language={language}
                specialties={specialties}
                weekdayShift={weekdayShift}
                featuredBest={featuredBest}
                featuredWorst={featuredWorst}
                toStandard={toStandard}
                notToStandard={notToStandard}
                showOnReport={showOnReport}
                fillReport={fillReport}
                scorelessReport={scorelessVersion}
                state={inspectedReport.state}
                reportSpecialtiesDefinition={specialtiesDefinition}
                reportStructure={reportStructure}
                mainProblem={mainProblem}
                totalNotToStandard={cards ? cards.filter(card => card.show_on_report === 1 && card.standard === 'not_to_standard').length : 0}
                classifiedPizzas={classifiedPizzas}
                trackOfDays={inspectedReport.track_of_days}
                whenSelectingDay={whenSelectingDay} />
              <span className={style.reportTitle}>Select Report Pizzas - Store {inspectedReport.doc.venue}</span>
              <div style={{ padding: '20px 0 10px', display: 'flex', gap: '15px' }}>
                {
                  displayActionOptions ? (
                    <Select
                      name='Actions'
                      optionsList={actionOptions}
                      multiple={false}
                      checkboxes={false}
                      callback={whenSelectingAction} />
                  ) : (
                    null
                  )
                }
                {
                  displaySpecialtiesOptions ? (
                    <Select
                      name='Specialties'
                      optionsList={reportSpecialties.map(specialty => { return { name: specialty, value: specialty } })}
                      multiple={false}
                      checkboxes={false}
                      callback={whenSelectingAction} />
                  ) : (
                    null
                  )
                }
                {
                  displayStandardOptions ? (
                    <Select
                      name='Standard'
                      optionsList={standardOptions}
                      multiple={false}
                      checkboxes={false}
                      callback={whenSelectingAction} />
                  ) : (
                    null
                  )
                }
              </div>
              <StepSelector report={inspectedReport} callback={changeState} beforeChangeState={() => { setLoading(true); }} errCallback={() => { setLoading(false) }}></StepSelector>
              <br />
            </React.Fragment>
          ) : (
            null
          )
        }
        {
          cards !== undefined ? (
            <div className={style.grid}>
              {
                cards.map((card, index) => {
                  if (!card.filtered) {
                    return <div key={index} className={style.card} style={card.show_on_report ? { backgroundColor: card.standard === 'not_to_standard' ? '#ffc1c1' : card.standard === 'to_standard' ? '#e1efd8' : 'lightgrey' } : {}}>
                      <img onClick={() => { setInspectedPicture(card.img_url); setDisplayInspectedPicture(true) }} className={style.pizzaImg} src={`${process.env.REACT_APP_IMAGE_SERVER}/api/static/${card.img_url}`} alt={'Pizza'} />
                      {
                        !scorelessVersion ? (
                          <input
                            className='large'
                            style={{
                              position: 'absolute',
                              margin: '5px',
                              top: '0',
                              left: '0'
                            }}
                            checked={selectedPizzas[card.index]}
                            onChange={(e) => { markPizzaCard(card, e.target.checked) }}
                            type='checkbox'
                            id={'featured' + card.index}>
                          </input>
                        ) : (
                          null
                        )
                      }
                      {
                        card.show_on_report && card.specialty !== 'other' ? (
                          <div><button style={{
                            position: 'absolute',
                            top: '5px',
                            left: '163px',
                            background: 'white',
                            border: 'none',
                            borderRadius: '3px',
                            margin: '5px',
                            fontSize: '20px'
                          }} onClick={() => showPizzaCandidates(card, inspectedReport.standard_scoreless_report && inspectedReport.state === 'scoreless data selection' ? 3 : inspectedReport.scoreless_report ? 2 : 1)} ><FontAwesomeIcon style={{ color: 'black' }} icon={faRecycle} /></button></div>
                        ) : (
                          null
                        )
                      }
                      <div className={style.checkboxFeatured}>
                        {
                          !scorelessVersion && card.show_on_report && card.specialty !== 'other' ? (
                            <React.Fragment>
                              <input
                                className={card.show_on_report ? card.standard === 'not_to_standard' ? style.notToStandardCheckbox : card.standard === 'to_standard' ? style.standardCheckbox : '' : ''}
                                checked={card.featured}
                                onChange={(e) => { modifyPizzaCard(e, card, 'featured', e.target.checked) }}
                                type='checkbox'
                                id={'featured' + index}>
                              </input>
                              <label htmlFor={'featured' + index}>Featured</label>
                            </React.Fragment>
                          ) : (
                            null
                          )
                        }
                      </div>
                      <div className={style.information}>
                        <div className={style.controls}>
                          {
                            scorelessVersion || (card.specialty !== 'other' && card.standard !== 'maybe_to_standard') ? (
                              <div className={style.checkboxShowReport}>
                                <input
                                  checked={card.show_on_report}
                                  onChange={(e) => { modifyPizzaCard(e, card, 'show_on_report', e.target.checked) }}
                                  type='checkbox'
                                  id={'show_report' + index}>
                                </input>
                                <label htmlFor={'show_report' + index}>Show on report</label>
                              </div>
                            ) : (
                              null
                            )
                          }
                          {
                            scorelessVersion ? (
                              null
                            ) : (
                              <div className={style.radioStandard}>
                                <input
                                  checked={card.standard === 'not_to_standard'}
                                  onChange={(e) => { modifyPizzaCard(e, card, 'standard', 'not_to_standard') }}
                                  type='radio'
                                  name={'standard' + index}
                                  id={'not_to_standard' + index}>
                                </input>
                                <label htmlFor={'not_to_standard' + index}><FontAwesomeIcon style={{ transform: 'rotate(45deg)', color: 'red' }} icon={faPlus} /></label>
                                <input
                                  checked={card.standard === 'to_standard'}
                                  onChange={(e) => { modifyPizzaCard(e, card, 'standard', 'to_standard') }}
                                  type='radio'
                                  name={'standard' + index}
                                  id={'to_standard' + index}>
                                </input>
                                <label htmlFor={'to_standard' + index}><FontAwesomeIcon style={{ color: '#00b050' }} icon={faCheck} /></label>
                                <input
                                  checked={card.standard === 'maybe_to_standard'}
                                  onChange={(e) => { modifyPizzaCard(e, card, 'standard', 'maybe_to_standard') }}
                                  type='radio'
                                  name={'standard' + index}
                                  id={'maybe_to_standard' + index}>
                                </input>
                                <label htmlFor={'maybe_to_standard' + index}><FontAwesomeIcon style={{ color: '#ffd43b' }} icon={faQuestion} /></label>
                              </div>
                            )
                          }
                          {
                            !scorelessVersion && card.standard === 'not_to_standard' && card.show_on_report === 1 && card.specialty !== 'other' ? (
                              <Select
                                autoWidth={true}
                                name='Main Problem'
                                multiple={true}
                                checkboxes={false}
                                {...mainProblemDropdown(card)}
                                extraActions={true} />
                            ) : (
                              null
                            )
                          }
                          {
                            !scorelessVersion ? (
                              <Select
                                autoWidth={true}
                                name='Recipe'
                                multiple={false}
                                {...recipeDropdown(card)} />
                            ) : (
                              null
                            )
                          }
                        </div>
                        <div className={style.separator}></div>
                        <div>
                          <div><span>{getSpecialtiesByLanguage(language)[card.specialty] ?? card.specialty}</span></div>
                          <div><span>{`${card.weekday} - ${card.shift}`}</span></div>
                        </div>
                      </div>
                    </div>
                  }
                  else return null;
                })
              }
            </div>
          ) : (
            null
          )
        }
      </div>
    </LoadingScreen >
  )
}

export default PizzaValidationPart1;