import { useEffect } from 'react';

export const useEscPressed = (callback) => {
  useEffect(() => {
    function handleEscPressed(event) {
      if (event.keyCode === 27) {
        if (callback){
          callback();
        }
      }
    }
    document.addEventListener('keydown', handleEscPressed);
    return () => {
        document.removeEventListener('keydown', handleEscPressed);
    };
  });
}