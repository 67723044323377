import { FetchService } from '../../../services/Api';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import Table from '../table/Table';
import { UI_TEXT, getSpecialtiesByLanguage } from '../../Constants';
import { useChartRendering } from '../../../hooks/useChartRendering';


const VenuesFocusOn = (props) => {
  const { url, itemName, language, data } = props;
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const [rawData, setRawData] = useState(undefined);
  const [chartData, setChartData] = useState(undefined);
  const [focusOnMetric, setFocusOnMetric] = useState('Specialty');
  const [focusOnMetricSelector, setFocusOnMetricSelector] = useState({});
  useEffect(() => {
    const setFocusOnSelector = (selection, whenChangingMetric) => {
      return {
        name: '',
        label: 'Specialty',
        optionsList: [
          { name: 'Specialty', value: 'Specialty', selected: selection === 'Specialty' ? true : false },
          { name: 'Attribute', value: 'Attribute', selected: selection === 'Attribute' ? true : false },
        ],
        callback: (name, selection) => {
          if (selection.length !== 1) return;
          whenChangingMetric(selection[0].value)
        }
      }
    }
    setFocusOnMetricSelector({ ...setFocusOnSelector(focusOnMetric, setFocusOnMetric) })
  }, [focusOnMetric])

  useChartRendering(chartData, props.onRendering);

  useEffect(() => {
    if (data !== undefined) setRawData(data);
    else setChartData(undefined);
  }, [data])

  useEffect(() => {
    if (rawData === undefined) return;
    let data = [];
    for (let item of Object.keys(rawData.focus_on)) {
      if (focusOnMetric === 'Specialty')
        data.push([item, getSpecialtiesByLanguage(language)[rawData.focus_on[item][`worst_${focusOnMetric.toLowerCase()}`]]])
      else
        data.push([item, UI_TEXT.evaluation[rawData.focus_on[item][`worst_${focusOnMetric.toLowerCase()}`]]])
    }
    setChartData({ header: [itemName, focusOnMetric], data: data })
  }, [rawData, focusOnMetric, language, itemName])

  const fetchFocusOn = useCallback(() => {
    if (url === undefined) return;
    setChartData(undefined);
    let requestConf = {
      endpoint: url,
      method: 'GET',
      data: {}
    }
    FetchService(requestConf).then(
      (response) => {
        if (!mountedRef.current) {
          return;
        }
        else {
          setRawData(response.data);
        }
      },
      (err) => {
      }
    ).catch(err => console.log(err));
  }, [url])

  useEffect(() => {
    fetchFocusOn();
  }, [fetchFocusOn])

  return (
    chartData ? (
      <Table
        style={{ height: '346px' }}
        title='Focus On'
        filters={[focusOnMetricSelector]}
        header={chartData.header}
        data={chartData.data} />
    ) : (
      <React.Fragment />
    )
  )
}

export default VenuesFocusOn;