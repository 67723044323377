import { useEffect } from 'react';
import style from './Login.module.css'

const Login = () => {

  useEffect(() => {
    window.location.replace(`/api/login`);
  })

  return (
    <div className={style.background}>
    </div>
  )
}

export default Login;