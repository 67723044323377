import React, { useEffect, useState } from 'react';
import style from './Toggle.module.css';

const Toggle = (props) => {
  const { modes } = props;
  const [tModes, setTModes] = useState(undefined);

  useEffect(() => {
    setTModes(modes);
  }, [modes])

  const changeMode = (newMode) => {
    if (tModes.length === 1) {
      tModes[0].active = newMode;
      setTModes([...tModes]);
    }
    else if (tModes.length === 2) {
      tModes[newMode].active = true;
      tModes[1 - newMode].active = false;
      setTModes([...tModes]);
    }
    else return;
  }

  const callback = () => {
    if (tModes.length === 1) {
      if (tModes[0].callback) {
        tModes[0].callback(!tModes[0].active, changeMode)
      }
    }
    else if (tModes.length === 2) {
      if (tModes[0].active && tModes[0].callback) {
        tModes[0].callback(1, changeMode);
      }
      else if (tModes[1].active && tModes[1].callback) {
        tModes[1].callback(0, changeMode);
      }
    }
    else return;
  }

  return (
    tModes !== undefined ? (
      tModes.length > 1 ? (
        <div className={style.wrapper}>
          <div className={`${style.mode} ${tModes[0].active ? style.active : {}}`} onClick={() => callback()}>
            <span>{tModes[0].name}</span>
          </div>
          <div className={`${style.mode} ${tModes[1].active ? style.active : {}}`} onClick={() => callback()}>
            <span>{tModes[1].name}</span>
          </div>
        </div>
      ) : (
        <div className={style.singleModeToggle}>
          <div className={`${style.wrapper} ${tModes[0].active ? style.active : ''}`}>
            <div className={`${style.mode} ${!tModes[0].active ? style.active : ''}`} onClick={() => callback()}>
            </div>
            <div className={`${style.mode} ${tModes[0].active ? style.active : ''}`} onClick={() => callback()}>
            </div>
          </div>
          <span>{tModes[0].name}</span>
        </div>
      )
    ) : (
      null
    )
  )
}

export default Toggle