import React from 'react';
import style from './Breadcrumb.module.css';
import Select from '../select/Select';

const Breadcrumb = (props) => {
  const { path, pageBreadcrumb, editing, callback } = props;

  return (
    <div className={style.wrapper}>
      {
        path.map((step, index) => {
          return (
            <div className={`${style.step} ${pageBreadcrumb ? style.small : ''}`} key={index}>
              {
                editing ? (
                  <div></div>
                ) : (
                  <span>{step.name}</span>
                )
              }
              {
                !pageBreadcrumb && step.editable && editing ? (
                  <Select
                    {...step.editable}
                    label='editable'
                    callback={callback}
                    quickSelection={step.quickSelection}>
                  </Select>
                ) : (
                  <div></div>
                )
              }
            </div>
          )
        })
      }
    </div>
  )
}

export default Breadcrumb