import React from 'react';
import UsersTable from './usersTable/UsersTable';
import UserForm from './userForm/UserForm';

const UserManagement = (props) => {
  const { users, venues, regions, reload, selectedCompany } = props;

  return (
    <div>
      {
        users && venues ? (
          <div>
            <UserForm
              venues={venues}
              regions={regions}
              onSubmit={reload}
              selectedCompany={selectedCompany} />
            <UsersTable
              users={users}
              venues={venues}
              regions={regions}
              onDelete={reload}
              selectedCompany={selectedCompany} />
          </div>
        ) : (
          <React.Fragment />
        )
      }
    </div>
  )
}

export default UserManagement;