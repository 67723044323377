import React from "react";

const NotFound = () => {
    return (
        <div>
            <h1 style={{fontSize: '80px', marginBottom: '0'}}>404</h1>
            <h4 style={{fontSize: '20px', marginTop: '20px'}}>Page Not Found</h4>
        </div>
    );
}

export default NotFound;