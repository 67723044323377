import ModalWrapper from '../common/modalWrapper/ModalWrapper';
import React, { useState, useEffect } from 'react';
import style from './Navbar.module.css';
import logoColors from '../../vectors/logo_colors.svg';
import burger from '../../vectors/icn_burger.svg';
import gear from '../../vectors/gear.svg';
import { Link } from 'react-router-dom';

const Navbar = (props) => {
  const { user, companyManager } = props;
  const [displayMenu, setDisplayMenu] = useState(false);
  const [displayAdminMenu, setDisplayAdminMenu] = useState(false);
  const [displayUserMenu, setDisplayUserMenu] = useState(false);
  const [displayReportMenu, setDisplayReportMenu] = useState(false);
  const [displayReportMobileMenu, setDisplayReportMobileMenu] = useState(false);
  const [username, setUsername] = useState('');

  useEffect(() => {
    if (user === undefined) return;
    setUsername(user.name && user.name.replace(' ', '') ? user.name : user.email)
  }, [user])

  const toggleMenu = () => {
    setDisplayMenu(!displayMenu);
  }

  const toggleAdminMenu = () => {
    setDisplayAdminMenu(!displayAdminMenu);
  }

  const toggleUserMenu = () => {
    setDisplayUserMenu(!displayUserMenu);
  }

  const toggleReportMenu = () => {
    setDisplayReportMenu(!displayReportMenu);
  }

  const toggleReportMobileMenu = () => {
    setDisplayReportMobileMenu(!displayReportMobileMenu);
  }

  const getUserID = () => {
    if (!username) return '';
    if (username.split(' ').length > 1) return `${username.split(' ')[0][0]}${username.split(' ')[1][0]}`;
    return `${username[0]}${username[1]}`;
  }

  return (
    <div className={style.wrapper}>
      <div className={style.mobile}>
        <div className={style.burger}>
          <button onClick={() => toggleMenu()}>
            <img src={burger} alt='menu' />
          </button>
        </div>
        <div className={style.logo}>
          <img src={logoColors} alt='Kwali' />
        </div>
        {
          displayMenu ? (
            <div className={style.burgerMenu}>
              <ul>
                {
                  user.reports_only ? (
                    <li><Link onClick={() => toggleMenu()} to="/quality-reports">Quality Reports</Link></li>
                  ) : (
                    <React.Fragment>
                      {
                        companyManager ? (
                          <li><Link onClick={() => toggleMenu()} to="/management">Management</Link></li>
                        ) : (
                          null
                        )
                      }
                      <li><Link onClick={() => toggleMenu()} to="/daily-insights">Daily Insights</Link></li>
                      <li onClick={() => toggleReportMobileMenu()}>Report</li>
                      {
                        displayReportMobileMenu ? (
                          <React.Fragment>
                            <li style={{ fontSize: '12px' }}><Link onClick={() => toggleMenu()} to="/report/production">Production</Link></li>
                            {
                              ((process.env.REACT_APP_DEMO || 'false').toLowerCase() === 'false') ? (
                                <React.Fragment>
                                  <li style={{ fontSize: '12px' }}><Link onClick={() => toggleMenu()} to="/report/performance">Performance</Link></li>
                                  <li style={{ fontSize: '12px' }}><Link onClick={() => toggleMenu()} to="/report/leaderboard">Leaderboard</Link></li>
                                  <li style={{ fontSize: '12px' }}><Link onClick={() => toggleMenu()} to="/report/compare">Compare</Link></li>
                                  <li style={{ fontSize: '12px' }}><Link onClick={() => toggleMenu()} to="/report/portion-control">Portion Control</Link></li>
                                </React.Fragment>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )
                            }
                          </React.Fragment>
                        ) : (
                          null
                        )
                      }
                      <li><Link onClick={() => toggleMenu()} to="/daily-feed">Daily Feed</Link></li>
                    </React.Fragment>
                  )
                }
                <br></br>
                <br></br>
                <li>{username || 'user'}</li>
                {
                  !user.reports_only && companyManager && (user.employer || user.has_company_memberships) ? (
                    <React.Fragment>
                      <li><Link onClick={() => toggleMenu()} to="/admin/user-management">User Management</Link></li>
                      <li><Link onClick={() => toggleMenu()} to="/admin/variable-administration">Variable Administration</Link></li>
                    </React.Fragment>
                  ) : (
                    null
                  )
                }
                <li><a onClick={() => toggleMenu()} href={`/api/logout/`} >Log out</a></li>
              </ul>
            </div>
          ) : (
            <div></div>
          )
        }
      </div>
      <div className={style.desktop}>
        <div className={style.leftCol}>
          <div className={style.logo}>
            <img src={logoColors} alt='kwali' />
          </div>
          {
            user.reports_only ? (
              <React.Fragment>
                <Link to="/quality-reports">{'Quality Reports'}</Link>
              </React.Fragment>
            ) : (
              user.daily_feed_only ? (
                <Link to="/daily-feed">Daily feed</Link>
              ) : (
                <React.Fragment>
                  {
                    companyManager ? (
                      <Link to="/management">Management</Link>
                    ) : (
                      null
                    )
                  }
                  {
                    ((process.env.REACT_APP_DEMO || 'false').toLowerCase() === 'false') ? (
                      <Link to="/daily-insights">Daily Insights</Link>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )
                  }
                  <button onClick={() => toggleReportMenu()} className={`${style.adminButton} ${style.reportMenuButton}`}>
                    <div className={style.buttonArt}>
                      <span>Reports</span>
                    </div>
                    {
                      displayReportMenu ? (
                        <div className={style.miniMenuWrapper}>
                          <ModalWrapper
                            display={displayReportMenu}
                            setDisplay={setDisplayReportMenu}>
                            <div className={style.miniMenu}>
                              <Link to="/report/production">Production</Link>
                              {
                                ((process.env.REACT_APP_DEMO || 'false').toLowerCase() === 'false') ? (
                                  <React.Fragment>
                                    <Link to="/report/performance">Performance</Link>
                                    <Link to="/report/leaderboard">Leaderboard</Link>
                                    <Link to="/report/compare">Compare</Link>
                                    <Link to="/report/portion-control">Portion Control</Link>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment></React.Fragment>
                                )
                              }
                            </div>
                          </ModalWrapper>
                        </div>
                      ) : (
                        null
                      )
                    }
                  </button>
                  <Link to="/daily-feed">Daily feed</Link>
                  {
                    user.employer && user.employer.pk === 'Kwali' ? (
                      <React.Fragment>
                        <Link to="/pizza-tv-explorer">{'PIZZA TV & PDF'}</Link>
                        <Link to="/pizza-tv-month-explorer">{'PIZZA TV (Month)'}</Link>
                        <Link to="/report-control">{'Report Control'}</Link>
                      </React.Fragment>
                    ) : (
                      null
                    )
                  }
                </React.Fragment>
              )
            )
          }
        </div>
        <div className={style.rightCol}>
          {
            !user.reports_only && companyManager && (user.employer || user.has_company_memberships) ? (
              <button onClick={() => toggleAdminMenu()} className={style.adminButton}>
                <div className={style.buttonArt}>
                  <img src={gear} alt='menu' />
                </div>
                {
                  displayAdminMenu ? (
                    <div className={style.miniMenuWrapper}>
                      <ModalWrapper
                        display={displayAdminMenu}
                        setDisplay={setDisplayAdminMenu}>
                        <div className={style.miniMenu}>
                          <Link to="/admin/user-management">User Management</Link>
                          <Link to="/admin/variable-administration">Variable Administration</Link>
                        </div>
                      </ModalWrapper>
                    </div>

                  ) : (
                    null
                  )
                }
              </button>
            ) : (
              null
            )
          }
          <button onClick={() => toggleUserMenu()} className={style.userButton}>
            <div className={style.buttonArt}>
              <span>{getUserID()}</span>
            </div>
            {
              displayUserMenu ? (
                <div className={style.miniMenuWrapper}>
                  <ModalWrapper
                    display={displayUserMenu}
                    setDisplay={setDisplayUserMenu}>
                    <div className={style.miniMenu}>
                      <a href={`/api/logout/`} >Log out</a>
                    </div>
                  </ModalWrapper>
                </div>

              ) : (
                null
              )
            }
          </button>
        </div>
      </div>
    </div>
  )
}

export default Navbar;
