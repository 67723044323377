import React, { useState } from 'react';
import ProgressBar from '../progressBar/ProgressBar';
import Toggle from '../toggle/Toggle';
import style from './PizzaFeedback.module.css';
import Score from '../score/Score';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faPlus, faQuestion, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import { UI_TEXT, PEPPERONI_SPECS, NON_PEPPERONI_ING_ADJUST } from '../../Constants';

const PizzaFeedback = (props) => {
  const {
    companyInfo,
    imgLink,
    evaluationResult,
    openFeebackDialog,
    updateScoreCriteria,
    editionCallback,
    omitSoft,
    rawScore,
    specialty,
    kwali_score,
    allowFeedback,
    pizzaVisibilityToggle,
    pizzaBubblesToggle,
    pizzaOverbakedToggle,
    pizzaCutToggle,
    originalData,
    deltaScore,
    standard,
    changePizzaAttr,
    allowStandardFeedback,
    user } = props;
  const [editing, setEditing] = useState(props.editing || false);

  const getTotal = (key) => {
    if (key.startsWith('Ingredientes') && !omitSoft) {
      let softIngredientsScore = evaluationResult.soft['Ingredients']
      if (!rawScore && !PEPPERONI_SPECS.includes(specialty)) {
        softIngredientsScore = Math.min(4.0, softIngredientsScore + NON_PEPPERONI_ING_ADJUST)
      }
      return softIngredientsScore / 2;
    }
    return;
  }

  return (
    <div className={style.wrapper}>
      <div className={style.imageWrapper}>
        <img className={style.image} src={imgLink} alt='' />
        {
          openFeebackDialog ? (
            <button className={`${style.imageButton} ${style.commentButton}`} onClick={() => openFeebackDialog()}><FontAwesomeIcon icon={faCommentDots} /></button>
          ) : (
            <div></div>
          )
        }
        <button className={`${style.imageButton} ${style.expandButton}`}><FontAwesomeIcon icon={faExpandAlt} /></button>
      </div>
      {
        !user.daily_feed_only && (props.score || allowStandardFeedback) ? (
          <div className={style.rightPanel}>
            <div className={style.controls}>
              {
                props.score ? (
                  <div className={style.barsWrapper}>
                    {
                      kwali_score ? (
                        <React.Fragment />
                      ) : (
                        <ProgressBar
                          name='Base score'
                          scoreDetails={{ 0: 1.2 }}
                          max={1.2}
                          step={0.1}>
                        </ProgressBar>
                      )
                    }
                    {
                      Object.keys(evaluationResult.score_details).sort((a, b) => UI_TEXT.evaluation[a] < UI_TEXT.evaluation[b] ? -1 : UI_TEXT.evaluation[a] > UI_TEXT.evaluation[b] ? 1 : 0).map((key, index) => {
                        return <ProgressBar
                          key={index}
                          keyName={key}
                          name={UI_TEXT.evaluation[key] || key}
                          originalData={originalData}
                          allowFeedback={allowFeedback}
                          scoreDetails={evaluationResult.score_details[key]}
                          total={getTotal(key)}
                          max={companyInfo.evaluation_key_max[key] + 0.001}
                          step={key.startsWith('Ingredientes') ? 0.01 : 0.1}
                          editing={editing}
                          callback={updateScoreCriteria}
                          weight={companyInfo.kwali_score_weights ? companyInfo.kwali_score_weights[key] : undefined}>
                        </ProgressBar>
                      })
                    }
                    {
                      evaluationResult.info && evaluationResult.info.kwali_score && evaluationResult.info.kwali_score.Cheese !== undefined ? (
                        <ProgressBar
                          keyName={'Cheese'}
                          name={UI_TEXT.evaluation['Cheese']}
                          allowFeedback={false}
                          scoreDetails={evaluationResult.info.kwali_score['Cheese']}
                          total={getTotal('Cheese')}
                          max={companyInfo.evaluation_key_max['Cheese'] + 0.001}
                          step={0.1}
                          editing={false}>
                        </ProgressBar>
                      ) : (
                        null
                      )
                    }
                    <div className={style.mobileScore}>
                      <span>
                        Total
                      </span>
                      <div className={style.scoreWrapper}>
                        {
                          deltaScore !== undefined ? (
                            <span style={{ color: deltaScore < 0 ? '#35CF6C' : deltaScore > 0 ? '#FF5E48' : '#2864C8' }} className={style.deltaScore}>
                              {`(${deltaScore > 0 ? '-' : '+'} ${(Math.abs(deltaScore)).commaRepresentation(2)})`}
                            </span>
                          ) : (
                            null
                          )
                        }
                        <Score
                          score={props.score}>
                        </Score>
                      </div>
                    </div>
                  </div>
                ) : (
                  null
                )
              }
              {
                allowFeedback ? (
                  <div className={style.toggles}>
                    {
                      props.score ? (
                        <React.Fragment>
                          <div className={style.pizzaToggle}>
                            {
                              pizzaBubblesToggle ? (
                                <Toggle
                                  modes={pizzaBubblesToggle}>
                                </Toggle>
                              ) : (
                                null
                              )
                            }
                          </div>
                          <div className={style.pizzaToggle}>
                            {
                              pizzaOverbakedToggle ? (
                                <Toggle
                                  modes={pizzaOverbakedToggle}>
                                </Toggle>
                              ) : (
                                null
                              )
                            }
                          </div>
                          <div className={style.pizzaToggle}>
                            {
                              pizzaCutToggle ? (
                                <Toggle
                                  modes={pizzaCutToggle}>
                                </Toggle>
                              ) : (
                                null
                              )
                            }
                          </div>
                        </React.Fragment>
                      ) : (
                        null
                      )
                    }
                    <div style={{ display: 'flex', gap: '7px', alignItems: 'center' }}>
                      <input
                        checked={standard === 'not_to_standard'}
                        onChange={(e) => { e.preventDefault(); changePizzaAttr('standard', 'not_to_standard', null) }}
                        type='radio'
                        name={'standard'}
                        id={'not_to_standard'}>
                      </input>
                      <label htmlFor={'not_to_standard'}><FontAwesomeIcon style={{ transform: 'rotate(45deg)', color: 'red' }} icon={faPlus} /></label>
                      <input
                        checked={standard === 'to_standard'}
                        onChange={(e) => { e.preventDefault(); changePizzaAttr('standard', 'to_standard', null) }}
                        type='radio'
                        name={'standard'}
                        id={'to_standard'}>
                      </input>
                      <label htmlFor={'to_standard'}><FontAwesomeIcon style={{ color: '#00b050' }} icon={faCheck} /></label>
                      <input
                        checked={standard === 'maybe_to_standard'}
                        onChange={(e) => { e.preventDefault(); changePizzaAttr('standard', 'maybe_to_standard', null) }}
                        type='radio'
                        name={'standard'}
                        id={'maybe_to_standard'}>
                      </input>
                      <label htmlFor={'maybe_to_standard'}><FontAwesomeIcon style={{ color: '#ffd43b' }} icon={faQuestion} /></label>
                      <span style={{ fontSize: '12px' }}>Report Standard</span>
                    </div>
                    <br />
                    {
                      props.score ? (
                        <div className={style.pizzaToggle}>
                          {
                            pizzaVisibilityToggle ? (
                              <Toggle
                                modes={pizzaVisibilityToggle}>
                              </Toggle>
                            ) : (
                              null
                            )
                          }
                        </div>
                      ) : (
                        null
                      )
                    }
                  </div>
                ) : (
                  <div className={style.marks}>
                    {
                      pizzaBubblesToggle && pizzaBubblesToggle[0].active ? (
                        <span>Bad pizza - Bubbles</span>
                      ) : (
                        null
                      )
                    }
                    {
                      pizzaOverbakedToggle && pizzaOverbakedToggle[0].active ? (
                        <span>Bad pizza - Overbaked</span>
                      ) : (
                        null
                      )
                    }
                    {
                      pizzaCutToggle && pizzaCutToggle[0].active ? (
                        <span>Bad pizza - Cut</span>
                      ) : (
                        null
                      )
                    }
                  </div>
                )
              }
            </div>
            {
              allowFeedback && props.score ? (
                <button className={style.editScoring} onClick={e => { editionCallback(); setEditing(!editing); }}><FontAwesomeIcon className={style.editScoringIcon} icon={faPen} />
                  {editing ? ('Give Feedback') : ('Scoring Feedback')}
                </button>
              ) : (
                <React.Fragment />
              )
            }
          </div>
        ) : (
          null
        )
      }
    </div>
  )
}

export default PizzaFeedback