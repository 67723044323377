import { FetchService } from '../../services/Api';
import React, { useState, useEffect, useCallback } from 'react';
import Header from '../header/Header';
import style from './Admin.module.css';
import UserManagement from './userManagement/UserManagement';
import VariableAdministration from './variableAdministration/VariableAdministration';
import LoadingScreen from '../common/loadingScreen/LoadingScreen';
import { useParams } from 'react-router-dom';

const Admin = (props) => {
  const { user } = props

  const params = useParams();
  const breadcrumb = [{ name: 'Admin' }, { name: params.adminPage.replace('-', ' ') }];
  const [selectedCompany, setSelectedCompany] = useState(undefined);
  const [companies, setCompanies] = useState(undefined);
  const [companyMembership, setCompanyMembership] = useState(undefined);
  const [rawData, setrawData] = useState(undefined);
  const [users, setUsers] = useState(undefined);
  const [venues, setVenues] = useState(undefined);
  const [regions, setRegions] = useState(undefined);
  const [qualityDefinition, setQualityDefinition] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (companyMembership !== undefined) return;
    let requestConf = {
      endpoint: `dashboard/company-membership/`,
      method: 'GET',
      data: {}
    }

    FetchService(requestConf).then(
      (response) => {
        setCompanyMembership(response.data);
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }, [companyMembership])

  useEffect(() => {
    if (companyMembership === undefined) return;
    if (companyMembership.length === 0 && user.employer) {
      setSelectedCompany(user.employer);
      return;
    }
    if (companyMembership.length === 1) setSelectedCompany(companyMembership[0].space);
    else {
      let companies = companyMembership.map(m => { return { displayName: m.space.pretty_name || m.space.name, pk: m.space.pk } });
      companies = companies.sort((a, b) => (a.displayName > b.displayName) ? 1 : (b.displayName > a.displayName) ? -1 : 0);
      companies = [...new Set(companies.map(JSON.stringify))].map(JSON.parse);
      setLoading(false);
      if (companies.length === 1) setSelectedCompany(companies[0]);
      else setCompanies(companies);
    }
  }, [companyMembership, user])

  const fetchUserAndVenues = useCallback(() => {
    if (selectedCompany) {
      let requestConf = {
        endpoint: `dashboard/admin/${selectedCompany.pk}`,
        method: 'GET',
        data: {}
      }

      FetchService(requestConf).then(
        (response) => {
          setrawData(response.data);
        },
        (err) => {
          console.log(err);
        }
      ).catch(err => console.log(err));
    }
  }, [selectedCompany])

  const reload = () => {
    setrawData(undefined);
  }

  useEffect(() => {
    if (rawData === undefined) {
      setLoading(true);
      fetchUserAndVenues()
    }
    else {
      setUsers(rawData.users ? rawData.users : undefined);
      setVenues(rawData.venues ?? undefined);
      setRegions(rawData.regions ?? undefined);
      setQualityDefinition(rawData.quality_definition ? rawData.quality_definition : undefined);
      setLoading(false);
    }
  }, [rawData, fetchUserAndVenues])

  return (
    <LoadingScreen loading={loading}>
      <div className={style.wrapper}>
        <Header breadcrumb={breadcrumb} pageTitle={params.adminPage.replace('-', ' ')} />
        {
          params.adminPage === 'user-management' ? (
            <UserManagement
              users={users}
              venues={venues}
              regions={regions}
              reload={reload}
              selectedCompany={selectedCompany} />
          ) : (params.adminPage === 'variable-administration' ? (
            <div>
              <VariableAdministration qualityDefinition={qualityDefinition} onSubmit={reload} />
            </div>
          ) : (
            <React.Fragment />
          )
          )
        }
        {
          companies && selectedCompany === undefined ? (
            <div className={style.companySelector}>
              {
                companies.map((company, index) => {
                  return <button className={style.companyButton} key={index} onClick={() => setSelectedCompany(company)}>{company.displayName}</button>
                })
              }
            </div>
          ) : (
            <React.Fragment />
          )
        }

      </div>
    </LoadingScreen>
  )
}

export default Admin;