import React, { useState, useEffect, useCallback, useRef } from 'react';
import BasicChart from '../basicChart/BasicChart';
import Select from '../../common/select/Select';
import { getQualitySelector, getGranularitySelector, getGranularityCats } from '../Aux'
import { useChartRendering } from '../../../hooks/useChartRendering';


const RegionsPerformanceEvolution = (props) => {
  const { url, dateRangeMin, dateRangeMax, data } = props;
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const [rawData, setRawData] = useState(undefined);
  const [chartData, setChartData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [performanceEvolutionMetric, setPerformanceEvolutionMetric] = useState('Bad');
  const [performanceEvolutionMetricSelector, setPerformanceEvolutionMetricSelector] = useState(undefined);
  useEffect(() => {
    setPerformanceEvolutionMetricSelector({ ...getQualitySelector(performanceEvolutionMetric, setPerformanceEvolutionMetric) })
  }, [performanceEvolutionMetric])
  const [performanceEvolutionGranularity, setPerformanceEvolutionGranularity] = useState('weekly')
  const [performanceEvolutionGranularitySelector, setPerformanceEvolutionGranularitySelector] = useState({});
  useEffect(() => {
    setPerformanceEvolutionGranularitySelector({ ...getGranularitySelector(performanceEvolutionGranularity, setPerformanceEvolutionGranularity) })
  }, [performanceEvolutionGranularity])
  const [regionsOptionList, setRegionsOptionList] = useState(undefined);
  const [selectedRegion, setSelectedRegion] = useState(undefined);
  const [chartYaxisBoundaries, setChartYaxisBoundaries] = useState({ min: 0, max: 1.05 });

  useChartRendering(chartData, props.onRendering);

  useEffect(() => {
    if (data !== undefined) setRawData(data);
    else setChartData(undefined);
  }, [data])

  const whenSelectingRegion = useCallback((name, selection) => {
    setSelectedRegion(selection[0].value);
  }, [])

  useEffect(() => {
    if (selectedRegion === undefined) return;
    let subcats = [selectedRegion, 'Company'];
    let dictData = {};
    let data = [];
    let key = `pct_${performanceEvolutionMetric.toLowerCase()}s`;
    let sortedSelectedRegion = rawData.data[selectedRegion][performanceEvolutionGranularity].sort((a, b) => (parseFloat(a[key]) > parseFloat(b[key])) ? -1 : ((parseFloat(b[key]) > parseFloat(a[key])) ? 1 : 0));
    for (let period of rawData.data[selectedRegion][performanceEvolutionGranularity]) {
      dictData[period.datetime] = parseFloat(period[`pct_${performanceEvolutionMetric.toLowerCase()}s`]) / 100;
    }
    let conf1 = getGranularityCats({ min: dateRangeMin, max: dateRangeMax }, dictData, performanceEvolutionGranularity);
    let sortedCompany = rawData.company[performanceEvolutionGranularity].sort((a, b) => (parseFloat(a[key]) > parseFloat(b[key])) ? -1 : ((parseFloat(b[key]) > parseFloat(a[key])) ? 1 : 0));
    dictData = {};
    for (let period of rawData.company[performanceEvolutionGranularity]) {
      dictData[period.datetime] = parseFloat(period[`pct_${performanceEvolutionMetric.toLowerCase()}s`]) / 100;
    }
    let conf2 = getGranularityCats({ min: dateRangeMin, max: dateRangeMax }, dictData, performanceEvolutionGranularity);
    for (let i = 0; i < conf1.data.length; i++) {
      data.push([conf1.data[i][0], conf2.data[i][0]])
    }
    if (sortedSelectedRegion.length !== 0) {
      let max = Math.max((sortedSelectedRegion[0][key] / 100), sortedCompany ? (sortedCompany[0][key] / 100) : 0);
      let min = Math.min((sortedSelectedRegion[sortedSelectedRegion.length - 1][key] / 100), sortedCompany ? (sortedCompany[sortedCompany.length - 1][key] / 100) : 10.0);
      let median = (max + min) / 2.0;
      let offset = (max - median) * 1.15;
      setChartYaxisBoundaries({ min: median - offset, max: median + offset });
    }
    setChartData({ cats: conf1.cats, subcats: subcats, data: data, customXAxis: { tickVals: conf1.tickVals, tickText: conf1.tickText } })
    setLoading(false);
  }, [selectedRegion, rawData, performanceEvolutionGranularity, performanceEvolutionMetric, dateRangeMin, dateRangeMax])

  useEffect(() => {
    if (rawData === undefined) return;
    let options = [];
    for (let region of rawData.regions) {
      options.push({ name: region, value: region, selected: false })
    }
    options = options.sort((a, b) => (a.name > b.name) ? 1 : (b.name > a.name) ? -1 : 0);
    if (options.length > 0) {
      options[0].selected = true;
      setSelectedRegion(options[0].value);
      setRegionsOptionList(options);
    }
    else {
      setChartData({});
    }
  }, [rawData])

  useEffect(() => {
    if (url === undefined) return;
    setChartData(undefined);
  }, [url])

  return (
    chartData && Object.keys(chartData).length > 0 ? (
      <BasicChart
        type='Scater'
        title={`Performance evolution - Regional manager vs Company`}
        height={500 - 70}
        customXAxis={chartData ? chartData.customXAxis : undefined}
        cats={chartData ? chartData.cats : undefined}
        subcats={chartData ? chartData.subcats : undefined}
        data={chartData ? chartData.data : undefined}
        filters={[performanceEvolutionGranularitySelector, performanceEvolutionMetricSelector]}
        subcatsColors={performanceEvolutionMetric === 'Bad' ? ['#F66156', '#F99D96'] : performanceEvolutionMetric === 'Good' ? ['#F1C02E', '#F6D87D'] : ['#29C42F', '#7ADA7E']}
        loading={loading}
        preBuiltFilters={[<Select multiple={false} callback={whenSelectingRegion} main={false} optionsList={regionsOptionList} />]}
        showYTickLabels={true}
        legend={true}
        yrange={[chartYaxisBoundaries.min, chartYaxisBoundaries.max]}
        tickFormat=',.0%' />
    ) : (
      <React.Fragment />
    )
  )
}

export default RegionsPerformanceEvolution;