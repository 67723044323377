import React, { useState, useEffect, useRef } from 'react';
import style from './DateSelect.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import './Styles.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ModalWrapper from '../../common/modalWrapper/ModalWrapper';
import calendar from '../../../vectors/calendar.svg';

const LOADING_MESSAGES = [
  'Select a Store',
  'Searching days with data...'
]

const DateSelect = (props) => {
  const { name, callback, main, range, defStartDate, defEndDate, onYearMonthChange, excludedDates, quickSelection } = props;
  const [value, setValue] = useState(undefined);
  const [displayDatePicker, setDisplayDatePicker] = useState(false);
  const myRef = useRef();
  const [boundary, setBoundary] = useState(undefined);

  const setDefaultDate = () => {
    let date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
  }

  const [startDate, setStartDate] = useState(defStartDate || setDefaultDate());
  const [endDate, setEndDate] = useState(defEndDate || setDefaultDate());
  const [firstTime, setFirstTime] = useState(true);
  const [loadingMsgIndex, setLoadingMsgIndex] = useState(0);
  const [excludeRangeStart, setExcludeRangeStart] = useState(undefined);
  const [excludeRangeEnd, setExcludeRangeEnd] = useState(undefined);

  useEffect(() => {
    if (loadingMsgIndex === 0 && excludedDates !== undefined)
      setLoadingMsgIndex(loadingMsgIndex + 1)
  }, [excludedDates, loadingMsgIndex])

  useEffect(() => {
    if (startDate === undefined || endDate === undefined) return;
    let parsedStartDate = parseDate(startDate);
    let value = range ? { min: startDate, max: endDate } : parsedStartDate;
    setValue(value);
    if ((firstTime || quickSelection) && callback && name) {
      callback(name, value)
      setFirstTime(false);
    }
    if (!firstTime && quickSelection) setDisplayDatePicker(false);
  }, [startDate, endDate, callback, firstTime, name, range, quickSelection])

  useEffect(() => {
    if (myRef && myRef.current) {
      if (myRef.current.getBoundingClientRect().x + myRef.current.getBoundingClientRect().width > document.body.clientWidth) {
        myRef.current.style.right = 0
      }
    }
  })

  useEffect(() => {
    if (boundary === 'start') {
      setExcludeRangeStart(endDate);
      setExcludeRangeEnd(new Date(endDate.getFullYear() + 10, endDate.getMonth(), endDate.getDate()));
    }
    else if (boundary === 'end') {
      setExcludeRangeStart(new Date(startDate.getFullYear() - 10, startDate.getMonth(), startDate.getDate()));
      setExcludeRangeEnd(startDate);
    }
  }, [boundary, startDate, endDate])

  const updateDate = (date, boundary) => {
    if (!(date instanceof Date)) {
      date = new Date(date)
      date.setTime(date.getTime() + date.getTimezoneOffset() * 2 * 60 * 1000)
    }
    if (range && boundary === 'end') setEndDate(date);
    else setStartDate(date);
    if (range) {
      /* if (boundary === 'end') toggleDisplayDatePicker(!displayDatePicker) */
      setBoundary(boundary === 'end' ? 'start' : 'end');
    }
  }

  const updateDateMobile = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date)
      date.setTime(date.getTime() + date.getTimezoneOffset() * 2 * 60 * 1000)
    }
    setStartDate(date)
    if (date === undefined) return;
    let parsed = parseDate(date);
    setValue(parsed);
    if (callback && name) {
      callback(name, parsed)
    }
  }

  const setNewDate = () => {
    if (callback)
      callback(name, value);
    setDisplayDatePicker(!displayDatePicker);
  }

  /* const clear = () => {
    setValue(undefined);
  } */

  const toggleDisplayDatePicker = (newBoundary) => {
    if (range && newBoundary) {
      setBoundary(newBoundary);
      if (boundary && boundary !== newBoundary && displayDatePicker)
        return;
    }
    if (!displayDatePicker && !range) calendarYearMonthChanged(startDate);
    setDisplayDatePicker(!displayDatePicker);
  }

  const parseDate = (date) => {
    return { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() }
  }

  const iconStyles = {
    transform: 'rotate(90deg)'
  }

  const _onFocus = (e) => {
    e.currentTarget.type = "date";
  }
  const _onBlur = (e) => {
    e.currentTarget.type = "text";
    e.currentTarget.placeholder = "Enter a Date";
  }

  const customMonthPeriodSelected = (startDate, endDate, months) => {
    startDate.setMonth(startDate.getMonth() - months)
    startDate.setDate(1)
    endDate = new Date(startDate);
    endDate.setMonth(endDate.getMonth() + months)
    endDate.setDate(endDate.getDate() - 1)
    return [startDate, endDate]
  }

  const customPeriodSelected = (type, months = 0) => {
    let range = [new Date(), new Date()]
    switch (type) {
      case 1:
        range[0].setDate(range[0].getDate() - 7)
        break;
      case 2:
        range = customMonthPeriodSelected(range[0], range[1], months);
        break;
      default:
    }
    updateDate(range[0], 'start');
    updateDate(range[1], 'end');
  }

  const calendarYearMonthChanged = (date) => {
    if (onYearMonthChange) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      onYearMonthChange(year, month)
    }
  }

  return (
    <ModalWrapper
      display={displayDatePicker}
      setDisplay={setDisplayDatePicker}>
      <div className={style.wrapper}>
        <div className={`${style.selectMobile} ${range ? style.range : ''}`}>
          {/* <span className={style.boxWrapperLabel}>{name}</span> */}
          <input
            type="date"
            className={`${style.boxWrapper} ${main ? style.main : ''}`} name={name} onChange={(e) => { updateDateMobile(e.target.value) }}
            onFocus={_onFocus}
            onBlur={_onBlur} />
        </div>
        <div className={`${style.selectDesktop} ${range ? style.range : ''}`}>
          {/* <span className={style.boxWrapperLabel}>
            {
              main ? (
                `${name}`
              ) : (
                ''
              )
            }
          </span> */}
          <div name={name} className={`${style.boxWrapper} ${main ? style.main : ''} ${range ? style.range : ''}`} onClick={() => toggleDisplayDatePicker('start')}>
            <span className={style.selectionLabel}>
              <img src={calendar} alt={calendar} style={{marginRight: '8px'}}/>
              {
                value ? (
                  range ? (
                    `${value.min.toLocaleString('default', { month: 'long' }).substring(0, 3)} ${value.min.getDate().toString().padStart(2, '0')}, ${value.min.getFullYear()}`
                  ) : (
                    `${value.month.toString().padStart(2, '0')}/${value.day.toString().padStart(2, '0')}/${value.year}`
                  )
                ) : (
                  `-- ${name} --`
                )
              }
            </span>
            <FontAwesomeIcon style={iconStyles} icon={faAngleRight} />
          </div>
          {
            range ? (
              <div name={name} className={`${style.boxWrapper} ${main ? style.main : ''} ${range ? style.range : ''}`} onClick={() => toggleDisplayDatePicker('end')}>
                <span className={style.selectionLabel}>
                  <img src={calendar} alt={calendar} style={{marginRight: '8px'}}/>
                  {
                    value ? (
                      range ? (
                        `${value.max.toLocaleString('default', { month: 'long' }).substring(0, 3)} ${value.max.getDate().toString().padStart(2, '0')}, ${value.max.getFullYear()}`
                      ) : (
                        `${value.month.toString().padStart(2, '0')}/${value.day.toString().padStart(2, '0')}/${value.year}`
                      )
                    ) : (
                      `-- ${name} --`
                    )
                  }
                </span>
                <FontAwesomeIcon style={iconStyles} icon={faAngleRight} />
              </div>
            ) : (
              <React.Fragment />
            )
          }
        </div>

        {
          displayDatePicker ? (
            <div className={`${style.datePickerWrapper} datePicker-wrapper`} ref={myRef}>
              {
                range ? (
                  <div className={style.rangeCalendarSelector}>
                    <div className={style.customPeriods}>
                      <span>Options</span>
                      <button onClick={() => customPeriodSelected(1)}>7 Days</button>
                      <button onClick={() => customPeriodSelected(2, 1)}>Last Month</button>
                      <button onClick={() => customPeriodSelected(2, 3)}>Last 3 Months</button>
                      <button onClick={() => customPeriodSelected(2, 6)}>Last 6 Months</button>
                    </div>
                    <div className={style.rangeCalendarWrapper}>

                      <div className={style.rangeCalendar}>
                        {/* <div className={style.rangeHelp}>
                          <span>Select a {boundary} date</span>
                        </div> */}
                        <DatePicker
                          selected={startDate}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={(date) => { updateDate(date, boundary) }}
                          showMonthDropdown
                          showYearDropdown
                          calendarClassName="kwali-range-calendar"
                          monthsShown={2}
                          excludeDateIntervals={excludeRangeStart && excludeRangeEnd ? [{ start: excludeRangeStart, end: excludeRangeEnd }] : []}
                          inline
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={style.calendar}>
                    {
                      excludedDates === undefined ? (
                        <div className={style.loading}>
                          <span>{LOADING_MESSAGES[loadingMsgIndex]}</span>
                        </div>
                      ) : (
                        <React.Fragment />
                      )
                    }
                    <DatePicker
                      selected={startDate}
                      startDate={startDate}
                      onChange={(date) => { updateDate(date) }}
                      showMonthDropdown
                      showYearDropdown
                      calendarClassName="kwali-calendar"
                      onMonthChange={(date) => calendarYearMonthChanged(date)}
                      onYearChange={(date) => calendarYearMonthChanged(date)}
                      excludeDates={excludedDates}
                      inline
                    />
                  </div>
                )
              }
              {
                range || !quickSelection ? (
                  <div className={style.buttonsWrapper}>
                    {
                      range ? (
                        <span className={style.daysCount}>{`${Math.ceil(1 + ((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)))} days selected`}</span>
                      ) : (
                        <React.Fragment />
                      )
                    }
                    <button className={style.clearApply} onClick={setNewDate} >Apply</button>
                  </div>
                ) : (
                  <React.Fragment />
                )
              }
            </div>
          ) : (
            <React.Fragment />
          )
        }
      </div >
    </ModalWrapper>
  )
}

export default DateSelect;


