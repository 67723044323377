import React, { useState, useEffect, useRef } from 'react';
import reportStyle from '../Report.module.css';
import { parseDate } from '../Aux';
import ItemsQualityComparison from '../charts/ItemsQualityComparison';
import VenueQualityComparison from '../charts/VenueQualityComparison';
import ItemsAvgScoreComparison from '../charts/ItemsAvgScoreComparison';
import VenuesFocusOn from '../charts/VenuesFocusOn';
import VenuesShiftPerformanceComparison from '../charts/VenuesShiftPerformanceComparison';
import { FetchService } from '../../../services/Api';


const Compare = (props) => {
  const { language, selectedVenue, venues, dateRangeMin, dateRangeMax, whenContentUpdateStarts, whenContentUpdateEnds } = props;
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const [venueQualityComparisonUrl, setVenueQualityComparisonUrl] = useState(undefined);

  const [renderingVenuesQualityComparison, setRenderingVenuesQualityComparison] = useState(false);
  const [renderingVenuesAvgScoreComparison, setRenderingVenuesAvgScoreComparison] = useState(false);
  const [renderingVenuesFocusOn, setRenderingVenuesFocusOn] = useState(false);
  const [renderingVenueShiftPerformanceComparison, setRenderingVenueShiftPerformanceComparison] = useState(false);
  const [companyQualityAvg, setCompanyQualityAvg] = useState(undefined);

  useEffect(() => {
    if (whenContentUpdateStarts) whenContentUpdateStarts();
    setVenueQualityComparisonUrl(`start_date/${parseDate(dateRangeMin)}/end_date/${parseDate(dateRangeMax)}/`);
  }, [dateRangeMin, dateRangeMax, whenContentUpdateStarts])

  useEffect(() => {
    if (whenContentUpdateEnds && renderingVenuesQualityComparison && renderingVenuesAvgScoreComparison && renderingVenuesFocusOn && renderingVenueShiftPerformanceComparison) whenContentUpdateEnds();
  }, [whenContentUpdateEnds, renderingVenuesQualityComparison, renderingVenuesAvgScoreComparison, renderingVenuesFocusOn, renderingVenueShiftPerformanceComparison])

  const [data, setData] = useState(undefined);

  useEffect(() => {
    setData(undefined);
    let requestConf = {
      endpoint: `analytics/compare/start_date/${parseDate(dateRangeMin)}/end_date/${parseDate(dateRangeMax)}/`,
      method: 'GET',
    }
    FetchService(requestConf)
      .then(response => {
        if (!mountedRef.current) {
          return;
        }
        else {
          setData(response.data);
        }
      })
      .catch(err => whenContentUpdateEnds());
  }, [dateRangeMin, dateRangeMax, whenContentUpdateEnds, language])

  return (
    <div>
      <div className={reportStyle.col2}>
        <ItemsQualityComparison
          data={data}
          title={`Pizza - Store vs Company average`}
          avgLegend={`Stores Average`}
          onCompanyAvg={setCompanyQualityAvg}
          onRendering={setRenderingVenuesQualityComparison} />
        <VenueQualityComparison
          url={venueQualityComparisonUrl}
          venues={venues}
          preselectedVenue={selectedVenue}
          companyQualityAvg={companyQualityAvg}
          dateRangeMin={dateRangeMin}
          dateRangeMax={dateRangeMax} />
      </div>
      <div className={reportStyle.col2}>
        <ItemsAvgScoreComparison
          data={data}
          title='Store vs Company average score'
          avgLegend={`Stores Average`}
          onRendering={setRenderingVenuesAvgScoreComparison} />
        <VenuesFocusOn
          data={data}
          itemName='Stores'
          language={language}
          onRendering={setRenderingVenuesFocusOn} />
      </div>
      <div className={reportStyle.col1}>
        <VenuesShiftPerformanceComparison
          data={data}
          title='Shift performance ranking'
          onRendering={setRenderingVenueShiftPerformanceComparison} />
      </div>
    </div>
  )
}

export default Compare;