import React, { useState, useEffect } from 'react';
import reportStyle from '../Report.module.css';
import 'react-input-range/lib/css/index.css';
import { parseDateRange } from '../Aux';
import VenueTotalPizzas from '../charts/VenueTotalPizzas';
import VenuePizzasDistribution from '../charts/VenuePizzasDistribution';


const Production = (props) => {
  const { selectedVenue, dateRange, whenContentUpdateStarts, whenContentUpdateEnds } = props;

  const [venueTotalPizzasUrl, setVenueTotalPizzasUrl] = useState(undefined);
  const [venuePizzasDistributionUrl, setVenuePizzasDistributionUrl] = useState(undefined);

  const [renderingVenueTotalPizzas, setRenderingVenueTotalPizzas] = useState(undefined);
  const [renderingVenuePizzasDistribution, setRenderingVenuePizzasDistribution] = useState(undefined);

  useEffect(() => {
    setVenueTotalPizzasUrl(`start_date/${parseDateRange(dateRange)[0]}/end_date/${parseDateRange(dateRange)[1]}/`);
    setVenuePizzasDistributionUrl(`start_date/${parseDateRange(dateRange)[0]}/end_date/${parseDateRange(dateRange)[1]}/`);
  }, [dateRange])

  useEffect(() => {
    if (renderingVenueTotalPizzas && renderingVenuePizzasDistribution && whenContentUpdateEnds) whenContentUpdateEnds();
  }, [renderingVenueTotalPizzas, renderingVenuePizzasDistribution, whenContentUpdateEnds])

  useEffect(() => {
    if (whenContentUpdateStarts) whenContentUpdateStarts();
    if (selectedVenue === undefined || dateRange === undefined) return;
  }, [selectedVenue, dateRange, whenContentUpdateStarts])

  return (
    <div>
      <div className={reportStyle.col2}>
        <VenueTotalPizzas
          url={venueTotalPizzasUrl}
          selectedVenue={selectedVenue}
          dateRange={dateRange}
          onRendering={setRenderingVenueTotalPizzas} />
        <VenuePizzasDistribution
          url={venuePizzasDistributionUrl}
          selectedVenue={selectedVenue}
          title='Production distribution ( % - whole period)'
          onRendering={setRenderingVenuePizzasDistribution} />
      </div>
    </div>
  )
}

export default Production