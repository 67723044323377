import Select from '../select/Select'
import { FetchService } from '../../../services/Api';
import React, { useState, useEffect, useRef } from 'react';
import { MAIN_FILTERS } from '../../Constants';

const VenueSelector = (props) => {
  const { venues, multiple, callback, position, main, defaultVenue, whenPrettyNames, autoSelectFirstVenue } = props;
  const [venueSelector, setVenueSelector] = useState(undefined);
  const [optionsList, setOptionsList] = useState([]);
  const [rawOptionsList, setRawOptionsList] = useState([]);
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (venues && venues.length > 0) {
      let requestConf = {
        endpoint: 'dashboard/venue_pretty_name/',
        method: 'POST',
        data: {
          venues: venues
        },
        dataType: 'JSON'
      }

      FetchService(requestConf).then(
        (response) => {
          if (!mountedRef.current) {
            return;
          }
          else {
            let rawOptionList = venues.map(venue => { return { value: venue, name: response.data.venues[venue] ?? venue, selected: false } });
            rawOptionList.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0)
            setRawOptionsList(rawOptionList);
            if (whenPrettyNames) whenPrettyNames(response.data.venues);
          }
        },
        (err) => {
          console.log(err);
        }
      ).catch(err => console.log(err));
    }
  }, [venues, whenPrettyNames])

  useEffect(() => {
    setOptionsList(
      rawOptionsList.map((obj, index) => {
        let newObj = { ...obj, selected: false };
        if (obj.value === defaultVenue) newObj.selected = true;
        if (defaultVenue === undefined && autoSelectFirstVenue && index === 0) {
          callback('Select store', [obj]);
          newObj.selected = true;
        }
        return newObj;
      })
    )
    // eslint-disable-next-line
  }, [callback, autoSelectFirstVenue, rawOptionsList])

  useEffect(() => {
    setOptionsList(prevData => {
      return prevData.map(obj => {
        let newObj = { ...obj, selected: false };
        if (obj.value === defaultVenue) newObj.selected = true;
        return newObj;
      })
    })
  }, [defaultVenue])

  useEffect(() => {
    let venueSelector = {
      ...MAIN_FILTERS['Select store'],
      multiple: multiple,
      optionsList: optionsList,
      callback: callback
    }
    setVenueSelector(venueSelector);
  }, [optionsList, multiple, callback])

  return venueSelector ? (
    <Select {...venueSelector} position={position} main={main} searchable={true} extraActions={false} />
  ) : (
    <React.Fragment />
  )
}

export default VenueSelector;