import { FetchService } from '../../../services/Api';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { INGREDIENT_ICON } from '../../Constants';
import BasicChart from '../basicChart/BasicChart';
import Table from '../table/Table';
import { useChartRendering } from '../../../hooks/useChartRendering';


const RegionsPortionControl = (props) => {
  const { url, data } = props;
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const [portionControl, setPortionControl] = useState(undefined);
  const [portionControlChartData, setPortionControlChartData] = useState(undefined);
  const [regionPortionControl, setRegionPortionControl] = useState(undefined);
  const [regionPortionControlChartData, setRegionPortionControlChartData] = useState(undefined);
  const [rendering, setRendering] = useState(undefined);

  useChartRendering(rendering, props.onRendering);

  useEffect(() => {
    if (data !== undefined) {
      setPortionControl(data.ingredients);
      setRegionPortionControl(data.regions);
    }
    else {
      setPortionControlChartData(undefined);
      setRegionPortionControlChartData(undefined);
      setPortionControl(undefined);
      setRegionPortionControl(undefined);
      setRendering(false);
    }
  }, [data])

  useEffect(() => {
    if (portionControl !== undefined && regionPortionControl !== undefined) setRendering(true);
  }, [portionControl, regionPortionControl])

  useEffect(() => {
    if (portionControl === undefined || Object.keys(portionControl).length === 0) return;
    let data = [];
    let sortedIngredients = Object.keys(portionControl).sort((a, b) => (a > b) ? -1 : (b > a) ? 1 : 0);
    let companyTotal = 0;
    for (let ingredient of sortedIngredients) {
      let overUsed = portionControl[ingredient].over_used * 100;
      let overSpent = portionControl[ingredient].over_spent;
      let overUsedColor = overUsed > 0 ? '#F44336' : '#00B807';
      companyTotal += overSpent;
      data.push([{ img: INGREDIENT_ICON[ingredient], imgStyle: { width: '35px' }, text: ingredient, style: { color: '#11111B' } }, { text: `$ ${overSpent.commaRepresentation(2)}`, style: { color: overUsedColor } }, { text: `${overUsed.commaRepresentation(0)} %`, style: { color: overUsedColor } }])
    }
    let companyOverUsedColor = companyTotal > 0 ? '#F44336' : '#00B807';
    data.push([{ text: 'Total Company', style: { color: '#11111B' } }, { text: `$ ${companyTotal.commaRepresentation(2)}`, style: { color: companyOverUsedColor } }, {}])
    setPortionControlChartData({ title: 'Food usage vs recipes', header: ['Ingredient', '$ Over spent', '% Over used'], data: data })
  }, [portionControl])

  useEffect(() => {
    if (regionPortionControl === undefined || Object.keys(regionPortionControl.data).length === 0) return;
    let data = [];
    let cats = [];
    let sortedData = regionPortionControl.data.sort((a, b) => (parseFloat(a.over_spent) > parseFloat(b.over_spent)) ? -1 : ((parseFloat(b.over_spent) > parseFloat(a.over_spent)) ? 1 : 0));
    let total = 0;
    let count = 0;
    for (let region of sortedData) {
      total += parseFloat(region.over_spent);
      count += 1;
    }
    let avg = total / count ?? 0;
    let upperTreshold = avg * 1.05;
    let lowerTreshold = avg * 0.95;
    for (let region of sortedData) {
      let over_spent = parseFloat(region.over_spent);
      data.push(over_spent > upperTreshold ? [over_spent, 0, 0] : over_spent < upperTreshold && over_spent > lowerTreshold ? [0, over_spent, 0] : [0, 0, over_spent])
      cats.push(region.name)
    }
    setRegionPortionControlChartData({ cats: cats, subcats: ['Bad', 'Good', 'Excellent'], data: data, avg: avg })
  }, [regionPortionControl])

  const fetchPortionControl = useCallback(() => {
    if (url === undefined) return;
    setPortionControlChartData(undefined);
    setRegionPortionControlChartData(undefined);
    setRendering(undefined);
    let requestConf = {
      endpoint: url,
      method: 'GET',
      data: {}
    }

    FetchService(requestConf).then(
      (response) => {
        if (Object.keys(response.data).length !== 0) {
          if (!mountedRef.current) {
            return;
          }
          else {
            setPortionControl(response.data.ingredients);
            setRegionPortionControl(response.data.regions);
          }
        }
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }, [url])

  useEffect(() => {
    fetchPortionControl();
  }, [fetchPortionControl])

  return (
    <React.Fragment>
      {
        portionControlChartData ? (
          <Table
            title={portionControlChartData.title}
            header={portionControlChartData.header}
            data={portionControlChartData.data}
            total={true} />
        ) : (
          <React.Fragment />
        )
      }
      {
        regionPortionControlChartData ? (
          <BasicChart
            title={'Food usage vs recipes distribution'}
            type='bar'
            tickFormat=',.2f'
            avgTextFormat='%{y:$,.2f}'
            tickPrefix='$'
            height={500 - 70}
            showYTickLabels={true}
            cats={regionPortionControlChartData.cats}
            subcats={regionPortionControlChartData.subcats}
            data={regionPortionControlChartData.data}
            avgLine={regionPortionControlChartData.avg}
            avgLegend='Company Average'
            subcatsColors={['#FC5245B2', '#FCD049B2', '#27CE2DB2']}
            tickSize='large'
            legend={true} />
        ) : (
          <React.Fragment />
        )
      }
    </React.Fragment>
  )
}

export default RegionsPortionControl;