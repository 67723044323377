import { FetchService } from '../../../services/Api';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import BasicChart from '../basicChart/BasicChart';
import { getGranularitySelector, getGranularityCats } from '../Aux';
import { useChartRendering } from '../../../hooks/useChartRendering';


const VenueAvgScoreDistribution = (props) => {
  const { url, selectedVenue, dateRange, staffUser } = props;
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const [completedUrl, setCompletedUrl] = useState(undefined);
  const [rawData, setRawData] = useState(undefined);
  const [chartData, setChartData] = useState(undefined);
  const [granularity, setGranularity] = useState('weekly')
  const [loading, setLoading] = useState(false);
  const [granularitySelector, setGranularitySelector] = useState({});
  const [chartYaxisBoundaries, setChartYaxisBoundaries] = useState({ min: 0, max: 10.1 });
  useEffect(() => {
    setGranularitySelector({ ...getGranularitySelector(granularity, setGranularity) })
  }, [granularity])
  
  useChartRendering(chartData, props.onRendering);

  useEffect(() => {
    if (rawData === undefined) return;
    let dictData = {};
    for (let period of rawData.data) {
      dictData[period.datetime] = period.avg_score;
    }
    let conf1 = getGranularityCats(dateRange, dictData, granularity);
    let data = conf1.data;
    let subcats = ['Average Score'];
    let key = 'avg_score';
    let sortedAverageScore = rawData.data.sort((a, b) => (parseFloat(a[key]) > parseFloat(b[key])) ? -1 : ((parseFloat(b[key]) > parseFloat(a[key])) ? 1 : 0));
    let sortedFranchiseAverage;
    let sortedBestStore;

    if (rawData.data_avg_all_venues && rawData.data_best_venue) {
      dictData = {};
      for (let period of rawData.data_avg_all_venues) {
        dictData[period.datetime] = period.avg_score;
      }
      let conf2 = getGranularityCats(dateRange, dictData, granularity);
      subcats.push('Franchisee Average');
      sortedFranchiseAverage = rawData.data_avg_all_venues.sort((a, b) => (parseFloat(a[key]) > parseFloat(b[key])) ? -1 : ((parseFloat(b[key]) > parseFloat(a[key])) ? 1 : 0));

      dictData = {};
      for (let period of rawData.data_best_venue) {
        dictData[period.datetime] = period.avg_score;
      }
      let conf3 = getGranularityCats(dateRange, dictData, granularity);
      subcats.push('Best Store')
      sortedBestStore = rawData.data_best_venue.sort((a, b) => (parseFloat(a[key]) > parseFloat(b[key])) ? -1 : ((parseFloat(b[key]) > parseFloat(a[key])) ? 1 : 0));

      data = [];
      for (let i = 0; i < conf1.data.length; i++) {
        data.push([conf1.data[i][0], conf2.data[i][0], conf3.data[i][0]])
      }
    }
    if (sortedAverageScore.length !== 0) {
      let max = Math.max(sortedAverageScore[0][key], sortedFranchiseAverage ? sortedFranchiseAverage[0][key] : 0, sortedBestStore ? sortedBestStore[0][key] : 0);
      let min = Math.min(sortedAverageScore[sortedAverageScore.length - 1][key], sortedFranchiseAverage ? sortedFranchiseAverage[sortedFranchiseAverage.length - 1][key] : 10.0, sortedBestStore ? sortedBestStore[sortedBestStore.length - 1][key] : 10.0);
      let median = (max + min) / 2.0;
      let offset = (max - median) * 1.15;
      setChartYaxisBoundaries({ min: median - offset, max: median + offset });
    }
    setChartData({ cats: conf1.cats, subcats: subcats, data: data, customXAxis: { tickVals: conf1.tickVals, tickText: conf1.tickText } })
  }, [rawData, granularity, dateRange])

  useEffect(() => {
    if (url === undefined || selectedVenue === undefined || granularity === undefined) return;
    setCompletedUrl(`analytics/performance/average/venue/${selectedVenue}/type/${granularity}/${url}`)
  }, [url, selectedVenue, granularity])

  useEffect(() => {
    if (url === undefined) return;
    setChartData(undefined);
  }, [url])

  const fetchAvgScore = useCallback(() => {
    if (completedUrl === undefined) return;
    setLoading(true);
    let requestConf = {
      endpoint: completedUrl,
      method: 'GET',
      data: {
        staff_user: staffUser ? 1 : 0
      }
    }
    FetchService(requestConf).then(
      (response) => {
        if (!mountedRef.current) {
          return;
        }
        else {
          setRawData(response.data);
          setLoading(false);
        }
      },
      (err) => {
      }
    ).catch(err => console.log(err));
  }, [completedUrl, staffUser])

  useEffect(() => {
    fetchAvgScore();
  }, [fetchAvgScore])

  return (
    chartData ? (
      <BasicChart
        type='Scater'
        title='Average score'
        height={500 - 70}
        customXAxis={chartData.customXAxis}
        yrange={[chartYaxisBoundaries.min, chartYaxisBoundaries.max]}
        cats={chartData.cats}
        subcats={chartData.subcats}
        subcatsColors={['#5BC4FF', '#FC5245B2', '#27CE2DB2']}
        dash={['solid', 'dash', 'dash']}
        data={chartData.data}
        filters={[granularitySelector]}
        loading={loading}
        legend={true} />
    ) : (
      <React.Fragment />
    )
  )
}

export default VenueAvgScoreDistribution;