import React from 'react';
import style from './WeekShift.module.css'

const WeekShift = (props) => {
  const { weekdayShift, scoreless, REPORT_PIZZAS_SCORELESS_GOAL_PER_SHIFT } = props;
  const getBColor = (count) => {
    if (scoreless) {
      return count === 0 ? 'black' : count === REPORT_PIZZAS_SCORELESS_GOAL_PER_SHIFT ? '#4bc738' : count > REPORT_PIZZAS_SCORELESS_GOAL_PER_SHIFT ? '#ff2500' : '#4472c4';
    }
    else {
      return count > 0 ? '#4472c4' : 'black'
    }
  }

  return (
    <div className={style.wrapper}>
      <table>
        <tbody>
          <tr>
            <th></th>
            <th>S</th>
            <th>M</th>
            <th>T</th>
            <th>W</th>
            <th>T</th>
            <th>F</th>
            <th>S</th>
          </tr>
          <tr>
            <th>AM</th>
            <td style={{ backgroundColor: getBColor(weekdayShift.AM.Sunday), color: 'white' }}>{weekdayShift.AM.Sunday}</td>
            <td style={{ backgroundColor: getBColor(weekdayShift.AM.Monday), color: 'white' }}>{weekdayShift.AM.Monday}</td>
            <td style={{ backgroundColor: getBColor(weekdayShift.AM.Tuesday), color: 'white' }}>{weekdayShift.AM.Tuesday}</td>
            <td style={{ backgroundColor: getBColor(weekdayShift.AM.Wednesday), color: 'white' }}>{weekdayShift.AM.Wednesday}</td>
            <td style={{ backgroundColor: getBColor(weekdayShift.AM.Thursday), color: 'white' }}>{weekdayShift.AM.Thursday}</td>
            <td style={{ backgroundColor: getBColor(weekdayShift.AM.Friday), color: 'white' }}>{weekdayShift.AM.Friday}</td>
            <td style={{ backgroundColor: getBColor(weekdayShift.AM.Saturday), color: 'white' }}>{weekdayShift.AM.Saturday}</td>
          </tr>
          <tr>
            <th>PM</th>
            <td style={{ backgroundColor: getBColor(weekdayShift.PM.Sunday), color: 'white' }}>{weekdayShift.PM.Sunday}</td>
            <td style={{ backgroundColor: getBColor(weekdayShift.PM.Monday), color: 'white' }}>{weekdayShift.PM.Monday}</td>
            <td style={{ backgroundColor: getBColor(weekdayShift.PM.Tuesday), color: 'white' }}>{weekdayShift.PM.Tuesday}</td>
            <td style={{ backgroundColor: getBColor(weekdayShift.PM.Wednesday), color: 'white' }}>{weekdayShift.PM.Wednesday}</td>
            <td style={{ backgroundColor: getBColor(weekdayShift.PM.Thursday), color: 'white' }}>{weekdayShift.PM.Thursday}</td>
            <td style={{ backgroundColor: getBColor(weekdayShift.PM.Friday), color: 'white' }}>{weekdayShift.PM.Friday}</td>
            <td style={{ backgroundColor: getBColor(weekdayShift.PM.Saturday), color: 'white' }}>{weekdayShift.PM.Saturday}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default WeekShift;