import { FetchService } from '../../../services/Api';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import BasicChart from '../basicChart/BasicChart';
import { getQualitySelector } from '../Aux';
import { getSpecialtiesByLanguage } from '../../Constants';
import { useChartRendering } from '../../../hooks/useChartRendering';


const VenueQualityBySpecialty = (props) => {
  const { url, selectedVenue, language, data } = props;
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const [completedUrl, setCompletedUrl] = useState(undefined);
  const [rawData, setRawData] = useState(undefined);
  const [chartData, setChartData] = useState(undefined);
  const [metric, setMetric] = useState('Bad');
  const [metricSelector, setMetricSelector] = useState(undefined);
  useEffect(() => {
    setMetricSelector({ ...getQualitySelector(metric, setMetric) })
  }, [metric])

  useChartRendering(chartData, props.onRendering);

  useEffect(() => {
    if (data !== undefined) setRawData(data.quality_by_specialty);
    else setChartData(undefined);
  }, [data])

  useEffect(() => {
    if (rawData === undefined) return;
    let data = [];
    let cats = [];
    rawData.sort((a, b) => parseFloat(a[`pct_${metric.toLowerCase()}s`]) < parseFloat(b[`pct_${metric.toLowerCase()}s`]) ? 1 : parseFloat(a[`pct_${metric.toLowerCase()}s`]) > parseFloat(b[`pct_${metric.toLowerCase()}s`]) ? -1 : 0)
    for (let specialty of rawData) {
      let pct = parseFloat(specialty[`pct_${metric.toLowerCase()}s`]) / 100;
      data.push(metric === 'Bad' ? [pct, 0, 0] : metric === 'Good' ? [0, pct, 0] : [0, 0, pct]);
      cats.push(getSpecialtiesByLanguage(language)[specialty.specialty]);
    }
    setChartData({ cats: cats, subcats: ['Bad', 'Good', 'Excellent'], data: data })
  }, [rawData, metric, language])

  useEffect(() => {
    if (url === undefined || selectedVenue === undefined) return;
    setCompletedUrl(`analytics/performance/specialty_quality/venue/${selectedVenue}/${url}`)
  }, [url, selectedVenue])

  useEffect(() => {
    if (url === undefined) return;
    setChartData(undefined);
  }, [url])

  const fetchSpecialtyQualityDistribution = useCallback(() => {
    if (completedUrl === undefined) return;
    let requestConf = {
      endpoint: completedUrl,
      method: 'GET',
      data: {
      }
    }
    FetchService(requestConf).then(
      (response) => {
        if (!mountedRef.current) {
          return;
        }
        else {
          setRawData(response.data);
        }
      },
      (err) => {
      }
    ).catch(err => console.log(err));
  }, [completedUrl])

  useEffect(() => {
    fetchSpecialtyQualityDistribution();
  }, [fetchSpecialtyQualityDistribution])

  return (
    chartData ? (
      <BasicChart
      title={`% ${metric} pizzas by specialty`}
      type='bar'
      tickFormat=',.0%'
      height={500 - 70}
      showYTickLabels={true}
      yrange={[0, 1.05]}
      cats={chartData.cats}
      subcats={chartData.subcats}
      data={chartData.data}
      subcatsColors={['#FC5245B2', '#FCD049B2', '#27CE2DB2']}
      filters={[metricSelector]} />
    ) : (
      <React.Fragment />
    )
  )
}

export default VenueQualityBySpecialty;