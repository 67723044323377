import React from 'react';
import style from './LoadingScreen.module.css';

const LoadingScreen = (props) => {
  const { loading, adaptable } = props;

  return (
    <div>
      {
        loading ? (
          <div className={`${style.loadingScreen} ${adaptable ? style.adaptable : ''}`}>
            <span className={style.spinnerWrapper}>
              <span></span>
            </span>
          </div>
        ) : (
          <div></div>
        )
      }
      {props.children}
    </div>
  )
}

export default LoadingScreen;