import React, { useEffect, useState } from 'react';
import style from './OrdinalDate.module.css';
import { MONTHS } from '../../pizzaTv/Utils';


const OrdinalDate = ({ date }) => {
  const [suffix, setSuffix] = useState('');

  useEffect(() => {
    let num = date.getDate();
    let sffx = 'th';
    if (num % 10 === 1 && num % 100 !== 11) {
      sffx = 'st';
    }
    else if (num % 10 === 2 && num % 100 !== 12) {
      sffx = 'nd';
    }
    else if (num % 10 === 3 && num % 100 !== 13) {
      sffx = 'rd';
    }
    setSuffix(sffx)
  }, [date])

  return (
    <span className={style.ordinal}>
      {MONTHS[date.getMonth()]} {date.getDate()}<sup>{suffix}</sup>
    </span>
  )
}

export default OrdinalDate;