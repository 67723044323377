import { FetchService } from '../../../../services/Api';
import React, { useState, useEffect } from 'react';
import style from './UserForm.module.css';
import Select from '../../../common/select/Select'
import { MAIN_FILTERS } from '../../../Constants';
import UserDetailModal from '../userDetailModal/UserDetailModal';
import LoadingScreen from '../../../common/loadingScreen/LoadingScreen';

const UserForm = (props) => {
  const { venues, regions, onSubmit, selectedCompany } = props;
  const [form, setForm] = useState({ user: { email: '', first_name: '', last_name: '' }, venues: [], regions: [], company: selectedCompany.pk });
  const [venueSelector, setVenueSelector] = useState(undefined);
  const [regionSelector, setRegionSelector] = useState(undefined);
  const [selectedVenues, setSelectedVenues] = useState(undefined);
  const [selectedRegions, setSelectedRegions] = useState(undefined);
  const [createdUser, setCreatedUser] = useState(undefined);
  const [displayCreatedUserModal, setDisplayCreatedUserModal] = useState(false);
  const [venuesOptionsList, setVenuesOptionsList] = useState([]);
  const [regionsOptionsList, setRegionsOptionsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const postMemberships = (data) => {
    let requestConf = {
      endpoint: 'dashboard/membership/',
      method: 'POST',
      data: data,
      dataType: 'JSON'
    }

    setLoading(true);
    FetchService(requestConf).then(
      (response) => {
        setCreatedUser(response.data);
        setDisplayCreatedUserModal(true);
        setSelectedVenues(undefined);
        setSelectedRegions(undefined);
        setForm({ user: { email: '', first_name: '', last_name: '' }, venues: [], regions: [], company: selectedCompany.pk });
        setLoading(false);
        if (response.data.alert) window.alert(`User created but an error ocurred sending email: ${response.data.alert}`);
        if (onSubmit) onSubmit();
      }
    ).catch(
      (error) => {
        if (error.cause && error.cause.response.data && error.cause.response.data.user) {
          for (let key of Object.keys(error.cause.response.data.user)) {
            alert(error.cause.response.data.user[key][0])
          }
        }
      }
    );
  }

  useEffect(() => {
    form.venues = selectedVenues;
  }, [selectedVenues, form])

  useEffect(() => {
    form.regions = selectedRegions;
  }, [selectedRegions, form])

  useEffect(() => {
    if (venues === undefined || regions === undefined) return;
    let requestConf = {
      endpoint: 'dashboard/venue_pretty_name/',
      method: 'POST',
      data: {
        venues: venues,
        regions: regions
      },
      dataType: 'JSON'
    }

    FetchService(requestConf).then(
      (response) => {
        let venueOptions = venues.map(venue => { return { value: venue, name: response.data.venues[venue] ?? venue } });
        venueOptions.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
        let regionOptions = regions.map(region => { return { value: region, name: response.data.regions[region] ?? region } });
        regionOptions.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
        setVenuesOptionsList(venueOptions);
        setRegionsOptionsList(regionOptions);
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }, [venues, regions])

  useEffect(() => {
    let venueSelector = {
      ...MAIN_FILTERS['Select store'],
      multiple: true,
      optionsList: venuesOptionsList,
      callback: (name, selection) => {
        let selectedVenues = [];
        for (let i = 0; i < selection.length; ++i) {
          selectedVenues.push(selection[i].value)
        }
        setSelectedVenues(selectedVenues);
      }
    }
    setVenueSelector(venueSelector);
  }, [venuesOptionsList])

  useEffect(() => {
    let regionSelector = {
      ...MAIN_FILTERS['Select store'],
      label: 'Select Region',
      name: 'Select Region',
      multiple: true,
      optionsList: regionsOptionsList,
      callback: (name, selection) => {
        let selectedRegions = [];
        for (let i = 0; i < selection.length; ++i) {
          selectedRegions.push(selection[i].value)
        }
        setSelectedRegions(selectedRegions);
      }
    }
    setRegionSelector(regionSelector);
  }, [regionsOptionsList])

  const editFormData = (e) => {
    let newForm = { ...form };
    newForm.user[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const validateEmail = (email) => {
    //eslint-disable-next-line
    return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };

  const submit = () => {
    if (!form.user.first_name || !form.user.last_name || !form.user.email) {
      window.alert('All fields are required');
      return;
    }
    form.user.username = form.user.email
    if (!validateEmail(form.user.email)) {
      window.alert('Enter a valid email address');
      return;
    }
    postMemberships(form);
  }

  return (
    <LoadingScreen loading={loading}>
      <div className={style.wrapper}>
        <h4 style={{ marginTop: '0' }}>Create New User</h4>
        <UserDetailModal user={createdUser} displayModal={displayCreatedUserModal} setDisplayModal={setDisplayCreatedUserModal} />
        <div className={style.userForm}>
          <div className={style.userFormInputs}>
            <div className={style.userFormCel}><input className={style.userFormInput} type='text' name='email' placeholder='Email' value={form.user.email} onChange={(e) => { editFormData(e) }} /></div>
            <div className={style.userFormCel}><input className={style.userFormInput} type='text' name='first_name' placeholder='Name' value={form.user.first_name} onChange={(e) => { editFormData(e) }} /></div>
            <div className={style.userFormCel}><input className={style.userFormInput} type='text' name='last_name' placeholder='Last Name' value={form.user.last_name} onChange={(e) => { editFormData(e) }} /></div>
            <div className={style.userFormCel}>
              {
                venueSelector ? (
                  <Select {...venueSelector} style={{ width: '197px' }} />
                ) : (
                  <React.Fragment />
                )
              }
            </div>
            <div className={style.userFormCel}>
              {
                regionSelector ? (
                  <Select {...regionSelector} style={{ width: '197px' }} />
                ) : (
                  <React.Fragment />
                )
              }
            </div>
            <div className={`${style.userFormCel}`}>
              <button className={style.submitButton} onClick={() => submit()}>Create</button>
            </div>
          </div>
        </div>
      </div>
    </LoadingScreen>
  )
}

export default UserForm;