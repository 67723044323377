import React, { useState } from 'react';
import Table from '../table/Table';
import style from './PizzaDetail.module.css';
import Score from '../score/Score';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { faExpandAlt } from '@fortawesome/free-solid-svg-icons';
import { UI_TEXT, PEPPERONI_SPECS, NON_PEPPERONI_ING_ADJUST } from '../../Constants';

const PizzaDetail = (props) => {
  const { imgLink, evaluationResult, openFeebackDialog, editionCallback, omitSoft, rawScore, specialty, allowFeedback } = props;
  const [editing, setEditing] = useState(props.editing || false);

  const getTotal = () => {
    if (!evaluationResult.soft) return;
    let softIngredientsScore = evaluationResult.soft['Ingredients'];
    if (!rawScore && !PEPPERONI_SPECS.includes(specialty)) {
      softIngredientsScore = Math.min(4.0, softIngredientsScore + NON_PEPPERONI_ING_ADJUST)
    }
    return softIngredientsScore;
  }

  return (
    <div className={style.wrapper}>
      <div className={style.imageWrapper}>
        <img className={style.image} src={imgLink.slice(0, -4) + '/circle_image.jpg'} alt='' />
        {
          openFeebackDialog ? (
            <button className={`${style.imageButton} ${style.commentButton}`} onClick={() => openFeebackDialog()}><FontAwesomeIcon icon={faCommentDots} /></button>
          ) : (
            <div></div>
          )
        }
        <button className={`${style.imageButton} ${style.expandButton}`}><FontAwesomeIcon icon={faExpandAlt} /></button>
      </div>
      {
        props.score ? (
          <div className={style.rightPanel}>
            <React.Fragment>
              <div className={style.tablesWrapper}>
                {
                  Object.keys(evaluationResult.score_details).map(function (key, index) {
                    if (!key.startsWith('Ingredientes') || omitSoft) {
                      return <Table
                        key={index}
                        name={UI_TEXT.evaluation[key]}
                        decimals={2}
                        scoreDetails={evaluationResult.score_details[key]}>
                      </Table>
                    }
                    return <React.Fragment />
                  })
                }
                {
                  !omitSoft ? (
                    <Table
                      name={'Ingredients'}
                      decimals={2}
                      scoreDetails={{ "Pizza": getTotal() }}>
                    </Table>
                  ) : (
                    <React.Fragment />
                  )
                }
                <div className={style.mobileScore}>
                  <span>
                    Total
                  </span>
                  <Score
                    score={props.score}>
                  </Score>
                </div>
              </div>
              {
                allowFeedback ? (
                  <button className={style.editScoring} onClick={e => { editionCallback(); setEditing(!editing); }}><FontAwesomeIcon className={style.editScoringIcon} icon={faPen} />
                    {editing ? ('Save') : ('Give Feedback')}
                  </button>
                ) : (
                  <React.Fragment />
                )
              }
            </React.Fragment>
          </div>
        ) : (
          <React.Fragment />
        )
      }
    </div>
  )
}

export default PizzaDetail