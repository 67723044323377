import React from 'react';
import Card from '../card/Card';
import style from './Grid.module.css';

const Grid = (props) => {
  const { cards, callback, allowFeedback, companyInfo, markPizzaCard, user } = props;

  return (
    <div className={style.wrapper}>
      {
        cards.map((card, index) => {
          return <Card
            allowFeedback={allowFeedback}
            key={index}
            cardIndex={index}
            index={index}
            callback={callback}
            companyInfo={companyInfo}
            markPizzaCard={markPizzaCard}
            user={user}
            {...card}>
          </Card>
        })
      }
    </div>
  )
}

export default Grid