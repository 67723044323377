import React, { useState } from 'react';
import style from './FeedbackDialog.module.css';
import close from '../../../vectors/close.svg';
import { FetchService } from '../../../services/Api';

const FeedbackDialog = (props) => {
  const { closeFeedbackDialog } = props;
  const [extra, setExtra] = useState(props.gtExtra);

  const setPizzaGroundTruthFeedback = () => {
    let data = {
      endpoint: 'set_gt_for_ingredients_labeling',
      method: 'POST',
      data: {
        t_id: props.tId,
        pipe_id: props.pipeId,
        vid_id: props.vidId,
        split: props.split,
        gt_optimal_frame: props.gtOptimalFrame,
        gt_extra: extra || props.gtExtra,
        gt_category: props.gtCategory,
        gt_product: props.gtProduct,
        gt_crust: props.gtCrust,
        gt_snap_quality: props.gtSnapQuality,
        gt_score_criteria: props.gtScoreCriteria
      }
    }

    FetchService(data).then(response => {
      console.log(response);
      closeFeedbackDialog();
    }).catch(err => console.log(err));
  }

  return (
    <div className={style.background}>
      <div className={style.wrapper}>
        <div className={style.dialogHeader}>
          <img className={style.closeButton} onClick={() => closeFeedbackDialog()} src={close} alt='Close' />
        </div>
        <span className={style.dialogTitle}>User feedback</span>
        <div className={style.dialogBody}>
          <textarea
            name="textarea"
            rows="10"
            placeholder='Here you can add your remarks about the image or scoring'
            onChange={(e) => setExtra(e.target.value)}
            value={extra}>
          </textarea>
        </div>
        <div className={style.dialogFooter}>
          <button onClick={() => setPizzaGroundTruthFeedback()}>Send</button>
        </div>
      </div>
    </div>
  )
}

export default FeedbackDialog;