import { FetchService } from '../../../services/Api';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import BasicChart from '../basicChart/BasicChart';
import { useChartRendering } from '../../../hooks/useChartRendering';


const ItemsAvgScoreComparison = (props) => {
  const { url, title, avgLegend, data } = props;
  const mountedRef = useRef(true)

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const [rawData, setRawData] = useState(undefined);
  const [chartData, setChartData] = useState(undefined);
  const [chartYaxisBoundaries, setChartYaxisBoundaries] = useState({ min: 0, max: 10.1 });

  useChartRendering(chartData, props.onRendering);

  useEffect(() => {
    if (data !== undefined) setRawData(data);
    else setChartData(undefined);
  }, [data])

  useEffect(() => {
    if (rawData === undefined) return;
    if (rawData.data.length === 0) {
      setChartData({ cats: [], subcats: [], data: [] });
      return;
    }
    let data = [];
    let cats = [];
    let key = 'avg_score';
    let sortedData = rawData.data.sort((a, b) => (parseFloat(a[key]) > parseFloat(b[key])) ? -1 : ((parseFloat(b[key]) > parseFloat(a[key])) ? 1 : 0));
    let avg = parseFloat(rawData.all_venues_avg);
    let upperTreshold = avg * 1.05;
    let lowerTreshold = avg * 0.95;
    let offset = Math.max(Math.abs(sortedData[0][key] - avg), Math.abs(sortedData[sortedData.length - 1][key] - avg)) * 1.15;
    setChartYaxisBoundaries({ min: avg - offset, max: avg + offset });
    for (let item of sortedData) {
      data.push(item[key] < lowerTreshold ? [item[key], 0, 0] : item[key] >= lowerTreshold && item[key] <= upperTreshold ? [0, item[key], 0] : [0, 0, item[key]])
      cats.push(item.name)
    }
    setChartData({ cats: cats, subcats: ['Bad', 'Good', 'Excellent'], data: data, avg: avg })
  }, [rawData])

  const fetchAvgScore = useCallback(() => {
    if (url === undefined) return;
    setChartData(undefined);
    let requestConf = {
      endpoint: url,
      method: 'GET',
      data: {
      }
    }
    FetchService(requestConf).then(
      (response) => {
        if (!mountedRef.current) {
          return;
        }
        else {
          setRawData(response.data);
        }
      },
      (err) => {
      }
    ).catch(err => console.log(err));
  }, [url])

  useEffect(() => {
    fetchAvgScore();
  }, [fetchAvgScore])

  return (
    chartData ? (
      <BasicChart
        title={title}
        type='bar'
        height={500 - 70}
        showYTickLabels={true}
        yrange={[chartYaxisBoundaries.min, chartYaxisBoundaries.max]}
        cats={chartData.cats}
        subcats={chartData.subcats}
        data={chartData.data}
        avgLine={chartData.avg}
        avgLegend={avgLegend}
        subcatsColors={['#FC5245B2', '#FCD049B2', '#27CE2DB2']}
        legend={true} />
    ) : (
      <React.Fragment />
    )
  )
}

export default ItemsAvgScoreComparison;