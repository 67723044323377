import { FetchService } from '../../services/Api';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import Grid from './grid/Grid';
import CardDetail from './cardDetail/CardDetail';
import Filters from './filters/Filters';
import Summary from './summary/Summary';
import { PIZZA_SPEC, TEST_DATA } from '../ConstantsTest';
import { SORT_BY, CARDS_FILTERS, BASE_POINTS, NON_PEPPERONI_ING_ADJUST, PEPPERONI_SPECS } from '../Constants';
import FeedbackDialog from './feedbackDialog/FeedbackDialog';
import style from './Dashboard.module.css';
import { buildFilters, setSpecialtiesOptions, getSpecialtyDisplayName } from './Aux';
import LoadingScreen from '../common/loadingScreen/LoadingScreen';
import { useSearchParams } from 'react-router-dom';
import Select from '../common/select/Select';
import ModalWrapper from '../common/modalWrapper/ModalWrapper';


const Dashboard = (props) => {
  const { test, language, whenSelectingVenue, whenSelectingDate, selectedDate, venues, user, whenSelectingCompany } = props;
  const selectedVenue = test ? 'Bandera' : props.selectedVenue;
  const selectedCompany = test ? 'PapaJohns_CL' : props.selectedCompany;
  const staffUser = user && user.employer && user.employer.pk === 'Kwali';
  // Raw users are allowed to see "raw data" (ie without scoring)
  const rawUser = user && user.employer && ['GrupoTrigo', 'Korok', 'Jimanos', 'MiddleBy'].includes(user.employer.pk);
  const [dashboardVenues, setDashboardVenues] = useState(test ? ['Bandera'] : undefined);
  const [cards, setCards] = useState(test ? [] : []);
  const [cardDetail, setCardDetail] = useState({});
  const [pizzaSpec, setPizzaSpec] = useState(test ? PIZZA_SPEC : []);
  const [filters, setFilters] = useState([]);
  const [validScores, setValidScores] = useState([]);
  const [cardsFilters, setCardsFilters] = useState([]);
  const [feedbackDialog, setFeedbackDialog] = useState({});
  const [filteredByComment, setFilteredByComment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rawData, setRawData] = useState(test ? TEST_DATA : undefined);
  const [companyInfo, setCompanyInfo] = useState(undefined);
  const [reviewedCount, setReviewedCount] = useState(undefined);
  const [pizzaCount, setPizzaCount] = useState(undefined);
  const [censoredCount, setCensoredCount] = useState(undefined);
  const [companyMembership, setCompanyMembership] = useState(undefined);
  const [companies, setCompanies] = useState(undefined);
  const mountedRef = useRef(true)
  const [searchParams] = useSearchParams();
  const [mustResetFilters, setMustResetFilters] = useState(false);
  const [reportSpecialtiesDefinition, setReportSpecialtiesDefinition] = useState(undefined);
  const [reportSpecialtiesByStore, setReportSpecialtiesByStore] = useState(undefined);
  const [reportSpecialties, setReportSpecialties] = useState(undefined);
  const [summaryByReportSpecialties, setSummaryByReportSpecialties] = useState(undefined);
  const [actionOptions, setActionOptions] = useState(undefined);
  const [displayActionOptions, setDisplayActionOptions] = useState(false);
  const [displaySpecialtiesOptions, setDisplaySpecialtiesOptions] = useState(false);
  const [displayCardDetail, setDisplayCardDetail] = useState(false);
  const [index, setIndex] = useState(undefined);
  const [sortSelection, setSortSelection] = useState(undefined);
  const [standardOptions, setStandardOptions] = useState(undefined);
  const [displayStandardOptions, setDisplayStandardOptions] = useState(false);

  useEffect(() => {
    if (actionOptions === undefined) setActionOptions([
      { value: 'change_specialty', name: 'Change specialty' },
      { value: 'change_standard', name: 'Change standard' },
      { value: 'hide', name: 'Hide picture' },
      { value: 'show', name: 'Show picture' },
    ]);
  }, [actionOptions])

  useEffect(() => {
    if (standardOptions === undefined) setStandardOptions([
      { value: 'to_standard', name: 'To Standard' },
      { value: 'not_to_standard', name: 'Not to Standard' },
      { value: 'maybe_to_standard', name: 'Maybe to Standard' },
    ]);
  }, [standardOptions])

  const whenChangingCompany = useCallback((name, selection) => {
    whenSelectingCompany(name, selection);
    setCards([]);
    setValidScores([]);
    setReviewedCount(undefined);
    setPizzaCount(undefined);
    setCensoredCount(undefined);
  }, [whenSelectingCompany])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  /* Fetchers */

  useEffect(() => {
    if (!staffUser) {
      if (venues) setDashboardVenues(venues);
      return;
    }
    if (companyMembership !== undefined) return;
    let requestConf = {
      endpoint: `dashboard/company-membership/`,
      method: 'GET'
    }

    FetchService(requestConf).then(
      (response) => {
        setCompanyMembership(response.data);
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }, [staffUser, venues, companyMembership])

  useEffect(() => {
    if (selectedCompany === undefined) return;
    setLoading(true);
    let requestConf = {
      endpoint: `dashboard/venues-by-company/${selectedCompany}`,
      method: 'GET'
    }

    FetchService(requestConf).then(
      (response) => {
        setDashboardVenues(response.data.venues ?? undefined)
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err)).finally(() => { setLoading(false) });
  }, [selectedCompany])

  useEffect(() => {
    if (companyMembership === undefined) return;
    if (companyMembership.length === 0 && user.employer) {
      whenChangingCompany('', { value: user.employer });
      return;
    }
    if (companyMembership.length === 1) whenChangingCompany('', { value: companyMembership[0].space });
    else {
      let companies = companyMembership.map(m => { return { displayName: m.space.pretty_name || m.space.name, pk: m.space.pk } });
      companies = companies.sort((a, b) => (a.displayName > b.displayName) ? 1 : (b.displayName > a.displayName) ? -1 : 0);
      companies = [...new Set(companies.map(JSON.stringify))].map(JSON.parse);
      if (companies.length === 1) whenChangingCompany('', { value: companies[0] });
      else setCompanies(companies);
    }
  }, [companyMembership, user, whenChangingCompany])

  const fetchCompanyInfo = useCallback(() => {
    if (selectedVenue === undefined) return;
    let data = {
      endpoint: `dashboard/company-info/venue/${selectedVenue}/`,
      method: 'GET'
    }

    FetchService(data).then(
      (response) => {
        if (!mountedRef.current) {
          return;
        }
        else {
          setCompanyInfo(response.data);
        }
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => console.log(err));
  }, [selectedVenue])

  useEffect(() => {
    fetchCompanyInfo();
  }, [fetchCompanyInfo])

  /* End Fetchers */
  /* Effects */

  useEffect(() => {
    if (selectedCompany === undefined && selectedVenue === undefined) return;
    let specialtiesData = {
      endpoint: 'pizza_specialties_info',
      method: 'POST',
      data: {
        company: selectedCompany,
        venue: selectedVenue
      }
    }
    FetchService(specialtiesData).then(
      response => {
        if (!mountedRef.current) {
          return;
        }
        else {
          if (JSON.stringify(pizzaSpec) !== JSON.stringify(response.data.pizza_specialties_info)) setPizzaSpec(response.data.pizza_specialties_info);
          if (reportSpecialtiesDefinition === undefined) setReportSpecialtiesDefinition(response.data.report_specialties_definition);
          if (reportSpecialtiesByStore === undefined) setReportSpecialtiesByStore(response.data.report_specialties_by_store);
        }
      }).catch(err => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany, selectedVenue])

  useEffect(() => {
    if (reportSpecialtiesDefinition === undefined || reportSpecialtiesByStore === undefined || selectedVenue === undefined) return;
    let reportSpecialties = {};
    for (let specialty of (reportSpecialtiesByStore[selectedVenue] ?? reportSpecialtiesByStore._DEFAULT_)) {
      reportSpecialties[specialty] = {
        specialties: reportSpecialtiesDefinition[specialty].specialties
      }
    }
    setReportSpecialties(reportSpecialties);
  }, [reportSpecialtiesDefinition, reportSpecialtiesByStore, selectedVenue])

  useEffect(() => {
    if (selectedVenue === undefined) return;
    let qualityDefintionData = {
      endpoint: 'venue_stats',
      method: 'GET',
      data: {
        venue: selectedVenue
      }
    }

    FetchService(qualityDefintionData).then(response => {
      if (!mountedRef.current) {
        return;
      }
      else {
        localStorage.setItem('qualityDefinitionMax', response.data.quality_definition.max)
        localStorage.setItem('qualityDefinitionMin', response.data.quality_definition.min)
      }
    }).catch(err => console.log(err));
  }, [selectedVenue])

  useEffect(() => {
    if (pizzaSpec.length !== 0) {
      let temp = [...filters];
      for (let i = 0; i < filters.length; ++i) {
        if (filters[i].name === 'All specialties') {
          temp.splice(i, 1);
          setFilters([...temp]);
        }
      }
      setCardsFilters(buildFilters(setSpecialtiesOptions(CARDS_FILTERS, pizzaSpec, language)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pizzaSpec, language])

  useEffect(() => {
    if (test === true) return;
    if (selectedDate === undefined || selectedVenue === undefined) return;
    let data = {
      endpoint: 'shift_report_data',
      data: {
        venue: selectedVenue,
        ...selectedDate
      },
    }

    setLoading(true);
    setCards([]);
    setRawData(undefined);
    setValidScores([]);
    FetchService(data).then(response => {
      if (!mountedRef.current) {
        return;
      }
      else {
        setRawData(response.data)
      }
    }).catch(err => console.log(err));
  }, [selectedDate, selectedVenue, test])


  /* End Effects */

  const calculateScore = (evaluationResult, specialty, omitSoft, rawScore, badCut, overbaked, bubbles) => {
    let qualityScore = BASE_POINTS;
    let scoreCategs = Object.keys(evaluationResult['score_details']);

    if (!omitSoft) {
      scoreCategs = scoreCategs.filter(e => !e.startsWith('Ingredientes'))
    }

    scoreCategs.forEach(function (k) {
      Object.keys(evaluationResult['score_details'][k]).forEach(function (n) {
        // Assumes that keys 'n' are integers from 1 to 8, as defined in #score_table in html file
        qualityScore += evaluationResult['score_details'][k][n]
      })
    })

    if (!omitSoft) {
      let softIngredientsScore = evaluationResult['soft']['Ingredients']

      // Soft Ingredients adjustment ***
      if (!rawScore && !PEPPERONI_SPECS.includes(specialty)) {
        softIngredientsScore = Math.min(4.0, softIngredientsScore + NON_PEPPERONI_ING_ADJUST)
      }
      qualityScore += softIngredientsScore
    }
    if (badCut) qualityScore -= 1;
    if (overbaked) qualityScore /= 2;
    if (bubbles) qualityScore /= 1.5;
    return qualityScore;
  }

  const getStandardAttrByScoreAndCheeseScore = useCallback((score, evaluationResult) => {
    // IMPORTANT! if it is needed to modify the calculation of the next value remember to change it also at backend in report initialization,
    // estimated location papajohns_detection/platform/pizza_tv/views.py InitializeReportAPIView, look up for this string "pizza_standard_attr"
    if (evaluationResult.info && evaluationResult.info.kwali_score && evaluationResult.info.kwali_score.Cheese < companyInfo.kwali_score_cheese_threshold) return 'not_to_standard';
    return Number(score) > 8.5 ? 'to_standard' : Number(score) < 7.5 ? 'not_to_standard' : 'maybe_to_standard';
  }, [companyInfo])

  useEffect(() => {
    if (rawData === undefined) return;
    if (companyInfo === undefined) return;
    const bad = searchParams.get('bad');
    const raw = searchParams.get('raw');
    let vidsAndResults = rawData.vids_and_results;
    let cards = [];
    let tempValidScores = [];

    if (vidsAndResults === undefined) {
      setValidScores([]);
      setCards([]);
      setLoading(false);
      return;
    };
    vidsAndResults.forEach(vRes => {
      if (vRes.pipeline_result) {
        if (vRes.pipeline_result.trackeds.length > 0) {
          vRes.pipeline_result.trackeds.forEach(t => {
            if (t.censored){
              if (!staffUser) {
                return;
              }
            }
            if (!('snap_quality' in t)) {
              if (!((staffUser && raw == '1') || rawUser)) {
                return;
              }
            }
            if ('snap_quality_trusted' in t && t.snap_quality_trusted && t.snap_quality !== 'buena') {
              if (!(staffUser && bad == '1')) {
                return;
              }
            }
            let imgLink = test ? 'https://staging.kwali.ai/api/static/from_edge/Bandera/2022-11-17/j4_2022-11-17_13:02:01_v9p1-ssd-dense_id0002(None)-fr2408-05:01.jpg' : `/api/static/${t.REPORT_optimal_snap_path.substring(2)}`;
            let evaluation_results = t.id in vRes.pipeline_result.evaluation_results ? vRes.pipeline_result.evaluation_results[t.id] : {};
            let kwali_score = 'score_details' in evaluation_results && Object.keys(evaluation_results.score_details).length < 8;
            let score = kwali_score ? evaluation_results.score_final.commaRepresentation(2) : t.id in vRes.pipeline_result.evaluation_results && 'score_details' in vRes.pipeline_result.evaluation_results[t.id] ? calculateScore(vRes.pipeline_result.evaluation_results[t.id], t.category, t.omit_soft, t.raw_score, t.bad_cut, t.overbaked, t.bubbles).commaRepresentation(2) : '';
            if (score.length > 0)
              tempValidScores.push(score);
            cards.push({
              index: cards.length,
              imgLink: imgLink,
              ts: new Date(t.REPORT_localized_capture_start_ts),
              legend: t.REPORT_localized_capture_start_ts,
              specialty: getSpecialtyDisplayName(t.category, language),
              category: t.category,
              score: score,
              kwali_score: kwali_score,
              evaluationResult: evaluation_results,
              crustType: t.crust,
              tId: t.id,
              vidId: vRes.video.REPORT_id,
              pipeId: vRes.pipeline_result.pipeline_id,
              pizzaInfoId: vRes.pipeline_result.REPORT_id,
              split: rawData.split,
              omitSoft: t.omit_soft,
              rawScore: t.raw_score,
              multisize: t.multisize,
              censored: t.censored,
              bubbles: t.bubbles,
              badCut: t.bad_cut,
              standard: t.standard,
              standardAttr: t.standard || (score && score !== '' ? getStandardAttrByScoreAndCheeseScore(score, evaluation_results) : undefined),
              overbaked: t.overbaked,
              ...(staffUser && { originalData: t.original_data }),
              reviewer: t.reviewer,
              attributes: t.attributes,
              /* feedback */
              gtExtra: t.gt_extra || '',
              gtOptimalFrame: t.gt_optimal_frame || '',
              gtCategory: t.gt_category || '',
              gtProduct: t.gt_product || '',
              gtCrust: t.gt_crust || '',
              gtSnapQuality: t.gt_snap_quality || '',
              gtScoreCriteria: t.gt_score_criteria || '',
              selected: false
            })
          });
        }
      }
    });
    if (sortSelection) setCards(SORT_BY[sortSelection[0].value].sortFunction(cards).map((obj, i) => { return { ...obj, index: i } }));
    else setCards(cards);
    setLoading(false);
    setFilters(prevData => [...prevData]);
    // eslint-disable-next-line
  }, [rawData, language, companyInfo, staffUser, rawUser, test, getStandardAttrByScoreAndCheeseScore, searchParams])

  useEffect(() => {
    setValidScores(cards.filter(card => (!card.censored && card.score !== '')).map(card => card.score));
    setPizzaCount(cards.filter(card => !card.censored).length);
    setCensoredCount(cards.filter(card => card.censored).length);
    setReviewedCount(cards.filter(card => card.reviewer).length);
  }, [cards])

  useEffect(() => {
    if (reportSpecialties === undefined) return;
    let summaryByReportSpecialty = JSON.parse(JSON.stringify(reportSpecialties));
    for (let key of Object.keys(reportSpecialties)) {
      let specialtyCards = cards.filter(card => reportSpecialties[key].specialties.includes(card.category) && !card.censored)
      summaryByReportSpecialty[key].count = specialtyCards.length;
      summaryByReportSpecialty[key].scored = specialtyCards.filter(card => (card.score !== '')).length;
    }
    setSummaryByReportSpecialties(summaryByReportSpecialty);
  }, [cards, reportSpecialties])

  const updateReviewerAndOriginalData = useCallback((index, data) => {
    setCards(prevData => {
      return prevData.map((obj, i) => {
        let newObj = { ...obj };
        if (i === index) {
          newObj = { ...newObj, reviewer: data.reviewer };
          if ('original_data' in data && !Object.keys(data).includes('censored') && i === index) {
            return { ...newObj, originalData: data.original_data };
          }
        }
        return newObj;
      });
    });
    setCardDetail({ ...cards[index] });
    setFilters(prevData => prevData.map(obj => { return { ...obj } }));
  }, [cards])

  const updatePizzaData = useCallback((index, data) => {
    if (!language) return;
    if ('specialty' in data) {
      cards[index].category = data.specialty;
      cards[index].specialty = getSpecialtyDisplayName(data.specialty, language);
    }
    if ('crust' in data) cards[index].crustType = data.crust;
    if ('attributes' in data) cards[index].attributes = data.attributes;
    updateReviewerAndOriginalData(index, data);
  }, [cards, language, updateReviewerAndOriginalData])

  const updatePizzaAttr = useCallback((index, data, attr) => {
    cards[index][attr] = data[attr];
    if (attr === 'standard') cards[index]['standardAttr'] = data[attr];
    updateReviewerAndOriginalData(index, data);
  }, [cards, updateReviewerAndOriginalData])

  const updateScoreDetails = useCallback((index, data) => {
    setCards(prevData => {
      return prevData.map((obj, i) => {
        if (i === index) {
          let newObj = { ...obj };
          newObj.evaluationResult.score_details = data.score_details;
          newObj.evaluationResult.score_final = data.score_final;
          let score = newObj.kwali_score ? data.score_final.commaRepresentation(2) : calculateScore(newObj.evaluationResult, newObj.category, newObj.omitSoft, newObj.rawScore, newObj.badCut, newObj.overbaked, newObj.bubbles).commaRepresentation(2);
          newObj.score = score;
          if ([undefined, null].includes(newObj.standard)) newObj.standardAttr = getStandardAttrByScoreAndCheeseScore(score, newObj.evaluationResult);
          return newObj;
        }
        return obj;
      });
    });
    updateReviewerAndOriginalData(index, data);
  }, [updateReviewerAndOriginalData, getStandardAttrByScoreAndCheeseScore])

  const openCardDetail = useCallback((key) => {
    setIndex(key);
  }, [])

  const closeCardDetail = useCallback(() => {
    setIndex(undefined);
  }, [])

  const changeCard = useCallback((direction) => {
    const mod = (n, m) => ((n % m) + m) % m;
    setIndex(prevData => {
      let newIndex = mod((prevData + direction), cards.length);
      while (!cards[newIndex].filtered) {
        newIndex = mod((newIndex + direction), cards.length);
      }
      return newIndex;
    });
  }, [cards])

  useEffect(() => {
    if (index === undefined) {
      setCardDetail({});
      setDisplayCardDetail(false);
      return;
    }
    setCardDetail({ ...cards[index], index: index });
    setDisplayCardDetail(true);
  }, [index, cards])

  const addFilter = (key, selection) => {
    let temp = filters;
    for (let i = 0; i < filters.length; ++i) {
      if (filters[i].name === key) {
        if (selection.length === 0) {
          temp.splice(i, 1);
        }
        else {
          temp[i].selection = selection;
        }
        setFilters([...temp]);
        return;
      }
    }
    temp.push({ name: key, selection: selection, eval: CARDS_FILTERS[key].eval });
    setFilters([...temp]);
  }

  const sortCards = useCallback((name, selection) => {
    if (selection.length !== 1) return;
    setSortSelection(selection);
    setCards(prevData => SORT_BY[selection[0].value].sortFunction(prevData).map((obj, i) => { return { ...obj, index: i } }));
  }, [])

  const openFeebackDialog = useCallback(() => {
    setFeedbackDialog({ open: true });
  }, [])

  const closeFeedbackDialog = useCallback(() => {
    setFeedbackDialog({});
  }, [])

  const changefilteredByComment = () => {
    if (!filteredByComment)
      addFilter('Has comment', [{ name: 'Has comment', value: '1' }]);
    else
      addFilter('Has comment', []);
    setFilteredByComment(!filteredByComment);
  }

  const resetFilters = () => {
    setFilters([]);
    setMustResetFilters(true);
  }

  useEffect(() => {
    if (mustResetFilters) setMustResetFilters(false);
  }, [mustResetFilters])

  const markPizzaCard = useCallback((cardIndex, value) => {
    setCards(prevData => {
      return prevData.map((obj, index) => {
        if (index === cardIndex) {
          return { ...obj, selected: value };
        }
        return obj;
      });
    });
  }, [])

  const whenSelectingAction = useCallback((name, selection) => {
    let option = selection[0];
    let value, action;
    switch (option.value) {
      case 'hide':
        action = option.value;
        value = 0;
        setDisplaySpecialtiesOptions(false);
        setDisplayStandardOptions(false);
        break;
      case 'show':
        action = option.value;
        value = 1;
        setDisplaySpecialtiesOptions(false);
        setDisplayStandardOptions(false);
        break;
      case 'change_specialty':
        setDisplaySpecialtiesOptions(true);
        setDisplayStandardOptions(false);
        return;
      case 'change_standard':
        setDisplayStandardOptions(true);
        setDisplaySpecialtiesOptions(false);
        return;
      default:
        if (name === 'Specialties') {
          action = 'specialty';
          value = option.value
        }
        else if (name === 'Standard') {
          action = 'standard';
          value = option.value
        }
        break;
    }
    const processResponse = (response) => {
      setCards(prevData => {
        return prevData.map((obj, index) => {
          if (response.data.pizzas.successes.includes(index)) {
            switch (option.value) {
              case 'show':
                return { ...obj, censored: 0, reviewer: response.data.reviewer };
              case 'hide':
                return { ...obj, censored: 1, reviewer: response.data.reviewer };
              default:
                if (name === 'Specialties') {
                  let newObj = { ...obj, category: value, specialty: getSpecialtyDisplayName(value, language), reviewer: response.data.reviewer };
                  if (obj.originalData) {
                    if (!obj.originalData.specialty) {
                      newObj['originalData'] = { ...obj.originalData, specialty: obj.category };
                    }
                    else {
                      newObj['originalData'] = { ...obj.originalData };
                    }
                  }
                  else {
                    newObj['originalData'] = { specialty: obj.category };
                  }
                  return newObj;
                }
                else if (name === 'Standard') {
                  return { ...obj, reviewer: response.data.reviewer, standard: value, standardAttr: value };
                }
            }
          }
          return obj;
        });
      });
    }
    let config = {
      endpoint: `dashboard/qa_feedback_multiple/`,
      method: 'POST',
      dataType: 'JSON',
      alertErrors: false,
      data: {
        pizzas: cards.filter(card => card.selected === true),
        action: action,
        value: value
      }
    }
    setLoading(true);
    FetchService(config)
      .then(response => {
        processResponse(response);
      })
      .catch(err => {
        processResponse(err.cause.response);
        window.alert(`${err.cause.response.data.err} pizzas: ${err.cause.response.data.pizzas.failures}`);
      })
      .finally(() => {
        setLoading(false);
        setDisplayActionOptions(false);
        setDisplaySpecialtiesOptions(false);
        setDisplayStandardOptions(false);
        setCards(prevData => {
          return prevData.map((obj, index) => {
            return { ...obj, selected: false };
          });
        });
        setFilters(prevData => prevData.map(obj => { return { ...obj } }));
      });
  }, [cards, language])

  useEffect(() => {
    if (staffUser && cards.filter(card => card.selected).length > 0) setDisplayActionOptions(true);
    else {
      setDisplayActionOptions(false);
      setDisplaySpecialtiesOptions(false);
      setDisplayStandardOptions(false);
    }
  }, [cards, staffUser])

  useEffect(() => {
    setCards(prevData => {
      if (prevData === undefined) return;
      return prevData.map(obj => {
        for (let i = 0; i < filters.length; ++i) {
          if (!filters[i].eval(obj, filters[i].selection)) {
            return { ...obj, filtered: false, selected: false };
          }
        }
        return { ...obj, filtered: true, selected: false };
      })
    })
  }, [filters])

  return (
    <LoadingScreen loading={loading}>
      <div>
        {
          Object.keys(feedbackDialog).length !== 0 ? (
            <FeedbackDialog
              {...cardDetail}
              closeFeedbackDialog={closeFeedbackDialog}>
            </FeedbackDialog>
          ) : (
            null
          )
        }
        {
          displayCardDetail ? (
            <ModalWrapper
              allScreen={true}
              fitContent={true}
              display={displayCardDetail}
              callback={closeCardDetail}
              setDisplay={setDisplayCardDetail} >
              <CardDetail
                {...cardDetail}
                changeCard={changeCard}
                updatePizzaData={updatePizzaData}
                updatePizzaAttr={updatePizzaAttr}
                updateScoreDetails={updateScoreDetails}
                closeCardDetail={closeCardDetail}
                openFeebackDialog={staffUser ? openFeebackDialog : undefined}
                allowFeedback={staffUser}
                companyInfo={companyInfo}
                language={language}
                user={user} />
            </ModalWrapper>
          ) : (
            null
          )
        }
        {
          venues === undefined ? (
            <React.Fragment />
          ) : (
            <div>
              <Filters
                resetFilters={resetFilters}
                mustResetFilters={mustResetFilters}
                companies={companies}
                sortCallback={sortCards}
                cardsFilters={cardsFilters}
                addFilter={addFilter}
                whenSelectingDate={whenSelectingDate}
                selectedDate={selectedDate}
                whenSelectingVenue={whenSelectingVenue}
                selectedVenue={selectedVenue}
                venues={dashboardVenues}
                allowFeedback={staffUser}
                whenSelectingCompany={whenChangingCompany}
                selectedCompany={selectedCompany}
                actionOptions={actionOptions}
                user={user} />
              {
                cards.length > 0 ? (
                  <div className={style.summaryGrid}>
                    {
                      !user.daily_feed_only ? (
                        <Summary
                          scores={validScores}
                          cardsCount={cards.length}
                          censoredCount={censoredCount}
                          reviewedCount={reviewedCount}
                          pizzaCount={pizzaCount}
                          allowFeedback={staffUser}
                          summaryByReportSpecialties={summaryByReportSpecialties} />
                      ) : (
                        null
                      )
                    }
                    {
                      !user.daily_feed_only ? (
                        <div className={style.commentedFilter}>
                          <input type='checkbox' id='onlyCommentedPictures' onChange={() => changefilteredByComment()}></input>
                          <label htmlFor='onlyCommentedPictures'>Show only commented pictures</label>
                        </div>
                      ) : (
                        null
                      )
                    }
                    <div style={{ padding: '20px 50px 10px', display: 'flex', gap: '15px' }}>
                      {
                        displayActionOptions ? (
                          <Select
                            name='Actions'
                            optionsList={actionOptions}
                            multiple={false}
                            checkboxes={false}
                            callback={whenSelectingAction} />
                        ) : (
                          null
                        )
                      }
                      {
                        displaySpecialtiesOptions ? (
                          <Select
                            name='Specialties'
                            optionsList={CARDS_FILTERS['All specialties'].optionsList.map(specialty => { return { value: specialty.db_value, name: specialty.name, selected: specialty.name === props.specialty } })}
                            multiple={false}
                            checkboxes={false}
                            callback={whenSelectingAction} />
                        ) : (
                          null
                        )
                      }
                      {
                        displayStandardOptions ? (
                          <Select
                            name='Standard'
                            optionsList={standardOptions}
                            multiple={false}
                            checkboxes={false}
                            callback={whenSelectingAction} />
                        ) : (
                          null
                        )
                      }
                    </div>
                    <Grid
                      allowFeedback={staffUser}
                      callback={openCardDetail}
                      cards={cards}
                      filters={filters}
                      companyInfo={companyInfo}
                      markPizzaCard={markPizzaCard}
                      user={user} />
                  </div>
                ) : (
                  loading ? (
                    <div style={{ marginTop: '25px' }}></div>
                  ) : (
                    <div style={{ marginTop: '25px' }}>No data available</div>
                  )
                )
              }

            </div>
          )
        }
      </div>
    </LoadingScreen>
  )
}

export default Dashboard;
